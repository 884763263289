const { default: AlarmRule } = require('./../../eodvuecomponents/src/models/alarmRule');

module.exports = {
    routes: [
        {
            path: '/alarms',
            name: 'alarms',
            meta:{
                permissions:['alarms.read']
            },
            component: () => import('./views/index.vue')
        },
    ],
    search: AlarmRule.searchConfig,
}