import moment from 'moment';
import {RRule, RRuleSet, rrulestr} from 'rrule';
import _ from 'lodash';

export default class Availability {
    static ADVANCED_RECURRING_DAYS = [
        {
            value: RRule.MO,
            text: 'Ma'
        },
        {
            value: RRule.TU,
            text: 'Di'
        },
        {
            value: RRule.WE,
            text: 'Wo'
        },
        {
            value: RRule.TH,
            text: 'Do'
        },
        {
            value: RRule.FR,
            text: 'Vr'
        },
        {
            value: RRule.SA,
            text: 'Za'
        },
        {
            value: RRule.SU,
            text: 'Zo'
        },
    ]
    static ADVANCED_RECURRING_RULES = [{
            value: RRule.DAILY,
            text: 'dag'
        },
        {
            value: RRule.WEEKLY,
            text: 'week'
        },
        {
            value: RRule.MONTHLY,
            text: 'maand'
        },
        {
            value: RRule.YEARLY,
            text: 'jaar'
        },
    ]

    static BASIC_RECURRING_RULES = [
        {
            value: null,
            text: 'Niet herhalen'
        },
        {
            value: {
                freq: RRule.DAILY,
                interval: 1
            },
            text: 'Dagelijks'
        },
        {
            value: {
                freq: RRule.WEEKLY,
                interval: 1
            },
            text: 'Wekelijks'
        },
        {
            value: {
                freq: RRule.MONTHLY,
                interval: 1
            },
            text: 'Maandelijks'
        },
        {
            value: {
                freq: RRule.YEARLY,
                interval: 1
            },
            text: 'Jaarlijks'
        },
        {
            value: 'advanced',
            text: 'Anders...'
        },
    ]

    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }

    get timed() {
        return true;
    }

    get name(){
        return 'Beschikbaar';
    }

    get color() {
        return 'success lighten-1';
    }

    get start(){
        if(this.startDateTime){
            return moment(this.startDateTime).unix()*1000;
        }
        return null;
    }

    set start(val){
        this.startDateTime = moment(val).toISOString();
    }

    get end() {
        if (this.endDateTime) {
            return moment(this.endDateTime).unix() * 1000;
        }
        return null;
    }

    set end(val) {
        this.endDateTime = moment(val).toISOString();
    }

    _recurringFreq = null;
    set recurringFreq(val){
        this._recurringFreq = val;
    }

    get recurringFreq(){
        return this._recurringFreq;
    }

    _recurringAdvanced = {
        freq: RRule.WEEKLY,
        interval: 1,
        byweekday: [],
        ending: null,
        count: 1,
        until: moment().add(1, 'years').format('DD/MM/YYYY'),
        exceptionDates: []
    };
    get recurringAdvanced(){
        //Check if recurrence is advanced
        return this._recurringAdvanced;
    }

    set recurringAdvanced(val){
        this._recurringAdvanced = val;
    }

    _recurrence = null;
    get recurrence(){
        if(this.recurringFreq){
            let data = null;

            if(this.recurringFreq.value != 'advanced'){
                data = {
                    ...this.recurringFreq.value,
                    dtstart: moment(this.startDateTime).toDate()
                };
            }else{
                data = {
                    freq:  this.recurringAdvanced.freq,
                    interval: this.recurringAdvanced.interval,
                    dtstart: moment(this.startDateTime).toDate()
                };
    
                if(this.recurringAdvanced.ending == 'date'){
                    data.until = moment(this.recurringAdvanced.until, 'DD/MM/YYYY').toDate();
                }else if (this.recurringAdvanced.ending == 'count'){
                    data.count = this.recurringAdvanced.count;
                }

                if(this.recurringAdvanced.byweekday.length > 0){
                    data.byweekday = [];
                    for (let i = 0; i < this.recurringAdvanced.byweekday.length; i++) {
                        const dayIndex = this.recurringAdvanced.byweekday[i];
                        data.byweekday.push(Availability.ADVANCED_RECURRING_DAYS[dayIndex].value);
                    }
                }
            }

            const ruleSet = new RRuleSet();
            const rule = new RRule(data);
            ruleSet.rrule(rule);
            
            if(this.recurringAdvanced.exceptionDates && this.recurringAdvanced.exceptionDates[0]){
                for (let i = 0; i < this.recurringAdvanced.exceptionDates.length; i++) {
                    const date = this.recurringAdvanced.exceptionDates[i];
                    ruleSet.exdate(date);
                }
            }
            
            return ruleSet.toString();
        }
        
        return null;
        
    }

    set recurrence(val){

        if(val){
            const ruleSet = rrulestr(val, {forceset: true});
            const rule = ruleSet.rrules()[0];
            if(rule){
                if(rule.options.until == null && rule.options.count == null && rule.options.byweekday == null && rule.options.interval == 1){
                    this.recurringFreq = this.freqByValue(rule.options.freq);
                }else{
                    this.recurringFreq = this.freqByValue('advanced');
                }
            }else{
                this.recurringFreq = this.freqByValue(null);
            }
            
            if(rule){
                this.recurringAdvanced = {
                    freq: rule.options.freq,
                    interval: rule.options.interval,
                    byweekday: rule.options.byweekday,
                    ending: (rule.options.until?'date':(rule.options.count?'count':null)),
                    count: rule.options.count?rule.options.count:1,
                    until: rule.options.until?rule.options.until:moment().add(1, 'years').format('DD/MM/YYYY'),
                    exceptionDates: []
                };
            }else{
                this.recurringAdvanced = {
                    freq: null,
                    interval: 1,
                    byweekday: null,
                    ending: null,
                    count: null,
                    until: null,
                    exceptionDates: []
                };
            }

            if(ruleSet.exdates){
                this.recurringAdvanced.exceptionDates = ruleSet.exdates();
            }
            
        }

        this._recurrence = val;
    }
    contains(date){
        const ruleSet = rrulestr(this.recurrence, {forceset:true});
        const moment_date = moment(date);
        const moment_start = moment_date.clone().startOf('day');
        const moment_end = moment_date.clone().endOf('day');
        const dates = ruleSet.between(moment_start.toDate(), moment_end.toDate());

        for (let i = 0; i < dates.length; i++) {
            const available_date = dates[i];
            const start = moment(available_date);
            const moment_end_datetime = moment(this.endDateTime);
            const end = moment(start.format('YYYY-MM-DD')+' '+moment_end_datetime.format('HH:mm'));
            if(moment_date.isBetween(start, end, 'minutes', '[]')){
                return available_date;
            }
        }

        return false;
    }
    freqByValue(val){
        for (let i = 0; i < Availability.BASIC_RECURRING_RULES.length; i++) {
            const rule = Availability.BASIC_RECURRING_RULES[i];
            if(rule.value && rule.value.freq == val){
                return rule;
            }else if (rule.value == val){
                return rule;
            }
        }
        return null;
    }
    getFirstEventAfter(moment_start){
        const ruleSet = rrulestr(this.recurrence, {forceset:true});
        return ruleSet.after(moment_start.toDate());
    }
    getRecurringEvents(moment_start, moment_end){
        if(!this.recurrence){
            return null;
        }

        const recurringEvents = [];

        const availabilityStartDate = moment(this.startDateTime);
        const availabilityEndDate = moment(this.endDateTime);

        const ruleSet = rrulestr(this.recurrence, {forceset:true});
        const dates = ruleSet.between(moment_start.toDate(), moment_end.toDate());

        const duration = availabilityEndDate.diff(availabilityStartDate, 'milliseconds');

        for (let i = 0; i < dates.length; i++) {
            const moment_start = moment(this.startDateTime).tz('Europe/Brussels');
            const date = moment(dates[i]).set({
                hour: moment_start.hours(),
                minute: moment_start.minutes(),
                second: moment_start.seconds(),
            });

            if(date.isAfter(availabilityEndDate)){
                const start = date.clone();
                const end = date.add(duration, 'milliseconds').clone();
                let event = this.getSaveData();
                event.startDateTime = start.toISOString();
                event.endDateTime = end.toISOString();
                event.isOriginal = false;

                recurringEvents.push(new Availability(event));
            }

        }
        
        return recurringEvents;
    }

    getSaveData(){
        const fields = ['startDateTime', 'endDateTime', 'endTimeUnspecified', 'recurrence'];
        let data = {};

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            data[field] = this[field];
        }

        return data;
    }
}
