<template>
    <div>
        <v-row v-if="!isConnector()" class="pt-4">
            <v-col>
                <eod-text-field :rules="!isConnector() ? [val => rules.required(val, 'Voornaam')] : []" :value="value.firstName"
                    :label="'Voornaam' + (!isConnector() ? '*' : '')" outlined hide-details @input="setFirstName"></eod-text-field>
            </v-col>
            <v-col>
                <eod-text-field :rules="!isConnector() ? [val => rules.required(val, 'Familienaam')] : []" :value="value.lastName"
                    :label="'Familienaam' + (!isConnector() ? '*' : '')" outlined hide-details
                    @input="setLastName"></eod-text-field>
            </v-col>
            <v-col>
                <eod-text-field
                    :value="getAttribute('jobtitle')" label="Jobtitel" outlined hide-details
                    @input="val => setAttribute('jobtitle', val)"></eod-text-field>
            </v-col>
        </v-row>
        <v-row v-if="!isConnector()">
            <v-col>
                <eod-text-field :value="value.email" :label="'E-mailadres' + (!isConnector() ? '*' : '')"
                    :rules="[val => rules.required(val, 'E-mailadres')]" outlined hide-details name="email"
                    autocomplete="off" @input="val => setProperty('email', val)"></eod-text-field>
            </v-col>
            <v-col>
                <eod-text-field :disabled="value.id ? true : (isGuest() && !usernameExists)" :value="value.username"
                    label="Gebruikersnaam" :rules="value.id ? [] : [val => rules.required(val, 'Gebruikersnaam')]" outlined
                    hide-details name="username" autocomplete="off"
                    @input="val => setProperty('username', val)"></eod-text-field>
            </v-col>
        </v-row>
        <v-row v-if="!isConnector()">
            <v-col>
                <eod-text-field :value="getAttribute('phone_number')" label="Telefoon" outlined hide-details
                    @input="val => setAttribute('phone_number', val)"></eod-text-field>
            </v-col>
            <v-col>
                    <eod-text-field :value="getAttribute('extra_phone')" label="Extra telefoon" outlined hide-details
                    @input="val => setAttribute('extra_phone', val)"></eod-text-field>
                </v-col>
        </v-row>
        <v-row v-if="isConnector()">
            <v-col>
                <eod-text-field :value="value.name" label="Naam*" outlined hide-details
                    :rules="[val => rules.required(val, 'Naam')]" @input="val => setProperty('name', val)"></eod-text-field>
            </v-col>
            <!--<v-col class="d-flex align-items-center">
                <v-checkbox class="mt-0" :value="value.isSuper" @change="val => setProperty('isSuper', val)"
                    label="Is super" hide-details></v-checkbox>
            </v-col>-->
        </v-row>
        <v-row v-if="role && value.assignedRealmRoles">
            <v-col>
                <v-select @change="val => setRole(val)" outlined hide-details :rules="[val => rules.required(val, 'Rol')]"
                    label="Rol*" :items="rolesList" :item-text="getRoleName" return-object
                    :value="value.assignedRealmRoles[0]"></v-select>
            </v-col>
        </v-row>
        <v-row v-if="!isGuest() || value.id">
            <v-col>
                <eod-text-field :value="value.credentials.value" :label="'Wachtwoord'+(value.id?'':'*')"
                    :rules="value.id ? [passwordMatch] : [val => rules.required(val, 'Wachtwoord'), passwordMatch]"
                    :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" outlined hide-details name="password"
                    autocomplete="new-password" @input="val => setPassword(val)"></eod-text-field>
            </v-col>
            <v-col>
                <eod-text-field :label="'Herhaal wachtwoord'+(value.id?'':'*')"
                    :rules="value.id ? [passwordMatch] : [val => rules.required(val, 'Herhaal wachtwoord'), passwordMatch]"
                    :type="showRepeatPassword ? 'text' : 'password'"
                    @click:append="showRepeatPassword = !showRepeatPassword"
                    :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'" outlined hide-details
                    autocomplete="new-password"></eod-text-field>

            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <eod-textarea :value="getAttribute('notes')" label="Notities" @input="val => setAttribute('notes', val)" info="Informatieve gegevens die aan het gebruikersprofiel worden gekoppeld"></eod-textarea>
            </v-col>
            <v-col v-if="isConnector()">
                <v-textarea :value="getAttribute('description')" label="Omschrijving" outlined hide-details
                    @input="val => setAttribute('description', val)"></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        value: Object,
        role: {
            default: false,
            type: Boolean
        },
        usernameExists: {
            default: false,
            type: Boolean
        },
        roles: Array
    },
    data: () => ({
        showBirthdatePickerMenu: false,
        rolesList: [],
        showRepeatPassword: false,
        showPassword: false,
        rules: {
            required: (value, fieldName) => !!value || (fieldName ? fieldName : 'Veld') + ' is verplicht.',
        },
    }),
    created() {
        if (this.role) {
            this.$eod.getRoles().then(result => {
                if (this.roles) {
                    let count = result.length;
                    for (let i = 0; i < count; i++) {
                        if (this.roles.includes(result[i].name)) {
                            this.rolesList.push(result[i]);
                        }
                    }
                } else {
                    let settings = require('./../classes/settings');
                    for (let i = 0; i < result.length; i++) {
                        if (!settings.roles.exception.includes(result[i].name) && !settings.roles.eod.includes(result[i].name) && !settings.roles.anonymous.includes(result[i].name)) {
                            this.rolesList.push(result[i]);
                        }
                    }
                }

                if (this.rolesList[0] && (!this.value.assignedRealmRoles || !this.value.assignedRealmRoles[0])) {
                    this.setRole(this.rolesList[0]);
                }
            });
        } else if (this.roles && (!this.value.assignedRealmRoles || !this.value.assignedRealmRoles[0])) {
            this.setRole({ name: this.roles[0] });
        }
    },
    watch: {
        showBirthdatePickerMenu(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    },
    methods: {
        isConnector() {
      if (this.value && this.value.assignedRealmRoles) {
        for (let i = 0; i < this.value.assignedRealmRoles.length; i++) {
          const role = this.value.assignedRealmRoles[i];
          if(role.name == 'connector'){
            return true;
          }
        }
      }
      
      return false;
    },
    isGuest() {
      
      if (this.value && this.value.assignedRealmRoles) {
        for (let i = 0; i < this.value.assignedRealmRoles.length; i++) {
          const role = this.value.assignedRealmRoles[i];
          if(role.name == 'contributor' || role.name == 'responsible'){
            return true;
          }
        }
      }

      return false;
    },
        getRoleName(role) {
            return this.$t(role.name);
        },
        passwordMatch(value) {
            return this.value.credentials.value == value || 'Beide wachtwoorden komen niet overeen.';
        },
        setFirstName(val) {
            if (!this.value.id) {
                this.setProperty('username', (val + '.' + (this.value.lastName?this.value.lastName:'')).toLowerCase());
            }

            this.setProperty('firstName', val);
        },
        setLastName(val) {
            if (!this.value.id) {
                this.setProperty('username', ((this.value.firstName?this.value.firstName:'') + '.' + val).toLowerCase());
            }

            this.setProperty('lastName', val);
        },
        setPassword(value) {
            let item = this.value;

            item.credentials = {
                temporary: false,
                type: 'password',
                value: value
            }

            this.$emit('input', item);
        },
        saveBirthdate(date) {
            this.$refs.menu.save(date)
            this.setAttribute('birthdate', date);
        },
        setProperty(propertyName, value) {
            let item = this.value;
            item[propertyName] = value;
            this.$emit('input', item);
            this.$nextTick(this.$forceUpdate);
        },
        setRole(value) {
            let item = this.value;
            if(!item.assignedRealmRoles){
                item.assignedRealmRoles = [];
            }
            item.assignedRealmRoles[0] = value;

            this.$emit('input', item);

            this.$nextTick(this.$forceUpdate);
        },
        getAttribute(attrName){
            if(this.value.attributes && this.value.attributes[attrName]){
                return this.value.attributes[attrName][0];
            }

            return null;
        },
        setAttribute(attrName, value) {
            let item = this.value;

            if(!item.attributes){
                item.attributes = {};
            }

            if(!item.attributes[attrName]){
                item.attributes[attrName] = [];
            }

            item.attributes[attrName][0] = value;
            this.$emit('input', item);
        }
    },
}
</script>
