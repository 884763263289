let users = require('./users');
let teams = require('./teams');
let settings = require('./settings');
let integrations = require('./integrations');

module.exports = {
    routes: [
    {
        path: '/organization/general',
        name: 'organization',
        component: () => import('./views/detail.vue'),
        meta:{
            permissions:['organization.settings.read']
        },
    },
    ...users.routes,
    ...teams.routes,
    ...settings.routes,
    ...integrations.routes
    ],
    detail: {
        route: 'organization',
        endpoint: 'organization',
        objectName: 'Organization',
        fields: ['id', 'name', 'description', 'configuration{version settings{key value label isDefault isRoot}}'],
        tabs: [{
                path: 'organization',
                text: 'Algemeen'
            },
            {
                path: 'organizationUsers',
                text: 'Medewerkers'
            },
            {
                path: 'organizationContacts',
                text: 'Contacten'
            },
            {
                path: 'organizationConnectors',
                text: 'Data connectors'
            },
            {
                path: 'Teams',
                text: 'Teams'
            },
            {
                path: 'organizationIntegrations',
                text: 'Koppelingen'
            },
            {
                path: 'organizationSettings',
                text: 'Instellingen',
                permissions: ['organization.settings.read']
            }
        ],
    },
    users: users,
    teams: teams,
    settings: settings,
    integrations: integrations,
}