
class Toaster{
    defaults = {
        theme: {
            success: '#50e67b',
            warning: '#e69650',
            danger: '#e65050',
            info: '#2356ff',
        },
        type: 'info',
        icon: true,
        title: null,
        body: null,
        timeout: 2000,
        transition: 1
    }
    app = null
    constructor(options) {
       this._mergeDeep(this.defaults, options);
    }
    notify(options){

        let data = this._mergeDeep({...this.defaults}, options);        
        let color = data.theme[data.type];

        let html = '<div style="margin-bottom:10px;box-shadow: 0px 0px 41px -19px rgba(0,0,0,0.75);padding: 15px 25px;background-color:#ffffff;border-left:5px solid ' + color + ';display:flex;flex-direction:row;min-width:300px;max-width:500px;border-radius:4px;transition: '+data.transition+'s all;opacity:0;right:-600px;position:relative;">';

        if (data.icon) {
            let icon = '<div style="margin-right:25px;"><div style="width:40px;height:40px;text-align:center;line-height:40px;background-color:' + color + ';border-radius:50%;overflow:hidden;">';

            if (data.type == 'success') {
                icon += '<i class="v-icon mdi mdi-check" style="color:#ffffff;"></i>';
            } else if (data.type == 'warning') {
                icon += '<i class="v-icon mdi mdi-exclamation-thick" style="color:#ffffff;"></i>';
            } else if (data.type == 'danger') {
                icon += '<i class="v-icon mdi mdi-cancel" style="color:#ffffff;"></i>';
            } else {
                icon += '<i class="v-icon mdi mdi-information-variant" style="color:#ffffff;"></i>';
            }

            icon += '</div></div>';

            html += icon;
        }

        if (data.title || data.body){
            html += '<div style="flex-grow:1;">';

            if (data.title) {
                html += '<div style="font-size:18px;font-weight:bold;color: #444444;">' + data.title + '</div>';
            }

            if (data.body) {
                html += '<div style="font-size:12px;color: #888888;">' + data.body + '</div>';
            }

            html += '</div>';
        }
        
        html += '</div>';

        let notification = document.createElement('div');
        notification.innerHTML = html;
    
        let wrapper = this._getWrapper();
        notification = notification.firstChild;
        wrapper.appendChild(notification);

        setTimeout(() => {
            notification.style.right = '0px';
            notification.style.opacity = '1';

            setTimeout(() => {
                notification.style.right = '-600px';
                notification.style.opacity = '0';

                setTimeout(() => {
                    wrapper.removeChild(notification);
                    if (wrapper.childElementCount <= 0) {
                        document.getElementById('app').removeChild(wrapper);
                    }
                }, data.transition*1000);
            }, (data.transition * 1000) + data.timeout);
        }, 100);
    }

    _getWrapper(){
        if (!document.getElementById('toasterWrapper')) {
            let wrapperHtml = '<div id="toasterWrapper" style="position:fixed;right:16px;top:80px;z-index: 999999;"></div>';
            let wrapper = document.createElement('div');
            wrapper.innerHTML = wrapperHtml;
            document.getElementById('app').appendChild(wrapper.firstChild);
        }

        return document.getElementById('toasterWrapper');
    }

    _isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    _mergeDeep(target, ...sources) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (this._isObject(target) && this._isObject(source)) {
            for (const key in source) {
                if (this._isObject(source[key])) {
                    if (!target[key]) Object.assign(target, {
                        [key]: {}
                    });
                    this._mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, {
                        [key]: source[key]
                    });
                }
            }
        }

        return this._mergeDeep(target, ...sources);
    }
}

export default Toaster;