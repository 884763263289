<template>
    <eod-dialog width="800" :value="value" @input="val => $emit('input', val)" title="Project toegang" icon="mdi-folder-open-outline" iconColor="primary">
        <eod-contacts class="mt-4" v-if="project" @add="showOptions = true" v-model="project.users" :roles="['contributor', 'responsible']" :import-show="false" :export-show="false"></eod-contacts>
        <template v-slot:footer>
            <div class="w-100 px-4 grey lighten-4 py-2" v-if="showOptions">
                <v-checkbox class="my-0" v-model="options.mailProjectAccess" hide-details label="Nieuwe leden laten weten dat ze zijn toegevoegd aan het project"></v-checkbox>
                <eod-textarea rows="3" class="white my-2" v-if="options.mailProjectAccess" v-model="options.mailProjectAccessText" info="Bericht om mee te sturen met de e-mail." placeholder="Extra info (optioneel)"></eod-textarea>
                <v-checkbox class="my-0" v-model="options.addToCriticalAlarms" hide-details label="Nieuwe leden automatisch meldingen geven bij een kritiek alarm voor dit project"></v-checkbox>
            </div>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn text rounded @click="$emit('input', false)">
                Sluiten
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded @click="saveUsers">
                <v-icon left>mdi-content-save</v-icon>Opslaan
            </v-btn>
        </template>
    </eod-dialog>
</template>
    <script>
    import eodDialog from './eod-dialog';
    import eodContacts from './eod-contacts.vue';
    import Project from '../models/project';
    import User from '../models/user';
    
    export default{
        name: 'eod-project-access-dialog',
        components:{
            eodDialog,
            eodContacts
        },
        props:{
            value: Boolean,
            projectId: String
        },
        data(){
            return {
                loading: false,
                initialUsers:null,
                project:null,
                showOptions:false,
                options: {
                    mailProjectAccess: true,
                    mailProjectAccessText: null,
                    addToCriticalAlarms: true,
                }
            }
        },
        mounted(){
            this.init();
        },
        methods:{
            init(){
                // Get project users
                this.$eod.getById('project', this.projectId, ['id', 'name', 'users{id}']).then(response => {
                    if(response.data.data.project){
                        this.project = response.data.data.project;
                        this.initialUsers = this.project.users.map((item) => {return item.id});
                    }
                })
            },
            async saveUsers(){

                this.loading = true;
            
                // Check if added users need e-mail or alarm notifications 
                for (let i = 0; i < this.project.users.length; i++) {
                    const newUser = new User(this.project.users[i]);
                    if(!this.initialUsers.includes(newUser.id)){
                        if(this.options.mailProjectAccess){
                            await this.$eod.sendMail([newUser.email], 'ProjectAccessMail', {
                                project_id: this.projectId,
                                user_id: newUser.id,
                                text: this.options.mailProjectAccessText
                            });
                        }

                        if(this.options.addToCriticalAlarms){
                            if(!newUser.notificationSettings){
                                newUser.notificationSettings = {};
                            }

                            if(!newUser.notificationSettings.notifications){
                                newUser.notificationSettings.notifications = {};
                            }

                            newUser.notificationSettings.notifications.crit = ['email'];

                            if(!newUser.notificationSettings.alarms){
                                newUser.notificationSettings.alarms = [];
                            }

                            newUser.notificationSettings.alarms.push({
                                id: this.projectId,
                                class: 'PROJECT',
                                module: 'projects',
                            });

                            const data = await newUser.getSaveData();
                            await this.$eod.save('User', data);
                        }
                    }
                }

                // Save project
                const project = new Project(this.project);
                const data = await project.getSaveData();
                await this.$eod.save('Project', data);

                this.loading = false;

                this.$emit('input', false);
            }
        }
    }
    </script>