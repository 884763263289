<template>
    <div class="h-100 d-flex direction-column">
        <div style="margin:auto 0;">
            <div>
                <div>
                    <template v-for="(tile, index) in tiles">
                        <hr :key="'border_' + index" v-if="index > 0" style="border:none;border-top: 1px solid #DDDDDD;" />
                        <div :key="'tile_' + index" class="py-1">
                            <div class="tile-value">
                                <v-avatar v-if="tile.color && tile.icon" class="mr-2" :color="tile.color" size="30"><v-icon v-if="tile.icon" v-text="'mdi mdi-'+tile.icon"></v-icon></v-avatar>
                                <v-icon class="mr-2" v-else-if="tile.icon" v-text="'mdi mdi-'+tile.icon"></v-icon>
                                <v-avatar v-else-if="tile.color" class="mr-2" :color="tile.color" size="14"></v-avatar>
                                {{ getValue(value[index], tile) }}
                                <v-btn v-if="showSetValueButton() && $eod.getUser().can('requests.create')" @click="showDialog(tile)" small
                    icon><v-icon small>mdi-pencil</v-icon></v-btn>
                <template v-if="showSetValueButton() && $eod.getUser().can('requests.read')">
                    <v-dialog v-if="requests && requests[0]" v-model="historyDialog" max-width="600">
                        <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
                            <v-menu open-on-hover offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip class="ml-2" v-on="{ ...on, ...dialogOn }" v-bind="{ ...attrs, ...dialogAttrs }"
                                        small v-if="requests[0]" :color="requests[0].getStatusCode().color"
                                        :dark="requests[0].getStatusCode().textColor != 'dark'"><v-icon small
                                            left>mdi-history</v-icon><span><strong>{{ requests[0].value
                                            }}</strong></span></v-chip>
                                </template>
                                <v-card>
                                    <div class="px-4 py-2"
                                        :style="'background-color:' + requests[0].getStatusCode().color + ';'"
                                        :class="requests[0].getStatusCode().textColor == 'dark' ? '' : 'white--text'">
                                        <v-icon :dark="requests[0].getStatusCode().textColor != 'dark'" small left>{{
                                            requests[0].getStatusCode().icon }}</v-icon>{{ requests[0].getStatusCode().text}}
                                    </div>
                                    <v-card-text>
                                        <div><v-icon small left>mdi-clock-outline</v-icon>{{
                                            $moment(requests[0].createdAt).format('DD/MM HH: mm')}}</div>
                                        <div v-if="requests[0].getUser()"><v-icon small left>mdi-account-outline</v-icon>{{
                                            requests[0].getUser().getFullName() }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </template>
                        <v-card>
                            <v-card-title class="mb-4">
                                <v-avatar color="primary" size="40" class="elevation-3 mr-4">
                                    <v-icon dark>
                                        mdi-history
                                    </v-icon>
                                </v-avatar><span class="headline">Historiek</span>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table hide-default-footer :items="requests"
                                    :headers="[{ value: 'createdAt', text: 'Tijdstip' }, { value: 'user', text: 'Gebruiker' }, { value: 'statusCode', text: 'Status' }, { value: 'value', text: 'Waarde' }]">
                                    <template v-slot:item.createdAt="{ item }">
                                        {{ $moment(item.createdAt).format('DD/MM HH:mm') }}
                                    </template>
                                    <template v-slot:item.user="{ item }">
                                        {{ item.getUser()?item.getUser().getFullName():null }}
                                    </template>
                                    <template v-slot:item.statusCode="{ item }">
                                        <v-chip small :color="item.getStatusCode().color"
                                            :dark="item.getStatusCode().textColor != 'dark'"><v-icon small left>{{
                                                item.getStatusCode().icon }}</v-icon>{{ item.getStatusCode().text}}</v-chip>
                                    </template>
                                    <template v-slot:item.value="{ item }">
                                        {{ item.value }}
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions class="grey lighten-3 py-3 px-3">
                                <v-spacer></v-spacer>
                                <v-btn text @click="historyDialog = false; $forceUpdate();">Sluiten</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
                            </div>
                            <v-menu open-on-hover v-if="(tile.time_interval && getShowField('timestamp')) || (tile.measurement_resolved && getShowField('measurementType')) || (tile.product_id_resolved && getShowField('product')) || (tile.owner_id_resolved && getShowField('ownerName')) || (tile.owner_id_resolved && getShowField('ownerDescription'))">
                                <template v-slot:activator="{on, attrs}">
                                    <v-chip v-on="on" v-bind="attrs" small color="grey lighten-4">
                                        <template v-if="tile.time_interval && getShowField('timestamp')">
                                            <v-icon small left>mdi-clock-outline</v-icon>
                                            <span>{{ $moment(tile.time_interval).format('DD-MM HH:mm')}}</span>
                                        </template>
                                        <template v-else-if="tile.measurement_resolved && getShowField('measurementType')">
                                            <v-icon small left>mdi-waveform</v-icon>
                                            <span>{{ tile.measurement_resolved.name }}</span>
                                        </template>
                                        <template v-else-if="tile.product_id_resolved && getShowField('product')">
                                            <v-icon small left>mdi-package-variant</v-icon>
                                            <span>{{ tile.product_id_resolved.name }}</span>
                                        </template>
                                        <template v-else-if="tile.owner_id_resolved && getShowField('ownerName')">
                                            <v-icon small left>mdi-account-outline</v-icon>
                                            <span>{{ getUserName(tile.owner_id_resolved) }}</span>
                                        </template>
                                        <template v-else-if="tile.owner_id_resolved && getShowField('ownerDescription')">
                                            <v-icon small left>mdi-account-outline</v-icon>
                                            <span>{{ tile.owner_id_resolved.attributes.description[0] }}</span>
                                        </template>
                                    </v-chip>
                                </template>
                                <v-card>
                                    <v-card-text>
                                    <div v-if="tile.time_interval && getShowField('timestamp')"><v-icon small left>mdi-clock-outline</v-icon> {{ $moment(tile.time_interval).format('DD-MM HH:mm') }}</div>
                                    <div v-if="tile.measurement_resolved && getShowField('measurementType')"><v-icon small left>mdi-waveform</v-icon> {{ tile.measurement_resolved.name }}</div>
                                    <div v-if="tile.product_id_resolved && getShowField('product')"><v-icon small left>mdi-package-variant</v-icon> {{ tile.product_id_resolved.name }}</div>
                                    <div v-if="tile.owner_id_resolved && getShowField('ownerName')"><v-icon small left>mdi-account-outline</v-icon> {{ getUserName(tile.owner_id_resolved) }}</div>
                                    <div v-if="tile.owner_id_resolved && tile.owner_id_resolved.attributes && tile.owner_id_resolved.attributes.description && tile.owner_id_resolved.attributes.description[0] && getShowField('ownerDescription')"><v-icon small left>mdi-account-outline</v-icon> {{ tile.owner_id_resolved.attributes.description[0] }}</div>
                                </v-card-text>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>
                </div>
                
                <v-dialog v-if="showSetValueButton()" v-model="showSetValueDialog" max-width="600">
                    <v-card>
                        <v-card-title class="mb-4">
                            <v-avatar color="primary" size="40" class="elevation-3 mr-4">
                                <v-icon dark>
                                    mdi-pencil
                                </v-icon>
                            </v-avatar><span class="headline">Waarde instellen</span>
                        </v-card-title>
                        <v-form ref="formNewValue">
                        <v-card-text>
                            <eod-object-autocomplete v-if="!getRequestSettings(cell.settings).project_id" class="mb-4"
                                label="Project" endpoint="projects" v-model="newValue.projectId"></eod-object-autocomplete>
                            <eod-object-autocomplete v-if="!getRequestSettings(cell.settings).job_type_id" class="mb-4" label="Jobtype"
                                endpoint="jobTypes" v-model="newValue.jobTypeId"></eod-object-autocomplete>
                            <eod-objects-treeview-input v-if="!getRequestSettings(cell.settings).product_id" class="mb-4" label="Toestel" :selectable="['DEVICE']" v-model="newValue.productId"></eod-objects-treeview-input>
                            <eod-user-selector v-if="!getRequestSettings(cell.settings).owner_id" class="mb-4" label="Eigenaar" dense
                                v-model="newValue.owner_id"></eod-user-selector>
                            <eod-text-field label="Waarde" :rules="[val => $validator.required(val), rules.range]" v-if="isRange(newValue.measurementType)" :min="getRange(newValue.measurementType).low" :max="getRange(newValue.measurementType).high" :step="getRange(newValue.measurementType).stepSize" type="number" required v-model="newValue.value"></eod-text-field>
                            <template v-else-if="isToggle(newValue.measurementType)">
                                <v-btn-toggle rounded color="primary" v-model="newValue.value">
                                    <v-btn v-for="listItem in Object.values(newValue.measurementType.valueItems)" :key="listItem.id" :value="listItem.threshold">
                                       {{listItem.name}}
                                    </v-btn>
                                </v-btn-toggle>
                            </template>
                            <v-autocomplete dense label="Waarde"
                                v-else-if="isList(newValue.measurementType)"
                                v-model="newValue.value" :items="Object.values(newValue.measurementType.valueItems)"
                                item-value="threshold" item-text="name" outlined hide-details></v-autocomplete>
                            <div v-else class="d-flex">
                                <eod-text-field label="Waarde" v-model="newValue.value"
                                    type="number" step="0.01" :rules="[val => $validator.required(val), val => $validator.min(val, getMinValue(newValue)), val => $validator.max(val, getMaxValue(newValue))]"></eod-text-field>
                                <div class="grey lighten-3 px-4 d-flex justify-content-center align-items-center"
                                    v-if="newValue.measurementType && newValue.measurementType.unitFields && newValue.measurementType.unitFields.Symbol">
                                    {{ newValue.measurementType.unitFields.Symbol }}</div>
                            </div>
                        </v-card-text>
                        <v-card-actions class="grey lighten-3 py-3 px-3">
                            <v-spacer></v-spacer>
                            <v-btn text @click="showSetValueDialog = false">Annuleren</v-btn>
                            <v-btn :loading="setValueLoading"
                                :disabled="!(newValue.value && newValue.value != '' && newValue.jobTypeId && newValue.productId && newValue.ownerId && newValue.projectId)"
                                class="pl-4" rounded depressed color="secondary" @click="sendValue">Instellen <v-icon small
                                    right>mdi-pencil</v-icon></v-btn>
                        </v-card-actions>
                    </v-form>
                    </v-card>
                </v-dialog>
            </div>
            <!--<div style="width:200px;height:60%" v-if="tiles.length == 1">
                <eod-graph-line v-if="tiles[0]" ref="chart" group="eod-graph-tile" :id="graphId" :options="options"
                    :defaultSerie="serie" :value="graphValue" :template="template"></eod-graph-line>
            </div>-->
            <div v-if="!tiles[0]">Geen resultaten gevonden.</div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.tile-value {
    font-size: 1.4em;
    padding: 5px;
}
</style>
<script>
import eodGraphLine from './eod-graph-line.vue';
import eodObjectAutocomplete from './../eod-object-autocomplete.vue';
import eodObjectsTreeviewInput from './../eod-objects-treeview-input.vue';
import eodUserSelector from './../eod-user-selector.vue';
import { v4 as uuidv4 } from 'uuid';
import Request from './../../models/request';
import User from './../../models/user';

export default {
    name: 'eod-graph-tile',
    components: {
        eodGraphLine,
        eodObjectAutocomplete,
        eodObjectsTreeviewInput,
        eodUserSelector
    },
    props: {
        id: String,
        value: Object | Array,
        template: Object | Array,
        cell: Object | Array,
        variables: Object,
    },
    data: function () {
        return {
            rules: {
                range: v => {
                    const range = this.getRange(this.newValue.measurementType);
                    const number = parseFloat(v);
                    if(number >= range.low && number <= range.high && number % range.stepSize == 0){
                        return true;
                    }
                    return 'Waarde moet tussen '+range.low+' en '+ range.high+' liggen met stappen van '+range.stepSize+'!';
                }
            },
            graphId: uuidv4(),
            tiles: [],
            historyDialog: false,
            query: null,
            graphValue: [],
            lastRequest: null,
            showSetValueDialog: false,
            setValueLoading: false,
            requests: [],
            settings: require('./../../classes/settings'),
            newValue: {
                serie: null,
                tile: null,
                valueItems: null,
                value: null
            },
            options: {
                color: ['#cccccc'],
                toolbox: {
                    show: false,
                },
                tooltip: {
                    show: false
                },
                grid: {
                    top: '1px',
                    left: '1px',
                    right: '1px',
                    bottom: '1px',
                    containLabel: false,
                    borderWidth: 0,
                },
                xAxis: {
                    type: 'time',
                    show: false
                },
                yAxis: {
                    show: false
                },
            },
            serie: {
                type: 'line',
                smooth: true,
                lineStyle: {
                    width: 2,
                },
                data: [],
                showSymbol: false,
            }
        }
    },
    methods: {
        getMinValue(newVal){
            if(this.cell && this.cell.settings && this.cell.settings.request && this.cell.settings.request.min != '' && !isNaN(this.cell.settings.request.min)){
                return this.cell.settings.request.min;
            }

            if(newVal.measurementType && newVal.measurementType.typeConfig && newVal.measurementType.typeConfig.min != null){
                return newVal.measurementType.typeConfig.min;
            }

            return null;
        },
        getMaxValue(newVal){

            console.log(newVal.measurementType, this.cell.settings.request);

            if(this.cell && this.cell.settings && this.cell.settings.request && this.cell.settings.request.max != '' && !isNaN(this.cell.settings.request.max)){
                return this.cell.settings.request.max;
            }

            if(newVal.measurementType && newVal.measurementType.typeConfig && newVal.measurementType.typeConfig.max != null){
                return newVal.measurementType.typeConfig.max;
            }

            return null;
        },
        getShowField(fieldName){
            if(!this.cell){
                return true;
            }

            if(!this.cell.settings){
                return true;
            }

            if(!this.cell.settings.fields){
                return true;
            }

            if(this.cell.settings.fields.includes(fieldName)){
                return true;
            }

            return false;
        },
        getUserName(usr){
            return (new User(usr)).getFullName();
        },
        isToggle(measurementType){
            if(measurementType && measurementType.valueItems && Object.values(measurementType.valueItems).length == 2){
                for (let i = 0; i < Object.values(measurementType.valueItems).length; i++) {
                    const listItem = Object.values(measurementType.valueItems)[i];
                    // If name is too long, show in dropdown
                    if(listItem.name.length > 30){
                        return false;
                    }
                }
                return true;
            }

            return false;
        },
        isList(measurementType){
            if(measurementType && measurementType.valueItems && Object.keys(measurementType.valueItems).length > 0){
                return true;
            }

            return false;
        },
        getRange(measurementType){
            const firstListItem = Object.values(measurementType.valueItems)[0];
            return firstListItem;
        },
        isRange(measurementType){
            if(measurementType && measurementType.valueConfigList && measurementType.valueConfigList.itemType == 'RANGE' && measurementType.valueItems && Object.keys(measurementType.valueItems).length > 0){
                return true;
            }

            return false;
        },
        getValue(serie, tile) {

            if(!serie){
                return '-';
            }

            let val = '';

            if(this.cell){
                const settings = this.cell.settings.queries[serie.queryIndex];
                if (settings && settings.view == 'timestamp') {
                    val += this.$moment(tile.time_interval).locale('nl').fromNow();
                } else {
                    val += tile.value;
                    if (!this.hasListItem(serie, tile) && tile.unit && tile.unit.Symbol) {
                        val += ' '+tile.unit.Symbol;
                    }
                }
            }else{
                val += tile.value;
                if (!this.hasListItem(serie, tile) && tile.unit && tile.unit.Symbol) {
                    val += ' '+tile.unit.Symbol;
                }
            }

            

            return val;
        },
        getRequestSettings(settings){
            if(settings.request){
                if(!settings.request.isWritable){
                    return null;
                }

                return this.resolveRequestParams(settings.request);
            }else if(settings.queries[0]){
                if(!settings.queries[0].isWritable){
                    return null;
                }

                return this.resolveRequestParams(settings.queries[0]);
            }
        },
        resolveRequestParams(settings){
            if(!settings){
                return settings;
            }

            settings = {...settings};

            for(const key in settings){
                const value = settings[key];

                if(value && value.isVariable){
                    if(this.variables && this.variables[value.id] && this.variables[value.id].id){
                        settings[key] = this.variables[value.id].id;
                    }else{
                        settings[key] = null;
                    }
                }
            }

            return settings;
        },
        async getRequests(amount) {

            const {owner_id, project_id, product_id, job_type_id} = this.resolveRequestParams(this.cell.settings.request?this.cell.settings.request:this.cell.settings.queries[0]);
            const measurementType = await this.getMeasurementType();

            let filter = {
                orderBy: {
                    column: 'createdAt',
                    type: 'DESC'
                },
                limit: amount,
                offset: 0,
                where: [
                    {
                        column: 'measurementTypeId',
                        operator: '=',
                        value: measurementType.id
                    }
                ]
            };

            if (owner_id) {
                filter.where.push({
                    column: 'ownerId',
                    operator: '=',
                    value: owner_id
                });
            }

            if (job_type_id) {
                filter.where.push({
                    column: 'jobTypeId',
                    operator: '=',
                    value: job_type_id
                });
            }

            if (project_id) {
                filter.where.push({
                    column: 'projectId',
                    operator: '=',
                    value: project_id
                });
            }

            if (product_id) {
                filter.where.push({
                    column: 'productId',
                    operator: '=',
                    value: product_id
                });
            }

            return this.$eod.get('requests', ['id', 'value', 'statusCode', 'createdAt', 'user{id firstName lastName username}'], filter);
        },
        async getMeasurementType() {
            return new Promise((resolve, reject) => {
                if(this.value && this.value[0]){
                    const measurementType = Object.values(this.value[0].measurementTypes)[0];
                    resolve({
                        ...measurementType,
                        valueItems: this.$eod.getMeasurementTypeItems(measurementType),
                        unitFields: this.$eod.getMeasurementTypeUnitFields(measurementType)
                    });
                }else{
                    if(this.template && this.template[0]){
                        const templateMeasurementType = this.template[0].content.measurementTypes[0].object;
                        this.$eod.getById('measurementType', templateMeasurementType.id, ['id', 'name', 'typeConfig', 'valueConfigList{id itemType listItems{id name description isActive op low high threshold stepSize}}', 'valueConfigListItems{id name description isActive op low high threshold stepSize}']).then(response => {
                            if(response.data.data.measurementType){
                                const measurementType = response.data.data.measurementType;
                                resolve({
                                    ...measurementType,
                                    valueItems: this.$eod.getMeasurementTypeItems(measurementType),
                                    unitFields: this.$eod.getMeasurementTypeUnitFields(measurementType)
                                });
                            }else{
                                resolve(null);
                            }
                        }).catch(() => {
                            resolve(null);
                        });
                    }else{
                        resolve(null);
                    }
                }
            });
        },
        async showDialog(tile) {
            
            const {owner_id, project_id, product_id, job_type_id} = this.resolveRequestParams(this.cell.settings.request?this.cell.settings.request:this.cell.settings.queries[0]);
            const measurementType = await this.getMeasurementType();

            // When toggle button, select current value
            let val = null;
            if(this.isToggle(measurementType)){
                val = tile.rawValue;
            }
            
            
            this.newValue = {
                value: val,
                measurementType: measurementType,
                ownerId: owner_id,
                projectId: project_id,
                productId: product_id,
                jobTypeId: job_type_id,
            };

            this.showSetValueDialog = true;
        },
        showSetValueButton() {

            if(this.cell && this.cell.settings && this.getRequestSettings(this.cell.settings)){
                return true;
            }

            return false;
        },
        sendValue() {
            if(!this.$refs.formNewValue){
                this.$toaster.notify({
                        type: 'danger',
                        title: this.$t('Error'),
                        body: this.$t('Fout bij het versturen van de nieuwe waarde')
                    });
                return false;
            }

            if(!this.$refs.formNewValue.validate()){
                this.$toaster.notify({
                        type: 'danger',
                        title: this.$t('Error'),
                        body: this.$t('Ongeldige waarde!')
                    });
                    return false;
            }

            this.setValueLoading = true;

            let requestData = {
                jobTypeId: this.newValue.jobTypeId,
                ownerId: this.newValue.ownerId,
                projectId: this.newValue.projectId,
                userId: this.$eod.getUser().id,
                statusCode: 'to_do',
                timestamp: this.$moment().unix(),
                measurementTypeId: this.newValue.measurementType.id,
                value: '' + this.newValue.value,
                productId: this.newValue.productId,
                type: 'DCMD'
            }

            this.$eod.save('Request', requestData).then(response => {
                if (response.data.data && response.data.data.createRequest) {
                    this.$toaster.notify({
                        type: 'success',
                        title: this.$t('Succes'),
                        body: this.$t('De nieuwe waarde wordt verstuurd!')
                    });

                    setTimeout(() => {
                        this.$emit('reload');
                    }, 5000);
                } else {
                    this.$toaster.notify({
                        type: 'danger',
                        title: this.$t('Error'),
                        body: this.$t('Fout bij het versturen van de nieuwe waarde')
                    });
                }
            }).catch(() => {
                this.$toaster.notify({
                    type: 'danger',
                    title: this.$t('Error'),
                    body: this.$t('Fout bij het versturen van de nieuwe waarde')
                });
            }).finally(() => {
                this.showSetValueDialog = false;
                this.setValueLoading = false;
            })
        },
        resize() {
            if (this.$refs.chart) {
                this.$refs.chart.resize();
            }
        },
        async update() {

            if(!this.template[0]){
                return false;
            }

            this.tiles = [];

            if (this.template[0]) {
                if (this.showSetValueButton()) {
                    this.requests = [];

                    const measurementType = await this.getMeasurementType();

                    await this.getRequests(5).then(response => {
                        if (response.data.data.requests && response.data.data.requests.edges[0]) {
                            const requests = response.data.data.requests.edges;
                            for (let i = 0; i < requests.length; i++) {
                                let request = requests[i];

                                if (measurementType.valueItems) {
                                    if (measurementType.valueItems[request.value]) {
                                        request.value = measurementType.valueItems[request.value].name;
                                    }
                                }

                                this.requests.push(new Request(request));
                            }
                        }
                    });
                }
            }

            if(this.value){
                for (let i = 0; i < this.value.length; i++) {
                    let tile = {};
                    const serie = this.value[i];
                    let val = null;
                    for (let i = 0; i < serie.data.length; i++) {
                        val = serie.data[i];
                        if (val) {
                            tile = val;
                            break;
                        }
                    }
                    
                    tile.rawValue = this.getTileRawValue(serie, tile);
                    tile.value = this.getTileValue(serie, tile);
                    tile.color = this.getTileColor(serie, tile);
                    tile.icon = this.getTileIcon(serie);
                    tile.unit = this.getTileUnit(serie, tile);
                    this.tiles.push(tile);
                }
            }
            
            if(this.tiles.length == 0){
                this.tiles.push({
                    value: null,
                });
            }

            this.$nextTick(() => {
                if (this.$refs.chart) {
                    this.setGraphValue();
                } else {
                    this.$emit('finished', null, null);
                }
                this.$forceUpdate();
            });

        },
        getTileIcon(serie) {
            for (const key in serie.measurementTypes) {
                let measurementType = serie.measurementTypes[key];
                if (measurementType.icon) {
                    return measurementType.icon;
                }
            }

            return null;
        },
        getTileColor(serie, tile) {

            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    let value = parseInt(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                    if (measurementType.valueItems && measurementType.valueItems[value]) {
                        return measurementType.valueItems[value].color;
                    }
                }
            }

            return null;
        },
        getTileValue(serie, tile) {
            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    if(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]){
                        let value = parseFloat(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                        if (measurementType.valueItems && measurementType.valueItems[value]) {
                            return measurementType.valueItems[value].name;
                        }

                        return value;
                    }
                }
            }

            return '-';
        },
        getTileRawValue(serie, tile) {
            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    if(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]){
                        let value = parseFloat(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                        return value;
                    }
                }
            }

            return null;
        },
        hasListItem(serie, tile) {
            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    let value = parseInt(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                    if (measurementType.valueItems && measurementType.valueItems[value]) {
                        return true;
                    }
                }
            }

            return false;
        },
        getTileUnit(serie, tile) {

            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    if(aggMeasurementType.aggregation == 'count'){
                        return null;
                    }
                }
            }

            for (const key in serie.measurementTypes) {
                let measurementType = serie.measurementTypes[key];
                return { ...measurementType.unit, ...measurementType.unitFields };
            }

            return null;
        },
        setGraphValue() {

            // Only first query
            let query = Object.assign([], this.value[0]);

            //Only show last 20 results
            let data = [];

            let start = query.data.length >= 20 ? query.data.length - 20 : 0;
            for (let i = start; i < query.data.length; i++) {
                let value = query.data[i];
                data.push(value);
            }

            query.data = data;

            this.graphValue = [query];

            this.$nextTick(() => {
                this.$refs.chart.update();
                this.$emit('finished', this.graphId, null);
            });
        },
    }
}
</script>