<template>
    <v-list-item ref="item" @click="$emit('click')">
        <v-list-item-icon class="align-self-center mr-2">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs" v-on="on" :color="getStatusColor(value)" size="20"></v-avatar>
                </template>
                {{ getStatusText(value) }}
            </v-tooltip>

        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>
                {{ value.parent.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ value.project.name }} | {{ value.company.name }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="value.route"><v-icon small left>mdi-calendar</v-icon>{{
                $moment(value.dueAfterDate).format('YYYY-MM-DD HH:mm') }} -
                {{ $moment(value.dueBeforeDate).format('YYYY-MM-DD HH:mm') }}</v-list-item-subtitle>
            <v-list-item-subtitle v-else-if="value.parent.dueRequestDate"><v-icon small left>mdi-calendar</v-icon>{{
                $moment(value.parent.dueRequestDate).format('YYYY-MM-DD') }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <div class="d-flex align-items-center">
                <template v-if="value.route">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-4" @click="$emit('cancelItem', value)" small icon color="error" v-bind="attrs"
                                v-on="on"><v-icon>mdi-cancel</v-icon></v-btn>
                        </template>
                        <span>Afwijzen</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-4" @click="$emit('acceptItem', value)" icon small color="success"
                                v-bind="attrs" v-on="on"><v-icon>mdi-check</v-icon></v-btn>
                        </template>
                        <span>Accepteren</span>
                    </v-tooltip>
                </template>
            </div>
        </v-list-item-action>
    </v-list-item>
</template>
<style lang="scss" scoped>
.v-list-item {
    user-select: none;
    background-color: #ffffff;

    &.dragging {
        border-radius: 0 10px 10px 0;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, .2);
        opacity: .8;
        z-index: 5;
        max-width: 450px;

        .v-list-item__action {
            display: none;
        }
    }

    &:hover {
        background-color: #F8F8F8;
        cursor: grab;
    }
}
</style>
<script>
export default {
    name: 'eod-scheduler-sidebar-item',
    props: {
        value: Object,
    },
    mounted() {
        this.$refs.item.$el.removeEventListener('mousedown', this.mouseDown);
        this.$refs.item.$el.addEventListener('mousedown', this.mouseDown);
    },
    data: () => ({
        dragItem: null,
        originalPos: null,
        dragging: false,
        settings: require('./../classes/settings')
    }),
    methods: {
        getStatusColor(item) {
            if (item && item.statusCode) {
                const status = this.settings.getStatusCode(item.statusCode);

                if (status && status.color) {
                    return status.color;
                }
            }

            return 'grey';
        },
        getStatusText(item) {
            if (item && item.statusCode) {
                const status = this.settings.getStatusCode(item.statusCode);

                if (status && status.text) {
                    return status.text;
                }
            }

            return '';
        },
        mouseDown(e) {
            window.document.removeEventListener('mouseup', this.mouseUp);
            window.document.addEventListener('mouseup', this.mouseUp);

            window.document.removeEventListener('mousemove', this.mouseMove);
            window.document.addEventListener('mousemove', this.mouseMove);

            const rect = e.currentTarget.getBoundingClientRect();
            this.originalPos = {
                element: e.currentTarget,
                elementLeft: e.pageX - rect.left,
                elementTop: e.pageY - rect.top,
                pageX: e.pageX,
                pageY: e.pageY
            }

        },
        mouseUp(e) {
            window.document.removeEventListener('mouseup', this.mouseUp);
            window.document.removeEventListener('mousemove', this.mouseMove);

            this.dragging = false;

            if (this.dragItem) {
                this.dragItem.remove();
                this.$emit('dragEnd', e, this.value);
            }

        },
        mouseMove(e) {

            // Check if mouse has moved
            const offset = 10;
            if (!this.dragging && ((e.pageX > this.originalPos.pageX + offset) || (e.pageX < this.originalPos.pageX - offset) || (e.pageY < this.originalPos.pageY - offset) || (e.pageY < this.originalPos.pageY - offset))) {
                this.dragging = true;

                // Copy existing item
                let clone = this.originalPos.element.cloneNode(true);
                clone.style.position = "fixed";
                clone.style.left = e.pageX - this.originalPos.elementLeft + 'px';
                clone.style.top = e.pageY - this.originalPos.elementTop + 'px';
                clone.classList.add('dragging');
                window.document.getElementById('app').appendChild(clone);

                this.dragItem = clone;
            }

            if (this.dragItem) {
                this.dragItem.style.left = e.pageX - this.originalPos.elementLeft + 'px';
                this.dragItem.style.top = e.pageY - window.pageYOffset - this.originalPos.elementTop + 'px';
                this.$emit('drag', e, this.value);
            }




        }
    }
}
</script>