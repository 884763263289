<template>
<div>
        <v-autocomplete
        :search-input.sync="search"
        :loading="isUpdating"
        :readonly="isUpdating"
        :items="itemResults"
        label="Materiaaltype toevoegen"
        return-object
        item-text="name"
        v-model="searchItem"
        rounded
        auto-select-first
        no-filter
        @change="addItem"
        hide-details
        :item-disabled="itemDisabled"
        class="mb-4"
        :solo="!flat?true:false"
        :outlined="flat?true:false"
        :dense="dense"
    >
    <template v-slot:item="{ item }">
        <v-list-item-title v-if="item.header">{{item.header}}</v-list-item-title>
        <v-list-item-content two-line v-else>
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle v-if="item.description || item.quantity">
              <strong v-if="item.quantity">Aantal: {{item.quantity}}<span v-if="item.unit"> {{ item.unit.name }}</span></strong>
              <span v-if="(item.quantity) && item.description"> | </span>
              <span v-if="item.description">{{item.description}}</span>
            </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-if="showCreate" v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item @click.stop="showAddItem">
            <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>Nieuw type materiaal aanmaken</v-list-item-title>
                <v-list-item-subtitle>Maak type materiaal aan en voeg deze toe aan de lijst</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
<v-card :outlined="flat">
    <v-card-text>
        <div v-if="items && items.length > 0" >
            <v-list dense>
                <v-list-item v-for="item in items" :key="item.id">
                    <v-list-item-content>
                        <v-row no-gutters>
                            <v-col>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                            </v-col>
                            <v-col cols="auto">
                                <div class="d-flex grey lighten-3 rounded-l rounded-r-lg">
                                <v-text-field v-if="showQuantity" class="white" label="Aantal" dense outlined hide-details v-model="item.quantity" type="number" @input="$emit('input', items)"></v-text-field>
                                <div class="h-full d-flex align-items-center px-2 text--grey" v-if="item.unit">{{ item.unit.name }}</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <eod-actions-column :showEdit="showEdit" :item="item" :deleteAction="deleteItem" :editAction="editItem"></eod-actions-column>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </div>
        <v-list v-else>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Er is nog geen type materiaal gekoppeld.</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        
    </v-card-text>

    <v-dialog
      v-model="productDialog"
      persistent
      scrollable
      max-width="800px"
    >
      <eod-add-productType v-model="editProduct" @onCancel="productDialog = false" @onSaved="itemSaved"></eod-add-productType>
    </v-dialog>
</v-card>
</div>
</template>

<script>
import {ProductType} from '@/eodvuecomponents';
import eodErrorsDialog from './eod-errors-dialog.vue';
import eodColumnActions from './columns/eod-column-actions.vue';
import eodAddProductType from './eod-add-productType.vue';
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';

export default {
  name: 'eod-products',
  props: {
      value: Array,
      showQuantity:{
          type: Boolean,
          default: false
      },
      showCreate:{
          type: Boolean,
          default: true
      },
      showEdit:{
          type: Boolean,
          default: true
      },
      flat:{
          type: Boolean,
          default: false
      },
      dense:{
          type: Boolean,
          default: false
      },
      showStockLocation:{
          type: Boolean,
          default: false
      },
      defaultStockLocation:{
          type: Object
      },
      suggested: Array
  },
  components:{
      'eod-add-productType': eodAddProductType,
      'eod-actions-column': eodColumnActions,
      'eod-errors-dialog': eodErrorsDialog,
      'eod-listitem-autocomplete': eodListItemAutocomplete,
  },
  data(){
      return {
        search: '',
        isUpdating: false,
        searchResults: [],
        searchItem: null,
        timeout: null,
        productDialog: false,
        items: [],
        editProduct: new ProductType(),
      }
  },
  created(){
      this.items = this.value;
  },
  computed: {
      itemResults(){
          let items = [];
          if(this.suggested && this.suggested.length > 0){
              items.push({header: 'Voorgesteld'});
              for (let i = 0; i < this.suggested.length; i++) {
                  items.push(this.suggested[i]);
              }
          }

          if(this.searchResults.length > 0){
              items.push({header: 'Zoekresultaten'});
              for (let i = 0; i < this.searchResults.length; i++) {
                  items.push(this.searchResults[i]);
              }
          }

          return items;
      }
  },
  methods: {
      itemDisabled(item){
        if(this.items){
            for (let j = 0; j < this.items.length; j++) {
                if (item.id == this.items[j].id) {
                    this.$emit('input', this.items);
                    return true;
                }
            }
        }
        return false;
      },
      deleteItem(item){
          for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].id == item.id) {
                  this.items.splice(i, 1);
                  this.$emit('input', this.items);
                  return;
              }
          }
      },
      editItem(item){
          this.editProduct = item;
          this.productDialog = true;
      },
      itemSaved(item){
        this.productDialog = false;

        let saveData = item.getSaveData();
        delete saveData.quantity;
        this.$eod.save('ProductType', saveData).then(result => {
            if (!item.id) {
              item.id = result.data.data.createProductType.id;
              this.addItem(item);
            }
        });
      },
      showAddItem(){
          this.editProduct = new ProductType();
          this.productDialog = true;
      },
      addItem(item){
        if (!this.items) {
            this.items = [];
        }

        if(this.defaultStockLocation){
            item.stockLocation = this.defaultStockLocation;
        }

        this.items.push(item);
        this.$emit('input', this.items);
        
        this.$nextTick(() => {
            this.searchResults = [];
            this.search = '';
            this.searchItem = null;
            this.$forceUpdate();
        });
      }
  },
  watch: {
      search (val) {
        // Items have already been requested
        if (this.timeout) {
            clearInterval(this.timeout);
        }

        this.timeout = setTimeout(() => {
            if (this.isUpdating) return;

            if(!val || val == '' || val.length < 2){
                this.searchResults = [];
                return;
            };

            this.isUpdating = true;

            let variables = {
                where: [
                    {
                        column: 'name',
                        operator: 'ILIKE',
                        value: '%'+val+'%',
                        chain: 'or'
                    },
                    {
                        column: 'description',
                        operator: 'ILIKE',
                        value: '%'+val+'%',
                        chain: 'or'
                    }
                ],
            }

            // Filter products
            this.$eod.get('productTypes', ['id', 'name', 'description', 'unit{name}', 'vendor', 'type', 'url'], variables)
                .then(result => {
                    this.searchResults = [];
                    let count = result.data.data.productTypes.edges.length;
                    for (let i = 0; i < count; i++) {
                        this.searchResults.push(new ProductType(result.data.data.productTypes.edges[i]));
                    }
                    this.isUpdating = false;
                    this.$forceUpdate();
            });

            this.timeout = null;
        }, 500);

        
        
      },
    },
}
</script>