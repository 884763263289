<template>
    <v-row>
        <v-col cols="12" md="">
            <component :is="valueItems.length > 0 ? vAutocomplete : vTextField"
                :type="valueItems.length > 0 ? 'text' : 'number'" step="0.01" class="error lighten-5" hide-details
                :value="value.content.severities.criticalLow" outlined dense
                @change="val => saveSeverity('criticalLow', val)" :items="valueItems" item-value="threshold"
                item-text="name" clearable>
                <template slot="label">
                    Kritiek
                    <v-icon small>mdi-arrow-down</v-icon>
                </template>
            </component>
        </v-col>
        <v-col cols="12" md="">
            <component :is="valueItems.length > 0 ? vAutocomplete : vTextField"
                :type="valueItems.length > 0 ? 'text' : 'number'" step="0.01" class="warning lighten-5" hide-details
                :value="value.content.severities.warningLow" outlined dense @change="val => saveSeverity('warningLow', val)"
                :items="valueItems" item-value="threshold" item-text="name" clearable>
                <template slot="label">
                    Waarschuwing
                    <v-icon small>mdi-arrow-down</v-icon>
                </template>
            </component>
        </v-col>
        <v-col cols="12" md="">
            <component :is="valueItems.length > 0 ? vAutocomplete : vTextField"
                :type="valueItems.length > 0 ? 'text' : 'number'" step="0.01" class="info lighten-5" hide-details
                :value="value.content.severities.infoLow" outlined dense @change="val => saveSeverity('infoLow', val)"
                :items="valueItems" item-value="threshold" item-text="name" clearable>
                <template slot="label">
                    Informatief
                    <v-icon small>mdi-arrow-down</v-icon>
                </template>
            </component>
        </v-col>
        <v-col cols="12" md="">
            <component :is="valueItems.length > 0 ? vAutocomplete : vTextField"
                :type="valueItems.length > 0 ? 'text' : 'number'" step="0.01" class="success lighten-5" hide-details
                :value="value.content.severities.ok" outlined dense @change="val => saveSeverity('ok', val)"
                :items="valueItems" item-value="threshold" item-text="name" clearable>
                <template slot="label">
                    Oké
                </template>
            </component>
        </v-col>
        <v-col cols="12" md="">
            <component :is="valueItems.length > 0 ? vAutocomplete : vTextField"
                :type="valueItems.length > 0 ? 'text' : 'number'" step="0.01" class="info lighten-5" hide-details
                :value="value.content.severities.infoHigh" outlined dense @change="val => saveSeverity('infoHigh', val)"
                :items="valueItems" item-value="threshold" item-text="name" clearable>
                <template slot="label">
                    Informatief
                    <v-icon small>mdi-arrow-up</v-icon>
                </template>
            </component>
        </v-col>
        <v-col cols="12" md="">
            <component :is="valueItems.length > 0 ? vAutocomplete : vTextField"
                :type="valueItems.length > 0 ? 'text' : 'number'" step="0.01" class="warning lighten-5" hide-details
                :value="value.content.severities.warningHigh" outlined dense
                @change="val => saveSeverity('warningHigh', val)" :items="valueItems" item-value="threshold"
                item-text="name" clearable>
                <template slot="label">
                    Waarschuwing
                    <v-icon small>mdi-arrow-up</v-icon>
                </template>
            </component>
        </v-col>
        <v-col cols="12" md="">
            <component :is="valueItems.length > 0 ? vAutocomplete : vTextField"
                :type="valueItems.length > 0 ? 'text' : 'number'" step="0.01" class="error lighten-5" hide-details
                :value="value.content.severities.criticalHigh" outlined dense
                @change="val => saveSeverity('criticalHigh', val)" :items="valueItems" item-value="threshold"
                item-text="name" clearable>
                <template slot="label">
                    Kritiek
                    <v-icon small>mdi-arrow-up</v-icon>
                </template>
            </component>
        </v-col>
    </v-row>
</template>
<script>
import { VTextField, VAutocomplete } from 'vuetify/lib'

export default {
    name: 'eod-alarm-detail-thresholds',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        valueItems: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            vTextField: VTextField,
            vAutocomplete: VAutocomplete,
        }
    },
    created() {
        let val = this.value;
        if (!val.content.severities) {
            val.content.severities = {
                criticalLow: null,
                warningLow: null,
                infoLow: null,
                ok: null,
                infoHigh: null,
                warningHigh: null,
                criticalHigh: null,
            };
            this.$emit('input', val);
        }


    },
    methods: {
        saveSeverity(severityName, value) {
            const val = this.value;
            if (val) {
                val.content.severities[severityName] = value;
                this.$emit('input', val);
            }
        },
    }
}
</script>