<template>
    <div class="h-100 w-100">
        <v-chart v-show="value.length > 0 && tileVal && (tileVal <= 1 && tileVal >= 0)" :ref="id" :initOptions="graphOptions" :autoresize="true" :manualUpdate="true"/>
        <div v-if="!value || value.length == 0">Gelieve de grafiek in te stellen</div>
        <v-alert color="warning" v-if="tileVal && (tileVal > 1 || tileVal < 0)">Ongeldige waarde, waarde moet tussen 0 en 1 liggen (percentage)</v-alert>
    </div>
</template>
<style lang="scss">
    .echarts {
        width: 100%;
        height: 100%;
    }
</style>
<script>
    import ECharts from './echarts/ECharts';

    export default {
        name: 'eod-graph-liquid',
        components:{
            'v-chart': ECharts,
        },
        props:{
            id: String,
            value:Object|Array,
            template:Object|Array,
            xAxis:Object|Array,
            cell: Object,
        },
        data: () => ({
            graphOptions: {},
            query: null,
            tile: null,
            defaultOptions:{
                //renderer: 'svg',
                legend: {
                    show:false,
                    type: 'scroll',
                    pageButtonPosition: 'start'
                },
                grid:{
                    left:0,
                    top:0,
                    right:0,
                    bottom:0,
                    containLabel: true
                },             
                series: [],
            }
        }),
        computed:{
            tileVal(){
                if (this.tile && this.template[0]) {
                    const content = this.template[0].content;
                    for (let i = 0; i < content.measurementTypes.length; i++) {
                        const aggMeasurementType = content.measurementTypes[i];
                        const measurementType = this.query.measurementTypes[aggMeasurementType.object.id];
                        let value = this.tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id];
                        if (measurementType.valueItems && measurementType.valueItems[value]) {
                            return measurementType.valueItems[value].name;
                        }

                        return value;
                    }
                }

                return '-';
            }
        },
        mounted(){
            this.graphOptions = this.defaultOptions;
        },
        methods: {
            resize(){
                this.$refs[this.id].resize();
            },
            update(){
                this.tile = null;
                this.query = this.value[0];
                this.graphOptions.series = [];

                let val = null;

                if(this.query && this.query.data){
                    for (let i = this.query.data.length-1; i >= 0; i--) {
                        val = this.query.data[i];
                        if(val){
                            this.tile = val;
                            break;
                        }
                    }
                }
                
                let serie = {
                    type: 'liquidFill',
                    radius: '90%',
                    itemStyle: {
                        shadowBlur: 0
                    },
                    outline: {
                        show:false,
                        itemStyle: {
                            shadowBlur: 0,
                        }
                    },
                    data: [this.tileVal]
                };

                if(this.cell && this.cell.settings && this.cell.settings.options){
                    serie = {
                        ...serie,
                        ...this.cell.settings.options
                    }
                }

                this.graphOptions.series.push(serie);

                this.$refs[this.id].mergeOptions(this.graphOptions);
                
                if(this.tile){
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                    
                    
                }
            }
        }
    }
</script>