module.exports = class User {
    firstName = '';
    lastName = '';
    attributes = {
        phone_number: [],
        jobtitle: [],
        nickname: [],
        birthdate: [],
        gender: [],
        locality: [],
        locale: [],
        iban: [],
        bic: [],
        postal_code: [],
        street_address: [],
        region: [],
        country: [],
        notes: [],
        extra_email: [],
        extra_phone: []
    }
    credentials = {
        temporary: false,
        type: 'password',
        value: null
    }
    assignedRealmRoles = [];
    constructor(item) {
        if (item) {
            let settings = require('./settings');
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    if (key == 'attributes') {
                        for (const prop in item[key]) {
                            if (item[key].hasOwnProperty(prop)) {
                                this[key][prop] = item[key][prop];
                                if (item[key][prop] == null) {
                                    this[key][prop] = [];
                                }
                            }
                        }
                    } else if (key == 'assignedRealmRoles' || key == 'roles') {
                        let roles = [];
                        let count = item[key].length;
                        for (let i = 0; i < count; i++) {
                            const role = item[key][i];
                            if (!settings.roles.exception.includes(role.name)) {
                                roles.push(role);
                            }
                        }
                        this[key] = roles;
                    } else {
                        this[key] = item[key];
                    }
                }
            }
        }
    }
    get organizationId(){
        return this.organization.id;
    }
    can(action){
        let settings = require('./settings');
        let my_group = this.getRoleGroup();

        if (settings.permissions[action]) {
            if (settings.permissions[action].includes(my_group)) {
                return true;
            }
        }

        return false;
    }
    getRoleGroup(){
        let settings = require('./settings');
        for (const group in settings.roles) {
            if (Object.hasOwnProperty.call(settings.roles, group)) {
                const groups = settings.roles[group];
                if (groups.includes(this.getRole().name)) {
                    return group;
                }
            }
        }

        return null;
    }
    getRole() {
        let settings = require('./settings');
        let roles = this.assignedRealmRoles;

        for (let i = 0; i < roles.length; i++) {
            if (settings.roles.contacts.includes(roles[i].name)) {
                return roles[i];
            } else if (settings.roles.collaborators.includes(roles[i].name)) {
                return roles[i];
            } else if (settings.roles.eod.includes(roles[i].name)) {
                return roles[i];
            }
        }

        return null;
    }
    getInitials(){
        let name = this.username.charAt(0);

        if (this.firstName && this.lastName) {
            name = this.firstName.charAt(0) + this.lastName.charAt(0);
        } else if (this.firstName) {
            name = this.firstName.charAt(0);
        } else if (this.lastName) {
            name = this.lastName.charAt(0);
        }

        return name;
    }
    getFullName(){

        let name = this.username;

        if(this.firstName && this.lastName){
            name = this.firstName + ' ' + this.lastName;
        }else if(this.firstName){
            name = this.firstName;
        }else if (this.lastName){
            name = this.lastName;
        }

        return name;
    }
    getSaveData() {
        let settings = require('./settings');
        let data = Object.assign({}, this);

        if (data.credentials.value == '' || data.credentials.value == null) {
            delete data.credentials;
        }

        if (data.assignedRealmRoles && data.assignedRealmRoles[0]) {
            for (let i = 0; i < settings.roles.exception.length; i++) {
                data.assignedRealmRoles.push({name:settings.roles.exception[i]});
            }
        }

        if (data.companies) {
            for (let i = 0; i < data.companies.length; i++) {
                data.companies[i] = {
                    id: data.companies[i].id,
                    organizationId: data.organizationId
                }
            }
        }
        
        if (data.projects) {
            for (let i = 0; i < data.projects.length; i++) {
                data.projects[i] = {
                    id: data.projects[i].id,
                    organizationId: data.organizationId
                }
            }
        }

        return data;
    }
};