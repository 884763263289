<template>
    <div>
        <v-card flat outlined class="mb-2">
            <v-card-subtitle class="grey lighten-4 py-2">Algemeen</v-card-subtitle>
            <v-card-text class="mt-2">
                <div v-if="value.name && value.name != ''"><strong>Naam:</strong> {{value.name}}</div>
                <div v-if="value.description && value.description != ''"><strong>Omschrijving:</strong> {{value.description}}</div>
            </v-card-text>
        </v-card>
        <v-card flat outlined v-if="sharepoint">
            <v-card-subtitle class="grey lighten-4 py-2">Koppelingen</v-card-subtitle>
            <v-card-text class="mt-2">
                <v-row class="mt-2">
                    <v-col>
                        <a target="_blank" style="max-width:50px;display:block;" :href="sharepoint"><v-img :src="require('@/assets/img/integrations/sharepoint.svg')" contain/></a>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        
        <eod-tags :value="value.tags" small></eod-tags>
    </div>
</template>

<script>
import eodTags from './eod-tags.vue';

export default {
    name:'eod-project-general',
    components:{
        'eod-tags': eodTags
    },
    props:{
        value: Object
    },
    data(){
        return {
            sharepoint: null
        }
    },
    mounted(){
        if(this.$helper.replaceTags){
            let sharepointURLSetting = this.$eod.getOrganizationSetting('share.sharepoint.projects.url');
            if(sharepointURLSetting && sharepointURLSetting.value){
                this.sharepoint = this.$helper.replaceTags(sharepointURLSetting.value, {
                    project: this.value
                });
            }
        }
        
    }
}
</script>