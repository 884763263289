<template>
<eod-dialog :value="value" @input="val => $emit('input', val)" title="Delen" icon="mdi-share-variant" iconColor="primary">
                        <v-text-field type="text" label="Link" dense outlined readonly :value="url" append-outer-icon="mdi-content-copy" @click:append-outer="copyUrl"></v-text-field>
                        <v-alert v-if="shareUrlCopy" color="success" text dense>URL succesvol gekopieerd!</v-alert>
                        <v-img max-height="280" :src="qrCode" aspect-ratio="1" contain />
                        <div class="d-flex"><v-spacer></v-spacer><v-btn icon download="qr_code.png" :href="qrCode"><v-icon>mdi-download</v-icon></v-btn></div>

                        <template v-slot:actions>
                            <v-spacer></v-spacer>
                            <v-btn text rounded @click="$emit('input', false)">
                                Sluiten
                            </v-btn>
                        </template>
                    </eod-dialog>
</template>
<script>
import eodDialog from './eod-dialog';
import QRCode from 'qrcode';

export default{
    name: 'eod-share-dialog',
    components:{
        eodDialog
    },
    props:{
        value: Boolean,
        url: {
            type: String,
            default: window.location.href
        }
    },
    data(){
        return {
            qrCode: null,
            shareUrlCopy:false
        }
    },
    mounted(){
        this.init();
    },
    methods:{
        init(){
            QRCode.toDataURL(this.url).then(result => {
                this.qrCode = result;
                this.showShareDialog = true;
            });
        },
        async copyUrl(){
            await navigator.clipboard.writeText(this.url);
            this.shareUrlCopy = true;
        },
    }
}
</script>