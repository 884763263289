<template>
    <div>
        <v-checkbox class="mb-2" v-model="value.send" @change="val => updateSetting('send', val)"
            label="Verstuur via e-mail"></v-checkbox>
        <v-select v-if="value.send" label="Tijdstip" dense outlined :value="value.sendTime ? value.sendTime : null" return-object
            @change="val => updateSetting('sendTime', val)" :items="sendTimeOptions">
        </v-select><v-card v-if="value.send">
            <v-card-text>
                <v-label>Naar</v-label><br>
                <v-alert text color="info" v-if="users == null">
                    Standaard wordt de e-mail verstuurd naar de contacten van het project.
                    <span><br>Om de gewenste contacten te selecteren, dien je de optie <strong>'Ik wil deze gegevens per workflow
                    kunnen aanpassen'</strong> hierboven aan te vinken.</span>
                </v-alert>
                <template v-else>
                <v-autocomplete :loading="loadingUsers" ref="addUserComponent" multiple class="mb-4 mt-2" outlined dense :items="users" item-value="id" item-text="email" v-model="value.addresses" label="Ontvangers" hide-details>
                    <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)" small>
                        <strong>{{ data.item.email }}</strong>&nbsp;|&nbsp;<small>{{ $t(data.item.getRole().name) }}</small>
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-action>
                    <v-checkbox v-model="value.addresses" :value="data.item.id" disabled></v-checkbox>
                </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.email"></v-list-item-title>
                    <v-list-item-subtitle v-html="$t(data.item.getRole().name)"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click.stop="editUser(data.item)"><v-icon>mdi-pencil</v-icon></v-btn>
                  </v-list-item-action>
              </template>
              <template v-slot:append-item>
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-card flat>
                    <v-card-title class="subtitle-2 pt-2 pb-2">Gebruiker toevoegen aan project</v-card-title>
                    <v-card-text>
                <eod-user-selector dense outlined @change="addUserToProject" :value="null" return-object></eod-user-selector>
            </v-card-text>
            </v-card>
              </template>
                </v-autocomplete>
                <v-dialog v-if="userDialog" v-model="userDialog" persistent scrollable max-width="1200px">
                <eod-add-contact :activeTab="2" v-model="activeUser" :show-role="false" @onCancel="userDialog = false"
                    @onSaved="userSaved"></eod-add-contact>
            </v-dialog>
            </template>

                <v-text-field :value="value.subject" @input="val => updateSetting('subject', val)" label="Onderwerp" dense
                    outlined></v-text-field>
                <v-checkbox class="mt-0" label="Logo verbergen bovenaan de e-mail" v-model="value['hide-logo']"
                    :value="true" @change="val => updateSetting('hide-logo', val)"></v-checkbox>
                <div class="mb-4">
                    <eod-rte :value="value.content" @input="val => updateSetting('content', val)"></eod-rte>
                </div>
                <v-label>Tokens</v-label><br>
                Gebruik onderstaande tokens om gegevens over de workflow in de tekst te integreren.
                <v-chip-group column>
                    <v-chip small v-for="token in tokens" :key="token">{{ tokenize(token) }}</v-chip>
                </v-chip-group>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import eodRte from './eod-rte.vue';
import User from '../models/user';
import eodUserSelector from './eod-user-selector.vue';
import eodAddContact from './eod-add-contact.vue';

export default {
    name: 'eod-workflow-mail-config',
    components: {
        eodRte,
        eodUserSelector,
        eodAddContact
    },
    props: {
        value: Object,
        contacts: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            users: null,
            loadingUsers: false,
            userDialog: false,
            activeUser: null,
            tokens: [
                'user.first_name', 'user.last_name', 'user.email', 'project.name', 'project.description', 'company.name', 'company.description', 'service.name', 'service.description', 'job.reported_at'
            ],
            sendTimeOptions: [
                { value: null, text: 'Bij het uitvoeren van deze stap' },
                { value: 'due_before_date', type: 'task', text: 'Op het eindtijdstip van de workflow' }
            ]
        }
    },
    async mounted() {
        await this.resolveContacts();
        this.initialize();
    },
    methods: {
        userSaved(user){
            this.activeUser = null;
            this.userDialog = false;
            this.resolveContacts();
        },
        editUser(user){
            this.activeUser = user;
            this.userDialog = true;
        },
        addUserToProject(user){
            const addresses = this.value.addresses;
            addresses.push(user.id);
            this.updateSetting('addresses', addresses);

            if(this.$refs.addUserComponent){
                this.$refs.addUserComponent.blur();
            }
            
            this.$emit('addContact', user);
        },
        remove (item) {
            this.value.addresses.splice(this.value.addresses.indexOf(item), 1);
        },
        async resolveContacts() {
            if (this.contacts) {
                const userIds = [];
                for (let i = 0; i < this.contacts.length; i++) {
                    const user = this.contacts[i];
                    userIds.push(user.id);
                }

                if (userIds.length > 0) {
                    this.loadingUsers = true;
                    await this.$eod.get('resolveUsers', ['id', 'firstName', 'username', 'email', 'lastName', 'attributes{jobtitle phone_number extra_phone notes}', 'assignedRealmRoles{id name}', 'notificationSettings'], {
                        whereIn: {
                            column: 'id',
                            array: userIds
                        }
                    }).then(response => {
                        this.users = [];
                        const users = response.data.data.resolveUsers.edges;
                        for (let i = 0; i < users.length; i++) {
                            this.users.push(new User(users[i]));
                        }
                        this.$forceUpdate();
                    }).finally(() => {
                        this.loadingUsers = false;
                    });
                    
                    
                }
            }
        },
        initialize(){
            const defaultMailIds = [];
            for (let i = 0; i < this.users.length; i++) {
                const user = this.users[i];
                if(user.notificationSettings && user.notificationSettings.reports && user.notificationSettings.reports.sendAll){
                    defaultMailIds.push(user.id);
                }
            }

            this.updateSetting('addresses', defaultMailIds);
        },
        tokenize(val) {
            return '{{ ' + val + ' }}';
        },
        updateSetting(propertyName, value) {
            let val = this.value;
            this.$helper.setValueByDotName(val, propertyName, value);
            this.$emit('input', val);

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
    }
}
</script>