const { MeasurementType } = require('../../eodvuecomponents');

module.exports = {
    routes: [
    {
        path: '/measurements/types',
        name: 'measurementTypes',
        meta:{
            permissions:['measurementtypes.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/measurements/types/detail/:id?',
        name: 'measurementType',
        meta:{
            permissions:['measurementtypes.update', 'measurementtypes.create']
        },
        component: () => import('./types/detail.vue')
    }],
    search: [{
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'settings': {
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'name'
        }
    ],
    detail: {
        route: 'measurement',
        endpoint: 'measurement',
        objectName: 'Measurement',
        fields: ['id', 'name', 'description', 'company{id}', 'measurementType{id}', 'startDate', 'endDate'],
        tabs:[]
    },
    index: {
        route: 'measurements',
        endpoint: 'measurements',
        fields: ['id', 'name', 'description', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Type',
                value: 'measurementType.name',
                sortable: false,
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
        tabs: [{
            path: 'measurements',
            text: 'Overzicht'
        }, {
            path: 'measurementTypes',
            text: 'Types'
        }],
    },
    export: {
        endpoint: 'measurements',
        fields: ['id', 'name', 'description', 'startDate', 'endDate', 'createdAt', 'updatedAt']
    },
    import: {
        endpoint: 'Measurement',
        objectName: 'Measurement',
    },
    types: {
        itemObject: MeasurementType,
        search: MeasurementType.searchConfig,
        index: {
            route: 'measurementTypes',
            endpoint: 'measurementTypes',
            fields: ['id', 'name', 'icon', 'createdAt', 'updatedAt'],
            headers: [
                {
                    text: 'Icoon',
                    value: 'icon',
                    width:30,
                    sortable:false,
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-icon.vue')
                },
                {
                    text: 'Naam',
                    value: 'name',
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
            tabs: [{
                path: 'measurementTypes',
                text: 'Types'
            }],
        },
        detail: {
            route: 'measurementType',
            endpoint: 'measurementType',
            objectName: 'MeasurementType',
            fields: ['id', 'name', 'description', 'color', 'icon', 'unit{id}', 'typeConfig', 'isWritable', 'field', 'metricConfig{hardwareId dataType factor offset interval method}', 'valueConfigList{id name description itemType fields{name description isActive}, listItems{id name description low high threshold stepSize fields{name description isActive}}}', 'dashboardConfig', 'formConfig{enabled required photo photoRequired comment commentRequired type category subCategory defaultValue values}'],
            tabs: [],
        },
        export: {
            endpoint: 'measurementTypes',
            fields: ['id', 'name', 'description']
        },
        import: {
            endpoint: 'MeasurementTypes',
            objectName: 'MeasurementType',
        },
    }
}