<template>
    <v-dialog :value="value" @input="(val) => {$emit('input', val)}" max-width="800">
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">
                    Gegevens
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">
                    Resultaat
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
            <v-stepper-content step="1">
                <div class="px-2 py-2 mb-4">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select required :disabled="importLoading" return-object v-model="selectedObject" :items="moduleObjects" item-value="endpoint" item-text="name" outlined label="Wat wilt u importeren?"></v-select>
                        <v-file-input required outlined v-model="selectedFile" :disabled="importLoading" label="Bestand selecteren (.zip, .xlsx)" truncate-length="50" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,xlsx" prepend-icon="mdi-archive-arrow-up"></v-file-input>
                    </v-form>
                </div>
                <v-divider></v-divider>
                <div class="px-2 py-2 d-flex">
                    <v-btn text @click="$emit('input', false)">Annuleren</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="importItems" :loading="importLoading">
                            Volgende
                        </v-btn>
                </div>
            </v-stepper-content>
            <v-stepper-content step="2">
                <div class="px-2 py-2 mb-4" v-if="selectedObject">
                    <h2>{{selectedObject.name}} zijn geïmporteerd!</h2>
                    <v-alert v-for="(error, index) in importErrors" :key="index" border="left" dense text type="error" v-html="error"></v-alert>       
                </div>
                <v-divider></v-divider>
                <div class="px-2 py-2 d-flex">
                    <v-btn text @click="$emit('input', false)">
                            Sluiten
                        </v-btn>
                </div>
            </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>
<script>
    export default {
        props:{
            value: Boolean
        },
        data: () => ({
            // General
            step: 1,
            valid: true,

            // Data
            selectedObject: null,
            selectedFile: null,
            moduleObjects: [
                {
                    name:'Antwoorden',
                    endpoint: 'responses',
                    objectName: 'Response'
                },
                {
                    name:'Projecten',
                    endpoint: 'Projects',
                    objectName: 'Project'
                }
            ],

            // Export
            importLoading: false,
            importErrors: null,
        }),
        methods:{
            importItems(){
                if(this.$refs.form.validate()){
                    this.importLoading = true;

                    let method = 'import';
                    if(this.selectedObject.endpoint == 'responses'){
                        method = 'importResponses';
                    }

                    this.$eod[method](this.selectedObject, this.selectedFile).then(response => {
                        if(response.data.success){
                            this.importErrors = response.data.errors;
                        }else{
                            this.importErrors = ['Er heeft zich een fout voorgedaan bij het importeren.'];
                        }
                    }).catch(e => {
                        this.importErrors = [e];
                    }).finally(() => {
                        this.importLoading = false;
                        this.step = 2;
                    });

                }
            }
        }
    }
</script>