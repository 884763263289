import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import nl from 'vuetify/es5/locale/nl';
import Ripple from 'vuetify/lib/directives/ripple';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#031859',
          lighten2: '#445487',
          lighten5: '#e1e4ed'
        },
        secondary: '#F23D3D',
        accent: '#da9812',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: {
          base: '#FFC107',
          lighten2: '#ffd147',
          lighten3: '#ffdc73',
          lighten4: '#fce8a9',
          lighten5: '#fff5d9',
        },
        background: '#f4f6fd',
      },
    },
  },
  lang: {
    locales: { nl },
    current: 'nl',
  },
});
