<template>
    <v-textarea hide-details outlined v-model="value" class="form-control" rows="2" auto-grow :disabled="readonly"></v-textarea>
</template>
<style lang="scss" scoped>
.eod-measurement-text{
    display:flex;
    align-items: center;

    input{
        flex-grow: 1;
    }

    span{
        display: block;
        margin-left: 10px;
    }
}
</style>
<script>
export default {
    name: 'eod-measurement-text',
    props: {
        readonly: Boolean,
        value: String,
    },
}
</script>