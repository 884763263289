<template>
    <v-slider
            :value="value"
            @input="val => $emit('input', val)"
            class="align-center"
            :max="value.valueConfigList.listItems[0].high"
            :min="value.valueConfigList.listItems[0].low"
            hide-details
            :disabled="readonly"
            :thumb-size="24"
            thumb-label="always"
            color="secondary"
          >
            <template v-slot:append>
              <v-text-field
              :disabled="readonly"
                :value="value"
                @input="val => $emit('input', val)"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
</template>
<script>
export default {
    name: 'eod-measurement-list',
    props: {
        readonly: Boolean,
        value: Number
    },
    data: () => ({
        items: []
    }),
}
</script>