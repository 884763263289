<template>
    <div class="d-flex flex-row align-items-center">
        <vc-date-picker :minute-increment="15" :attributes="[{key: 'today', highlight: {color: 'orange', fillMode:'solid', class:'primary'},dates: new Date()}]" v-if="value && value.type == 'fixed'" class="flex-grow-1" :value="value?$moment(value.value).format('DD-MM-YYYY HH:mm'):null" @input="updateVal" mode="dateTime" is24hr :model-config="{type: 'string',mask: 'DD-MM-YYYY HH:mm'}">
            <template v-slot="{ inputValue, inputEvents, updateValue }">
                <v-text-field hide-details prepend-icon="mdi-calendar" :value="inputValue" @input="updateValue($event, {formatInput: false, hidePopover:false,debounce:1000})" @change="updateValue($event, {formatInput: true,hidePopover: false,})" v-on="inputEvents" dense outlined></v-text-field>
            </template>
        </vc-date-picker>
        <v-text-field hide-details prepend-icon="mdi-calendar" v-else outlined dense :value="getValueLabel()" readonly></v-text-field>
        <v-menu v-if="showDynamicRange" ref="datePicker" v-model="showDatePicker" transition="scale-transition" offset-y min-width="auto" max-width="400px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" v-bind="attrs" v-on="on" icon small><v-icon>mdi-chevron-down</v-icon></v-btn>
            </template>
            <v-list dense>
                <v-list-item v-for="range in defaultDynamicRanges" :key="getItemValue(range)" @click="$emit('input', range)">
                    <v-list-item-title v-html="range.label"></v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<style lang="scss">
    .vc-popover-content-wrapper{
        z-index: 50 !important;
        .vc-popover-content{
            z-index: 50 !important;
        }
        
    }
</style>
<script>
    export default {
        props:{
            value: {
                type: String|Object,
            },
            label: String,
            showDynamicRange:{
                type: Boolean,
                default: false
            },
            dynamicRanges: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        data: () => {
            return {
                showDatePicker: false,
                hours: [],
                minutes: [],
                defaultDynamicRanges: [
                    {
                        value: 0,
                        type: 'days',
                        label: 'Nu'
                    }
                ]
            }
        },
        mounted(){
            let today = {
                value: this.$moment().format('YYYY-MM-DD HH:mm'),
                type: 'fixed',
                label: 'Vaste datum'
            };

            this.defaultDynamicRanges.unshift(today);

            for (let i = 0; i < this.dynamicRanges.length; i++) {
                const date = this.dynamicRanges[i];
                this.defaultDynamicRanges.push(date);
            }
        },
        methods:{
            getItemValue(item){
                return item.type+item.value;
            },
            getValueLabel(){
                if(this.value && this.value.type == 'fixed'){
                    return this.value.value;
                }else if(this.value && this.value.label){
                    return this.value.label;
                }else{
                    return this.value;
                }
            },
            updateVal(val){
                this.value.value = this.$moment(val, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
                this.$emit('input', this.value);
            }
        }
    }
</script>