<template>
    <v-expansion-panels v-model="openPanels" :disabled="!collapsable" :flat="flat">
        <v-expansion-panel key="comments">
            <v-btn v-if="!readonly" small style="position:absolute;right:10px;top:-10px;z-index:5;" color="secondary" fab
                @click="addComment"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panel-header class="primary white--text" hide-actions ripple
                :style="dense ? 'min-height:40px;padding-top:0;padding-bottom:0;' : ''">
                <div>
                    <v-icon dark left>mdi-comment-processing-outline</v-icon>
                    <v-badge v-if="value && value.length > 0" color="secondary" :content="value.length">{{ $t('Opmerkingen')
                    }}</v-badge>
                    <span v-else>{{ $t('Opmerkingen') }}</span>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="comments__expansion-panel-content">
                <div v-for="(comment, index) in value" :key="index">
                    <eod-comment v-model="value[index]" :readonly="readonly" @delete="deleteItem(index);"></eod-comment>
                    <v-card-actions v-if="!readonly || showStartWorkflow">
                        <v-tooltip bottom v-if="showStartWorkflow">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-on="on" v-bind="attrs"
                                    @click="$emit('createWorkflow', { comment: value[index].content, images: getImages(comment) })"><v-icon>mdi-clipboard-plus-outline</v-icon></v-btn>
                            </template>
                            <span>Workflow aanmaken op basis van opmerking</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-btn v-if="!readonly" icon
                            @click="$refs['photo_' + index][0].click();"><v-icon>mdi-image-plus</v-icon></v-btn>
                        <input style="display:none;" :ref="'photo_' + index" class="hidden" type="file" multiple
                            accept="image/*" @change="addImage(index, $event)">

                    </v-card-actions>

                    <v-container fluid>
                        <v-row v-if="getImages(comment).length > 0">
                            <v-col cols="6" sm="4" md="4" lg="3" v-for="(image, imageindex) in getImages(comment)"
                                :key="imageindex">
                                <v-card @click="showLightbox(index, imageindex)" class="p-4" bordered>
                                    <v-img aspect-ratio="1" contain
                                        :src="(image.key ? $eod.getImageUrl(image.key) : 'data:image/jpeg;base64,' + image)"></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import Lightbox from '@/eodvuecomponents/src/classes/lightbox.js';
import ImageLib from './../classes/imageLib';
import eodComment from './eod-comment';

export default {
    name: 'eod-comments',
    components: {
        eodComment
    },
    props: {
        value: Array,
        collapsable: {
            default: false,
            type: Boolean
        },
        flat: {
            default: false,
            type: Boolean
        },
        dense: {
            default: false,
            type: Boolean
        },
        readonly: {
            default: false,
            type: Boolean
        },
        showStartWorkflow: {
            default: false,
            type: Boolean
        },
        tags: {
            type: Object,
            default: () => ({})
        },
        imageFieldName: {
            type: String,
            default: 'images'
        },
        imageObjects: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            openPanels: null,
        }
    },
    created() {
        if (!this.collapsable) {
            this.openPanels = 0;
        }
    },
    methods: {
        addComment() {

            let val = this.value;

            if (!val) {
                val = [];
                this.$emit('input', val);
            }

            var comment = {
                content: '',
                createdAt: this.$moment().toISOString(),
                userId: this.$eod.getUser().id,
                organizationId: this.$eod.getRealm(),
            }

            if (this.imageObjects) {
                comment[this.imageFieldName] = [];
            } else {
                comment[this.imageFieldName] = '[]';
            }


            val.unshift(comment);

            this.$emit('input', val);

            this.$nextTick(this.$forceUpdate);
        },
        getImages(comment) {
            if (comment[this.imageFieldName]) {
                if (typeof comment[this.imageFieldName] == 'string') {
                    return JSON.parse(comment[this.imageFieldName]);
                } else {
                    return comment[this.imageFieldName];
                }
            }

            return [];
        },
        async addImage(index, event) {
            let images = this.getImages(this.value[index]);
            var self = this;
            if (event.target.files[0]) {
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i];

                    await ImageLib.readFile(file).then(async result => {
                        await ImageLib.resize(result, 1200).then(async url => {
                            let data = {
                                tags: JSON.stringify(this.tags),
                                storageObject: JSON.stringify({
                                    name: file.name,
                                    base64: url
                                })
                            };

                            await this.$eod.uploadStorageObject(data).then(result => {
                                const storageObject = result.data.storageObject;
                                images.push({
                                    id: storageObject.id,
                                    key: storageObject.key,
                                    name: storageObject.name,
                                });

                                if (this.imageObjects) {
                                    self.value[index][this.imageFieldName] = images;
                                } else {
                                    self.value[index][this.imageFieldName] = JSON.stringify(images);
                                }
                            }).catch(err => {
                                // Show error if upload went wrong
                            });
                        });
                    });

                }
            }

            this.$forceUpdate();
        },
        deleteItem(index) {
            let val = this.value;
            val.splice(index, 1);

            this.$emit('input', val);

            this.$nextTick(this.$forceUpdate);
        },
        deleteImage(index, imageindex) {
            let val = this.value;
            let images = this.getImages(val[index]);
            images.splice(imageindex, 1);

            if (this.imageObjects) {
                val[index][this.imageFieldName] = images;
            } else {
                val[index][this.imageFieldName] = JSON.stringify(images);
            }

            this.$emit('input', val);

            this.$nextTick(this.$forceUpdate);
        },
        showLightbox(index, imageindex) {
            let com_images = this.getImages(this.value[index]);
            let images = [];

            for (let i = 0; i < com_images.length; i++) {
                const el = com_images[i];
                if (el.key) {
                    images.push(this.$eod.getImageUrl(el.key));
                } else {
                    images.push('data:image/jpeg;base64,' + el);
                }

            }

            let options = {
                delete: function (imageindex) {
                    self.deleteImage(index, imageindex);
                }
            };

            if (this.readonly) {
                options = {}
            }

            let self = this;
            let lightbox = new Lightbox(images, imageindex, options);
            lightbox.show();
        }
    }
}
</script>