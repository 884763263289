<template>
    <div v-if="value" class="eod-loader d-flex justify-content-center align-items-center" style="padding:50px 0;">
        <div style="margin:0 auto;" class="text-center">
            <div style="width:100px;max-width:100%;position:relative;margin:auto;">
                <svg style="width:100%;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.33 177.79"><g><g><path d="M115.36,177.79a5.31,5.31,0,0,1-1-.1C51.3,165,3.92,95.06,1.93,92.09L0,89.2l1.93-2.89c2-3,49.37-72.94,112.39-85.6a5.21,5.21,0,1,1,2.06,10.22C64.51,21.36,22.25,75.94,12.66,89.2c9.6,13.26,51.91,67.85,103.72,78.26a5.22,5.22,0,0,1-1,10.33Z" style="fill:#f23d3d"/><path d="M188,177.19a5.24,5.24,0,0,0,1-.1c63-12.67,110.4-82.63,112.39-85.6l1.93-2.89-1.93-2.89c-2-3-49.37-72.94-112.39-85.6A5.21,5.21,0,1,0,187,10.33c51.87,10.43,94.13,65,103.73,78.27-9.61,13.26-51.92,67.85-103.73,78.26a5.22,5.22,0,0,0,1,10.33Z" style="fill:#f23d3d"/></g></g></svg>
                <svg style="position:absolute;left:50%;top:50%;transform: translate(-50%,-50%) rotate(90deg);width:30%;" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg" fill="#f23d3d"> <rect y="10" width="15" height="120" rx="6"> <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" /> <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" /> </rect> <rect x="30" y="10" width="15" height="120" rx="6"> <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" /> <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" /> </rect> <rect x="60" width="15" height="140" rx="6"> <animate attributeName="height" begin="0s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" /> <animate attributeName="y" begin="0s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" /> </rect> <rect x="90" y="10" width="15" height="120" rx="6"> <animate attributeName="height" begin="0.25s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" /> <animate attributeName="y" begin="0.25s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" /> </rect> <rect x="120" y="10" width="15" height="120" rx="6"> <animate attributeName="height" begin="0.5s" dur="1s" values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear" repeatCount="indefinite" /> <animate attributeName="y" begin="0.5s" dur="1s" values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear" repeatCount="indefinite" /> </rect></svg>
            </div>
            <strong v-if="text" class="secondary--text">{{text}}</strong>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .eod-loader{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255, 0.8);
        z-index: 4;
    }
</style>
<script>
export default {
    name:'eod-loader',
    props:{
        value: Boolean,
        text: String
    }
}
</script>