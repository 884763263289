<template>
    <div>
        <v-btn width="100%" color="primary" light large @click="expanded = !expanded">
            <v-icon size="20">mdi-bell</v-icon>&nbsp;
            <v-badge v-for="(total, severity) in visibleTotals" :key="severity" :content="'' + total" inline
                :color="Event.getSeverity(severity).color"></v-badge>
            &nbsp;<v-icon size="20">mdi-chevron-up</v-icon>
        </v-btn>

        <v-bottom-sheet v-model="expanded" scrollable>
            <v-sheet class="text-center">
                <eod-events-drawer v-if="expanded"></eod-events-drawer>
                <v-btn width="100%" large color="primary" @click="expanded = !expanded">
                    <v-icon size="20">mdi-bell</v-icon>&nbsp;

                    <v-badge v-for="(total, severity) in visibleTotals" :key="severity" :content="'' + total" inline
                        :color="Event.getSeverity(severity).color"></v-badge>
                    &nbsp;<v-icon size="20">mdi-chevron-down</v-icon>
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>
  
<script>
import eodEventsDrawer from './eod-events-drawer';
import Event from '../models/event';
import {getEnv} from '@/eodvuecomponents';

export default {
    name: 'eod-events-drawer-collapse',
    components: {
        eodEventsDrawer,
    },
    data: () => ({
        expanded: false,
        Event: Event,
        totals: {
            crit: 0,
            alert: 0,
            warning: 0,
            ok: 0,
            info: 0,
        },
    }),

    computed: {
        visibleTotals() {
            const visibleTotals = {};
            for (const severity in this.totals) {
                if (Object.hasOwnProperty.call(this.totals, severity)) {
                    const total = this.totals[severity];
                    if (total > 0) {
                        visibleTotals[severity] = total;
                    }
                }
            }

            return visibleTotals;
        },
    },

    created() {
        this.$websocket.subscribe('events/count', (response => {
                if(response && response.data){
                    for (const severity in this.totals) {
                            this.totals[severity] = 0;
                            for (let i = 0; i < response.data.length; i++) {
                                const stat = response.data[i];
                                if (stat.severity == severity) {
                                    this.totals[severity] = stat.count;
                                    break;
                                }
                            }
                        }
                }
            }));

        this.countEventsPerSeverity();
    },
    destroyed() {
        this.$websocket.unsubscribe('events/count');
    },
    methods: {
        async countEventsPerSeverity() {
            this.$eod.stats('Events').then(response => {
                if (response.data.data.statEvents.stat) {
                    for (const severity in this.totals) {
                        this.totals[severity] = 0;
                        for (let i = 0; i < response.data.data.statEvents.stat.length; i++) {
                            const stat = response.data.data.statEvents.stat[i];
                            if (stat.severity == severity) {
                                this.totals[severity] = stat.count;
                                break;
                            }
                        }
                    }
                }
            });
        },
    },
}
</script>