<template>
    <v-dialog v-model="value" persistent max-width="550">
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>
        <v-card>
            <v-card-title class="mb-4">
                <v-avatar color="error" size="40" class="elevation-3 mr-4">
                    <v-icon dark v-text="icon ? icon : 'mdi-trash-can-outline'"></v-icon>
                </v-avatar><span class="headline" v-html="title ? title : 'Verwijderen?'"></span>
            </v-card-title>
            <v-card-text>
                <div class="text-body-1">
                <slot name="default">
                    <span
                    v-html="body ? body : 'Weet u zeker dat u \'<strong>' + item[nameField] + '</strong>\' wilt verwijderen?'"></span>
                </slot>
            </div>
            </v-card-text>
            <v-card-actions class="grey lighten-3 py-3 px-3">
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('input', false)" rounded>Annuleren</v-btn>
                <v-btn color="error" depressed rounded @click="deleteItem">
                    <v-icon left v-text="icon ? icon : 'mdi-trash-can-outline'"></v-icon>
                    {{ deleteText?deleteText: 'Verwijderen' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'eod-delete-dialog',
    props: {
        value: Boolean,
        item: Object,
        title: String,
        body: String,
        deleteText: String,
        nameField:{
            type: String,
            default: 'name'
        },
        icon: String,
    },
    methods: {
        deleteItem() {
            this.$emit('delete', this.item);
            this.$emit('input', false);
        }
    }
}
</script>