import {io} from 'socket.io-client';
import getEnv from './utils';
import eod from './eod';

export default class EODWebSocket {
    ws = null
    token = null
    subscriptions = []
    constructor(token){
        this.token = token;

        this.init();
    }
    setToken(token) {
        this.token = token;
        this.init();
    }
    subscribe(topic, callback, options){
       this.subscriptions.push(topic);
       if (this.ws.connected) {
        this.ws.emit('subscribe', eod.getRealm(), [topic], options);
       }
       this.ws.on(topic, (message) => {
            callback(message);
        });
    }
    unsubscribe(topic) {
        const i = this.subscriptions.indexOf(topic);
        if (i !== -1) {
          this.subscriptions.splice(i, 1);
          if (this.ws.connected) {
            this.ws.emit("unsubscribe", eod.getRealm(), topic);
          }
        }
      }
    init(){
        this.ws = io(getEnv('VUE_APP_BACKEND_URL'), {
            auth: {
                token: this.token
            },
        });
        this.ws.on("connect", () => {
            if (this.subscriptions.length && !this.ws.recovered) {
              this.ws.emit("subscribe", eod.getRealm(), this.subscriptions);
            }
        });
    }
}