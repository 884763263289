<template>
    <div class="map" ref="map" :style="'padding-bottom:'+(100/ratio)+'%;'"></div>
</template>
<style lang="scss" scoped>
    .map{
        width:100%;
        padding-bottom: 100%;
        z-index: 1;
    }
</style>
<script>
export default {
    name: 'eod-locations-map',
    props:{
        value:{
            type: Array,
            default: () => ([])
        },
        ratio:{
            type: Number|String,
            default: 1
        },
        readonly:{
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        map: null,
    }),
    mounted(){
        this.initMap();
    },
    watch:{
        value(){
            this.initMap();
        }
    },
    methods:{
        getMarkers(locations){

            const bounds = [];
            const markers = [];

            for (let i = 0; i < locations.length; i++) {
                const location = locations[i];

                if(location.lat && location.lon){
                    const pos = [location.lat, location.lon];
                    bounds.push(pos);
                    
                    const marker = this.createMarker(pos);

                    let popup_html = '<div class="marker-popup">';
                    popup_html += '<h3>'+location.name+'</h3><hr class="mb-2" />';

                    const address = this.$helper.getLocationAddress(location);

                    if(address != ''){
                        popup_html += '<div>'+address+'</div>';
                    }

                    popup_html += '</div>';

                    marker.bindPopup(popup_html);

                    markers.push(marker);
                }
            }

            return {markers:markers, bounds: bounds};
        },
        createMarker(pos) {
            let icon = this.$leaflet.divIcon({
                html: this.getMarkerHtml(),
                iconSize: [30, 34],
                iconAnchor: [15, 38],
                className: ''
            });

            const options = {
                icon: icon,
            }

            if(!this.readonly){
                options.draggable = 'true';
            }

            const marker = this.$leaflet.marker(pos, options);

            return marker;
        },
        getMarkerHtml() {
            return '<div class="eod-marker primary"><div class="triangle" style="border-top-color:var(--v-primary-base);"></div><i style="color:#ffffff;" class="mdi mdi-circle"></i></div>';
        },
        initMap(){
            if (!this.$refs.map) {
                return false;
            }

            if (this.map) {
                this.map.remove();
                this.map = null;
            }

            this.map = this.$leaflet.map(this.$refs.map, {
                center: [51.022684, 3.187897],
                zoom: 14,
                zoomControl: false
            });

            if(this.value && this.value[0]){
                const {markers, bounds} = this.getMarkers(this.value);
                this.map.fitBounds(bounds, { padding: [25, 25], maxZoom:12 });
                this.$leaflet.layerGroup(markers).addTo(this.map);
                
            }

            this.$leaflet.control.zoom({
                position: 'bottomleft'
            }).addTo(this.map);

            this.map.createPane('labels');
            this.map.getPane('labels').style.pointerEvents = 'none';

            this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png', {
                attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>'
            }).addTo(this.map);
            this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png', {
                attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>',
                pane: 'labels'
            }).addTo(this.map);
        }
    }
}
</script>