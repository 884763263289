<template>
    <v-container fluid>
        <main>
            <v-row>
                <v-col>
                </v-col>
                <v-col cols="auto">
                    <v-menu v-if="showCreate" offset-y close-on-click close-on-content-click>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mb-2 ml-2" color="secondary" rounded>
                    <v-icon left>mdi-plus-box-multiple</v-icon>
                    Toevoegen
                    <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                
                <v-list-item v-if="showCreateNew" :to="{name:'dashboard.create', query:params}">
                    <v-list-item-icon>
                        <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Nieuw dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-dialog v-model="existingDashboardDialog" scrollable>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-icon>
                                <v-icon>mdi-view-dashboard-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Bestaand dashboard</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-card flat>
                        <v-card-title class="mb-4">
                            <v-avatar color="primary" size="40" class="elevation-3 mr-4">
                                <v-icon dark>
                                mdi-view-dashboard-outline
                                </v-icon>
                            </v-avatar>
                            <span class="headline">Dashboards</span>
                        </v-card-title>    
                        <v-card-text>
                            <eod-dashboards @selected="selectionChanged" :showCreate="false" selectable></eod-dashboards>
                        </v-card-text>
                        <v-card-actions class="grey lighten-3 py-3 px-3">
                            <v-spacer></v-spacer>
                            <v-btn text @click="existingDashboardDialog = false" rounded>Annuleren</v-btn>
                            <v-btn color="secondary" depressed rounded @click="addSelectedTemplate">
                                <v-icon left>mdi-plus-circle-outline</v-icon> Toevoegen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-list>
        </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" xl="2" lg="3" md="4" v-for="(item) in items" :key="item.id">
                    <eod-storage-object :showImage="false" :selectable="selectable" @selectionChanged="objectSelectionChanged" @click="viewItem" :value="item" @delete="showDeleteDialog" @edit="editItem"></eod-storage-object>
                    <template v-if="showDefault">
                        <v-card color="primary" v-if="item.default" dark>
                            <v-card-text>
                                Standaard
                            </v-card-text>
                        </v-card>
                        <v-card v-else @click="setDefault(item)">
                            <v-card-text>
                                Stel in als standaard
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </main>

        <v-dialog v-if="itemToDelete" v-model="deleteDialog" persistent max-width="550">
            <v-card>
                <v-card-title class="mb-4">
                  <v-avatar color="error" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-trash-can-outline
            </v-icon>
          </v-avatar><span class="headline">Verwijderen?</span>
          </v-card-title>
                <v-card-text>
                  <span class="text-body-1">Weet u zeker dat u '<strong>{{itemToDelete.name}}</strong>' wilt verwijderen?</span>
                </v-card-text>
                <v-card-actions class="grey lighten-3 py-3 px-3">
                <v-spacer></v-spacer>
                <v-btn text @click="deleteDialog = false" rounded>Annuleren</v-btn>
                <v-btn color="error" depressed rounded @click="deleteItem">
                    <v-icon left>mdi-trash-can-outline</v-icon> Verwijderen
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import eodStorageObject from './eod-storage-object.vue';

export default {
    name: 'eod-dashboards',
    components:{
        'eod-storage-object': eodStorageObject,
    },
    props:{
        showDefault:{
            type:Boolean,
            default: false
        },
        showCreate:{
            type:Boolean,
            default: true
        },
        showCreateNew:{
            type:Boolean,
            default: false
        },
        selectable:{
            type:Boolean,
            default: false
        },
        params:{
            default: () => ({}),
            type: Object
        },
        value: {
            default: null,
            type: Array
        }
    },
    data: () => ({
        existingDashboardDialog: false,
        deleteDialog: false,
        itemToDelete: null,
        items: [],
        selectedLibraryItems: []
    }),
    mounted(){
        if(this.value){
            for (let i = 0; i < this.value.length; i++) {
                const template = this.value[i];
                if(template.type == 'DASHBOARD'){
                    this.items.push(template);
                }
            }
            
        }else{
            this.getDataFromApi();
        }
    },
    watch:{
        value(val){
            this.items = val;
        }
    },
    methods:{
        setDefault(template){
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                if(item.id == template.id){
                    this.items[i].default = true;
                }else{
                    this.items[i].default = false;
                }
            }

            this.$emit('input', this.items);
        },
        objectSelectionChanged(item, selected){
            if (selected) {
                this.selectedLibraryItems.push(item); 
            }else{
                for (let i = 0; i < this.selectedLibraryItems.length; i++) {
                    const selected_item = this.selectedLibraryItems[i];
                    if (selected_item.id == item.id) {
                        this.selectedLibraryItems.splice(i, 1);
                        break;
                    }
                }
            }

            this.$emit('selected', this.selectedLibraryItems);
        },
        selectionChanged(items){
            this.selectedLibraryItems = items;
        },
        showDeleteDialog(item){
            this.itemToDelete = item;
            this.deleteDialog = true;
        },
        deleteItem(){
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                if(item.id == this.itemToDelete.id){
                    this.items.splice(i, 1);
                }
            }
            this.$emit('input', this.items);
            this.deleteDialog = false;
        },
        editItem(item){
            this.$router.push({
                name: 'dashboard',
                params: {id: item.id},
                query: this.params
            });
        },
        viewItem(item){
            this.$router.push({
                name: 'dashboard',
                params: {id: item.id},
                query: this.params
            });
        },
        addSelectedTemplate(){
            for (let i = 0; i < this.selectedLibraryItems.length; i++) {
                
                this.items.push(this.selectedLibraryItems[i]);
                this.$emit('input', this.items);
                
            }

            this.existingDashboardDialog = false;
        },
        getDataFromApi(){

            let variables = {
                where: [
                    {
                        column: 'type',
                        operator: '=',
                        value: 'DASHBOARD',
                        chain: 'and'
                    }
                ]
            };

            this.$eod.get('templates', ['id', 'type', 'name', 'projects{id name}', 'companies{id name}', 'tasks{id name}', 'tags {id name}'], variables).then(result => {
                this.items = result.data.data.templates.edges;
                this.$forceUpdate();
            });
        }
    }
}
</script>