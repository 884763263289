<template>
    <eod-dialog :inset="false" hide-actions :value="value" icon="mdi-account-multiple" iconColor="primary" title="Contacten" @input="val => $emit('input', val)">
        <template v-slot:headerActions>
            <v-btn @click="$emit('input', false)" icon><v-icon>mdi-close</v-icon></v-btn>
        </template>
        <eod-contacts-info :value="contacts" hide-title dense></eod-contacts-info>
    </eod-dialog>
</template>
<script>
import eodContactsInfo from './eod-contacts-info';
import eodDialog from './eod-dialog';

export default {
    name: 'eod-contacts-dialog',
    components:{
        eodContactsInfo,
        eodDialog,
    },
    props:{
        value: Boolean,
        contacts: Array,
    }
}
</script>