import Template from './template';
import Query from './query';
import _ from 'lodash';

export default class Export extends Template {

    constructor(item) {
        if (!item) {
            super({
                name: null,
                type: 'EXPORT',
                access: 'PUBLIC',
                isActive: true,
                content: {
                    source:'measurements',
                    periodicity:null,
                    location:null,
                    format:'csv',
                    filters: [],
                    from:{
                        type:'fixed'
                    },
                    until:{
                        type:'fixed'
                    }
                }
            });
        }else{
            super(item);
        }
    }

    static OPTIONS = _.merge(Query.OPTIONS, {
        measurements:{
            filterOptions:[
                {
                    endpoint: 'measurementTypes',
                    key: 'measurement_type_id',
                    title: 'Type meetwaarde'
                }
            ]
        }
    });

    static PERIODICIY = [
        {
            value: null,
            text: 'Eenmalig'
        },
        {
            value: '0 4 * * *',
            text: 'Dagelijks'
        },
        {
            value: '0 4 * * 1',
            text: 'Wekelijks'
        }
    ];

    static SOURCES = [
        {
            value: 'measurements',
            text: 'Meetwaardes'
        },
        {
            value: 'events',
            text: 'Events'
        },
    ];

    static FORMATS = [
        {
            value:'csv',
            text: 'CSV'
        },
        {
            value: 'xlsx',
            text: 'Excel (xlsx)'
        },
        {
            value: 'parquet',
            text: 'Parquet'
        }
    ]
}