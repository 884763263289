<template>
    <v-dialog :value="value" @input="val => $emit('input', val)" max-width="800">
        <v-card>
            <v-card-title class="primary white--text">{{ label }}</v-card-title>
            <slot name="search">
                <div class="px-4 py-4">
                <v-text-field type="search" solo label="Zoeken..." hide-details rounded v-model="search" @keyup="filterItems"></v-text-field>
            </div>
            </slot>
            <v-virtual-scroll
        :items="filteredItems"
        height="800"
        max-height="80vh"
        item-height="50"
      >
        <template v-slot:default="{ item }">
        <v-list-item @click="$emit('click', item)" :key="item.id">
            <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        </template>
    </v-virtual-scroll>
</v-card>
</v-dialog>
</template>
<script>
export default {
    name: 'eod-object-selector',
    props:{
        label:{
            type: String,
            required:true
        },
        value: {
            type: Boolean,
        },
        endpoint: {
            type: String,
            required: true
        },
        fields: {
            type: Array,
            default: () => (['id','name'])
        },
        variabeles: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => {
        return {
            loading: false,
            items: [],
            search: null,
            filteredItems:[]
        }
    },
    mounted(){
        this.reload();
    },
    methods:{
        filterItems(){
            const self = this;
            if(this.search && this.search != ''){
                this.filteredItems = this.items.filter(function (el) {
                    return el.name.toLowerCase().includes(self.search.toLowerCase());
                });
            }else{
                this.filteredItems = this.items;
            } 
        },
        reload(){
            this.loading = true;
            this.$eod.get(this.endpoint, this.fields, this.variables)
                .then(response => {
                    if(response.data.data && response.data.data[this.endpoint]){
                        this.items = response.data.data[this.endpoint].edges;
                        this.filterItems();
                    }
                }).finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>