<template>
    <div>
        <div class="px-4 py-4 d-flex">
            <span class="subtitle-1">Topics</span>
            <v-spacer></v-spacer>
            <v-btn @click="addAcl" class="ml-4" rounded small depressed color="secondary"><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>
        </div>
    <v-data-table dense :headers="[{text:'Topic', value:'topic'}, {value:'acc', text:'Rechten'}, {text:null, value:'actions', width:50}]" :items="value">
        <template v-slot:item.topic="{item}">
            <span class="grey--text">{{prefix}}</span>{{ item.topic }}
        </template>
        <template v-slot:item.acc="{item}">
            {{ Acl.getAccess(item.acc).text }}
        </template>
        <template v-slot:item.actions="{item}">
            
            <eod-button-copy tooltip="Kopïeren naar klembord" :text="prefix+item.topic"></eod-button-copy>
            <v-btn @click="editAcl(item)" icon><v-icon small>mdi-pencil-outline</v-icon></v-btn>
            <v-btn @click="deleteAcl(item)" color="error" icon><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
        </template>
    </v-data-table>
    <eod-dialog v-if="showAclDialog" okText="Opslaan" width="800" @okAction="saveTopic" icon="mdi-send" icon-color="primary" v-model="showAclDialog" :title="'Topic '+(activeAcl.id?'aanpassen':'toevoegen')">
        <v-form ref="editForm">
        <v-text-field class="mb-2" :rules="[val => rules.required(val, 'Topic')]" dense outlined label="Topic*" v-model="activeAcl.topic">
        <template v-slot:prepend-inner>
        <span class="grey--text mt-1">{{ prefix }}</span>
        </template>
        </v-text-field>
        <v-select v-model="activeAcl.acc" dense outlined label="Rechten" :items="Acl.ACCESS"></v-select>
    </v-form>
    </eod-dialog>
    <eod-delete-dialog v-if="showDeleteDialog" @delete="deleteAclAction" :item="activeAcl" name-field="topic" v-model="showDeleteDialog"></eod-delete-dialog>
</div>
</template>
<script>
import eodDialog from './eod-dialog.vue';
import eodDeleteDialog from './eod-delete-dialog.vue';
import eodButtonCopy from './eod-button-copy.vue';

export default {
    components:{
        eodDialog,
        eodDeleteDialog,
        eodButtonCopy
    },
    props:{
        value: Array,
        defaults:Object
    },
    data:() => {
        return {
            Acl: require('./../models/acl').default,
            showAclDialog: false,
            showDeleteDialog: false,
            activeAcl:null,
            rules: {
                required: (value, fieldName) => !!value || (fieldName ? fieldName : 'Veld') + ' is verplicht.',
            },
            prefix: null
        }
    },
    created(){
        this.prefix = this.$eod.getOrganizationSetting('acls.prefix').value+'/'+this.$eod.getOrganization().id+'/';
    },
    methods:{
        addAcl(){
            this.activeAcl = {
                topic: '',
                acc:3
            };
            this.showAclDialog = true;
        },
        editAcl(acl){
            this.activeAcl = {...acl};
            this.showAclDialog = true;
        },
        deleteAcl(acl){
            this.activeAcl = acl;
            this.showDeleteDialog = true;
        },
        deleteAclAction(){
            let index = null;
            for (let i = 0; i < this.value.length; i++) {
                const acl = this.value[i];
                if(acl.id == this.activeAcl.id){
                    index = i;
                    break;
                }
            }

            if(index != null){
                this.$eod.delete('Acl', this.activeAcl.id).then(() => {
                    this.showDeleteDialog = false;
                    this.activeAcl = null;

                    const val = this.value;
                    val.splice(index, 1);
                    this.$emit('input', val);
                });
            }
            
        },
        saveTopic(){
            if(this.$refs.editForm.validate()){
                this.$eod.save('Acl', {...this.defaults, ...this.activeAcl}).then((response) => {
                    if(response.data.data.createAcl){
                        const val = this.value;
                        val.push({...this.activeAcl, ...response.data.data.createAcl});
                        this.$emit('input', val);
                    }else{
                        const val = this.value;
                        for (let i = 0; i < val.length; i++) {
                            const acl = val[i];
                            if(acl.id == this.activeAcl.id){
                                val[i] = {...this.activeAcl};
                                break;
                            }
                        }
                        this.$emit('input', val);
                    }

                    this.showAclDialog = false;
                    this.activeAcl = null;
                });
            }
            
            
        },
    }
}
</script>