export default class List {
    fields = []
    listItems = []
    itemType = "VALUE_TO_TEXT"
    isActive = true
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getItemType() {
        let settings = require('./../classes/settings');
        for (let i = 0; i < settings.listItemTypes.length; i++) {
            const type = settings.listItemTypes[i];
            if (type.id == this.itemType) {
                return type;
            }
        }

        return null;
    }
    getSaveData() {
        let data = Object.assign({}, this);
        delete data.listItems;

        return data;
    }
}