<template>
    <v-menu v-model="showMenu" offset-y :close-on-content-click="false" @input="menuChanged" :disabled="readonly">
        <template v-slot:activator="{ on, attrs }">
            <v-card :id="label?'card_item_treeview_'+$helper.slugify(label):''" flat :outlined="outlined" v-bind="attrs" v-on="on">
                <v-card-text>
                    {{ label }}
                    <v-row no-gutters>
                        <v-col v-for="(item, index) in items" :key="item.id" cols="12" sm="auto">
                            <v-chip class="mt-2 mr-2"><v-icon left v-html="getIconByLevel(index)"></v-icon>{{ item.name
                            }}</v-chip>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>
        <eod-item-treeview :id="label?'item_treeview_'+$helper.slugify(label):''" bodyStyle="height:220px;overflow-y:auto;" ref="treeview" hoverable openOnClick flat
            :activatable="true" :tree="tree" :fields="fields" :modules="modules"
            @change="treeviewSelected"></eod-item-treeview>
    </v-menu>
</template>
<script>
import eodItemTreeview from './eod-item-treeview.vue';

export default {
    components: {
        'eod-item-treeview': eodItemTreeview
    },
    props: {
        label: String,
        tree: Array,
        fields: Array,
        modules: Object,
        value: Array | Object,
        readonly: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showMenu: false,
        search: '',
        items: []
    }),
    mounted() {
        this.items = this.value;
    },
    watch: {
        value(val) {
            this.items = val;
        }
    },
    methods: {
        getIconByLevel(level) {
            let module = this.modules[this.tree[level]];
            return module.icon;
        },
        menuChanged(val) {
            if (val) {
                if (this.$refs.treeview) {
                    this.$refs.treeview.focusSearch();
                }
            }
        },
        inputChanged(val) {
            if (!this.showMenu) {
                this.showMenu = true;
            }
            this.search = val;
        },
        getItemsFlat(selectedItem, level) {
            if (!level) {
                level = this.tree.length - 1;
            }

            let itemsFlat = [selectedItem];

            let selectedEndpoint = this.tree[level];
            let selectedModule = this.modules[selectedEndpoint];

            if (selectedModule.parents) {
                for (const key in selectedModule.parents) {
                    if (Object.hasOwnProperty.call(selectedModule.parents, key)) {
                        const parentItemName = selectedModule.parents[key];
                        if (selectedItem[parentItemName]) {
                            let parent = this.getItemsFlat(selectedItem[parentItemName], level - 1);
                            for (let i = 0; i < parent.length; i++) {
                                const parentItem = parent[i];
                                itemsFlat.push(parentItem);
                            }
                        }
                    }
                }
            }

            return itemsFlat.reverse();
        },
        treeviewSelected(val) {
            this.$refs.treeview.loadRootTree();
            if (val && val[0]) {

                let selectedItem = val[0];
                let arr_return = this.getItemsFlat(selectedItem);

                this.showMenu = false;
                this.items = arr_return;
                this.$emit('input', arr_return);
            } else {
                this.$emit('input', null);
            }
        }
    }
}
</script>