<template>
    <div class="d-flex">
        <v-chip v-if="$attrs.label" label style="border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;">{{$attrs.label}}</v-chip>
        <v-autocomplete v-on="$listeners" style="border-top-left-radius: 0;border-bottom-left-radius: 0;" v-bind="$attrs" hide-details dense outlined background-color="secondary" :label="null" append-icon="mdi-chevron-down" dark></v-autocomplete>
    </div>
</template>
<script>
    export default {
        name: 'eod-filter-autocomplete',
    }
</script>