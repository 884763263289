<template>
    <v-menu
                        :value="show"
                        @input="(val) => $emit('showChanged', val)"
                        :close-on-content-click="false"
                        :nudge-width="200"
                    >
                    <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-if="value && value[0]" v-bind="attrs" v-on="on">
                                <v-list-item-avatar color="primary">
                                    <v-icon dark v-text="modules[tree[value[0].level]].icon"></v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="value[0].name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-else v-bind="attrs" v-on="on">
                                <v-list-item-avatar>
                                    <v-icon>mdi-magnify</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Selecteer een item...</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                    </template>
                    <v-card>
                        <slot name="prepend-item"></slot>
                    <eod-item-treeview flat :activatable="true" bodyStyle="max-height:450px;overflow-y:scroll;" :tree="tree" @change="changed" :value="value" :modules="modules"></eod-item-treeview>
                    <slot name="append-item"></slot>
                    </v-card>
                </v-menu>
</template>
<script>
import eodItemTreeview from './eod-item-treeview.vue'

export default {
    name:'eod-item-selector',
    components:{
        eodItemTreeview
    },
    props:{
        value: Object|Array,
        tree: Array,
        show: {
            type:Boolean,
            default:false
        }
    },
    data: () => ({
        modules: require('./../classes/settings').modules,
    }),
    methods: {
        changed(item){
            this.$emit('showChanged', false);
            this.$emit('input', item);
        }
    }
}
</script>