<template>
<v-select
          :value="value"
          @change="val => $emit('input', val)"
          :items="items"
          small-chips
          :disabled="readonly"
           outlined
           item-value="threshold"
           :item-text="getProjectName"
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              @click:close="data.parent.selectItem(data.item)"
            >
              <v-avatar
                color="primary"
                class="white--text"
                left
                v-text="data.item.threshold"
              ></v-avatar>
              {{ getProjectName(data.item) }}
            </v-chip>
          </template>
        </v-select>
</template>
<script>
export default {
    name: 'eod-measurement-list',
    props: {
        readonly: Boolean,
        value: Number,
        measurementType: Object,
    },
    data: () => ({
        items: [],
        projects: []
    }),
    mounted(){
        if (this.measurementType.valueConfigListItems && this.measurementType.valueConfigListItems.length > 0) {
          // Use valueConfigListItems
          this.items = this.measurementType.valueConfigListItems;
        }else if (this.measurementType.valueConfigList.listItems && this.measurementType.valueConfigList.listItems.length > 0){
          // Use valueConfigList.listItems
          this.items = this.measurementType.valueConfigList.listItems;
        }

        this.setProjects(this.items);
    },
    methods: {
      setProjects(items){
        let ids = [];
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            ids.push(item.name);
          }
          this.$eod.get('resolveProjects', ['id', 'name', 'description'], {
            whereIn:{
              column: 'id',
              array: ids
            }
          }).then(result => {
            this.projects = result.data.data.resolveProjects.edges;
          });
      },
      getProjectName(item){
        let id = item.name;
        for (let i = 0; i < this.projects.length; i++) {
          const project = this.projects[i];
          if (id == project.id) {
            return project.name;
          }
        }

        return '';
      }
    }
}
</script>