<template>
    <div>
        <h3 class="text-subtitle-1 white--text primary d-block px-2 py-2">{{ project.name }}</h3>
        <v-carousel v-if="project.favoriteStorageObjects" height="120" show-arrows-on-hover hide-delimiters
            :show-arrows="project.favoriteStorageObjects.length > 1" class="white">
            <v-carousel-item v-for="storageObject in project.favoriteStorageObjects" :key="storageObject.id">
                <v-img :src="$eod.getImageUrl(storageObject.key)" aspect-ratio="1.5" width="100%" height="100%" cover />
            </v-carousel-item>
        </v-carousel>
        <div class="grow-1 white">
            <eod-dashboards-projects :value="project" @edit="val => $emit('edit', val)" :hide-generated="hideGenerated"
                @delete="val => $emit('delete', val)" :hide-delete="hideDelete"></eod-dashboards-projects>
        </div>
    </div>
</template>
<script>
import eodDashboardsProjects from './eod-dashboards-projects';

export default {
    name: 'eod-project-popup',
    components: {
        eodDashboardsProjects
    },
    props: {
        project: Object,
        hideGenerated: {
            type: Boolean,
            default: false
        },
        hideDelete: {
            type: Boolean,
            default: false
        }
    },

}
</script>