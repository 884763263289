<template>
    <v-card flat v-if="value">
        <v-container fluid class="px-0 pt-0">
            
            <v-card flat class="d-flex align-items-center justify-content-center flex-column mb-4">
                <round-slider :key="updateSlider" :editableTooltip="false" :value="$moment(value.startedAt).unix()+','+$moment(value.startedAt).add(value.duration, 'milliseconds').unix()" rangeColor="var(--v-primary-base)" :change="rangeChanged" start-angle="315" end-angle="+270" sliderType="range" line-cap="round" radius="120" :tooltipFormat="formatTooltip" :min="minimum" :max="maximum" step="60"/>
                <div class="d-flex justify-content-center align-items-center" style="margin-top:-30px;">
                    <v-btn class="text-lowercase" color="primary lighten-2" rounded @click="addMinutes(-15)" depressed small>-15 min</v-btn>
                    <v-btn class="mx-2" @click="showEditAdvancedDialog" icon><v-icon>mdi-pencil-outline</v-icon></v-btn>
                    <v-btn class="text-lowercase" color="primary lighten-2" rounded @click="addMinutes(15)" depressed small>+15 min</v-btn>
                </div>
            </v-card>
            <div class="text-center text-subtitle-2" style="z-index:2;position:absolute;top:46px;left:50%;transform:translateX(-50%);opacity:0.4;" v-if="min && max">
                <strong>Inschatting</strong><br>
                {{$moment.duration((max-min), 'seconds').hours().pad(2)}}:{{$moment.duration((max-min), 'seconds').minutes().pad(2)}}
            </div>
            <v-row>
                <v-col>
                    <eod-list-item-select dense :disabled="readonly" v-if="$eod.getOrganizationSetting('responses.timetrackings.worktypes.listId')" outlined :value="value.workTypeId" @input="val => editProperty('workTypeId', val)" label="Type" nullable :listId="$eod.getOrganizationSetting('responses.timetrackings.worktypes.listId').value"></eod-list-item-select>
                </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row>
                <v-col>
                    <eod-user-selector dense :disabled="readonly" label="Gebruiker" :value="value.userId" @input="val => editProperty('userId', val)"></eod-user-selector>
                </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>
            <v-row>
                <v-col>
                    <v-textarea :disabled="readonly" label="Opmerking" outlined :value="value.comment" @input="val => editProperty('comment', val)" :rows="readonly?null:3" auto-grow hide-details></v-textarea>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="editAdvancedDialog" v-if="editAdvancedData" persistent>
            <v-card>
                <v-card-title>Aanpassen</v-card-title>
                <v-card-text>
                    <vc-date-picker :popover="{ visibility: 'focus' }" :minute-increment="1" :attributes="[{key: 'today', highlight: {color: 'orange', fillMode:'solid', class:'primary'},dates: new Date()}]" :value="editAdvancedData.dueAfterDate" @input="dueAfterDateChanged" mode="dateTime" is24hr :model-config="{type: 'string',mask: 'DD-MM-YYYY HH:mm'}">
                        <template v-slot="{ inputValue, inputEvents, updateValue }">
                            <v-text-field class="mb-4" hide-details label="Van" prepend-icon="mdi-calendar" :value="inputValue" @input="updateValue($event, {formatInput: false, hidePopover:false,debounce:1000})" @change="updateValue($event, {formatInput: true,hidePopover: false,})" v-on="inputEvents" dense outlined></v-text-field>
                        </template>
                    </vc-date-picker>
                    <vc-date-picker :popover="{ visibility: 'focus' }" :minute-increment="1" :attributes="[{key: 'today', highlight: {color: 'orange', fillMode:'solid', class:'primary'},dates: new Date()}]" :value="editAdvancedData.dueBeforeDate" @input="dueBeforeDateChanged" mode="dateTime" is24hr :model-config="{type: 'string',mask: 'DD-MM-YYYY HH:mm'}">
                        <template v-slot="{ inputValue, inputEvents, updateValue }">
                            <v-text-field hide-details label="Tot" prepend-icon="mdi-calendar" :value="inputValue" @input="updateValue($event, {formatInput: false, hidePopover:false,debounce:1000})" @change="updateValue($event, {formatInput: true,hidePopover: false,})" v-on="inputEvents" dense outlined></v-text-field>
                        </template>
                    </vc-date-picker>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="editAdvancedDialog = false" text>Annuleren</v-btn>
                    <v-btn color="secondary" depressed rounded @click="setEditAdvancedDate">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import eodListItemSelect from './eod-list-item-select.vue';
import RoundSlider from 'vue-round-slider'
import eodUserSelector from './eod-user-selector.vue';

export default {
  components: {
      eodListItemSelect,
      RoundSlider,
      eodUserSelector
      },
       name:'eod-add-timetracking',
       props:{
           defaultUsers:{
               type: Array,
               default:null
           },
           value:{
               type: Object,
               default: () => {
                   return {};
               }
           },
           readonly:{
            type: Boolean,
            default: false
           },
           min:{
            type: Number,
            default: null
           },
           max:{
            type: Number,
            default: null
           }
       },
       created(){

        this.calcMinimum();
        this.calcMaximum();

           let val = this.value;
           if(!this.value.organizationId){
               val.organizationId = this.$eod.getOrganization().id;
           }

           if(!this.value.startedAt){
               val.startedAt = this.$moment().toISOString();
           }

           if(!this.value.duration){
               val.duration = 3600000;
           }

           if(!this.value.userId){
               val.userId = this.$eod.getUser().id;
           }

           if(this.value.isInvoicable != false){
               val.isInvoicable = true;
           }

           const defaultWorkTypeSetting = this.$eod.getOrganizationSetting('responses.timetrackings.worktypes.default');
           if(defaultWorkTypeSetting && defaultWorkTypeSetting.value && !this.value.workTypeId){
                val.workTypeId = defaultWorkTypeSetting.value;
           }
           
           this.$emit('input', val);
       },
       data: () => {
           return {
               sliderValue: "0,0",
               showSlider: false,
               editAdvancedDialog: false,
               editAdvancedData: null,
               minimum: 0,
               maximum: 0,
               updateSlider: 987239723,
           }
       },
       methods:{
        addMinutes(minutes){
            let data = this.value;
            let seconds = minutes*60*1000;
            if(data.duration+seconds > 0){
                data.duration = data.duration+seconds;
            }

            this.$emit('input', data);
            this.recalculate();
        },
        recalculate(){
            this.$nextTick(() => {
                this.calcMinimum();
                this.calcMaximum();
            });
        },
        calcMaximum(){
            let max = null;

            if(this.max){
                if(this.value.startedAt){
                    if(this.max > this.$moment(this.value.startedAt).add(this.value.duration, 'milliseconds').unix()){
                        max = this.max;
                    }else{
                        max = this.$moment(this.value.startedAt).add(this.value.duration, 'milliseconds').unix();
                    }
                }else{
                    max = this.max;
                }
                
            }else if(this.value.startedAt){
                max = this.$moment(this.value.startedAt).add(this.value.duration, 'milliseconds').unix()
            }

            if(max){
                // end 1h after
                max = max + (60*60);
            }

            this.maximum = max;
        },
        calcMinimum(){
            let min = null;

            if(this.min){
                if(this.value.startedAt){
                    if(this.min < this.$moment(this.value.startedAt).unix()){
                        min = this.min;
                    }else{
                        min = this.$moment(this.value.startedAt).unix();
                    }
                }else{
                    min = this.min;
                }
            } else if(this.value.startedAt){
                min = this.$moment(this.value.startedAt).unix()
            }

            if(min){
                // Start 1h before
                min = min - (60*60);
            }

            this.minimum = min;
        },
        setEditAdvancedDate(){
            let data = this.value;
            data.startedAt = this.editAdvancedData.dueAfterDate;
            data.duration = this.$moment(this.editAdvancedData.dueBeforeDate).diff(this.editAdvancedData.dueAfterDate, 'milliseconds');

            this.editAdvancedDialog = false;

            this.$emit('input', data);
            this.$nextTick(() => {
                this.updateSlider++;
            });
            this.recalculate();
        },
        dueBeforeDateChanged(date){
            this.editAdvancedData.dueBeforeDate = this.$moment(date, 'DD-MM-YYYY HH:mm').toISOString();
        },
        dueAfterDateChanged(date){
            this.editAdvancedData.dueAfterDate = this.$moment(date, 'DD-MM-YYYY HH:mm').toISOString();
        },
        showEditAdvancedDialog(){
            this.editAdvancedData = {
                dueAfterDate: this.value.startedAt,
                dueBeforeDate: this.$moment(this.value.startedAt).add(this.value.duration, 'milliseconds').toISOString()
            };
            this.editAdvancedDialog = true;
        },
            rangeChanged(e){
                let val = e.value;
                const parts = val.split(',');
                let returnVal = this.value;
                returnVal.startedAt = this.$moment(parts[0]*1000).toISOString();
                returnVal.duration = this.$moment(parts[1]*1000).diff(parts[0]*1000, 'milliseconds');
                this.$emit('input', returnVal);
                this.recalculate();
            },
            formatTooltip(item){
                return this.$moment(item.value*1000).format('HH:mm');
            },
           editProperty(property, val){
               let timetrack = this.value;
               timetrack[property] = val;
               this.$emit('input', timetrack);
           }
       }
    }
</script>