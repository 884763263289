<template>
    <div class="eod-textarea" style="position:relative;">
        <v-textarea :id="$attrs.label?$helper.slugify($attrs.label):null" v-on="$listeners" v-bind="$attrs" outlined hide-details :rows="rows" :value="value"></v-textarea>
        <eod-translations v-if="translatable" class="mt-2" :value="value"></eod-translations>
        <div v-if="info" style="position:absolute;top:0;right:0;" class="grey lighten-3 rounded">
            <v-tooltip top open-on-click :open-on-focus="false">
                <template v-slot:activator="{on, attrs}">
                    <v-btn v-bind="attrs" v-on="on" icon small><v-icon small>mdi-information-variant</v-icon></v-btn>
                </template>
                <div v-html="info"></div>
            </v-tooltip>
        </div>
    </div>
</template>
<script>
import eodTranslations from './eod-translations.vue';

export default {
    name: 'eod-textarea',
    components:{
        eodTranslations
    },
    props:{
        info: String,
        translatable: Boolean,
        value: String,
        rows: {
            type: Number|String,
            default: 5
        }
    }
}
</script>