<template>
    <v-menu v-model="showMenu" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :class="fieldClass" :dense="dense" :label="label" v-bind="attrs" v-on="on" outlined hide-details :value="item.name" readonly clearable @input="valChanged"></v-text-field>
      </template>
      <v-card>
        <eod-treeview @click="itemClicked" :endpoint="endpoint" :parentProperty="parentProperty"></eod-treeview>
      </v-card>
    </v-menu>
</template>
<script>
import eodTreeview from './eod-treeview.vue';

export default {
    name: 'eod-treeview-dialog',
    components:{
        eodTreeview
    },
    props: {
        endpoint: String,
        parentProperty: String,
        value: String|Object,
        label: String,
        dense: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        fieldClass: String
    },
    data(){
        return {
            showMenu: false,
            item: {
                id: null,
                name: ''
            }
        }
    },
    watch:{
        value(newVal, oldVal){
            if(newVal && newVal != oldVal){
                if(this.returnObject){
                    this.initItem(newVal.id);
                }else{
                    this.initItem(newVal);
                }
            }else{
                this.item = {
                    id: null,
                    name: ''
                }
            }
        }
    },
    mounted(){
        if(this.value){
            if(this.returnObject){
                this.initItem(this.value.id);
            }else{
                this.initItem(this.value);
            }
            
        }
    },
    methods:{
        initItem(itemId){
            this.$eod.get(this.endpoint, ['id', 'name'], {
                    where: [
                        {
                            column: 'id',
                            operator: '=',
                            value: itemId
                        }
                    ]
                }).then(response => {
                    if(response.data.data[this.endpoint].edges){
                        this.item = response.data.data[this.endpoint].edges[0];
                    }
                });
        },
        valChanged(val){
            if(!val){
                this.$emit('input', null);
                this.item = {
                    id: null,
                    name: ''
                }
            }
        },
        itemClicked(item){
            if(this.returnObject){
                this.$emit('input', item);
            }else{
                this.$emit('input', item.id);
            }
            
            this.item = item;
            this.showMenu = false;
        }
    }
}
</script>