<template>
    <div class="row-pointer">
        <v-data-table dense :headers="headers" :group-by="['company.name']" sort-by="project.name" :items="itemsFlat"
            item-key="index" @click:row="(item) => $emit('itemClicked', item)" :search="search">
            <template v-slot:top>
                <div class="px-4 py-2">
        <v-text-field
          v-model="search"
          label="Zoeken..."
          dense outlined
          hide-details
        ></v-text-field>
    </div>
      </template>
            <template v-slot:item.id="{ item }">
                <v-btn icon small><v-icon color="primary" size="18">mdi-eye</v-icon></v-btn>
            </template>
            <template v-slot:group.header="{ group, headers, toggle, isOpen }">
                <td :colspan="headers.length">
                    <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                    {{ group }}
                </td>
            </template>
        </v-data-table>
    </div>
</template>
<style lang="css" scoped>
.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>
<script>
export default {
    name: 'eod-dashboards-list',
    data() {
        return {
            loading: false,
            search:'',
            headers: [{
                value: 'id',
                text: '',
                groupable: false,
            },
            {
                value: 'name',
                text: 'Naam',
                groupable: false,
            },
            {
                value: 'project.name',
                text: 'Project',
            },
            {
                value: 'project.description',
                text: 'Project',
            },
            {
                value: 'company.name',
                text: 'Bedrijf',
            }],
            itemsFlat: []
        }
    },
    mounted() {
        this.getDataFromApi();
    },
    methods: {
        flattenItems(items) {
            const itemsFlat = [];
            for (let i = 0; i < items.length; i++) {
                const company = items[i];
                for (let j = 0; j < company.projects.length; j++) {
                    const project = company.projects[j];
                    for (let t = 0; t < project.templates.length; t++) {
                        const template = project.templates[t];
                        itemsFlat.push({
                            index: itemsFlat.length,
                            id: template.id,
                            name: template.name,
                            project: project,
                            company: company,
                        });
                    }
                }
            }

            this.itemsFlat = itemsFlat;
        },
        getProjectTemplates(project) {
            let templates = [];
            for (let i = 0; i < this.templates.length; i++) {
                const template = this.templates[i];
                if (template.accessProject && template.accessProject.id == project.id) {
                    templates.push({ ...template });
                    this.templates.splice(i, 1);
                    i--;
                }
            }

            return templates;
        },
        getDataFromApi() {

            this.loading = true;

            let variables = this.$helper.mergeObjects({
                offset: this.page - 1,
                limit: this.itemsPerPage,
                orderBy: {
                    column: 'name',
                    type: 'ASC'
                },
                where: [
                    {
                        column: 'isActive',
                        operator: '=',
                        value: '1'
                    }
                ]
            }, this.$helper.filtersToVariables(this.filters));

            return this.$eod.get('resolveProjects', ['id', 'name', 'description', 'company{id name}', 'locations{lat lon}'], variables).then(projectsResult => {
                variables.where.push({
                    column: 'type',
                    operator: '=',
                    value: 'DASHBOARD'
                });
                return this.$eod.get('templates', ['id', 'type', 'access', 'accessProject{id isActive name locations{name streetAddress postalCode locality country lat lon}}', 'name'], variables).then(templatesResult => {
                    let templates = [];
                    for (let i = 0; i < templatesResult.data.data.templates.edges.length; i++) {
                        const template = templatesResult.data.data.templates.edges[i];
                        if (!(template.accessProject && template.accessProject.isActive == false)) {
                            templates.push(template);
                        }
                    }

                    this.templates = templates;
                    return projectsResult.data.data.resolveProjects.edges;
                });
            }).then(projects => {
                let projectByCompanies = {};

                for (let i = 0; i < projects.length; i++) {
                    let project = projects[i];

                    project.templates = this.getProjectTemplates(project);

                    if (project.templates.length == 0) {
                        continue;
                    }

                    if (!project.company) {
                        project.company = { id: null, name: 'NA' };
                    }

                    if (!projectByCompanies[project.company.id]) {
                        projectByCompanies[project.company.id] = {
                            ...project.company,
                            projects: []
                        }
                    }

                    projectByCompanies[project.company.id].projects.push(project);
                }

                this.otherTemplates = this.templates;

                projectByCompanies = Object.values(projectByCompanies);
                projectByCompanies.sort((a, b) => (a.name > b.name) ? 1 : -1)

                this.items = projectByCompanies;
                this.loading = false;

                this.flattenItems(projectByCompanies);
                this.$emit('loaded', projectByCompanies);
            });



        },
    }

}
</script>