import User from './user';
import settings from './../classes/settings';

export default class Request {
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }

    getUser(){
        if(this.user){
            return new User(this.user);
        }
        return null;
    }

    getStatusCode(){
        if (this.statusCode){
            return settings.getStatusCode(this.statusCode);
        }
        return null;
    }
}