<template>
    <v-card v-bind="$attrs" v-on="$listeners">
        <v-card-title v-if="icon || title || $slots['header-icon'] || $slots['header-title']" class="text-body-1 pb-2">
            <slot name="header-icon"><v-icon small left>{{ icon }}</v-icon></slot>
            <slot name="header-title">{{ title }}</slot>
        <v-spacer></v-spacer>
        <slot name="header-actions"></slot>
        </v-card-title>
        <slot name="default">
        </slot>
    </v-card>
</template>
<script>
export default {
    name: 'eod-card',
    props:{
        title: String,
        icon: String,
    }
}
</script>