<template>
    <div v-if="value">
        <div class="mb-4">
            <eod-comments v-if="value.responses[0].comments && value.responses[0].comments[0]" :value="value.responses[0].comments" readonly></eod-comments>
        </div>
        <eod-timetrackings class="mb-4" v-if="value.responses[0].timetrackings" :value="value.responses[0].timetrackings" :min="$moment(value.dueAfterDate).unix()" :max="$moment(value.dueBeforeDate).unix()" open @input="updateTimetrackings"></eod-timetrackings>
        <eod-task-info v-model="value.parent" :show-title="showTitle"></eod-task-info>
    </div>
</template>
<script>
import eodTaskInfo from './eod-task-info.vue';
import eodTimeTrackings from './eod-timetrackings.vue';
import eodComments from './eod-comments.vue';

export default {
    name:"eod-response-overview",
    components: {
        'eod-task-info': eodTaskInfo,
        'eod-timetrackings': eodTimeTrackings,
        'eod-comments': eodComments,
    },
    props: {
        value: Object,
        showTitle:{
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showProductsDialog: false
    }),
    methods:{
        updateTimetrackings(timetrackings){
            let job = this.value;
            job.responses[0].timetrackings = timetrackings;
            this.$emit('input', job);
        }
    }
}
</script>