<template>
    <div>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <template v-if="!loading && service">
            <v-card-text :style="isPopup?'height:240px;overflow-y:auto;':''">
                <v-card flat outlined>
                        <v-card-subtitle class="grey lighten-4 py-2">Algemeen</v-card-subtitle>
                        <v-card-text class="mt-2">
                            <div v-if="service.description && service.description != ''"><strong>Omschrijving:</strong> {{service.description}}</div>
                            <div v-if="service.serviceType"><strong>Type:</strong> {{service.serviceType.name}}</div>
                            <div v-if="service.startDate"><strong>Startdatum:</strong> {{$moment(service.startDate).format('YYYY-MM-DD')}}</div>
                            <div v-if="service.endDate"><strong>Einddatum:</strong> {{$moment(service.endDate).format('YYYY-MM-DD')}}</div>
                        </v-card-text>
                    </v-card>
                    <eod-tags :value="service.tags" small></eod-tags>
            </v-card-text>
            </template>
        
     </div>
</template>
<script>
import eodTags from './eod-tags.vue';

export default {
    name:'eod-service-info',
    components:{
        'eod-tags': eodTags
    },
    props:{
        value: String,
        isPopup: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        loading: true,
        service: null,
    }),
    mounted(){
        this.$eod.getById('service', this.value, ['id name description tags{id name color} serviceType{id name} startDate endDate'])
            .then(response => {
                if(response.data.data.service){
                    this.service = response.data.data.service;
                }
            }).finally(() => {
                this.loading = false;
            });
    }
}
</script>