<template>
    <div>
        <v-row>
            <v-col cols="12" md="8">
                <v-card outlined>
                    <v-card-title class="text-subtitle-1">Dienst</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field :value="value.name" @input="val => updateProperty('name', val)"
                                    :rules="nameRules" label="Naam*" required outlined hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field :value="value.description" @input="val => updateProperty('description', val)"
                                    label="Omschrijving" outlined hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <eod-object-autocomplete :dense="false" :rules="objectRules"
                                    :fields="['id', 'name', 'periodicityCode']" required return-object
                                    endpoint="serviceTypes" label="Type dienst*" :value="value.serviceTypeId"
                                    @input="serviceTypeChanged"></eod-object-autocomplete>
                            </v-col>
                            <v-col>
                                <eod-object-autocomplete :dense="false" :rules="objectRules" required endpoint="companies"
                                    label="Bedrijf*" :value="value.companyId"
                                    @input="val => updateProperty('companyId', val)"></eod-object-autocomplete>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
                <v-card class="mt-4" outlined>
                    <v-card-title class="text-subtitle-1">Periode</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-menu ref="startDateMenu" v-model="datepickers.startDate" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="value.startDateFormatted"
                                            @input="val => updateProperty('startDateFormatted', val)" label="Begindatum"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                                            hide-details></v-text-field>
                                    </template>
                                    <v-date-picker :value="value.startDateFormatted"
                                        @input="(val) => { updateProperty('startDateFormatted', val); datepickers.startDate = false; }"
                                        no-title scrollable></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu ref="endDateMenu" v-model="datepickers.endDate" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :value="value.endDateFormatted"
                                            @input="val => updateProperty('endDateFormatted', val)" label="Einddatum"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                                            hide-details></v-text-field>
                                    </template>
                                    <v-date-picker :value="value.endDateFormatted"
                                        @input="(val) => { updateProperty('endDateFormatted', val); datepickers.endDate = false; }"
                                        no-title scrollable></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete :items="settings.periodicity" :value="value.periodicityCode"
                                    @input="val => updateProperty('periodicityCode', val)" label="Herhaling" outlined
                                    hide-details></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="mb-4" outlined>
                    <v-card-text>
                        <label>Tags</label>
                        <eod-tags deletable addable :value="value.tags"
                            @input="val => updateProperty('tags', val)"></eod-tags>
                    </v-card-text>
                </v-card>
                <v-card outlined>
                    <v-card-text>
                        <v-textarea rows="6" label="Notities" outlined hide-details :value="value.notes"
                            @input="val => updateProperty('notes', val)"></v-textarea>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { eodTags, eodObjectAutocomplete } from './../../index';

export default {
    name: 'eod-service-detail',
    components: {
        'eod-tags': eodTags,
        'eod-object-autocomplete': eodObjectAutocomplete
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        flat: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            item: {},
            nameRules: [
                v => !!v || 'Naam is verplicht',
            ],
            objectRules: [
                v => !!v || 'Item selecteren is verplicht',
            ],
            serviceTypes: [],
            datepickers: {
                startDate: false,
                endDate: false,
            },
            settings: require('./../classes/settings'),
        }
    },
    mounted() {
        if (this.value) {
            this.init();
        }
    },
    watch: {
        value(newVal) {
            if (newVal) {
                this.init();
            }
        }
    },
    methods: {
        async init() {
            let item = this.value;

            if (!item.id) {
                let periodicityCode = null;

                const setting = this.$eod.getOrganizationSetting('services.periodicity.default');
                if (setting) {
                    periodicityCode = setting.value;
                }

                const defaultServiceTypeSetting = this.$eod.getOrganizationSetting('services.types.default');
                if (defaultServiceTypeSetting && defaultServiceTypeSetting.value) {

                    item.serviceTypeId = defaultServiceTypeSetting.value;

                    const defaultServiceType = await this.$eod.getById('serviceType', defaultServiceTypeSetting.value, ['id', 'name', 'periodicityCode'])
                        .then(response => {
                            return response.data.data.serviceType;
                        });

                    if (defaultServiceType.periodicityCode) {
                        periodicityCode = defaultServiceType.periodicityCode;
                    }
                }

                item.periodicityCode = periodicityCode;

                let now = Date.now();
                item.startDateFormatted = this.$moment(now).format('YYYY-MM-DD');

                if (periodicityCode) {
                    const newDate = this.$eod.getDateByPeriodicityCode(periodicityCode, now);
                    if (newDate) {
                        item.endDateFormatted = newDate.format('YYYY-MM-DD');
                    }
                }

                this.$emit('input', item);

                this.$nextTick(() => {
                    this.$forceUpdate();
                });

            }
        },
        serviceTypeChanged(serviceType) {
            this.updateProperty('serviceTypeId', serviceType.id);
        },
        updateProperty(propertyName, propertyValue) {
            let item = this.value;
            if (item[propertyName] !== propertyValue) {
                item[propertyName] = propertyValue;
                this.$emit('input', item);
            }

        },
    }
}
</script>