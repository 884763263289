<template>
    <div>
        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
        <template v-if="!loading && project">
            <v-card-title>
                <v-tabs fixed-tabs v-model="tab">
                    <v-tab href="#general"><v-icon>mdi-information-variant</v-icon></v-tab>
                    <v-tab href="#location"><v-icon>mdi-map-marker-outline</v-icon></v-tab>
                    <v-tab href="#contacts"><v-icon>mdi-account-multiple</v-icon></v-tab>
                </v-tabs>
            </v-card-title>
            <v-card-text :style="isPopup?'height:240px;overflow-y:auto;':''">
                <v-tabs-items v-model="tab">
                    <v-tab-item value="general" transition="transition-fade">
                        <eod-project-general :value="project"></eod-project-general>
                    </v-tab-item>
                    <v-tab-item value="location" transition="transition-fade">
                        <eod-locations-info :value="project.locations" flat outlined hide-title></eod-locations-info>
                    </v-tab-item>
                    <v-tab-item value="contacts" transition="transition-fade">
                        <eod-contacts-info @showUserDetails="userId => $emit('showUserDetails', userId)" :value="project.users" flat outlined hide-title show-edit dense></eod-contacts-info>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </template>
     </div>
</template>
<script>
import eodLocationsInfo from './eod-locations-info.vue';
import eodContactsInfo from './eod-contacts-info.vue';
import eodProjectGeneral from './eod-project-general.vue';
import eodTags from './eod-tags.vue';

export default {
    name:'eod-project-info',
    components:{
        'eod-locations-info': eodLocationsInfo,
        'eod-contacts-info': eodContactsInfo,
        'eod-project-general': eodProjectGeneral,
        'eod-tags': eodTags,
    },
    props:{
        value: String,
        isPopup: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        loading: true,
        project: null,
        tab: 'general'
    }),
    mounted(){
        this.$eod.getById('project', this.value, ['id name description tags{id name color} users{id, firstName, lastName, username, email, assignedRealmRoles{id name}, attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone}} locations{name description streetAddress postalCode locality region country notes department}'])
            .then(response => {
                if(response.data.data.project){
                    this.project = response.data.data.project;
                }
            }).finally(() => {
                this.loading = false;
            });
    }
}
</script>