<template>
    <div>
    <div class="px-4 py-4">
        <v-text-field dense outlined hide-details placeholder="Zoeken..." prepend-inner-icon="mdi-magnify" @input="search" v-model="searchVal" clearable></v-text-field>
    </div>
    <v-data-table
        :headers="headers"
        :items="visibleItems"
        :server-items-length="totalItems"
        :options.sync="options"
        :footer-props="{
            'items-per-page-options': [10, 50, 100],
            showFirstLastPage: true,
        }"
        :loading="loading"
        locale="nl-BE"
        item-key="id"
        dense
        fixed-header
        :height="250"
        @toggleChildren="toggleChildren"
    >
        <template v-slot:item="{item}">
            <tr :data-tree-collapsed="(item.hasOwnProperty('treeCollapsed') && !item.treeCollapsed)?false:true" :data-tree-level="item.treeLevel?item.treeLevel:0" :data-item-id="item.id" :data-children-count="item.numberOfChildren">
                <td v-for="(header, index) in headers" ref="item" :key="index"><a @click="$emit('click', item)">{{item[header.value]}}</a></td>
            </tr>
        </template>
    </v-data-table>
    </div>
</template>
<script>
export default {
    name: 'eod-treeview',
    props:{
        endpoint: String,
        parentProperty: String,
    },
    data(){
        return {
            items: [],
            loading: false,
            totalItems: 0,
            cancelRequest: null,
            childrenLoading: false,
            options: {},
            headers:[
                {
                    value: 'name',
                    text: 'Naam',
                    sortable: false
                }
            ],
            searchTimeout: null,
            searchVal: null
        }
    },
    watch: {
      options: {
        handler () {
            this.getDataFromApi();
        },
        deep: true,
      },
    },
    computed:{
        visibleItems(){
            let items = [];
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                if(!item.hasOwnProperty('treeVisible') ||  item.treeVisible == true){
                    items.push(item);
                }
            }
            return items;
        }
    },
    methods:{
        async search(val){
            clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                this.getDataFromApi();
            }, 500);
        },
        makeTree(allItems, parentId, treeLevel){
            let tree = [];

            if(!treeLevel){
                treeLevel = 0;
            }

            for (let i = 0; i < allItems.length; i++) {
                let item = allItems[i];
                if((!parentId && !item.parent) || (parentId && item.parent && item.parent.id == parentId)){
                    const children = this.makeTree(allItems, item.id, treeLevel+1);

                    item.treeLevel = treeLevel;
                    if(children.length > 0){
                        item.treeCollapsed = false;
                    }else{
                        item.treeCollapsed = true;
                    }
                    
                    tree.push(item);
                    
                    for (let j = 0; j < children.length; j++) {
                        const child = children[j];
                        tree.push(child);
                    }
                }
            }

            return tree;
        },
        loadTree(items, fetchedIds, allItems){
            return new Promise((resolve, reject) => {
                let toFetchIds = [];

                if(!fetchedIds){
                    fetchedIds = [];
                }

                if(!allItems){
                    allItems = [];
                }

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    allItems.push(item);
                    fetchedIds.push(item.id);
                }

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if(item.parent && !fetchedIds.includes(item.parent.id) && !toFetchIds.includes(item.parent.id)){
                        toFetchIds.push(item.parent.id);
                    }
                }

                if(toFetchIds.length > 0){
                    this.$eod.get(this.endpoint, ['id', 'name', 'numberOfChildren', 'parent{id}'], {
                        whereIn: {
                            column: 'id',
                            array: toFetchIds
                        }
                    })
                    .then(response => {
                        resolve(this.loadTree(response.data.data[this.endpoint].edges, fetchedIds, allItems));
                    });
                }else{
                    resolve(allItems);
                }
            });
        },
        getQueryVariables(){
            let variables = {};
            variables.orderBy = {
                column: 'name',
                type: 'asc'
            }

            return variables;
        },
        getDataFromApi(){

            if(this.cancelRequest){
                this.cancelRequest.cancel();
            }

            this.loading = true;
            let variables = this.getQueryVariables();

            if (this.options.page) {
                variables.offset = this.options.page-1;
            }
            if (this.options.itemsPerPage && this.options.itemsPerPage >= 0) {
                variables.limit = this.options.itemsPerPage;
            }

            if(this.searchVal && this.searchVal != ''){
                variables.where = [
                    {
                        column: 'name',
                        operator: 'ILIKE',
                        value: '%'+this.searchVal+'%'
                    }
                ];
            }else{
                variables.whereNull = {
                    column: this.parentProperty,
                };
            }

            this.items = [];

            this.cancelRequest = this.$eod.getCancelRequest();

            let extra = {
                cancelToken: this.cancelRequest.token
            };

            return this.$eod.get(this.endpoint, ['id', 'name', 'parent{id}', 'numberOfChildren'], variables, extra).then(async (result) => {

                let items = [];
                this.totalItems = 0;

                if(result.data.data && result.data.data[this.endpoint]){
                    items = result.data.data[this.endpoint].edges;
                    this.totalItems = result.data.data[this.endpoint].totalCount;   
                }

                return this.loadTree(items)
                    .then(allItems => {
                        this.items = this.makeTree(allItems);

                        this.$nextTick(() => {
                            this.loading = false;
                        });

                        return this.items;
                    });                
            }).catch(e => {
                if(e.__CANCEL__){
                    this.$log.info('Previous request aborted.', e);
                }else{
                    this.loading = false;
                    this.$log.error('Error loading items!', e);
                }
            });
        },
        toggleChildren(itemId){
            
            if(!this.childrenLoading){
                this.childrenLoading = true;

                let parentItemIndex = null;
                for (let i = 0; i < this.items.length; i++) {
                    const item = this.items[i];
                    if(item.id == itemId){
                        parentItemIndex = i;
                    }
                }

                if(!this.items[parentItemIndex].hasOwnProperty('treeCollapsed') || this.items[parentItemIndex].treeCollapsed){
                    if(this.items[parentItemIndex].treeLoaded){
                        for (let i = 0; i < this.items.length; i++) {
                            const item = this.items[i];
                            if(item.treeParentId == itemId){
                                item.treeVisible = true;
                            }
                        }

                        this.$nextTick(() => {
                            this.$forceUpdate();
                        });
                        this.items[parentItemIndex].treeCollapsed = false;
                        this.childrenLoading = false;
                    }else{
                        this.$eod.get(this.endpoint, ['id', 'name', 'numberOfChildren'], {
                            where: [
                                {
                                    column: this.parentProperty,
                                    operator: '=',
                                    value: itemId
                                }
                            ]
                        })
                        .then(response => {
                            
                            if(response.data.data[this.endpoint].edges){
                                let childItems = response.data.data[this.endpoint].edges;
                                // Insert items after parent
                                const parentItem = this.items[parentItemIndex];
                                this.items[parentItemIndex].treeCollapsed = false;
                                this.items[parentItemIndex].treeLoaded = true;
                                for (let j = 0; j < childItems.length; j++) {
                                    childItems[j].treeLevel = parentItem.treeLevel?(parentItem.treeLevel+1):1;
                                    childItems[j].treeVisible = true;
                                    childItems[j].treeParentId = itemId;
                                    this.items.splice(parentItemIndex+1, 0, childItems[j]);
                                }
                                this.$nextTick(() => {
                                    this.$forceUpdate();
                                });
                            }
                        }).finally(() =>{
                            this.childrenLoading = false;
                        });
                    }
                }else{
                    const itemLevel = this.items[parentItemIndex].treeLevel?this.items[parentItemIndex].treeLevel:0;
                    for (let i = parentItemIndex+1; i < this.items.length; i++) {
                        const item = this.items[i];
                        if(item.treeLevel > itemLevel){
                            this.items[i].treeCollapsed = true;
                            item.treeVisible = false;
                        }else{
                            break;
                        }
                    }

                    this.items[parentItemIndex].treeCollapsed = true;
                    this.childrenLoading = false;

                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                }

                
            }
            
        },
    }
}
</script>