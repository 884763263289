const { Service } = require('../../eodvuecomponents');

module.exports = {
    routes: [{
        path: '/services',
        name: 'services',
        meta:{
            permissions:['services.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/services/detail/:id?',
        name: 'service',
        meta:{
            permissions:['services.create', 'services.update']
        },
        component: () => import('./views/detail.vue')
    },
    {
        path: '/services/types',
        name: 'serviceTypes',
        meta:{
            permissions:['servicetypes.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/services/types/detail/:id?',
        name: 'serviceType',
        meta:{
            permissions:['servicetypes.create', 'servicetypes.update']
        },
        component: () => import('./types/detail.vue')
    }],
    search: Service.searchConfig,
    detail: {
        route: 'service',
        endpoint: 'service',
        objectName: 'Service',
        fields: ['id', 'name', 'description', 'notes', 'tags{id name color}', 'company{id}', 'serviceType{id}', 'startDate', 'endDate', 'periodicityCode'],
        tabs: [{
                tab: 'general',
                text: 'Algemeen'
            },
            {
                tab: 'tasks',
                text: 'Taken',
                counter: {
                    endpoint: 'countTasks',
                    getVariables: (item) => {
                        return {
                            where: [{
                                    column: 'category',
                                    operator: '=',
                                    value: 'JOB'
                                },
                                {
                                    column: 'serviceId',
                                    operator: '=',
                                    value: item.id
                                },
                            ]
                        };
                    }
                }
            },
        ],
    },
    index: {
        route: 'services',
        endpoint: 'services',
        fields: ['id', 'name', 'description', 'tags{id name color}', 'serviceType{id name}', 'createdAt', 'updatedAt', 'company{id name}'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Omschrijving',
                value: 'description',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Type',
                value: 'serviceType.name',
                sortable: false,
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Bedrijf',
                value: 'company',
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-company.vue'),
                extra: {
                    icon: 'mdi-office-building-outline',
                    collapsed: false,
                }
            },
            {
                text: 'Tags',
                value: 'tags',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-tags.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
    },
    export: {
        endpoint: 'services',
        fields: ['id', 'name', 'description', 'startDate', 'endDate', 'createdAt', 'updatedAt']
    },
    import: {
        endpoint: 'Services',
        objectName: 'Service',
    },
    itemObject: Service,
    types: {
        index: {
            route: 'serviceTypes',
            endpoint: 'serviceTypes',
            fields: ['id', 'name'],
            headers: [
                {
                    text: 'Naam',
                    value: 'name',
                    component: () => import('./../default/columns/link.vue')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('./../default/columns/date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('./../default/columns/date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
            tabs: [{
                path: 'services',
                text: 'Overzicht'
            }, {
                path: 'serviceTypes',
                text: 'Types'
            }],
        },
        detail: {
            route: 'serviceType',
            endpoint: 'serviceType',
            objectName: 'ServiceType',
            fields: ['id', 'name', 'description', 'periodicityCode'],
            tabs: []
        },
        export: {
            endpoint: 'serviceTypes',
            fields: ['id', 'name', 'description']
        },
        import: {
            endpoint: 'ServiceTypes',
            objectName: 'ServiceType',
        },
    }
}