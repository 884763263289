import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const main_routes = [
  {
    path: '/',
    redirect: { name: 'dashboards' }
  },
  {
    path: '/403',
    name: '403',
    component: () => import('./../403.vue')
  }
];

let routes = [
  ...main_routes,
  ...require('./../modules/companies').routes,
  ...require('./../modules/products').routes,
  ...require('./../modules/projects').routes,
  ...require('./../modules/services').routes,
  ...require('./../modules/tasks').routes,
  ...require('./../modules/storage').routes,
  ...require('./../modules/measurements').routes,
  ...require('./../modules/organization').routes,
  ...require('./../modules/profile').routes,
  ...require('./../modules/dashboard').routes,
  ...require('./../modules/lists').routes,
  ...require('./../modules/data').routes,
  ...require('./../modules/alarms').routes,
  ...require('./../modules/depots').routes,
  ...require('./../modules/vehicles').routes,
  ...require('./../modules/events').routes
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
