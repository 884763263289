<template>
    <div v-if="filters.length > 0">
        <v-row class="align-items-center">
            <v-col>
                    <eod-graph-filter-chip v-for="(filter, index) in activeFilters" :value="filter"
                        :key="'active_filter_' + index" @delete="clearFilter(filter)"></eod-graph-filter-chip>
            </v-col>
            <v-col cols="auto">
                <v-menu v-model="showFilters" offset-y :close-on-content-click="false" :close-on-click="false" bottom left transition="slide-y-transition">
                    <template v-slot:activator="{on, attrs}">
                        <v-card class="py-1 px-2 elevation-0" v-on="on" v-bind="attrs" :color="activeFilters.length > 0?'primary':'secondary'" dark>
                            <v-icon class="py-1" small>mdi-filter</v-icon>
                            <v-icon class="py-1" small right v-html="showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
                        </v-card>
                    </template>
                    <v-card>
                        <v-card-title class="subtitle-1"><v-icon small left>mdi-filter-outline</v-icon>Filters</v-card-title>
                        <v-card-text>
                        <v-row>
                            <v-col  v-for="filter in visibleFilters" :key="filter.measurementTypeId">
                                <label>{{ filter.label }}</label>
                                <v-autocomplete v-if="filter.measurementTypeId" hide-details v-model="filters[filter.index].value"
                                    @change="$emit('change', filters)" dense outlined :items="filter.listItems"
                                    item-value="threshold" item-text="name"></v-autocomplete>
                                <eod-user-selector
                                    v-if="filter.endpoint && (filter.endpoint == 'resolveUsers' || filter.endpoint == 'users')"
                                    hide-details v-model="filters[filter.index].value" @change="$emit('change', filters)" dense
                                    outlined></eod-user-selector>
                                <eod-object-autocomplete v-else-if="filter.endpoint" v-model="filters[filter.index].value"
                                    hide-details :endpoint="filter.endpoint" @change="$emit('change', filters)"
                                    clearable></eod-object-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                        
                    </v-card>
                </v-menu>
                
            </v-col>
            
        </v-row>
    </div>
</template>
<script>
import eodObjectAutocomplete from './../eod-object-autocomplete.vue';
import eodGraphFilterChip from './eod-graph-filter-chip.vue';
import eodUserSelector from './../eod-user-selector.vue';

export default {
    name: 'eod-graph-filters',
    components: {
        eodObjectAutocomplete,
        eodGraphFilterChip,
        eodUserSelector
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        showFilters: false,
        measurementTypeIds: [],
        objectFilters: [],
        filters: [],
    }),
    computed: {
        activeFilters() {
            let filters = [];
            for (let i = 0; i < this.filters.length; i++) {
                let filter = this.filters[i];
                if (filter.value) {
                    filters.push(filter);
                }
            }

            return filters;
        },
        visibleFilters() {
            let filters = [];
            for (let i = 0; i < this.filters.length; i++) {
                let filter = this.filters[i];
                if (!filter.isHidden) {
                    filter.index = i;
                    filters.push(filter);
                }
            }

            return filters;
        }
    },
    methods: {
        clearFilter(filter) {
            for (let i = 0; i < this.filters.length; i++) {
                const activeFilter = this.filters[i];
                if (filter.key == activeFilter.key && filter.value == activeFilter.value) {
                    this.filters[i].value = null;
                }
            }

            this.$emit('change', this.filters);
        },
        async init(route) {
            this.objectFilters = [];
            this.measurementTypeIds = [];
            this.filters = [];

            let filters = [];
            if (this.value.type == 'QUERY') {
                filters = this.$helper.getQueryFilters(this.value.content);
            } else if (this.value.type == 'DASHBOARD') {
                filters = await this.$eod.getDashboardFilters(this.value.content);
            }

            this.$eod.get('measurementTypes', ['id', 'name', 'valueConfigList{itemType listItems{id name threshold}}', 'valueConfigListItems{id name threshold}'], {
                whereIn: {
                    array: this.measurementTypeIds,
                    column: 'id'
                }
            }).then(response => {
                if (response.data.data.measurementTypes) {
                    this.fillFilters(filters, response.data.data.measurementTypes.edges, route);
                }
            })
        },
        async fillFilters(filters, measurementTypes, route) {
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];
                for (let j = 0; j < measurementTypes.length; j++) {
                    const measurementType = measurementTypes[j];
                    if (filter.measurementTypeId == measurementType.id) {

                        // Label
                        if (filter.label == '') {
                            filters[i].label = measurementType.name;
                        }

                        if (measurementType.valueConfigList) {
                            if (measurementType.valueConfigListItems && measurementType.valueConfigListItems.length > 0) {
                                filters[i].listItems = measurementType.valueConfigListItems;
                            } else {
                                filters[i].listItems = measurementType.valueConfigList.listItems;
                            }

                            if (measurementType.valueConfigList.itemType == 'USER') {
                                filters[i].listItems = await this.fillUsers(filters[i].listItems);
                            }
                        }

                        break;

                    }
                }
            }

            this.filters = filters;

            this.checkDefaultFilterValues(route);

            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
        checkDefaultFilterValues(route) {
            if(!route){
                route = this.$route;
            }
            for (let i = 0; i < this.filters.length; i++) {
                const filter = this.filters[i];
                if (route.query[filter.key]) {
                    this.filters[i].value = route.query[filter.key];
                }
            }
            this.$emit('change', this.filters);
        },
        async fillUsers(listItems) {
            let userIds = [];
            for (let i = 0; i < listItems.length; i++) {
                const listItem = listItems[i];
                userIds.push(listItem.name);
            }

            let users = await this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName'], {
                whereIn: {
                    column: 'id',
                    array: userIds
                }
            }).then(response => {
                return response.data.data.resolveUsers.edges;
            });

            for (let i = 0; i < listItems.length; i++) {
                const listItem = listItems[i];
                for (let j = 0; j < users.length; j++) {
                    const user = users[j];
                    if (listItem.name == user.id) {
                        listItems[i].name = user.firstName + ' ' + user.lastName;
                    }
                }
            }

            return listItems;

        },
    }
}
</script>