export default class Vehicle {
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getSaveData() {
        let data = Object.assign({}, this);

        const oneToManyRelations = ['owner', 'depot', 'supplier'];
        for (let i = 0; i < oneToManyRelations.length; i++) {
            const relation = oneToManyRelations[i];
            if (data.hasOwnProperty(relation)) {
                if (data[relation]){
                    data[relation + 'Id'] = data[relation].id;
                }else{
                    data[relation + 'Id'] = null;
                }
            }
            delete data[relation];
        }

        const manyToManyRelations = ['vehicleSkills', 'trailerSkills'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relationName = manyToManyRelations[i];
            if(data.hasOwnProperty(relationName)){
                for (let j = 0; j < data[relationName].length; j++) {
                    data[relationName][j] = {
                        id: data[relationName][j].id,
                    }
                }
            }
        }

        const floats = ['catalogueValue'];
        for (let i = 0; i < floats.length; i++) {
            const fieldName = floats[i];
            if(data.hasOwnProperty(fieldName) && data[fieldName] !== null){
                data[fieldName] = parseFloat(data[fieldName]);
            }
        }

        return data;
    }
}
