<template>
    <v-select @change="changed" :disabled="disabled" :placeholder="placeholder" hide-details :clearable="clearable" :loading="loading" :label="label" :multiple="multiple" :deletable-chips="multiple" :small-chips="multiple"  :return-object="returnObject" :value="value" :items="items" :dense="dense" :outlined="outlined" item-text="name" item-value="id" auto-select-first>
        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-slot:no-data>
            <v-list-item v-if="noData">
              <v-list-item-content>
                <v-list-item-title v-html="noDataText"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title v-html="searchText"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
</template>
<script>
    export default {
        props:{
            label: String,
            prependItems: {
                type: Array,
                default: () => ([])
            },
            noDataText: {
                type: String,
                default: 'Geen items gevonden met uw zoekopdracht'
            },
            searchText: {
                type: String,
                default: 'Zoek een item...'
            },
            placeholder: {
                type: String,
                default: ''
            },
            value: Object|String,
            listId: String,
            fields: {
                type: Array,
                default: () => {
                    return ['id', 'name', 'description'];
                }
            },
            returnObject: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            dense: {
                type: Boolean,
                default: false
            },
            outlined: {
                type: Boolean,
                default: false
            },
            nullable: {
                type: Boolean,
                default: false
            },
            clearable:{
                type: Boolean,
                default: false
            },
            disabled:{
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            noData: false,
            search: '',
            items: [],
            loading: false
        }),
        mounted(){
            this.getDataFromApi();
        },
        methods: {
            changed(val){
                this.$emit('input', val);
                this.$emit('change', val);
            },
            getDataFromApi(){
                this.loading = true;
                this.$eod.getById('list', this.listId, ['id', 'listItems{'+this.fields.join(' ')+' isActive}'])
                .then(result => {
                    let items = result.data.data.list.listItems;

                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        if(!item.isActive){
                            items[i].disabled = true;
                        }
                    }

                    if(this.nullable){
                        items.unshift({id:null,name:'N.V.T.',description:'Niet van toepassing'});
                    }
                    for (let i = 0; i < this.prependItems.length; i++) {
                        const item = this.prependItems[i];
                        items.unshift(item);
                    }

                    this.items = items;
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>