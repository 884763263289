<template>
    <v-list :dense="dense">
        
        <v-list-item v-for="item in items" :key="item.id" @click="gotoResponses(item)">
            <v-list-item-content>
                <v-list-item-title>{{item.parent.name}}</v-list-item-title>
                <v-list-item-subtitle><span v-if="item.responses && item.responses[0] && item.responses[0].user">{{ item.responses[0].user.firstName }} {{ item.responses[0].user.lastName }} | </span>{{item.name}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{$moment(item.createdAt).format('YYYY-MM-DD HHumm')}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text>
                <v-chip>{{item.responses.length}}</v-chip>
                <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action-text>
        </v-list-item>
        <div class="text-center">
        <v-progress-circular v-if="loading" size="40" color="primary" indeterminate></v-progress-circular>
        </div>
        <v-list-item v-if="showMore" @click="offset++;loadDataFromAPI()">
            Meer laden...
        </v-list-item>
    </v-list>
</template>
<script>
export default {
    name:'eod-jobs-list',
    props:{
        filter: {
            type: Object,
            default: () => ({})
        },
        dense:{
            type:Boolean,
            default: false
        }
    },
    data: () => ({
        fields:['id name createdAt jobType{id name} taskType{id name} parent{id name} responses{id createdAt user{id firstName lastName}}'],
        items: [],
        loading: false,
        offset: 0,
        showMore: false
    }),
    mounted(){
        this.loadDataFromAPI();
    },
    methods:{
        loadDataFromAPI(){
            this.loading = true;

            if(this.filter.limit){
                this.filter.offset = this.offset;
            }

            this.$eod.get('tasks', this.fields, this.filter)
                .then(response => {
                    for (let i = 0; i < response.data.data.tasks.edges.length; i++) {
                        const task = response.data.data.tasks.edges[i];
                        this.items.push(task);
                    }
                    
                    this.showMore = response.data.data.tasks.pageInfo.hasNextPage;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        gotoResponses(item){
            if(item.responses && item.responses.length == 1){
                this.$router.push({name:'report', params:{responseId:item.responses[0].id}});
            }
        }
    }
}
</script>