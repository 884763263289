<template>
  <div>
    <v-toolbar flat color="transparent">
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
        Vandaag
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="prev">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="next">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
      <v-calendar ref="calendar" v-model="focus" :weekdays="[1, 2, 3, 4, 5, 6, 0]" type="week" :events="events"
        :interval-format="intervalFormat" event-overlap-mode="column" event-overlap-threshold="30"
        event-color="var(--v-primary-base)" @change="getEvents" @click:event="showEvent" :interval-height="24"
        @mousedown:event="startDrag" @mousedown:time="startTime" @mousemove:time="mouseMove" @mouseup:time="endDrag"
        @contextmenu:event="showEventContextMenu">
        <template v-slot:event="{ event }">
          <div class="px-1 py-1">
            <strong>{{ $moment(event.start).format('HH:mm') }} - {{ $moment(event.end).format('HH:mm') }}</strong>
          </div>
        </template>
      </v-calendar>
      <v-menu v-model="showContextMenu" :position-x="menu.x" :position-y="menu.y" absolute offset-y
        style="max-width: 600px">
        <v-list dense>
          <v-list-item @click="evt => showEvent({ nativeEvent: evt, event: createEvent })">
            <v-list-item-icon class="mr-2"><v-icon>mdi-pencil-outline</v-icon></v-list-item-icon>
            <v-list-item-title>Aanpassen</v-list-item-title>
          </v-list-item>
          <v-list-item class="error--text" dark @click="evt => deleteEvent({ nativeEvent: evt, event: createEvent })">
            <v-list-item-icon class="mr-2"><v-icon class="error--text">mdi-trash-can-outline</v-icon></v-list-item-icon>
            <v-list-item-title>Verwijderen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card>
    <v-dialog v-if="showEditEventDialog" v-model="showEditEventDialog" max-width="800">
      <v-card>
        <v-card-title class="mb-4">
          <v-avatar color="success" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-clock-outline
            </v-icon>
          </v-avatar><span class="headline">Beschikbaar</span>
        </v-card-title>
        <v-card-text>
          <vc-date-picker :popover="{ visibility: 'focus' }" :minute-increment="1"
            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
            :value="createEvent.start" @input="createEventStartChanged" mode="dateTime" is24hr
            :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
            <template v-slot="{ inputValue, inputEvents, updateValue }">
              <v-text-field class="mb-4" hide-details label="Van" prepend-icon="mdi-calendar" :value="inputValue"
                @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                @change="updateValue($event, { formatInput: true, hidePopover: false, })" v-on="inputEvents" dense
                outlined></v-text-field>
            </template>
          </vc-date-picker>
          <vc-date-picker :popover="{ visibility: 'focus' }" :minute-increment="1"
            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
            :value="createEvent.end" @input="createEventEndChanged" mode="dateTime" is24hr
            :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
            <template v-slot="{ inputValue, inputEvents, updateValue }">
              <v-text-field class="mb-4" hide-details label="Tot" prepend-icon="mdi-calendar" :value="inputValue"
                @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                @change="updateValue($event, { formatInput: true, hidePopover: false, })" v-on="inputEvents" dense
                outlined></v-text-field>
            </template>
          </vc-date-picker>
          <template v-if="rrule">
            <v-autocomplete class="mb-4" :items="recurringOptions" return-object v-model="createEvent.recurringFreq"
              label="Herhalen" prepend-icon="mdi-history" dense outlined hide-details></v-autocomplete>
            <v-card outlined class="px-4 py-4 ml-8"
              v-if="(!rrule || (rrule && rrule != 'simple')) && createEvent.recurringFreq && createEvent.recurringFreq.value === 'advanced'">
              <div class="d-flex align-items-center mb-2">
                <span>Herhaal elke</span>
                <v-text-field class="mx-3" outlined dense hide-details type="number"
                  v-model="createEvent.recurringAdvanced.interval"></v-text-field>
                <v-autocomplete outlined dense hide-details :items="recurringAdvancedOptions"
                  v-model="createEvent.recurringAdvanced.freq"></v-autocomplete>
              </div>
              <div class="mb-2">
                Herhalen op
                <v-chip-group multiple active-class="primary" v-model="createEvent.recurringAdvanced.byweekday">
                  <v-chip v-for="day in recurringDays" :key="day.value.weekday">
                    {{ day.text }}
                  </v-chip>
                </v-chip-group>
              </div>
              Eindigt
              <v-radio-group v-model="createEvent.recurringAdvanced.ending" hide-details>
                <v-radio :value="null">
                  <template v-slot:label>
                    <div>Nooit</div>
                  </template>
                </v-radio>
                <v-radio value="date">
                  <template v-slot:label>
                    <div class="d-flex align-items-center">Op
                      <vc-date-picker :popover="{ visibility: 'focus' }"
                        :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                        v-model="createEvent.recurringAdvanced.until" mode="date"
                        :model-config="{ type: 'string', mask: 'DD/MM/YYYY' }">
                        <template v-slot="{ inputValue, inputEvents, updateValue }">
                          <v-text-field :disabled="createEvent.recurringAdvanced.ending != 'date'" class="ml-2" dense
                            outlined hide-details :value="inputValue"
                            @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                            @change="updateValue($event, { formatInput: true, hidePopover: false, })"
                            v-on="inputEvents"></v-text-field>
                        </template>
                      </vc-date-picker>
                    </div>
                  </template>
                </v-radio>
                <v-radio value="count">
                  <template v-slot:label>
                    <div class="d-flex">
                      <div class="d-flex align-items-center">Na</div><v-text-field
                        v-model="createEvent.recurringAdvanced.count"
                        :disabled="createEvent.recurringAdvanced.ending != 'count'" class="ml-2" dense outlined
                        hide-details type="number" style="width:80px;"></v-text-field>
                      <div class="grey lighten-2 px-2 d-flex align-items-center"
                        style="border-radius: 0 5px 5px 0;margin-left:-4px;">keer</div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card>
          </template>
        </v-card-text>
        <v-card-actions class="grey lighten-3 py-3 px-3" color="grey lighten-5">
          <v-btn text @click="showEditEventDialog = false">Annuleren</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded depressed color="secondary" @click="saveEventsClicked"><v-icon
              left>mdi-content-save</v-icon>Opslaan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showDeleteRepeatDialog" v-model="showDeleteRepeatDialog" max-width="600">
      <v-card>
        <v-card-title class="mb-4">
          <v-avatar color="error" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-trash-can-outline
            </v-icon>
          </v-avatar><span class="headline">Herhalende beschikbaarheid verwijderen</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="deleteRecurringAction">
            <v-radio value="future_items" label="Deze en volgende beschikbaarheden"></v-radio>
            <v-radio value="single_item" label="Enkel deze beschikbaarheid"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="grey lighten-3 py-3 px-3" color="grey lighten-5">
          <v-btn text @click="showDeleteRepeatDialog = false">Annuleren</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded depressed color="error"
            @click="deleteEventAction(deleteRecurringAction == 'future_items' ? true : false)">Verwijderen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showEditRecurringDialog" v-model="showEditRecurringDialog" max-width="600">
      <v-card>
        <v-card-title class="mb-4">
          <v-avatar color="success" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-clock-outline
            </v-icon>
          </v-avatar><span class="headline">Herhalende beschikbaarheid bewerken</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="editRecurringAction">
            <v-radio value="future_items" label="Deze en volgende beschikbaarheden"></v-radio>
            <v-radio value="all_items" label="Alle beschikbaarheden"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="grey lighten-3 py-3 px-3" color="grey lighten-5">
          <v-btn text @click="showEditRecurringDialog = false">Annuleren</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded depressed color="error" @click="saveRecurringEvent()">Opslaan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showDeleteDialog" v-model="showDeleteDialog" max-width="500">
      <v-card>
        <v-card-title class="mb-4">
          <v-avatar color="error" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-trash-can-outline
            </v-icon>
          </v-avatar><span class="headline">Verwijderen</span>
        </v-card-title>
        <v-card-text>
          Weet u zeker dat u deze beschikbaarheid wilt verwijderen?
        </v-card-text>
        <v-card-actions class="grey lighten-3 py-3 px-3" color="grey lighten-5">
          <v-btn text @click="showDeleteDialog = false">Annuleren</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded depressed color="error" @click="deleteEventAction(true)">Verwijderen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Availability } from '@/eodvuecomponents';

export default {
  name: 'eod-object-availability',
  props: {
    value: Object,
    deleteType: String,
    maxCreateDaily: {
      type: Number,
      default: null
    },
    rrule: {
      type: String,
      default: '1'
    },
    alterRecurringOptions: {
      type: Function,
      default: (arr_options) => {
        return arr_options;
      }
    }
  },
  data() {
    return {
      events: [],
      focus: '',
      showEditEventDialog: false,
      showDeleteRepeatDialog: false,
      showDeleteDialog: false,
      showEditRecurringDialog: false,
      editRecurringAction: 'future_items',
      deleteRecurringAction: 'future_items',

      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,

      showContextMenu: false,
      menu: {
        x: 0,
        y: 0
      },

      recurringAdvancedOptions: Availability.ADVANCED_RECURRING_RULES,
      recurringOptions: this.alterRecurringOptions(Availability.BASIC_RECURRING_RULES),
      recurringDays: Availability.ADVANCED_RECURRING_DAYS,
    }
  },
  methods: {
    intervalFormat(locale, getOptions) {
      return locale.time;
    },
    showEventContextMenu({ nativeEvent, event }) {
      nativeEvent.preventDefault();
      this.showContextMenu = false;
      this.menu.x = nativeEvent.clientX;
      this.menu.y = nativeEvent.clientY;
      this.createEvent = event;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },
    getSaveEvents() {
      const events = [];
      for (let i = 0; i < this.events.length; i++) {
        const event = this.events[i];
        if (event.isOriginal) {
          events.push(event.getSaveData());
        }
      }
      return events;
    },
    createEventStartChanged(data) {
      this.createEvent.start = this.$moment(data, 'DD-MM-YYYY HH:mm').unix() * 1000;
    },
    createEventEndChanged(data) {
      this.createEvent.end = this.$moment(data, 'DD-MM-YYYY HH:mm').unix() * 1000;
    },
    showEvent(data) {
      if (data.nativeEvent.which == 1) {
        this.createEvent = data.event;
        this.showEditEventDialog = true;
      }
    },
    saveRecurringEvent() {
      this.showEditRecurringDialog = false;
      if (this.editRecurringAction == 'future_items') {
        if (!this.createEvent.isOriginal) {
          this.events[this.createEvent.originalIndex].recurringAdvanced.ending = 'date';
          this.events[this.createEvent.originalIndex].recurringAdvanced.until = this.$moment(this.createEvent.startDateTime).format('DD/MM/YYYY');
          let evt = new Availability(this.createEvent.getSaveData());
          evt.recurringAdvanced.exceptionDates = [];
          evt.isOriginal = true;
          evt.originalIndex = this.events.length;
          this.events.push(evt);
        } else {
          this.events[this.createEvent.originalIndex].recurringAdvanced = this.createEvent.recurringAdvanced;
        }
      } else if (this.editRecurringAction == 'all_items') {
        this.events[this.createEvent.originalIndex].recurringAdvanced = this.createEvent.recurringAdvanced;
      }
      this.saveEvents();
    },
    saveEvents() {
      this.showEditEventDialog = false;
      let val = this.value;
      val.availability = this.getSaveEvents();
      this.$emit('input', val);
      this.$nextTick(() => {
        this.getEvents({ start: this.$refs.calendar.lastStart, end: this.$refs.calendar.lastEnd });
      });
    },
    saveEventsClicked() {
      if (this.createEvent && this.createEvent.isRecurring) {
        this.showEditRecurringDialog = true;
      } else {
        this.saveEvents();
      }
    },
    deleteEventAction(deleteFuture) {

      // If deleteFuture and original event, delete event
      if (this.createEvent.isOriginal && deleteFuture) {
        const i = this.events.indexOf(this.createEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
        // If deleteFuture and repeat event, set original event enddate
      } else if (!this.createEvent.isOriginal && deleteFuture) {
        this.events[this.createEvent.originalIndex].recurringAdvanced.ending = 'date';
        this.events[this.createEvent.originalIndex].recurringAdvanced.until = this.$moment(this.createEvent.startDateTime).format('DD/MM/YYYY');
        // If not deleteFuture and repeat event, set exception date
      } else if (!this.createEvent.isOriginal && !deleteFuture) {
        this.events[this.createEvent.originalIndex].recurringAdvanced.exceptionDates.push(this.$moment(this.createEvent.startDateTime).toDate());
        // If not deleteFuture and original event, set original dates to first repeat date
      } else if (this.createEvent.isOriginal && !deleteFuture) {
        const nextDate = this.createEvent.getFirstEventAfter(this.$moment(this.createEvent.startDateTime));
        if (nextDate) {
          const momentNextDate = this.$moment(nextDate);
          const diff = momentNextDate.diff(this.$moment(this.events[this.createEvent.originalIndex].startDateTime), 'milliseconds');
          const momentEndDate = this.$moment(this.events[this.createEvent.originalIndex].startDateTime).add(diff, 'milliseconds');
          this.events[this.createEvent.originalIndex].startDateTime = momentNextDate.toISOString();
          this.events[this.createEvent.originalIndex].endDateTime = momentEndDate.toISOString();
        } else {
          //Delete original
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;

      this.showDeleteRepeatDialog = false;
      this.showDeleteDialog = false;

      this.saveEvents();
    },
    deleteEvent(data) {
      if (data.nativeEvent.which == 1) {
        const i = this.events.indexOf(data.event);
        if (i !== -1) {
          this.createEvent = data.event;
        }

        this.showEditEventDialog = false;
        if (this.createEvent.recurrence) {
          if (this.deleteType && this.deleteType == "future") {
            this.deleteEventAction(true);
          } else if (this.deleteType && this.deleteType == "single") {
            this.deleteEventAction(false);
          } else {
            this.showDeleteRepeatDialog = true;
          }

        } else {
          this.showDeleteDialog = true;
        }
      }
    },
    getEvents({ start, end }) {
      this.events = [];
      if (this.value) {
        const startDate = this.$moment(start.date, 'YYYY-MM-DD');
        const endDate = this.$moment(end.date, 'YYYY-MM-DD').add(1, 'days');

        if (this.value.availability && this.value.availability.length > 0) {
          for (let i = 0; i < this.value.availability.length; i++) {
            const data = this.value.availability[i];
            if (data.startDateTime) {
              const availability = new Availability({
                ...data,
                isOriginal: true
              });

              const recurringEvents = availability.getRecurringEvents(startDate, endDate);
              if (recurringEvents) {
                availability.originalIndex = this.events.length;
                availability.isRecurring = availability.recurrence ? true : false;
                this.events.push(availability);
                for (let i = 0; i < recurringEvents.length; i++) {
                  recurringEvents[i].originalIndex = availability.originalIndex;
                  recurringEvents[i].isRecurring = availability.isRecurring;
                  this.events.push(recurringEvents[i]);
                }
              } else {
                this.events.push(availability);
              }
            }
          }
        }
      }
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },

    startDrag({ event, timed, nativeEvent }) {
      if (nativeEvent.which == 1 && event && timed && !this.showContextMenu && this.create) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {

      if (this.maxCreateDaily != null) {
        const mouse = this.toTime(tms)
        const selectedDate = this.$moment(this.roundTime(mouse));

        let count = 0;
        if (this.value.availability && this.value.availability.length > 0) {
          for (let i = 0; i < this.value.availability.length; i++) {
            const data = this.value.availability[i];
            if (data.startDateTime) {
              const availability = new Availability(data);
              const recurringEvents = availability.getRecurringEvents(selectedDate.clone().startOf('day'), selectedDate.clone().endOf('day'));
              if (selectedDate.isSame(availability.start, 'day') || recurringEvents && recurringEvents.length > 0) {
                count++;
              }
            }
          }
        }

        if (count >= this.maxCreateDaily) {
          return false;
        }
      }

      if (tms.nativeEvent.which == 1 && !this.showContextMenu) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start;

          this.dragTime = mouse - start
        } else {
          this.createStart = this.roundTime(mouse);

          this.createEvent = new Availability({
            startDateTime: this.$moment(this.createStart).toISOString(),
            endDateTime: this.$moment(this.createStart).toISOString(),
            endTimeUnspecified: false,
            recurrence: null,
            isOriginal: true,
            originalIndex: this.events.length
          });

          this.events.push(this.createEvent);
        }
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.saveEvents();
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
  }
}
</script>