<template>
    <v-list dense>
        <template v-if="!loading && (value.templates.length > 0 || jobTypes.length > 0)">
            <v-list-item v-for="item in value.templates" :key="item.id" @click="$emit('edit', item)">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-action v-if="!hideDelete">
                    <v-btn @click="$emit('delete', item)" small icon><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-list-item v-for="jobType in jobTypes" :key="jobType.id" @click="editJobTypeItem(jobType)">
                <v-list-item-title>{{ jobType.name }}</v-list-item-title>
            </v-list-item>
        </template>
        <template v-else-if="!loading">
            <v-list-item>
                <v-list-item-title>Geen dashboards gevonden voor dit project!</v-list-item-title>
            </v-list-item>
        </template>
        <template v-else-if="loading">
            <v-list-item>
                <v-progress-circular class="mx-auto" indeterminate size="40"></v-progress-circular>
            </v-list-item>
        </template>
    </v-list>
</template>
<script>
export default {
    name: 'eod-project-dashboards',
    props: {
        value: Object,
        hideGenerated: {
            type: Boolean,
            default: false
        },
        hideDelete: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            jobTypes: [],
            loading: false,
        }
    },
    mounted() {
        if (this.$eod.getUser().can('jobtypes.read') && !this.hideGenerated) {
            this.loading = true;
            this.getProjectJobTypes(this.value).then(jobTypes => {
                this.jobTypes = jobTypes;
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    methods: {
        editJobTypeItem(item) {
            // Get latest job of jobtype
            this.$eod.get('tasks', ['id', 'parent{id name templates{id type object content}}', 'owner{id}', 'responsibleUsers{id}'], {
                limit: 1,
                orderBy: {
                    column: 'created_at',
                    type: 'DESC'
                },
                where: [
                    {
                        column: 'category',
                        operator: '=',
                        value: 'JOB'
                    },
                    {
                        column: 'stepType',
                        operator: '=',
                        value: 'FORM'
                    },
                    {
                        column: 'projectId',
                        operator: '=',
                        value: this.value.id
                    },
                    {
                        column: 'jobTypeId',
                        operator: '=',
                        value: item.id
                    }
                ]
            }).then(result => {
                let tasks = result.data.data.tasks.edges;
                if (tasks[0]) {
                    const latest_job = tasks[0];

                    // Get ownerId or responsibleUser
                    let ownerId = null;
                    if (latest_job.owner) {
                        ownerId = latest_job.owner.id;
                    } else if (latest_job.responsibleUsers && latest_job.responsibleUsers[0]) {
                        ownerId = latest_job.responsibleUsers[0].id;
                    }

                    this.$router.push({ name: 'dashboard.jobtype', params:{id:item.id}, query: { project_id: this.value.id, owner_id: ownerId, job_type_id: item.id } })
                }
            });


        },
        getProjectJobTypes(project) {
            return this.$eod.get('tasks', ['id', 'name', 'jobType{id name}'], {
                where: [
                    {
                        column: 'category',
                        operator: '=',
                        value: 'JOB'
                    },
                    {
                        column: 'stepType',
                        operator: '=',
                        value: 'FORM'
                    },
                    {
                        column: 'projectId',
                        operator: '=',
                        value: project.id
                    }
                ]
            }).then((response) => {
                let jobTypes = {};
                let jobs = response.data.data.tasks.edges;
                for (let i = 0; i < jobs.length; i++) {
                    const job = jobs[i];
                    if (job.jobType && !jobTypes[job.jobType.id]) {
                        jobTypes[job.jobType.id] = {
                            ...job.jobType,
                            job: job
                        };
                    }
                }

                return Object.values(jobTypes);
            });
        },
    }
}
</script>