<template>
    <div v-if="disabled" class="ck-content" style="opacity:0.6;" v-html="value"></div>
    <ckeditor v-else ref="myeditor" :editor="editor" :value="value" @input="val => $emit('input', val)" :config="editorConfig"></ckeditor>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Editor from './../plugins/ckeditor';

export default {
    name: 'eod-rte',
    components: {
        'ckeditor': CKEditor.component
    },
    props: {
        value: String,
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data: () => {
        return {
            editor: Editor,
            editorConfig: {},
        };
    },
}
</script>