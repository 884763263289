<template>
    <div class="h-100 w-100">
        <v-chart v-show="value.length > 0" :ref="id" :initOptions="options" :autoresize="true" :manualUpdate="true"/>
        <div v-if="!value || value.length == 0">Gelieve de grafiek in te stellen</div>
    </div>
</template>
<style lang="scss">
    .echarts {
        width: 100%;
        height: 100%;
    }
</style>
<script>
    import ECharts from './echarts/ECharts';

    export default {
        name: 'eod-graph-line',
        components:{
            'v-chart': ECharts,
        },
        props:{
            id: String,
            value:Object|Array,
            template:Object|Array,
            xAxis:Object|Array,
            yAxis:Object|Array,
        },
        data: () => ({
            options: {
                radar: {
                    name: {
                        textStyle: {
                            color: '#444444',
                            padding: [3, 5]
                        }
                    },
                    splitNumber:6,
                    indicator: [],
                },
                series: [{
                    type: 'radar',
                    data: []
                }]
            },
        }),
        methods: {
            update(){

                this.options.radar.indicator = [];
                this.options.series = [];

                let data = {
                    value: []
                };

                let val = this.value[0];

                if(val){
                    for (const key in this.xAxis) {
                        this.options.radar.indicator.push({
                            name: this.xAxis[key],
                            max: 7
                        });

                        data.value.push(val[key])
                    }

                    this.options.series = [
                        {
                            type: 'radar',
                            label: {
                                show: true
                            },
                            tooltip: {
                                confine:true,
                                trigger: 'item'
                            },
                            areaStyle: {},
                            data: [
                                data
                            ]
                        }
                    ];

                    
                    this.$refs[this.id].mergeOptions(this.options);
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });   
                }

                
                
                
            }
        }
    }
</script>