<template>
<eod-graph v-if="!loading" ref="graph" type="line" :template="[{ content: evolutionTemplate }]" :fake-prediction="$eod.getOrganizationSetting('measurements.trendgraph.predictions')?$eod.getOrganizationSetting('measurements.trendgraph.predictions').value == '1':false" :predictionValue="predictionValue" @queueQuery="queueQuery" :from="from" :fields="['product','owner']" :until="until" :groupBy="['product_id','job_type_id','measurement_type_id']"></eod-graph>
</template>
<script>
import dashboardQueue from './../mixins/dashboardQueue';
import eodGraph from './graphs/eod-graph';

export default {
    name:'eod-evolution-graph',
    components:{
        eodGraph
    },
    props:{
        from: Object,
        until: Object,
        measurementTypeId: String,
        filters: Object,
        predictionValue: Number,
    },
    mixins: [dashboardQueue],
    data:() => {
        return {
            loading: true,
            evolutionTemplate: null
        }
    },
    mounted(){
        this.loadData();
    },
    methods: {
        loadData(){
            this.generateEvolutionTemplate();

            if(this.loading){
                this.loading = false;
                this.$nextTick(() => {
                    if (this.$refs.graph) {
                        this.$refs.graph.loadData();
                    }
                });
            }            
        },
        gotQueueResult(queueItem, result) {
            if (this.$refs.graph) {
                this.$refs.graph.queueResult(queueItem.templateIndex, queueItem.template, result);
            }
        },
        generateEvolutionTemplate() {
            const queryTemplate = {
                source: 'responses',
                measurementTypes: [{
                        aggregation: 'last',
                        object: {
                            id: this.measurementTypeId,
                            //name: this.measurementType.name,
                        }
                    }],
                group: {
                    key: 'time'
                },
                filters: []
            };

            if (this.filters && this.filters.projectId) {
                queryTemplate.filters.push({
                    field: {
                        key: 'project_id',

                    },
                    operator: '=',
                    value: this.filters.projectId
                });
            }

            if (this.filters && this.filters.jobTypeId) {
                queryTemplate.filters.push({
                    field: {
                        key: 'job_type_id',
                    },
                    operator: '=',
                    value: this.filters.jobTypeId
                });
            }

            if (this.filters && this.filters.productId) {
                queryTemplate.filters.push({
                    field: {
                        key: "product_id",

                    },
                    operator: '=',
                    value: this.filters.productId,
                });
            }

            this.evolutionTemplate = queryTemplate;
        },
    }
}
</script>