<template>
    <div ref="map" id="map"></div>
</template>
<style lang="scss" scoped>
#map {
    /*position: fixed;
        left:0;
        top:0;*/
    height: 100%;
    width: 100%;
    background: #ffffff;
}
</style>
<script>
import { User } from '@/eodvuecomponents';

export default {
    props: {
        value: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            markerClusterGroup: null,
            markers: [],
            polygons: [],
            bounds: [],
            map: null,
        }
    },
    mounted() {
        this.map = this.$leaflet.map(this.$refs.map, {
            center: [51.022684, 3.187897],
            zoom: 13
        });
        this.map.createPane('labels');
        this.map.getPane('labels').style.pointerEvents = 'none';

        this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png', {
            attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>'
        }).addTo(this.map);
        this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png', {
            attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>',
            pane: 'labels'
        }).addTo(this.map);

        //this.showRoutes(true);
    },
    methods: {
        resize() {
            if (this.bounds.length > 0) {
                this.map.invalidateSize();
                this.map.fitBounds(this.bounds, { padding: [25, 25] });
            }
        },
        routeClicked(routeId) {

            let routes = this.value;



            for (let i = 0; i < routes.length; i++) {
                if (routes[i].id == routeId) {
                    routes[i].visible = true;
                    for (let j = 0; j < routes[i].jobs.length; j++) {
                        routes[i].jobs[j].visible = true;
                    }
                } else {
                    routes[i].visible = false;
                    for (let j = 0; j < routes[i].jobs.length; j++) {
                        routes[i].jobs[j].visible = false;
                    }
                }
            }

            this.$emit('updateRoutes', routes);
            this.showRoutes();
        },
        clearMarkers() {
            if (this.markerClusterGroup) {
                this.markerClusterGroup.remove();
            }

            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].remove();
            }
        },
        clearPolygons() {
            for (let i = 0; i < this.polygons.length; i++) {
                this.polygons[i].remove();
            }
        },
        getMarkerHtml(route, index, options) {
            let opacity = 1;
            if (route.visible) {
                if (options && options.hasOwnProperty('opacity')) {
                    opacity = options.opacity;
                }
            } else {
                opacity = 0.2;
            }

            let bg = route.color;
            if (options && options.bg) {
                bg = options.bg;
            }

            let color = '#FFFFFF';
            if (options && options.color) {
                color = options.color;
            }

            return '<div class="eod-marker" style="opacity:' + opacity + ';background-color:' + bg + ';"><div class="triangle" style="border-top-color:' + bg + ';"></div><div class="eod-marker__number" style="color:' + color + ';">' + index + '</div></div>';
        },
        createMarker(route, pos, index, options) {
            this.bounds.push(pos);
            let icon = this.$leaflet.divIcon({
                html: this.getMarkerHtml(route, index, options),
                iconSize: [30, 34],
                iconAnchor: [15, 38],
                className: ''
            });

            return this.$leaflet.marker(pos, { icon: icon });
        },
        showRoutes(init) {
            this.clearMarkers();
            this.clearPolygons();

            const depots = {};

            const depotIcons = {
                'home': 'mdi-home-outline',
                'warehouse': 'mdi-warehouse',
            }

            this.bounds = [];

            const self = this;

            this.markerClusterGroup = this.$leaflet.markerClusterGroup();

            for (let i = 0; i < this.value.length; i++) {
                const route = this.value[i];

                let stops = [];

                if (route.startDepot) {
                    if (route.startDepot.lat && route.startDepot.lon) {
                        stops.push([route.startDepot.lat, route.startDepot.lon]);
                        if (!depots[route.startDepot.id]) {
                            depots[route.startDepot.id] = {
                                routes: [route],
                                depot: route.startDepot
                            };
                        } else if (!depots[route.startDepot.id].routes.includes(route)) {
                            depots[route.startDepot.id].routes.push(route);
                        }
                    }
                }

                let count = 0;
                for (let j = 0; j < route.jobs.length; j++) {
                    const job = route.jobs[j];

                    if (!job.isTransport && !job.isDepot && !job.isBreakEvent) {
                        count++;
                    }

                    if (job.lat && job.lon) {
                        let marker = this.createMarker(route, [job.lat, job.lon], count, {
                            opacity: (job.stepType == 'PLAN' || job.isJobActive == false) ? '0.6' : '1',
                            bg: job.stepType == 'PLAN' ? 'var(--v-warning-base)' : null,
                            color: job.stepType == 'PLAN' ? '#000000' : null,
                        });

                        let popup = this.getPopup(route, job);
                        marker.bindPopup(popup);
                        this.markers.push(marker);
                        stops.push([job.lat, job.lon]);
                        this.markerClusterGroup.addLayer(marker);
                    }
                }

                if (route.endDepot) {
                    if (route.endDepot.lat && route.endDepot.lon) {
                        stops.push([route.endDepot.lat, route.endDepot.lon]);
                        if (!depots[route.endDepot.id]) {
                            depots[route.endDepot.id] = {
                                routes: [route],
                                depot: route.endDepot
                            };
                        } else if (!depots[route.endDepot.id].routes.includes(route)) {
                            depots[route.endDepot.id].routes.push(route);
                        }
                    }
                }

                var polygon = this.$leaflet.polygon(stops, {
                    routeId: route.id,
                    color: route.color,
                    opacity: route.visible ? 1 : 0.2,
                    fillColor: route.color,
                    fillOpacity: route.visible ? 0.1 : 0,
                    visible: route.visible
                }).addTo(this.map);

                polygon.on('click', function (e) {
                    self.routeClicked(e.target.options.routeId);
                });

                polygon.on('mouseover', function (e) {
                    e.target.setStyle({
                        fillOpacity: 0.2,
                        opacity: 1,
                    });
                });

                polygon.on('mouseout', function (e) {
                    if (e.target.options.visible) {
                        e.target.setStyle({
                            fillOpacity: 0.1,
                            opacity: 1,
                        });
                    } else {
                        e.target.setStyle({
                            fillOpacity: 0.1,
                            opacity: 0,
                        });
                    }

                });

                this.polygons.push(polygon);
            }

            const depotMarkers = [];
            for (const depotId in depots) {
                if (Object.hasOwnProperty.call(depots, depotId)) {
                    const depotInfo = depots[depotId];
                    let marker = this.createMarker({
                        visible: true,
                        color: '#000000'
                    }, [depotInfo.depot.lat, depotInfo.depot.lon], '<i class="mdi ' + depotIcons[depotInfo.depot.category] + '"><i>');
                    let popup = this.getDepotPopup(depotInfo);
                    marker.bindPopup(popup);
                    depotMarkers.push(marker);
                    //this.markerClusterGroup.addLayer(marker);
                }
            }

            const markersLayer = this.$leaflet.layerGroup(depotMarkers);

            this.map.addLayer(markersLayer);
            this.map.addLayer(this.markerClusterGroup);

            this.map.invalidateSize();

            if (this.bounds.length > 0 && init) {
                this.map.fitBounds(this.bounds, { padding: [25, 25] });
            }
        },
        getDepotPopup(depot) {
            const self = this;
            const popup = window.document.createElement('div');
            popup.className = 'marker-popup';
            popup.style.minWidth = '240px';

            let popup_html = '';
            popup_html += '<h3>' + depot.depot.name + '</h3><hr class="mb-2" />';
            popup_html += "<strong>Adres:</strong> " + depot.depot.streetAddress + ', ' + depot.depot.postalCode + ' ' + depot.depot.locality + "<br>";
            popup_html += '<strong>Routes:</strong>';
            for (let i = 0; i < depot.routes.length; i++) {
                const route = depot.routes[i];
                popup_html += '<div style="display:flex;align-items:center;"><span style="background-color:' + route.color + ';display:block;width:10px;height:10px;margin-right:10px;"></span>' + route.name + '</div>';
            }
            popup_html += '</div>';

            popup.innerHTML = popup_html;

            return popup;
        },
        getPopup(route, job) {
            const self = this;
            const popup = window.document.createElement('div');
            popup.className = 'marker-popup';
            popup.style.minWidth = '240px';

            let popup_html = '';
            if (job.parent) {
                popup_html += '<h3>' + job.parent.name + '</h3><hr class="mb-2" />';
            }
            popup_html += "<strong>Project:</strong> " + job.parent.project.name + "<br>";
            popup_html += "<strong>Uur:</strong> " + this.$moment(job.dueAfterDate).format('HH:mm') + ' - ' + this.$moment(job.dueBeforeDate).format('HH:mm') + (job.stepType == 'PLAN' ? '<span style="font-size:.8rem;margin-left:5px;display:inline-block;padding:2px 4px;border-radius:4px;background-color:var(--v-warning-base);color:#FFFFFF;">Voorstel</span>' : '') + "<br>";

            if (job.responsibleUsers && job.responsibleUsers.length > 0) {
                popup_html += "<strong>Werknemer(s):</strong><br>";
                for (let i = 0; i < job.responsibleUsers.length; i++) {
                    const user = new User(job.responsibleUsers[i]);
                    popup_html += user.getFullName() + "<br>";
                }
            }

            popup_html += '</div>';

            popup.innerHTML = popup_html;

            if (job.stepType == 'PLAN') {
                const actionsContainer = window.document.createElement('div');
                actionsContainer.style.marginTop = '10px';
                actionsContainer.style.display = 'flex';
                actionsContainer.style.alignItems = 'center';
                actionsContainer.style.justifyContent = 'space-between';

                const cancelButton = window.document.createElement('button');
                cancelButton.type = 'button';
                cancelButton.className = 'v-btn v-btn--has-bg v-btn--rounded theme--light v-size--small error';
                cancelButton.innerHTML = 'Afwijzen';
                cancelButton.addEventListener('click', () => {
                    self.$emit('cancelItem', job);
                });

                actionsContainer.appendChild(cancelButton);

                const acceptButton = window.document.createElement('button');
                acceptButton.type = 'button';
                acceptButton.className = 'v-btn v-btn--has-bg v-btn--rounded theme--light v-size--small success';
                acceptButton.innerHTML = 'Accepteren';
                acceptButton.addEventListener('click', () => {
                    self.$emit('acceptItem', job);
                });

                actionsContainer.appendChild(acceptButton);

                popup.appendChild(actionsContainer);
            }

            return popup;
        }
    }
}
</script>