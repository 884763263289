<template>
  <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :weekdays="[1,2,3,4,5,6,0]"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          :interval-minutes="60"
          :event-height="32"
          :event-margin-bottom="4"
          :event-more="false"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          

          event-overlap-mode="column"

          @mouseenter:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
        >
        <template v-slot:event="{ event, timed }">
            <div class="pl-2 pt-2" style="line-height:1em;">
              <div class="mb-1">
                <strong style="line-height:1em;">{{event.task.parent.name}}</strong><br>
                <em style="font-size:.8em;">{{event.task.project.name}} | {{event.task.project.description}}</em>
              </div>
              <ul v-if="event.task.responsibleUsers" style="font-size:.9em;line-height:1.2em;">
                <li v-for="user in showUsers(event.task.responsibleUsers)" :key="user.id">{{user.getFullName()}}</li>
              </ul>
            </div>
            <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>
          </template>
        </v-calendar>
</template>
<style lang="scss">
.v-calendar-weekly {
display: table;
table-layout: fixed;
}
.v-calendar-weekly__week {
height: auto;
display: table-row;
}
.v-calendar-weekly__day {
display: table-cell;
width: calc(100% / 7)
}
.v-calendar-weekly__head {
    height: auto;
    display: table-row;
}

.v-calendar-weekly__head-weekday {
    display: table-cell;
    width: calc(100% / 7)
}

.v-event-draggable {
  padding-left: 6px;
}

.v-calendar .v-event-timed{
  overflow: hidden;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
<script>
export default {
    props:{
        type: {
            type: String,
            default: 'week'
        },
        value: {
          type: Array
        }
    },
    data: () => ({
        focus:'',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        min: null,
        max: null,
        eventUsers: [],

        dragEvent: null,
        dragStart: null,
        extendOriginal: null,
    }),
    mounted () {
      this.$refs.calendar.checkChange()
    },
    watch:{
      value(val){
        this.events = [];
        // add where to support tasks ending after min, but starting before min
        // add where to support tasks starting before max, but ending after max

        let resolveUsers = [];
            for (let i = 0; i < val.length; i++) {
              const job = val[i];

              if(job.responsibleUsers){
                for (let j = 0; j < job.responsibleUsers.length; j++) {
                  const usr = job.responsibleUsers[j];
                  if (!resolveUsers.includes(usr.id)) {
                    resolveUsers.push(usr.id);
                  }
                }
              }

              let color = 'primary';
              if(job.taskType.configuration && job.taskType.configuration.settings){
                for (let i = 0; i < job.taskType.configuration.settings.length; i++) {
                  const setting = job.taskType.configuration.settings[i];
                  if(setting.key == 'color'){
                    color = setting.value;
                  }
                }
              }
              

              this.events.push({
                name: job.name,
                task: job,
                start: new Date(job.dueAfterDate),
                end: new Date(job.dueBeforeDate),
                color: color,
                timed: true,
              });
            }

            this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username'], {
              whereIn:{
                column: 'id',
                array: resolveUsers
              }
            })
              .then(response => {
                this.eventUsers = response.data.data.resolveUsers.edges;
              })
      }
    },
    methods: {
      startDrag ({ event, timed }) {
        if (event && timed) {
          this.dragEvent = event;
          this.dragTime = null;
          this.extendOriginal = null;
        }
      },
      startTime (tms) {
        
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start

          this.dragTime = mouse - start
        } 
      },
      extendBottom (event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start;
          const end = this.dragEvent.end;
          const duration = end - start;
          const newStartTime = mouse - this.dragTime;
          const newStart = this.roundTime(newStartTime);
          const newEnd = newStart + duration;

          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false);
          const min = Math.min(mouseRounded, this.createStart);
          const max = Math.max(mouseRounded, this.createStart);

          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      },
      endDrag () {

        if(this.dragEvent){
          if(this.$moment(this.dragEvent.start).toISOString() == this.dragEvent.task.dueAfterDate && this.$moment(this.dragEvent.end).toISOString() == this.dragEvent.task.dueBeforeDate){
            this.$emit('eventClicked', this.dragEvent);
          }else{
            this.$emit('eventDragged', this.dragEvent)
          }
        }
        

        this.dragTime = null;
        this.dragEvent = null;
        this.createEvent = null;
        this.createStart = null;
        this.extendOriginal = null;
      },
      cancelDrag () {

        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            const i = this.events.indexOf(this.createEvent)
            if (i !== -1) {
              this.events.splice(i, 1)
            }
          }
        }

        this.createEvent = null;
        this.createStart = null;
        this.dragTime = null;
        this.dragEvent = null;
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      showUsers(users){
        if (users) {
          const User = require('./../models/user').default;
          let arr = [];
          
          for (let i = 0; i < users.length; i++) {
            const assignedUser = users[i];
            for (let j = 0; j < this.eventUsers.length; j++) {
              const eventUser = this.eventUsers[j];
              if (assignedUser.id == eventUser.id) {
                arr.push(new User(eventUser));
                break;
              }
            }            
          }

          return arr;
        }

        return [];
      },
        getTitle(){
            if (!this.$refs.calendar) {
                return '';
            }

            return this.$refs.calendar.title;
        },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color;
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      updateRange ({ start, end }) {
        this.min = new Date(`${start.date}T00:00:00`)
        this.max = new Date(`${end.date}T23:59:59`)

        this.$emit('rangeChanged', this.min, this.max);
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>