<template>
    <div class="h-100 w-100">
        <v-chart v-show="value.length > 0" @finished="finished" :ref="id"
            :initOptions="graphOptions" :autoresize="true" :manualUpdate="true"
        />
        <div v-if="!value">Gelieve de grafiek in te stellen</div>
        <div v-else-if="value.length == 0">Geen waardes gevonden</div>
    </div>
</template>
<style lang="scss">
.echarts {
    width: 100%;
    height: 100%;
}
</style>
<script>
import ECharts from './echarts/ECharts';
import _ from 'lodash';

export default {
    name: 'eod-graph-gauge',
    components: {
        'v-chart': ECharts,
    },
    props: {
        id: String,
        value: Object | Array,
        cell: Object,
        template: Object | Array,
        xAxis: Object | Array,
        yAxis: Object | Array,
        group: String,
        options: {
            type: Object,
            default() {
                return {}
            }
        },
        defaultSerie: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data: () => ({
        graphOptions: {},
        defaultOptions: {
            series: [],
            legend: {
                show:false,
                type: 'scroll',
                pageButtonPosition: 'start'
            },
        }
    }),
    methods: {
        finished() {
            this.$emit('finished', this.id, this.$refs[this.id]);
        },
        resize() {
            this.$refs[this.id].resize();
        },
        measurementTypeFieldName(index){
            if (this.template[index]) {
                        const content = this.template[index].content;
                        for (let i = 0; i < content.measurementTypes.length; i++) {
                            const aggMeasurementType = content.measurementTypes[i];
                            return aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id;
                        }
                    }
            return null;
        },
        async update() {
            this.graphOptions = {
                ...this.defaultOptions,
                //...this.options
            };

            let serie = {
                name: '',
                data: [],
                type: 'gauge',
                startAngle: 90,
                endAngle: -270,
                radius:'90%',
                min:0,
                max:100,
                pointer: {
                    show: false
                },
                progress: {
                    show: true,
                    overlap: false,
                    roundCap: true,
                    clip: false,
                    itemStyle: {
                        borderWidth: 0,
                        //borderColor: '#464646'
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: [
                            [1, '#F8F8F8'],
                        ],
                        width: 30
                    }
                },
                splitLine: {
                    show: false,
                    distance: 0,
                    length: 10
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                    distance: 20
                },
                title: {
                    fontSize: 14,
                },
                detail: {
                    //width: 50,
                    height: 12,
                    fontSize: 14,
                    color: 'inherit',
                    borderColor: 'inherit',
                    borderRadius: 20,
                    borderWidth: 1,
                    formatter: '{value}'
                }
            };

            if(this.cell && this.cell.settings && this.cell.settings.serie){
                serie = _.merge(serie, this.cell.settings.serie);
            }
            
            const labelPos = -1*(this.value.length*15);

            for (let i = 0; i < this.value.length; i++) {
                const value = this.value[i];
                if (value) {

                    const measurementTypeId = Object.keys(value.measurementTypes)[0];
                    const measurementType = value.measurementTypes[measurementTypeId];

                    if(measurementType.typeConfig){
                        if(measurementType.typeConfig.min != '' && !isNaN(measurementType.typeConfig.min) && !(this.cell && this.cell.settings && this.cell.settings.serie && this.cell.settings.serie.min != null && this.cell.settings.serie.min != '' && !isNaN(this.cell.settings.serie.min))){
                            serie.min = measurementType.typeConfig.min;
                        }

                        if(measurementType.typeConfig.max != '' && !isNaN(measurementType.typeConfig.max) && !(this.cell && this.cell.settings && this.cell.settings.serie && this.cell.settings.serie.max != null && this.cell.settings.serie.max != '' && !isNaN(this.cell.settings.serie.max))){
                            serie.max = measurementType.typeConfig.max;
                        }
                    }
                    
                    let data = {
                        name: value.name,
                        title: {
                            offsetCenter: ['0%', (labelPos+(serie.data.length*40))+'%'],
                        },
                        detail: {
                            valueAnimation: true,
                            offsetCenter: ['0%', (labelPos+20+(serie.data.length*40))+'%']
                        },
                        itemStyle:{}
                    };

                    if(measurementType.unitFields && measurementType.unitFields.Symbol){
                        data.detail.formatter = '{value} '+measurementType.unitFields.Symbol;
                    }

                    if (this.cell) {
                        data = {
                            ...data,
                            ...this.cell.settings.queries[value.queryIndex].options ? this.cell.settings.queries[value.queryIndex].options : {}
                        }
                    }

                    const fieldname = this.measurementTypeFieldName(value.queryIndex);

                    if(value.data[0]){
                        const measurement = value.data[0];

                        if(this.options && this.options.color && this.options.color[i]){
                            data.itemStyle.color = this.options.color[i];
                        }

                        if(measurementType.valueConfigList && measurementType.valueConfigList.itemType == 'RANGE' && measurementType.valueItems && Object.values(measurementType.valueItems)[0]){
                            const rangeItem = Object.values(measurementType.valueItems)[0];
                            const val = parseFloat(measurement[fieldname]);
                            const percentage = Math.round(((val-rangeItem.low)/(rangeItem.high - rangeItem.low))*10000)/100;
                            data.value = percentage;

                            data.detail.formatter = data.detail.formatter.replace('{value}', val);
                        }else{
                            data.value = parseFloat(measurement[fieldname]);
                        }
                    }

                    serie.data.push(data);
                }

            }

            const series = [serie];

            this.graphOptions.series = series;
            if (this.$refs[this.id]) {
                this.$refs[this.id].mergeOptions(this.graphOptions);
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }

        }
    }
}
</script>