<template>
<v-dialog :value="value" @input="val => {$emit('input', val)}" scrollable>
<v-card>
        <v-card-title>
          <v-avatar color="primary" size="40" class="elevation-3 mr-4">
            <v-icon dark v-if="item && item.id">
              mdi-pencil-outline
            </v-icon>
            <v-icon dark v-else>
              mdi-plus
            </v-icon>
          </v-avatar>
          <span class="headline" v-text="$t(name)+' '+(item && item.id?$t('aanpassen'):$t('toevoegen'))"></span>
          <v-spacer></v-spacer>
          <v-btn small icon @click="onCancel"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <v-form class="mt-4" ref="form" lazy-validation v-if="item">
                <slot v-bind:item="item"></slot>
            </v-form>
        </v-card-text>
        <v-card-actions class="grey lighten-3 py-3 px-3">
          <v-spacer></v-spacer>
          <v-btn @click="onCancel" rounded class="mr-2" text>
            Annuleren
          </v-btn>
          <v-btn id="item_dialog_save" color="secondary" @click="saveItem" :loading="saving" rounded depressed>
          <template v-if="item && item.id">
            <v-icon>mdi-content-save</v-icon>
            Opslaan
          </template>
          <template v-else>
              <v-icon>mdi-plus</v-icon>
            Toevoegen
          </template>
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
</template>

<script>
export default {
  name: 'eod-item-dialog',
  props: {
      itemId: String,
      defaultItem: Object,
      endpoint: String,
      fields: Array,
      value: Boolean,
      name: String,
      objectClass: Function
  },
  data: () => {
      return {
          item: null,
          saving: false,
      }
  },
  mounted(){
      if(this.itemId){
          this.getDataFromApi();
      }else if (this.defaultItem){
          if(this.objectClass){
              let objectClass = this.objectClass;
            this.item = new objectClass(this.defaultItem);
          }else{
              this.item = this.defaultItem;
          }
      }
    },
  methods: {
      async saveItem(){

            var valid = this.$refs.form.validate();
            if(valid){
                this.saving = true;

                let saveData = this.item;
                if(this.item.getSaveData){
                    saveData = await this.item.getSaveData();
                }

                let objectName = this.endpoint.charAt(0).toUpperCase() + this.endpoint.slice(1);

                this.$eod.save(objectName, saveData).then(result => {
                    this.saving = false;
                    this.showSaveAlert = false;
                    if(this.itemSaved){
                        this.itemSaved(result, this.item);
                    }else{
                        this.$toaster.notify({
                            type: 'success',
                            title: 'Succes',
                            body: 'Item is succesvol opgeslaan!'
                        });
                    }

                    let returnItem = null;
                    if(result.data.data['create'+objectName]){
                        returnItem = result.data.data['create'+objectName];
                    }else{
                        returnItem = result.data.data['update'+objectName];
                    }

                    this.item.id = returnItem.id;
                    this.$emit('onSaved', returnItem);

                }).catch(error => {
                    this.saving = false;
                });
            }
        },
        getDataFromApi(){

            let fetchMethod = this.fetchDataFromApi;
            if (this.fetchItemFromApi) {
                fetchMethod = this.fetchItemFromApi;
            }

            return (fetchMethod)().then(result => {
                let item = result;

                if (this.objectClass) {
                    let itemObject = this.objectClass;
                    this.item = new itemObject(item);
                }else{
                    this.item = item;
                }
                
                this.$emit('loaded', this.item);
                this.$nextTick(() => {
                    this.isLoaded = true;
                });
            });
        },
        fetchDataFromApi(){
            return this.$eod.getById(this.endpoint, this.itemId, this.fields).then(result => {
                return result.data.data[this.endpoint];
            });
        },
      onCancel(){
          this.$emit('input', false);
      }
  }
}
</script>