<template>
    <div class="eod-text-field w-100" style="flex-grow:1;">
        <div class="d-flex">
            <v-text-field :id="$attrs.label?$helper.slugify($attrs.label):null" :value="value" dense outlined v-on="$listeners" v-bind="$attrs" :style="info?'border-top-right-radius: 0;border-bottom-right-radius: 0;':''"></v-text-field>
            <div v-if="info" class="grey lighten-3 d-flex align-center" style="border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
                <v-tooltip top open-on-click :open-on-focus="false">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-bind="attrs" v-on="on" icon small><v-icon small>mdi-information-variant</v-icon></v-btn>
                    </template>
                    <div v-html="info"></div>
                </v-tooltip>
            </div>
        </div>
       
        <eod-translations v-if="translatable" class="mt-2" :value="value"></eod-translations>
    </div>
</template>
<style lang="scss" scoped>
    .v-input::v-deep {
        .v-input__control .v-input__slot{
            margin-bottom: 0;
        }
    }
</style>
<script>
import eodTranslations from './eod-translations.vue';
export default {
    components:{
        eodTranslations
    },
    props:{
        info: String,
        translatable: Boolean,
        value: Number|String
    },
    name: 'eod-text-field',
}
</script>