<template>
    <v-menu v-model="showMenu" offset-y width="auto" max-width="100%" min-width="300">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :hide-details="hideDetails" :outlined="outlined" :readonly="readonly" :label="label" v-bind="attrs" v-on="on" :value="itemName" @input="inputChanged" :required="required" :rules="rules"></v-text-field>
        </template>

        <eod-item-treeview ref="treeview" hoverable openOnClick flat :showSearch="false" :activatable="true" :search="search" :tree="tree" :modules="modules" @change="treeviewSelected">
            <template v-slot:extension>
                <slot name="extension"></slot>
            </template>
        </eod-item-treeview>
    </v-menu>
</template>
<script>
    import eodItemTreeview from './eod-item-treeview.vue';

export default {
    components:{
        'eod-item-treeview': eodItemTreeview
    },
    props:{
        label: String,
        tree: Array,
        modules: Object,
        value: Array|String,
        returnObject: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => {
                return [];
            }
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showMenu: false,
        search: '',
        itemName: '',
    }),
    watch:{
        value(val){
            if(val){
                let endpoint = this.tree[this.tree.length-1];
                this.$eod.get(endpoint, ['id','name'], {
                    limit:1,
                    where:[
                        {
                            column: 'id',
                            operator: '=',
                            value: val
                        }
                    ]
                }).then(result => {
                    if(result.data.data[endpoint] && result.data.data[endpoint].edges){
                        this.itemName = result.data.data[endpoint].edges[0].name;
                    }
                });
            }
        }
    },
    methods:{
        inputChanged(val){
            if(!this.showMenu){
                this.showMenu = true;
            }
            this.search = val;
        },
        treeviewSelected(val){
            this.$refs.treeview.loadRootTree();
            if(val && val[0]){
                this.itemName = val[0].name;
                if(this.returnObject){
                    this.$emit('input', val[0]);
                }else{
                    this.$emit('input', val[0].id);
                }
            }else{
                this.$emit('input', null);
            }
        }
    }
}
</script>