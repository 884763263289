<template>
    <div>
        <v-card class="mb-5">
            <v-card-title>
                <v-icon left>mdi-bell</v-icon>Meldingen
            </v-card-title>

            <v-card-text>
                <eod-events-table-compact
                    :events="events"
                    :statusIcons="statusIcons"
                    :eventTypeIcons="eventTypeIcons"
                    @openSidebar="openSidebar"
                />
            </v-card-text>
        </v-card>

        <eod-events-sidebar
            :event="sidebarEvent"
            :statusIcons="statusIcons"
            :eventTypeIcons="eventTypeIcons"
            @closed="sidebarClosed"
        ></eod-events-sidebar>
    </div>
</template>

<script>
import eodEventsTableCompact from './eod-events-table-compact';
import eodEventsSidebar from './eod-events-sidebar';

export default {
    name:'eod-events-widget',

    components: {
        eodEventsTableCompact,
        eodEventsSidebar
    },

    props:{
    },

    data: () => ({
        expanded: false,
        sidebarEvent: false,

        // Hardcoded

        statusIcons: {
          'open': 'mdi-checkbox-blank-circle-outline',
          'cleared': 'mdi-circle-off-outline',
          'masked': 'mdi-domino-mask',
        },

        eventTypeIcons: {
          'new': 'mdi-bell-ring',
          'dropped': 'mdi-bell-minus-outline',
          'escalated': 'mdi-bell-plus-outline',
          'acknowledged': 'mdi-bell-check-outline',
          'unresolved': 'mdi-bell-alert',
          'masked': 'mdi-bell-off-outline',
          'unmasked': 'mdi-bell-outline',
          'comment': 'mdi-comment-processing-outline'
        },

        events: [
          {
            id: 0,
            timestamp: 1670759655000,
            measurementType: 'Naam metingtype',
            eventType: 'new',
            severity: 4,
            value: 20,
            status: 'open',
            user: 'Lorem Ipsum',
            comments: 'Lorem ipsum can be a very long comment indeed. Multiple sentences blabla...',
            images: ['?', '?', '?'],
            device: 123,
            project: 'Bouwwerf Steenstraat',
            company: 'Company A',
            category: 'category ?',
            usedProducts: ['?', '?', '?'],
            children: [
              {
                id: 4,
                timestamp: 1670759629000,
                measurementType: 'Naam metingtype',
                eventType: 'acknowledged',
                severity: 3,
                value: 5,
                status: 'cleared',
                user: 'Lorem Lorem',
                comments: 'Lorem ipsum...',
                images: ['?', '?', '?'],
                device: 123,
                project: 'Bouwwerf Steenstraat',
                company: 'Company A',
                category: 'category ?',
                usedProducts: ['?', '?', '?'],
                children: []
              },
              {
                id: 5,
                timestamp: 1670759655000,
                measurementType: 'Naam metingtype',
                eventType: 'dropped',
                severity: 2,
                value: null,
                status: 'masked',
                user: 'Lorem Lorem',
                comments: 'Lorem ipsum...',
                images: ['?', '?', '?'],
                device: 123,
                project: 'Bouwwerf Steenstraat',
                company: 'Company B',
                category: 'category ?',
                usedProducts: ['?', '?', '?'],
                children: []
              },
            ]
          },
          {
            id: 1,
            timestamp: 1670759622000,
            measurementType: 'Naam metingtype',
            eventType: 'escalated',
            severity: 1,
            value: 900,
            status: 'cleared',
            user: 'Lorem Ipsum',
            comments: 'Lorem ipsum...',
            images: ['?', '?', '?'],
            device: 123,
            project: 'Project X',
            company: 'Company B',
            category: 'category ?',
            usedProducts: ['?', '?', '?'],
            children: []
          },
          {
            id: 10,
            timestamp: 1670759655000,
            measurementType: 'Naam metingtype',
            eventType: 'unresolved',
            severity: 2,
            value: 20,
            status: 'open',
            user: 'Lorem Ipsum',
            comments: 'Lorem ipsum...',
            images: ['?', '?', '?'],
            device: 123,
            project: 'Bouwwerf Steenstraat',
            company: 'Company A',
            category: 'category ?',
            usedProducts: ['?', '?', '?'],
            children: [
              {
                id: 14,
                timestamp: 1670759629000,
                measurementType: 'Naam metingtype',
                eventType: 'masked',
                severity: 1,
                value: 5,
                status: 'masked',
                user: 'Lorem Lorem',
                comments: 'Lorem ipsum...',
                images: ['?', '?', '?'],
                device: 123,
                project: 'Bouwwerf Steenstraat',
                company: 'Company A',
                category: 'category ?',
                usedProducts: ['?', '?', '?'],
                children: []
              },
              {
                id: 15,
                timestamp: 1670759655000,
                measurementType: 'Naam metingtype',
                eventType: 'unmasked',
                severity: 1,
                value: null,
                status: 'open',
                user: 'Lorem Lorem',
                comments: 'Lorem ipsum...',
                images: ['?', '?', '?'],
                device: 123,
                project: 'Bouwwerf Steenstraat',
                company: 'Company B',
                category: 'category ?',
                usedProducts: ['?', '?', '?'],
                children: []
              },

              {
                id: 16,
                timestamp: 1670759655000,
                measurementType: 'Naam metingtype',
                eventType: 'comment',
                severity: 1,
                value: null,
                status: 'open',
                user: 'Lorem Lorem',
                comments: 'Lorem ipsum...',
                images: ['?', '?', '?'],
                device: 123,
                project: 'Bouwwerf Steenstraat',
                company: 'Company B',
                category: 'category ?',
                usedProducts: ['?', '?', '?'],
                children: []
              },
            ]
          },
        ],
    }),

    mounted () {
    },

    watch:{
      
    },

    methods: {
        openSidebar(event) {
            this.sidebarEvent = event;
        },

        sidebarClosed() {
          this.sidebarEvent = false;
        },

        getColor(severity) {
            if (severity >= 4) {
            return 'error';
            }
            if (severity >= 3) {
            return 'warning';
            }
            if (severity >= 2) {
            return 'info';
            }
            if (severity >= 1) {
            return 'success';
            }

        return null;
      }
    },
  }
</script>