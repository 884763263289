<template>
<v-select
          :value="value"
          @change="val => $emit('input', val)"
          :items="items"
          small-chips
          :disabled="readonly"
           outlined
           item-value="threshold"
           :item-text="getUserName"
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              @click:close="data.parent.selectItem(data.item)"
            >
              <v-avatar
                color="primary"
                class="white--text"
                left
                v-text="data.item.threshold"
              ></v-avatar>
              {{ getUserName(data.item) }}
            </v-chip>
          </template>
        </v-select>
</template>
<script>
export default {
    name: 'eod-measurement-list',
    props: {
        readonly: Boolean,
        value: Number,
        measurementType: Object
    },
    data: () => ({
        items: [],
        users: []
    }),
    mounted(){
        if (this.measurementType.valueConfigListItems && this.measurementType.valueConfigListItems.length > 0) {
          // Use valueConfigListItems
          this.items = this.measurementType.valueConfigListItems;
        }else if (this.measurementType.valueConfigList.listItems && this.measurementType.valueConfigList.listItems.length > 0){
          // Use valueConfigList.listItems
          this.items = this.measurementType.valueConfigList.listItems;
        }

        this.setUsers(this.items);
    },
    methods: {
      setUsers(items){
        let ids = [];
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            ids.push(item.name);
          }

          if (ids.length > 0) {
            this.$eod.get('resolveUsers', ['id username firstName lastName'], {
                  whereIn:[{
                      column: 'id',
                      array: ids
                  }]
              }).then(result => {
                this.users = result.data.data.resolveUsers.edges;
              });
          }
          
      },
      getUserName(item){
        let id = item.name;
        for (let i = 0; i < this.users.length; i++) {
          const user = this.users[i];
          if (id == user.id) {
            return user.firstName+' '+user.lastName;
          }
        }

        return '';
      }
    }
}
</script>