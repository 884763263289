<template>
    <div>
        <eod-text-field :rules="[val => $validator.numeric(val), val => $validator.min(val, getTypeConfig('min')), val => $validator.max(val, getTypeConfig('max'))]" :suffix="measurementType.unit && measurementType.unit.name != ''?measurementType.unit.name:''" type="number" :min="getTypeConfig('min') != null?measurementType.typeConfig.min:null" :max="getTypeConfig('max') != null?measurementType.typeConfig.max:null" @keyup="checkValue" :disabled="readonly" :value="getValue()"></eod-text-field>
    </div>
</template>
<script>
export default {
    name: 'eod-measurement-number',
    props: {
        readonly: Boolean,
        value: Number,
        measurementType: Object
    },
    methods:{
        getTypeConfig(propertyName){
            
            if(this.measurementType.typeConfig && !isNaN(this.measurementType.typeConfig[propertyName])){
                return this.measurementType.typeConfig[propertyName];
            }
            return null;
        },
        getValue(){
            if(isNaN(this.value)){
                return null;
            }

            return this.value;
        },
        checkValue(event){
            const value = event.target.value;
            if(!isNaN(parseFloat(value))){
                this.$emit('input', value);
            }
        },
        cancelEvent(event) {// cross-browser code
            event.cancelBubble = true;
            event.returnValue = false;
            if(event.stopPropagation)
                event.stopPropagation();
            if(event.preventDefault)
                event.preventDefault();
            }
        }
}
</script>