module.exports = {
    routes: [{
        path: '/storage',
        name: 'storage',
        meta:{
            permissions:['storageobjects.read']
        },
        component: () => import('./views/index.vue')
    }],
    index: {
        endpoint: 'storageObjects',
        fields: ['id', 'key', 'name', 'type', 'task{id name}', 'taskType{id name}', 'project{id name}', 'company{id name}', 'measurementType{id name}', 'service{id name}', 'serviceType{id name}', 'product{id name}', 'productType{id name}', 'tags {id name}'],
        tabs: [],
    }
}