<template>
    <div v-if="value">
        <v-row v-if="!simple">
            <v-col>
                <v-text-field :value="value.name" @input="val => updateProperty('name', val)" :rules="nameRules"
                    label="Naam" required outlined hide-details :readonly="readonly"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field :value="value.description" @input="val => updateProperty('description', val)"
                    label="Omschrijving" outlined hide-details :readonly="readonly"></v-text-field>
            </v-col>
            <v-col>
                <v-autocomplete label="Categorie*" :rules="categoryRules" :value="value.category"
                    @input="val => updateProperty('category', val)" outlined hide-details :readonly="readonly"
                    :items="[{ value: 'home', text: 'Thuisadres' }, { value: 'warehouse', text: 'Depot' }]">
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="pt-4">
            <v-col>
                <eod-location-form v-model="value" @update="val => updateAddress(val)"></eod-location-form>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="12">
                        <v-text-field :rules="[val => rules.required(value, val, 'Straat + nummer')]"
                            :readonly="readonly" outlined hide-details :value="value.streetAddress"
                            @input="val => updateProperty('streetAddress', val)"
                            :label="'Straat + nummer'+(rules.required(value, value.streetAddress, null) != true?'*':'')"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field :readonly="readonly" outlined hide-details :value="value.postalCode"
                            @input="val => updateProperty('postalCode', val)" label="Postcode"></v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field :rules="[val => rules.required(value, val, 'Stad/Gemeente')]"
                            :readonly="readonly" outlined hide-details :value="value.locality"
                            @input="val => updateProperty('locality', val)"
                            :label="'Stad/Gemeente'+(rules.required(value, value.locality, null) != true?'*':'')"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="!simple">
                        <v-text-field :readonly="readonly" outlined hide-details :value="value.region"
                            @input="val => updateProperty('region', val)" label="Regio"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :rules="[val => rules.required(value, val, 'Land')]" :readonly="readonly" outlined
                            hide-details :value="value.country" @input="val => updateProperty('country', val)"
                            :label="'Land'+(rules.required(value, value.country, null) != true?'*':'')"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
        <v-row v-if="!simple">
            <v-col>
                <v-textarea :readonly="readonly" outlined hide-details :value="value.notes"
                    @input="val => updateProperty('notes', val)" label="Opmerkingen"></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import eodLocationForm from './eod-location-form.vue';
export default {
    name: 'eod-depot-detail',
    components:{
        eodLocationForm
    },
    props: {
        value: Object,
        readonly: {
            type: Boolean,
            default: false
        },
        simple: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            nameRules: [
                v => !!v || 'Adresnaam is verplicht',
            ],
            categoryRules: [
                v => !!v || 'Categorie is verplicht',
            ],
            rules: {
                required: (item, value, fieldName) => {
                    if(((item.streetAddress && item.streetAddress != '') || (item.locality && item.locality != '')) && !value){
                        return (fieldName ? fieldName : 'Veld') + ' is verplicht.';
                    }

                    return true;
                },
            },
        }
    },
    methods: {
        updateAddress(address){
            const val = this.value;

            if(address.road){
                val.streetAddress = address.road;

                if(address.house_number){
                    val.streetAddress += ' ' + address.house_number;
                }
            }
            
            if(address.country){
                val.country = address.country;
            }

            if(address.town || address.village){
                val.locality = address.town?address.town:address.village;
            }
            
            if(address.postcode){
                val.postalCode = address.postcode;
            }

            if(address.state){
                val.region = address.state;
            }

            this.$emit('input', val);
            this.$forceUpdate();
        },
        updateProperty(property, value) {
            let val = this.value;
            val[property] = value;
            this.$emit('input', val);
            this.$forceUpdate();
        }
    }
}
</script>