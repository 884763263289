import {
    openDB,
} from 'idb';

export default class Database {
    dbname = 'eye-on-data'
    dbversion = 5
    checkSupport(){
        if (!('indexedDB' in window)) {
            return false;
        }
    }
    async load() {
        this.db = await openDB(this.dbname, this.dbversion, {
            upgrade(db, oldVersion, newVersion, transaction) {
                if (oldVersion < 1) {
                    db.createObjectStore('running_tasks', {
                        keyPath: 'id',
                    });
                }
                if (oldVersion < 2) {
                    db.deleteObjectStore('running_tasks');
                    db.createObjectStore('responses', {
                        keyPath: 'id',
                    });
                }
                if (oldVersion < 3) {
                    try{
                        db.deleteObjectStore('responses');
                    }catch(e){

                    }
                    
                    db.createObjectStore('responses', {
                        keyPath: 'id',
                    });
                }
                if (oldVersion < 4) {
                    try {
                        db.deleteObjectStore('responses');
                    } catch (e) {

                    }
                    
                    db.createObjectStore('jobs', {
                        keyPath: 'id',
                    });
                }
                if (oldVersion < 5) {
                    db.createObjectStore('queue', {
                        keyPath: 'id',
                    });
                }
            }
        });
    }
    async put(storeName, value, key){
        await this.db.put(storeName, value, key);
    }
    async get(storeName, key) {
        return await this.db.get(storeName, key);
    }
    async delete(storeName, key) {
        await this.db.delete(storeName, key);
    }
    getAll(storeName) {
        return this.db.getAll(storeName);
    }
}