<template>
<div>
<v-slider
            :value="value"
            @change="val => $emit('input', val)"
            class="align-center"
            :max="max"
            :min="min"
            hide-details
            :disabled="readonly"
            :thumb-size="24"
            thumb-label="always"
            color="secondary"
            
          >
            <template v-slot:append>
              <v-text-field
                :value="value"
                @change="val => $emit('input', val)"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
    <div class="text-subtitle-1">{{getValueText()}}</div>
</div>
</template>
<script>
export default {
    name: 'eod-measurement-list',
    props: {
        readonly: Boolean,
        value: Number,
        measurementType: Object
    },
    data: () => ({
      min: 0,
      max: 0,
      items: []
    }),
    mounted(){
        if (this.measurementType.valueConfigListItems && this.measurementType.valueConfigListItems.length > 0) {
            // Use valueConfigListItems
            this.items = this.measurementType.valueConfigListItems;
            this.setMinMax(this.items);
        }else if (this.measurementType.valueConfigList.listItems && this.measurementType.valueConfigList.listItems.length > 0){
            this.items = this.measurementType.valueConfigList.listItems;
            this.setMinMax(this.items);
        }
    },
    methods:{
      getValueText() {
        if (this && this.items) {
          for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            if (item.low <= this.value && item.high >= this.value) {
              return item.name;
            }
          }
        }

        return '/';
      },
      setMinMax(items){
        let min, max = 0;

        if (items && items[0]) {
          min = items[0].low;
          max = items[0].high;
        }

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (min > item.low) {
            min = item.low;
          }
          if (max < item.high) {
            max = item.high;
          }
        }

        this.min = min;
        this.max = max;
      }
    }
}
</script>