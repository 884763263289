import { render, staticRenderFns } from "./eod-project-general.vue?vue&type=template&id=d5e75f1e"
import script from "./eod-project-general.vue?vue&type=script&lang=js"
export * from "./eod-project-general.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports