<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>


<script>
//import {eodEventsDrawer} from '@/eodvuecomponents';

export default {
  name: 'CleanApp',

  components: {
  },

  data: () => ({

  }),
}
</script>