<template>
    <v-chip v-if="name" color="grey lighten-2" :close="!this.value.isHidden" @click:close="$emit('delete')">
        {{value.label}}:&nbsp;<strong>{{name}}</strong>
    </v-chip>
</template>
<script>
import User from './../../models/user';

export default {
    name: 'eod-graph-filter-chip',
    props: {
        value: Object
    },
    data: () => ({
        name: null
    }),
    mounted(){
        let fields = ['id', 'name'];
        let isUser = false;
        if(this.value.endpoint == 'users' || this.value.endpoint == 'resolveUsers'){
            isUser = true;
            fields = ['id', 'firstName', 'lastName', 'username'];
        }

        this.$eod.get(this.value.endpoint, fields, {
            where:[
                {
                    column: 'id',
                    operator: '=',
                    value: this.value.value
                }
            ]
        }).then(response => {
            if(response.data.data[this.value.endpoint].edges){
                let item = response.data.data[this.value.endpoint].edges[0];
                if(isUser){
                    item = new User(item);
                    this.name = item.getFullName();
                }else{
                    this.name = item.name;
                }
            }
        });
    }
}
</script>