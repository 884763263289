const {eod} = require('@/eodvuecomponents');

module.exports = {
    fromDynamicRanges: [{
        value: -24,
        type: 'hours',
        label: '24u geleden'
    }, {
        value: -1,
        type: 'weeks',
        label: '1 week geleden'
    }, {
        value: -1,
        type: 'months',
        label: '1 maand geleden'
    }, {
        value: -1,
        type: 'years',
        label: '1 jaar geleden'
    }],
    routes: [
        {
            path: '/dashboard/home',
            name: 'dashboards',
            component: () => import('./views/detail.vue'),
            meta: {
                permissions:['dashboard.read'],
                isHome: true // Property to know if the dashboard is homepage
            }
        }, {
            path: '/dashboards/list',
            name: 'dashboards.list',
            component: () => import('./views/index.vue'),
            meta: {
                permissions:['dashboard.read'],
            }
        }, {
            path: '/dashboard/:id',
            name: 'dashboard',
            component: () => import('./views/detail.vue'),
            meta:{
                permissions:['dashboard.read']
            },
            beforeEnter: async (to, from, next) => {
                // Check permission dashboard through 'templates' endpoint
                const template = await eod.get('templates', ['id'], {
                    where: [{
                        column: 'id',
                        operator: '=',
                        value: to.params.id
                    }]
                }).then(response => {
                    if(response.data.data.templates && response.data.data.templates.edges[0]){
                        return response.data.data.templates.edges[0];
                    }

                    return null;
                }).catch(() => {
                    return null;
                });

                if(template){
                    return next();
                }

                return next({name:'403'});
            }
        },
        {
            path: '/dashboards/map',
            name: 'dashboards.map',
            component: () => import('./views/index.vue'),
            meta:{
                permissions:['dashboard.read']
            },
        },
        {
            path: '/dashboard/project/:id',
            name: 'dashboard.project',
            beforeEnter: async (to, from, next) => {
                // Check if access to project
                const projectsCheck = await eod.get('projects', ['id'], {
                    where:[{
                        column: 'id',
                        operator: '=',
                        value: to.params.id
                    }]
                }).then(response => {
                    if(response.data.data.projects && response.data.data.projects.edges[0]){
                        return true;
                    }

                    return false;
                });

                if(!projectsCheck){
                    return next({name:'403'});
                }

                const tabs = await eod.getProjectDashboardTabs(to.params.id);

                const firstTab = tabs[0];

                if(!firstTab){
                    return next({name: 'dashboard.create', query:{project_id:to.params.id}});
                }

                return next(firstTab.to);
            },
            meta:{
                permissions:['dashboard.read']
            },
        },
        {
            path: '/dashboard/product/:id',
            name: 'dashboard.product',
            component: () => import('./views/detail.vue'),
            meta:{
                permissions:['dashboard.read', 'products.devices.read']
            },
            beforeEnter: async (to, from, next) => {

                if(!to.query.project_id){
                    // Get project of product
                    const project = await eod.getById('product', to.params.id, ['project{id}'])
                        .then(response => {
                            if(response && response.data.data){
                                if(!response.data.data.product){
                                    return null;
                                }

                                return response.data.data.product.project;
                            }

                            return null;
                        }).catch(() => {
                            return null;
                        });

                    if(!project){
                        return next({name:'403'});
                    }

                    const tabs = await eod.getProjectDashboardTabs(project.id);
                    for (let i = 0; i < tabs.length; i++) {
                        const tab = tabs[i];
                        if(tab.products && tab.products.some(value => value.id == to.params.id)){
                            return next(tab.to);
                        }
                    }

                    return next({name:'dashboard.product', params:to.params, query:{project_id: project.id, product_id: to.params.id}});
                }

                 // Check if access to project
                 const projectsCheck = await eod.getById('project', to.query.project_id, ['id']).then(response => {
                    if(response.data.data && response.data.data.project){
                        return true;
                    }

                    return false;
                }).catch(() => {return false;});

                if(!projectsCheck){
                    return next({name:'403'});
                }

                return next();
            }
        },
        {
            path: '/dashboard/jobtype/:id',
            name: 'dashboard.jobtype',
            component: () => import('./views/detail.vue'),
            meta:{
                permissions:['dashboard.read', 'jobtypes.read']
            },
            beforeEnter: async (to, from, next) => {
                // Check permission dashboard through 'project_id' query param
                const project = await eod.get('resolveProjects', ['id'], {
                    where: [{
                        column: 'id',
                        operator: '=',
                        value: to.query.project_id
                    }]
                }).then(response => {
                    if(response.data.data.resolveProjects && response.data.data.resolveProjects.edges[0]){
                        return response.data.data.resolveProjects.edges[0];
                    }

                    return null;
                }).catch(() => {
                    return null;
                });

                if(project){
                    return next();
                }

                return next({name:'403'});
            }
        },
        {
            path: '/dashboard/:id/report',
            name: 'dashboard_report',
            component: () => import('./views/report.vue'),
            meta:{
                permissions:['dashboard.read']
            }
        },
        {
            path: '/dashboard/create',
            name: 'dashboard.create',
            component: () => import('./views/detail.vue'),
            meta:{
                permissions:['dashboard.create']
            }
        }],
    types: [
        {
            'icon': 'mdi-clipboard-outline',
            'label': 'Taaktype',
            'endpoint': 'taskTypes',
            'entity': 'taskType',
            'filter': 'tasktype_id'
        },
        {
            'icon': 'mdi-folder-open-outline',
            'label': 'Project',
            'endpoint': 'projects',
            'entity': 'project',
            'filter': 'project_id'
        },
        {
            'icon': 'mdi-file-sign',
            'label': 'Dienst',
            'endpoint': 'services',
            'entity': 'service',
            'filter': 'service_id'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'endpoint': 'companies',
            'entity': 'company',
            'filter': 'company_id'
        },
        {
            'icon': 'mdi-waveform',
            'label': 'Meetwaarde',
            'endpoint': 'measurementTypes',
            'entity': 'measurementType',
            'filter': 'measurementtype_id'
        }
    ],
    search: [
        {
            'icon': 'mdi-form-select',
            'label': 'Jobtype',
            'type': 'module',
            'settings': {
                'module': 'jobTypes',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'job_type_id'
        },
        {
            'icon': 'mdi-package-variant',
            'label': 'Toestel',
            'type': 'module',
            'settings': {
                'module': 'products',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'product_id'
        },
        {
            'icon': 'mdi-folder-open-outline',
            'label': 'Project',
            'type': 'module',
            'settings': {
                'module': 'projects',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'project_id'
        },
        {
            'icon': 'mdi-file-sign',
            'label': 'Dienst',
            'type': 'module',
            'settings': {
                'module': 'services',
                'fields': ['name'],
                'title': '{name}',
            },

            'filter': 'service_id'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'type': 'module',
            'settings': {
                'module': 'companies',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'company_id'
        },
        {
            'icon': 'mdi-account-outline',
            'label': 'Eigenaar',
            'type': 'module',
            'settings': {
                'module': 'searchUsers',
                'fields': ['firstName', 'lastName', 'username'],
                'title': '{firstName} {lastName} ({username})',
                'options': {
                    enabled: true
                }
            },

            'filter': 'owner_id'
        },
    ]
}