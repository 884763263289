<template>
    <v-dialog
      :value="value"
      @input="(val) => {$emit('input', val)}"
      max-width="800"
      persistent
    >
      <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        Actie
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        step="2"
      >
        Resultaat
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
          <div class="px-2 py-2 mb-4">
                Klik op 'Exporteren' om een backup te maken van alle data (uitgezonderd meetresultaten).<br>
                Klik op 'Importeren' om een backup terug te zetten.<br>
          </div>
          <v-divider></v-divider>
          <div class="px-2 py-2 d-flex">
              <v-btn text @click="$emit('input', false)">Annuleren</v-btn>
              <v-spacer></v-spacer>
              <input @change="importItems" class="d-none" id="importFile" type="file" name="backup" ref="importFile" />
              <label class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary mr-2" for="importFile">Importeren</label>
              <v-btn color="primary" @click="exportItems" :loading="loading">
                    Exporteren
                </v-btn>
          </div>
      </v-stepper-content>

      <v-stepper-content v-if="action == 'export'" step="2">
          <div class="px-2 py-2 mb-4">
              <template v-if="loading">
                  <h2>Exporteren</h2>
                  <p v-if="info">{{info}}</p>
                  <p v-else>Bezig met exporteren...</p>
                  <v-progress-linear :value="percentage"></v-progress-linear>
              </template>
              <template v-else-if="!error">
                <h2>Klaar</h2>
                <p>Exporteren gelukt!</p>
              </template>
              <template v-else>
                <h2>Fout</h2>
                <p>Fout bij het exporteren van de gegevens.</p>
                <v-alert type="error" v-html="error"></v-alert>
              </template>
          </div>

          <v-divider></v-divider>
          <div class="px-2 py-2 d-flex">
              <v-btn text @click="$emit('input', false)">
                    Sluiten
                </v-btn>
              <v-spacer></v-spacer>
                <v-btn v-if="!error" :loading="loading" :href="downloadUrl" color="primary"><v-icon left>mdi-microsoft-excel</v-icon>Downloaden</v-btn>
          </div>
      </v-stepper-content>
      <v-stepper-content v-if="action == 'import'" step="2">
          <div class="px-2 py-2 mb-4">
              <template v-if="loading">
                  <h2>Importeren</h2>
                  <p v-if="info">{{info}}</p>
                  <p v-else>Bezig met importeren...</p>
                  <v-progress-linear :value="percentage"></v-progress-linear>
              </template>
              <template v-else-if="!error">
                <h2>Klaar</h2>
                <p>Importeren gelukt!</p>
              </template>
              <template v-else>
                <h2>Fout</h2>
                <p>Fout bij het importeren van de gegevens.</p>
                <v-alert type="error" v-html="error"></v-alert>
                <v-expansion-panels v-if="errorDetails">
                  <v-expansion-panel>
                    <v-expansion-panel-header>Details</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-textarea :value="decodeHTMLEntities(errorDetails)" rows="10" outlined></v-textarea>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
          </div>

          <v-divider></v-divider>
          <div class="px-2 py-2 d-flex">
              <v-btn text @click="$emit('input', false)">
                    Sluiten
                </v-btn>
              <v-spacer></v-spacer>
                <v-btn v-if="!error" :loading="loading" @click="$emit('input', false)" color="primary">Sluiten</v-btn>
          </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
    </v-dialog>
</template>
<script>

    export default {
        name:'eod-export-dialog',
        props:{
            value: Boolean
        },
        data: () => ({
            // General
            step: 1,
            action: null,
            loading: false,
            error: null,
            errorDetails: null,
            percentage: 0,
            info: null,
            
            // Export
            downloadUrl: null
        }),
        methods:{
          decodeHTMLEntities(text) {
            var textArea = window.document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
          },
            importItems(){
              this.action = 'import';
              this.loading = true;
              this.$nextTick(() => {
                this.step = 2;
              });
              this.error = null;
              this.errorDetails = null;

              this.$eod.importBackup(this.$refs.importFile.files[0])
                    .then(async response => {
                        const total = response.data.jobs.length;
                        let jobResponse = null;

                        for (let i = 0; i < total; i++) {
                          const job = response.data.jobs[i];
                          this.info = job.text;

                          jobResponse = await this.$eod.post(job.url);
                          if(!jobResponse.data.success){
                            this.error = 'Fout bij het importeren';
                            return false;
                          }

                          this.percentage = (i+1)/total*100;
                        }
                    }).catch((e) => {
                        this.error = e;
                        this.errorDetails = e.response.data;
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            exportItems(){
              this.action = 'export';
              this.loading = true;

              this.$nextTick(() => {
                this.step = 2;
              });
              

                this.$eod.exportBackup()
                    .then(async response => {

                      const total = response.data.jobs.length;
                      let jobResponse = null;

                      for (let i = 0; i < total; i++) {
                        const job = response.data.jobs[i];
                        this.info = job.text;

                        jobResponse = await this.$eod.post(job.url);
                        
                        if(!jobResponse.data.success){
                          this.error = 'Fout bij het exporteren';
                          return false;
                        }

                        this.percentage = (i+1)/total*100;
                      }
                      
                      this.downloadUrl = jobResponse.data.path;
                    }).catch((e) => {
                        this.error = e;
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        }
    }
</script>