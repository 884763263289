<template>
    <v-card>
            <v-card-title>Meetwaardes<v-spacer></v-spacer><v-btn @click="selectMeasurementTypeDialog = true" color="primary" depressed rounded><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn></v-card-title>
            <v-card-text>
                <v-data-table :items="value" :headers="[{value:'id', text:'Meetwaarde'}, {value:'indicativeValue', text:'Indicatieve waarde'}, {value:'actions', width:10}]">
                    <template v-slot:item.indicativeValue="{ item }">
                        <v-text-field type="number" step="0.01" :value="item.indicativeValue" @input="val => item.indicativeValue = parseFloat(val)" dense outlined hide-details>
                                <template v-slot:append>
                                    <div class="d-flex justify-content-center align-items-center" style="height:24px">
                                    {{item.unit?item.unit.name:''}}/15 min
                                </div>
                                </template>
                            </v-text-field>
                    </template>
                    <template v-slot:item.id="{ item }">
                        {{item.name}}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="deleteItem(item)" color="error" icon><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <eod-object-selector v-if="selectMeasurementTypeDialog" v-model="selectMeasurementTypeDialog" endpoint="measurementTypes" label="Kies een meetwaarde" :fields="['id','name','unit{id name}']" @click="addMeasurementType"></eod-object-selector>
        </v-card>
        
</template>

<script>
import eodObjectSelector from './eod-object-selector.vue';

export default{
    components:{
        eodObjectSelector
    },
    props:{
        value: Array
    },
    data: () => {
        return {
            selectMeasurementTypeDialog:false
        }
    },
    methods:{
        deleteItem(item){
            const val = this.value;
            for (let i = 0; i < val.length; i++) {
                if(val[i].id == item.id){
                    val.splice(i, 1);
                };
            }
            this.$emit('input', val);
        },
        addMeasurementType(item){
            let val = this.value;

            if(!val){
                val = [];
            }
    
            val.push(item);
    
            this.selectMeasurementTypeDialog = false;
    
            this.$emit('input', val);
        },
    }
}
</script>