<template>
    <div class="h-100 w-100">
        <v-chart @mouseup="params => $emit('mouseup', { params: params, data: params.value[2], event: params.event.event ? params.event.event : params.event })"  v-show="value.length > 0" @finished="finished" @datazoom="selectChanged" :group="groupName" :ref="id" :initOptions="graphOptions" :autoresize="true" :manualUpdate="true"/>
            <div v-if="!value || value.length == 0">Gelieve de grafiek in te stellen</div>
    </div>
</template>
<style lang="scss">
    .echarts {
        width: 100%;
        height: 100%;
    }
</style>
<script>
    import ECharts from './echarts/ECharts';

    export default {
        name: 'eod-graph-hbar',
        components:{
            'v-chart': ECharts,
        },
        props:{
            id: String,
            value:Object|Array,
            template:Object|Array,
            xAxis:Object,
            options: {
                type: Object,
                default(){
                    return {}
                }
            },
        },
        data: () => ({
            graphOptions: {},
            defaultOptions:{
                    //renderer: 'svg',
                    toolbox:{
                        feature:{
                            dataZoom:{
                                show: true,
                                yAxisIndex: 'none',
                                icon:{
                                    zoom:'image://',
                                    back:'image://'
                                },
                                brushStyle:{
                                    color:"#000000",
                                    opacity: 0.3,
                                }
                            }
                        }
                    },
                    legend: {
                        show:false,
                        type: 'scroll',
                        pageButtonPosition: 'start'
                    },
                    grid:{
                        left:'5%',
                        top:10,
                        right:'5%',
                        bottom:10,
                        containLabel: true
                    },
                    tooltip: {
                        confine:true,
                        show: true,
                        trigger: 'axis',
                        snap:true,
                    },
                    xAxis: {
                        type: 'time',
                        id: 'timeline',
                    },
                    yAxis: {
                        type: 'value'
                    },
                    
                    series: [],
                }
        }),
        computed:{
            groupName(){
                if(this.group){
                    return this.group;
                }
                return 'eod-graph-line';
            }
        },
        methods: {
            selectChanged(params){
                this.$emit('selectChanged', this.$refs[this.id], params, this.value);
            },
            finished(){
                this.$emit('finished', this.id, this.$refs[this.id]);
            },
            resize(){
                this.$refs[this.id].resize();
            },
            update(){
                this.graphOptions = _.merge(this.defaultOptions, this.options);

                this.graphOptions.yAxis.data = [];

                if(this.xAxis){

                    let firstColumn = Object.keys(this.xAxis)[0];
                    for (let i = 0; i < this.value.length; i++) {
                        const item = this.value[i];
                        let val = null;

                        if(item[firstColumn]){
                            val = item[firstColumn];
                        }else if(firstColumn.includes('.')){
                            val = Object.assign({},item);
                            let parts = firstColumn.split('.');
                            for (let j = 0; j < parts.length; j++) {
                                const part = parts[j];
                                if(val[part]){
                                    val = val[part];
                                }else{
                                    val = '';
                                    break;
                                }
                            }
                        }
                        this.graphOptions.yAxis.data.push(val);
                    }
                }

                console.log('xAxis', this.xAxis, 'value', this.value);

                if(this.value){
                    let secondColumn = Object.keys(this.xAxis)[1];
                    let data = [];
                    for (let i = 0; i < this.value.length; i++) {
                        const val = this.value[i];
                        data.push(val[secondColumn]);
                    }

                    this.graphOptions.series = [
                        {
                            type: 'bar',
                            data: data
                        }
                    ];
                }

                this.$refs[this.id].mergeOptions(this.graphOptions);
                this.$nextTick(() => {
                    this.$forceUpdate();
                });               
            }
        }
    }
</script>