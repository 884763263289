<template>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :color="copySuccess?'success':(copyError?'error':'default')" v-clipboard:copy="text?text:value.id" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError" v-bind="attrs" v-on="on" icon small>
                    <v-icon v-if="copySuccess" small>mdi-check</v-icon>
                    <v-icon v-else-if="copyError" small>mdi-close-octagon</v-icon>
                    <v-icon v-else small>mdi-content-copy</v-icon>
                </v-btn>
            </template>
            <span>{{tooltip}}</span>
        </v-tooltip>
</template>
<script>
    export default {
        name:'eod-button-copy',
        props:{
            value: Object,
            tooltip:{
                type:String,
                default: 'ID kopiëren'
            },
            text: String
        },
        data(){
            return {
                copySuccess:false,
                copyError:false,
            }
        },
        methods: {
            onCopySuccess(){
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
                }, 1000);
                
            },
            onCopyError(){
                this.copyError = true;
                setTimeout(() => {
                    this.copyError = false;
                }, 1000);
            },
        }
    }
</script>