<template>
    <v-list :dense="dense">
        <v-progress-circular v-if="loading" size="50" color="primary" indeterminate></v-progress-circular>
        <v-list-item v-for="item in items" :key="item.id" @click="$router.push({name:'response', params:{id:item.id}})">
            <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
                <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>
<script>
export default {
    name:'eod-responses-list',
    props:{
        filter: {
            type: Object,
            default: () => ({})
        },
        dense:{
            type:Boolean,
            default: false
        }
    },
    data: () => ({
        fields:['id createdAt job{jobType{id name} taskType{id name}}'],
        items: [],
        loading:false
    }),
    mounted(){
        this.loadDataFromAPI();
    },
    methods:{
        loadDataFromAPI(){
            this.loading = true;
            this.$eod.get('responses', this.fields, this.filter)
                .then(response => {
                    this.items = response.data.data.responses.edges;
                })
                .finished(() => {
                    this.loading = false;
                })
        }
    }
}
</script>