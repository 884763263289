<template>
    <v-list :dense="dense">
                <v-list-item v-for="product in value" :key="product.id" @click="$router.push({name:'product', params:{id: product.id}})">
                    <v-list-item-content>
                        <v-list-item-title>{{product.name}}</v-list-item-title>
                        <v-list-item-subtitle v-if="product.quantity"><strong>Aantal:</strong> {{product.quantity}}<span v-if="product.productType && product.productType.unit"> {{ product.productType.unit.name }}</span></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
</template>
<script>
export default {
    name:'eod-products-list',
    props:{
        value: Array,
        dense:{
            type: Boolean,
            default: false
        }
    }
}
</script>