<template>
    <v-menu :close-on-content-click="false" v-model="isMenuVisible" :disabled="disabled">
        <template v-slot:activator="{on, attrs}">
            <div v-if="!multiple" class="d-flex" :class="filter?'eod-input-filter':''">
                <v-chip v-if="filter && label" label>{{label}}</v-chip>
                <v-text-field :dark="filter" append-icon="mdi-chevron-down" :background-color="filter?'secondary':''" :rules="rules" :required="required" :prepend-inner-icon="resolvedItems[0]?TreeObject.getIcon(resolvedItems[0].class):null" hide-details :value="resolvedItems[0]?resolvedItems[0].name:''" readonly dense outlined :label="filter?null:label" v-on="on" v-bind="attrs"></v-text-field>
            </div>
            <div v-else v-on="on" v-bind="attrs" class="v-input v-input--hide-details v-input--is-readonly v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                <div class="v-input__control">
                <div class="v-input__slot">
                <fieldset aria-hidden="true"><legend style="width: 0px;"><span class="notranslate">​</span></legend></fieldset>
                <div class="v-text-field__slot">
                <v-label class="objects-treeview-label" :class="{'v-label--active':(isMenuVisible || resolvedItems[0])}" aria-hidden="true" v-if="label">{{ label }}</v-label>
                <div class="chips">
                <v-chip small clearable style="margin:4px 5px 4px 0;" v-for="item in resolvedItems" :key="item.id"><v-icon small left>{{ TreeObject.getIcon(item.class) }}</v-icon>{{ item.name }}</v-chip>
            </div>
            </div>
        </div>
        </div>
            </div>
        </template>
        <v-card min-height="200">
            <v-card-title class="text-body-1">
                <eod-search v-model="searchFilters" flat topbar :readonly="searchReadonly" :config="searchConfig" @update="updateSearch"></eod-search>
            </v-card-title>
            <v-card-text>
            <eod-objects-treeview ref="treeview" :open="open" :multiple="multiple" :selected="resolvedItems" :selectable="selectable" :search="$helper.filtersToVariables(searchFilters)" readonly v-bind="$props" @selectionChanged="selectionChanged" @selected="itemSelected"></eod-objects-treeview>
        </v-card-text>
        </v-card>
    </v-menu>
</template>
<style lang="scss" scoped>
    .objects-treeview-label{
        position:absolute !important;
    }
</style>
<style lang="scss">
    .eod-input-filter{
        fieldset{
            border:none !important;
        }

        .v-input {
            border-top-left-radius:0 !important;
            border-bottom-left-radius:0 !important;
        }

        .v-chip {
            border-top-right-radius: 0 !important;border-bottom-right-radius: 0 !important;
        }
    }
</style>
<script>
    import eodObjectsTreeview from './eod-objects-treeview';
    import eodSearch from './eod-search';
    import TreeObject from './../models/treeObject';
    import Product from './../models/product';

    export default {
        name: 'eod-objects-treeview-input',
        components: {
            eodObjectsTreeview,
            eodSearch,
        },
        props:{
            label: String,
            disabled: Boolean,
            returnObject: Boolean,
            value: String|Object,
            required: Boolean,
            multiple: Boolean,
            rules: Array,
            open: Array,
            exclude: Array,
            classes: Array,
            filters: Array,
            search: Array,
            searchReadonly: Boolean,
            filter: Boolean,
            selectable: {
                type: Array,
                default: () => (['DEVICE', 'DEVICE_FOLDER', 'ARTICLE', 'ARTICLE_FOLDER', 'TOOL', 'TOOL_FOLDER', 'PROJECT'])
            },
        },
        data: () => ({
            searchConfig: null,
            searchFilters: [],
            resolvedItems: [],
            isMenuVisible: false,
            TreeObject:TreeObject
        }),
        created(){
            if(this.search){
                this.searchFilters = this.search;
            }

            const config = Product.searchConfig;
            if(this.filters){
                for (let i = 0; i < this.filters.length; i++) {
                    const filter = this.filters[i];
                    config.push(filter);
                }
            }
            
            this.searchConfig = config;

            this.resolveItem();
        },
        watch: {
            value(newVal, oldVal){
                if(!newVal){
                    this.resolvedItems = [];
                }else{
                    this.resolveItem();
                }
            },
        },
        methods:{
            updateSearch(){
                this.$nextTick(() => {
                    if(this.$refs.treeview){
                        this.$refs.treeview.fetchItems();
                    }
                })
            },
            itemSelected(item){
                if(!this.multiple){
                    this.resolvedItems = [item];

                    if(this.returnObject){
                        this.$emit('input', item);
                    }else{
                        this.$emit('input', item.id);
                    }
                    
                    this.isMenuVisible = false;
                }
            },
            selectionChanged(selection){
                if(this.multiple){
                    this.resolvedItems = selection;
                    if(this.returnObject){
                        this.$emit('input', selection);
                    }else{
                        this.$emit('input', selection.map(item => item.id));
                    }
                    
                }
            },
            resolveItem(){
                if(this.value){

                    if(this.multiple){
                        this.$eod.get('treeObjects', ['id', 'name', 'module', 'class', 'parentIds', 'childIds'], {
                            noTree: true,
                            whereIn:[
                                {
                                    column:'id',
                                    array: this.value
                                }
                            ]
                        })
                            .then(response => {
                                if(response && response.data.data && response.data.data.treeObjects){
                                    this.resolvedItems = response.data.data.treeObjects.edges;
                                }
                            });
                    }else{
                        let itemId = this.value;
                        if(this.value.id){
                            itemId = this.value.id;
                        }

                        this.$eod.getById('treeObject', itemId, ['id', 'name', 'module', 'class'])
                            .then(response => {
                                if(response && response.data.data && response.data.data.treeObject){
                                    this.resolvedItems = [response.data.data.treeObject];
                                }
                            });
                    }

                    
                }
            }
        }
    }
</script>