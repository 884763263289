<template>
<div>
<v-expansion-panels v-model="openPanels">
            <v-expansion-panel>
                <v-btn v-if="!readonly" small style="position:absolute;right:10px;top:-10px;z-index:5;" color="secondary" fab @click="addTracking"><v-icon>mdi-plus</v-icon></v-btn>
                <v-expansion-panel-header class="primary white--text" hide-actions ripple>
                    <div>
                        <v-icon dark left>mdi-timer-outline</v-icon>
                        <v-badge v-if="value && value.length > 0" color="secondary" :content="value.length">{{$t('Timetrackings')}}</v-badge>
                        <span v-else>{{$t('Timetrackings')}}</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="comments__expansion-panel-content">
                    <v-expansion-panels v-model="openTrackings" flat accordion focusable>
                        <v-expansion-panel v-for="(timetracking, index) in value" :key="'item_'+index">
                            <v-expansion-panel-header>
                                <div>
                                    <div class="font-weight-bold">{{$moment(timetracking.startedAt).format('DD/MM HH:mm')}} - {{$moment(timetracking.startedAt).add(timetracking.duration/1000, 'seconds').format('DD/MM HH:mm')}}</div>
                                    <div class="py-1" v-if="timetracking.comment && timetracking.comment != ''"><v-icon small left>mdi-text</v-icon>{{timetracking.comment}}</div>
                                    <div v-if="timetracking.userId && timetracking.userId != ''"><v-icon small left>mdi-account-outline</v-icon>{{getUserName(timetracking.userId)}}</div>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-4">
                                <eod-add-timetracking v-model="value[index]" @input="loadUsers" :readonly="readonly" :min="min" :max="max"></eod-add-timetracking>
                                <div class="d-flex" v-if="!readonly">
                                    <v-spacer></v-spacer>
                                <v-btn @click="duplicateTracking(index)" icon>
                                    <v-icon>mdi-content-duplicate</v-icon>
                                </v-btn>
                                <v-btn @click="showDeleteDialog(index)" color="error" icon>
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

        <v-dialog v-model="deleteDialog" persistent max-width="550" v-if="activeIndex != null">
            <v-card>
                <v-card-title class="mb-4">
                  <v-avatar color="error" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-trash-can-outline
            </v-icon>
          </v-avatar><span class="headline">Verwijderen?</span>
          </v-card-title>
                <v-card-text>
                  <span class="text-body-1">Weet u zeker dat u de tracking op '<strong>{{$moment(value[activeIndex].startedAt).format('DD-MM-YYYY HH:mm')}}</strong>' wilt verwijderen?</span>
                </v-card-text>
                <v-card-actions class="grey lighten-3 py-3 px-3">
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false" rounded>Annuleren</v-btn>
                    <v-btn color="error" depressed rounded @click="deleteActiveTracking">
                        <v-icon left>mdi-trash-can-outline</v-icon> Verwijderen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
       
</div>
</template>
<script>
import {User} from '@/eodvuecomponents';
import eodAddTimetracking from './eod-add-timetracking';

export default {
    name:'eod-trackings',
    components: {
        eodAddTimetracking
    },
    props: {
        value: Array,
        readonly: {
            type: Boolean,
            default: false
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        collapsable:{
            default: false,
            type: Boolean
        },
        open:{
            default: false,
            type: Boolean
        },
        openTracking:{
            default: null,
            type: Number
        },
    },
    data:() => {
        return {
            users: {},
            deleteDialog: false,
            activeIndex: null,
            openPanels: null,
            openTrackings: null,
        }
    },
    created(){
        if(!this.collapsable){
            this.openPanels = 0;
        }

        if(this.open){
            this.openPanels = 0;
        }

        if(this.openTracking !== null){
            this.openTrackings = this.openTracking;
        }

        if(!this.value){
            this.$emit('input', []);
        }
    },
    mounted(){
        this.loadUsers();
    },
    methods: {
        addTracking(){
            let items = this.value;

            let data = {};
            if(this.min && this.max){
                data.startedAt = this.$moment(this.min*1000).toISOString();
                data.duration = (this.max-this.min)*1000;
            }

            items.push(data);
            this.openTrackings = items.length-1;
            this.$emit('input', items);
        },
        duplicateTracking(index){
            let items = this.value;
            items.push(Object.assign({}, items[index]));
            this.openTrackings = items.length-1;
            this.$emit('input', items);

            this.$emit('duplicate', (items.length-1));
        },
        showDeleteDialog(index){
            this.activeIndex = index;
            this.deleteDialog = true;
        },
        deleteActiveTracking(){
            let items = this.value;
            items.splice(this.activeIndex, 1);
            this.deleteDialog = false;
            this.activeIndex = null;
            this.$emit('input', items);
        },
        editTimetracking(index){
            if(!this.readonly){
                this.$emit('edit', index);
            }
        },
        loadUsers(){
            if(this.value && this.value.length > 0){
                let ids = [];
                for (let i = 0; i < this.value.length; i++) {
                    const tracking = this.value[i];
                    if(tracking.userId && tracking.userId != ''){
                        ids.push(tracking.userId);
                    }
                }
                this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{id name}'], {
                        whereIn:{
                            column:'id',
                            array:ids
                        }
                    })
                        .then(result => {
                            let count = result.data.data.resolveUsers.edges.length;
                            for (let i = 0; i < count; i++) {
                                let user = new User(result.data.data.resolveUsers.edges[i]);
                                this.users[user.id] = user;
                            }
                            this.$forceUpdate();
                    });
            }
            
        },
        getUserName(userId){
            if(this.users[userId]){
                return this.users[userId].getFullName();
            }

            return '';
        }
    }
}
</script>