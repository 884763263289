<template>
    <div class="h-100 w-100" style="overflow-y:auto;">
        <v-chip-group v-if="showTypes" mandatory active-class="primary--text" v-model="graphType">
            <v-chip outlined v-for="visualization in visualizations" :key="visualization.graph" :value="visualization.graph">{{
                visualization.name
            }}</v-chip>
        </v-chip-group>
        <template v-if="loading">
            Bezig met laden...
        </template>
        <template v-else-if="data || ['tile'].includes(graphType)">
            <eod-graph-table :id="id" ref="graph" v-if="graphType == 'table'" :value="data"
                :template="templates" :cell="cell" :from="from" :until="until" @finished="finished"></eod-graph-table>
            <eod-graph-events :id="id" ref="graph" v-else-if="graphType == 'events'" :value="data"
                :template="templates" :filters="filters" @reload="loadData"></eod-graph-events>
            <div v-else :style="sizeGraph ? 'position:relative;width:100%;padding-bottom:50%;' : ''"
                :class="sizeGraph ? '' : 'h-100 w-100'">
                <div v-if="fakePrediction && showPredictionError">
                <v-alert dense type="warning">Let op, uw ingegeven waarde <strong>{{ predictionValue }}</strong> wijkt meer dan 10% af van de voorspelde waarde <strong>{{predictionNowValue.value}}</strong>!</v-alert>
            </div>
                <div :style="sizeGraph ? 'position:absolute;width:100%;height:100%;' : ''"
                    :class="sizeGraph ? '' : 'h-100 w-100'">
                    <eod-graph-pie ref="graph" v-if="graphType == 'pie'" @finished="finished"
                        @selectChanged="selectChanged" :id="id" :cell="cell" :value="data" :options="options"
                        :template="templates" @mouseup="mouseup"></eod-graph-pie>
                    <eod-graph-gauge ref="graph" v-if="graphType == 'gauge'" @finished="finished"
                        :id="id" :cell="cell" :value="data" :options="options"
                        :template="templates"></eod-graph-gauge>
                    <eod-graph-heatmap ref="graph" v-if="graphType == 'heatmap'" @finished="finished"
                        :id="id" :cell="cell" :value="data" :options="options"
                        :template="templates"></eod-graph-heatmap>
                    <eod-graph-line ref="graph" v-if="graphType == 'line'" @finished="finished"
                        @selectChanged="selectChanged" :id="id" :cell="cell" :value="data" :options="options" :from="from" :until="until"
                        :template="templates" @mouseup="mouseup" :filters="filters"></eod-graph-line>
                    <eod-graph-vbar ref="graph" v-if="graphType == 'vbar'" @finished="finished"
                        @selectChanged="selectChanged" :id="id" :cell="cell" :value="data" :options="options"
                        :template="templates" @mouseup="mouseup"></eod-graph-vbar>
                    <eod-graph-profile ref="graph" v-if="graphType == 'profile'" @finished="finished"
                        @selectChanged="selectChanged" :id="id" :cell="cell" :value="data" :options="options"
                        :template="templates" @mouseup="mouseup"></eod-graph-profile>
                    <eod-graph-hbar ref="graph" v-if="graphType == 'hbar'" :id="id" :value="data"
                        :template="templates"></eod-graph-hbar>
                    <eod-graph-tile :variables="variables" ref="graph" v-if="graphType == 'tile'" @finished="finished"
                        @reload="loadData" :id="id" :value="data" :cell="cell" :template="templates"></eod-graph-tile>
                    <eod-graph-radar ref="graph" v-if="graphType == 'radar'" :id="id" :value="data"
                        :template="templates"></eod-graph-radar>
                    <eod-graph-liquid ref="graph" v-if="graphType == 'liquid'" :cell="cell" :id="id" :value="data"
                        :template="templates" @finished="finished"></eod-graph-liquid>
                    <eod-graph-sankey ref="graph" v-if="graphType == 'sankey'" :cell="cell" :id="id" :value="data" :from="from" :until="until"
                        :template="templates" @finished="finished" :variables="variables" :filters="filters"></eod-graph-sankey>
                </div>

                <v-menu v-model="showContextMenu" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y>
                    <v-list dense>
                        <v-list-item @click="addEvent">
                            <v-list-item-icon><v-icon>mdi-bell-plus-outline</v-icon></v-list-item-icon>
                            <v-list-item-title>Event aanmaken</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="$eod.getUser().can('alarms.create')" @click="setAlarm">
                            <v-list-item-icon><v-icon>mdi-bell-alert-outline</v-icon></v-list-item-icon>
                            <v-list-item-title>Alarm aanmaken</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
        <template v-else>
            Geen resultaten gevonden.
        </template>
    </div>
</template>
<script>
import eodGraphTable from './eod-graph-table.vue';
import eodGraphLine from './eod-graph-line.vue';
import eodGraphVBar from './eod-graph-vbar.vue';
import eodGraphHBar from './eod-graph-hbar.vue';
import eodGraphTile from './eod-graph-tile.vue';
import eodGraphRadar from './eod-graph-radar.vue';
import eodGraphProfile from './eod-graph-profile.vue';
import eodGraphLiquid from './eod-graph-liquid.vue';
import eodGraphEvents from './eod-graph-events.vue';
import eodGraphPie from './eod-graph-pie.vue';
import eodGraphSankey from './eod-graph-sankey.vue';
import eodGraphGauge from './eod-graph-gauge.vue';
import eodGraphHeatmap from './eod-graph-heatmap.vue';
import _ from 'lodash';

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
    components: {
        eodGraphTable,
        eodGraphLine,
        'eod-graph-vbar': eodGraphVBar,
        'eod-graph-hbar': eodGraphHBar,
        eodGraphTile,
        eodGraphRadar,
        eodGraphProfile,
        eodGraphLiquid,
        eodGraphEvents,
        eodGraphPie,
        eodGraphSankey,
        eodGraphGauge,
        eodGraphHeatmap
    },
    props: {
        showTypes: {
            type: Boolean,
            default: false
        },
        fakePrediction: {
            type: Boolean,
            default: false
        },
        predictionValue: Number,
        queryMeasurementTypes:Object,
        activeEvents:Array,
        filters: {
            type: Array,
            default: () => {
                return []
            }
        },
        groupBy: {
            type: Array,
            default: () => {
                return null
            }
        },
        sizeGraph: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'table'
        },
        template: Object | Array,
        rawQuery: String,
        cell: Object,
        from: Object,
        until: Object,
        owner: String,
        variables: Object,
        index: Number,
        fields: Array,
        id: {
            type: String,
            default: uuidv4()
        }
    },
    data: function () {
        return {
            showContextMenu: false,
            contextMenuX: 0,
            contextMenuY: 0,
            contextMenuParams: null,
            serieIndex: 0,
            templates: [],
            cancelToken: null,
            axisFull: [],
            data: null,
            graphType: null,
            xAxis: null,
            showPredictionError: false,
            predictionNowValue: false,
            loading: false,
            initialized: false,
            gettingData: false,
            options: {},
            defaultSerie: {},
            queriesToLoad: [],
            all_visualizations: require('../../classes/dashboard').visualizations,
            time_formatting: {
                '1ms': 'DD-MM HH:mm:ss:SSS',
                '1m': 'DD-MM HH:mm',
                '5m': 'DD-MM HH:mm',
                '1h': 'DD-MM HH:[00]',
                '8h': 'DD-MM HH:[00]',
                '1d': 'DD-MM',
                '1mo': 'MM-YYYY',
            },
            time_fields: ['start', 'stop', 'time']
        }
    },
    mounted() {
        this.graphType = this.type;
        this.initOptions();
    },
    computed: {
        visualizations() {
            let arr = [];
            for (let i = 0; i < this.all_visualizations.length; i++) {
                const visualisation = this.all_visualizations[i];
                if (visualisation.isVisible(this.template[0])) {
                    arr.push(visualisation);
                }
            }

            return arr;
        }
    },
    watch: {
        graphType(val) {
            if (this.$refs.graph) {
                this.$refs.graph.update();
            }

            this.update();
        },
        type(val) {
            this.graphType = val;
        }
    },
    methods: {
        mouseup(params) {
            if (params.params.componentType == 'series') {
                this.contextMenuParams = params;
                this.contextMenuX = params.event.clientX;
                this.contextMenuY = params.event.clientY;

                setTimeout(() => {
                    this.showContextMenu = true;
                }, 100);

            } else if (params.params.componentType == 'markPoint') {
                this.$emit('editEvent', params);
            }
        },
        addEvent() {
            this.$emit('addEvent', this.contextMenuParams);
        },
        setAlarm() {
            let serie = null;
            if(this.cell.typeId == 'graph_profile'){
                serie = this.contextMenuParams.params.value[6];
            }else if (this.cell.typeId == 'graph_line'){
                serie = this.contextMenuParams.params.value[4];
            }

            if(serie){
                this.$emit('setAlarm', serie);
            }
            
        },
        initOptions() {
            return new Promise(async (resolve) => {
                if (this.cell && this.cell.settings.options) {
                    const cellOptions = this.cell.settings.options;
                    if(this.options && this.options.color){
                        cellOptions.color = this.options.color;
                    }
                    this.options = cellOptions;
                }

                let interval = this.getTimeInterval();
                let from = this.$moment(interval.from);
                let until = this.$moment(interval.until);

                // Sometimes returned data is out of interval range, set range to interval
                if(this.data && this.data[0] && this.data[0].data && this.data[0].data[this.data[0].data.length-1] && this.data[0].data[this.data[0].data.length-1].time_interval){
                    const firstData = this.data[0].data[this.data[0].data.length-1];
                    const fromData = this.$moment(firstData.time_interval);
                    if(fromData < from){
                        from = fromData;
                    }
                }

                let grouping = await this.$eod.getQueryGrouping(from, until);
                let time_formatting = grouping.grouping?this.time_formatting[grouping.grouping]:'YYYY-MM-DD HH:mm';

                this.options.xAxis = {
                    show: true,
                    type: 'time',
                    id: 'timeline',
                    min: from.format('YYYY/MM/DD[T]HH:mm:ss'),
                    max: until.format('YYYY/MM/DD[T]HH:mm:ss'),
                };

                if(this.fakePrediction){
                    const diff = until.diff(from, 'minutes');
                    this.options.xAxis.max = until.add(diff, 'minutes').format('YYYY/MM/DD[T]HH:mm:ss');
                }

                const fields = this.fields ? this.fields : (this.cell && this.cell.settings.fields?this.cell.settings.fields:null);

                let self = this;
                this.options.tooltip = {
                    show: true,
                    trigger: 'axis',
                    confine:true,
                    formatter: function (params, ticket, callback) {
                        let html = '<table style="min-width:100px;border-collapse:collapse;border-spacing:0;">';

                        if (params) {
                            html += '<tr><td style="border-collapse:collapse;border-spacing:0;padding:0;margin:0;" colspan="3">' + self.$moment(params[0].axisValue).format(time_formatting) + '</td></tr>';

                            // Sort params by value
                            params.sort((a, b) => (parseFloat(a.data.value[1]) < parseFloat(b.data.value[1])) ? 1 : -1);

                            // Check if multiple owners
                            let hasMultipleOwners = false;
                            let prevOwner = null;

                            if(!(fields && !fields.includes('owner'))){
                                for (let i = 0; i < params.length; i++) {
                                    const param = params[i];
                                    let data = param.value[2] ? param.value[2] : null;
                                    if (data && data.owner_id_resolved) {
                                        if (prevOwner && prevOwner != data.owner_id_resolved.id) {
                                            hasMultipleOwners = true;
                                            break;
                                        }
                                        prevOwner = data.owner_id_resolved.id;
                                    }
                                }
                            }

                            for (let i = 0; i < params.length; i++) {
                                const param = params[i];

                                let data = param.value[2] ? param.value[2] : null;
                                const serie = param.value[4] ? param.value[4] : null;

                                html += '<tr><td style="border-collapse:collapse;border-spacing:0;padding:0 5px;">' + param.marker + '</td><td style="border-collapse:collapse;border-spacing:0;padding:0 5px;">';
                                html += param.seriesName;
                                html += '</td><td style="border-collapse:collapse;border-spacing:0;padding:0 5px;font-weight:bold;">' + (param.value ? Math.round((parseFloat(param.value[1]) + Number.EPSILON) * 100) / 100 : '/');

                                if(serie && serie.measurementTypes){
                                    const measurementType = serie.measurementTypes[Object.keys(serie.measurementTypes)[0]];
                                    if (measurementType.unitFields && measurementType.unitFields.Symbol) {
                                        html += ' ' + measurementType.unitFields.Symbol;
                                    }
                                }

                                html += '</td></tr>';
                                if(!(fields && !fields.includes('owner'))){
                                    if (data && data.owner_id_resolved && hasMultipleOwners && (data.owner_id_resolved.firstName || data.owner_id_resolved.lastName)) {
                                        html += '<tr><td colspan="3" style="border-collapse:collapse;border-spacing:0;padding:0 5px;font-size:10px;">' + data.owner_id_resolved.firstName + ' ' + data.owner_id_resolved.lastName + '</td></tr>';
                                    }
                                }
                            }
                        }

                        html += '</table>';

                        //callback(ticket, toHTML(content));
                        return html;
                    }
                };

                resolve();
            })
            
        },
        getSerieLabel(data, measurementTypes){
            const measurementType = Object.values(measurementTypes)[0];
            const fields = this.fields ? this.fields : (this.cell && this.cell.settings.fields?this.cell.settings.fields:null);

            let label = '';
            if(!fields || (fields && fields.includes('measurementType'))){
                label += (measurementType.name ? measurementType.name : param.seriesName);
            }
            if (data && data.product_id_resolved && (!fields || (fields && fields.includes('product')))) {
                label += (label != ''?' | ':'') + data.product_id_resolved.name;
            }
            if (data && data.owner_id_resolved && (fields && fields.includes('owner'))) {
                label += (label != ''?' | ':'') + data.owner_id_resolved.username;
            }

            return label;
        },
        getSerieSettingsColor(index) {
            let color1 = this.$eod.getOrganizationSetting('organisation.color.primary') ? this.$eod.getOrganizationSetting('organisation.color.primary').value : '#7D3AC1';
            let color2 = this.$eod.getOrganizationSetting('organisation.color.secondary') ? this.$eod.getOrganizationSetting('organisation.color.secondary').value : '#EA7369';

            let colors = [color1, color2];

            if (!this.cell || !this.cell.settings.queries[index]) {
                return '#7D3AC1';
            }

            if (this.cell.settings.queries[index].settings && this.cell.settings.queries[index].settings.color && this.cell.settings.queries[index].settings.color != '') {
                return this.cell.settings.queries[index].settings.color;
            } else if (colors[index]) {
                return colors[index];
            } else {
                return '#7D3AC1';
            }
        },
        selectChanged(graph, params, data) {
            this.$emit('selectChanged', graph, params, data);
        },
        finished(id, graph, options) {
            if (!this.initialized) {
                this.initialized = true;
                this.$emit('loaded', this.data, this.xAxis, graph, id, options);
            }
        },
        resize() {
            if (this.$refs.graph && this.$refs.graph.resize) {
                this.$refs.graph.resize();
            }
        },
        loadEvents() {
            if (this.$refs.graph && this.$refs.graph.loadEvents) {
                this.$refs.graph.loadEvents();
            }
        },
        update(templateIndex) {
            if(typeof templateIndex != 'undefined'){
                var index = this.queriesToLoad.indexOf(templateIndex);
                if (index !== -1) {
                    this.queriesToLoad.splice(index, 1);
                }
            }

            if(!this.cell){
                this.loading = false;
            }else if(this.cell.settings && this.cell.settings.queries) {
                if(this.queriesToLoad.length == 0 && this.loading){
                    this.loading = false;
                }
            }
            this.updateAction();
        },
        async updateAction(){
            await this.initOptions();
            this.$nextTick(() => {
                if (this.$refs.graph) {
                    this.$refs.graph.update();
                }
            });
        },
        getGraphIndexByType(type) {
            for (let i = 0; i < this.visualizations.length; i++) {
                const vis = this.visualizations[i];
                if (vis && vis.graph == type) {
                    return i;
                }
            }

            return 0;
        },
        getTimeInterval() {
            return { from: this.from, until: this.until };
        },
        getCellQueryById(templateId) {
            if (!this.cell || !this.cell.settings) {
                return null;
            }

            for (let i = 0; i < this.cell.settings.queries.length; i++) {
                const query = this.cell.settings.queries[i];
                if (query.id == templateId) {
                    return query;
                }
            }
        },
        loadActiveEvent(serie) {
            // search through active events
            const filterMapping = {
                'product_id': 'product',
                'project_id': 'project',
            };
            if(this.activeEvents){
                for (let i = 0; i < this.activeEvents.length; i++) {
                    const activeEvent = this.activeEvents[i];

                    if((activeEvent.measurementType && !serie.measurement_type_id) || (!activeEvent.measurementType && serie.measurement_type_id)){
                        continue;
                    }

                    if(activeEvent.measurementType && serie.measurement_type_id && activeEvent.measurementType.id != serie.measurement_type_id){
                        continue;
                    }

                    if((activeEvent.product && !serie.product_id) || (!activeEvent.product && serie.product_id)){
                        continue;
                    }

                    if(activeEvent.product && serie.product_id && activeEvent.product.id != serie.product_id){
                        continue;
                    }

                    if((activeEvent.owner && !serie.owner_id) || (!activeEvent.owner && serie.owner_id)){
                        continue;
                    }

                    if(activeEvent.owner && serie.owner_id && activeEvent.owner.id != serie.owner_id){
                        continue;
                    }

                    if(this.filters){
                        let found = false;
                        for (let i = 0; i < this.filters.length; i++) {
                            const filter = this.filters[i];
                            if(filter.value && filterMapping[filter.key] && filter.value != activeEvent[filterMapping[filter.key]].id){
                                found = true;
                                continue;
                            }
                        }

                        if(found){
                            continue;
                        }
                    }
                    
                    

                    return activeEvent;
                }
            }

            return null;
        },
        loadData() {
            this.data = [];
            this.initialized = false;
            this.loading = true;
            this.xAxis = [];

            let interval = this.getTimeInterval();
            let from = interval.from;
            let until = interval.until;

            this.queriesToLoad = [];

            this.serieIndex = 0;
            this.options.color = [];

            if (this.template && this.template.length > 0) {

                this.templates = this.template;

                if (!Array.isArray(this.template)) {
                    this.templates = [this.template];
                }

                for (let i = 0; i < this.templates.length; i++) {
                    let template = _.cloneDeep(this.templates[i]);

                    if (template.content) {

                        const options = {
                            limit: null
                        };

                        // Tile only needs last record to show trend
                        if (this.graphType && (this.graphType == 'tile' || this.graphType == 'liquid' || this.graphType == 'gauge')) {
                            options.limit = 1;
                        }

                        options.from = this.$moment.tz(from, 'Europe/Brussels').utc();
                        options.until = this.$moment.tz(until, 'Europe/Brussels').utc();

                        if(this.groupBy){
                            options.group_fields = this.groupBy;
                        }else if (template.content.group.key != 'time' && template.content.type != 'recursive'){
                            options.group_fields = [];
                        }

                        if(this.cell && this.cell.typeId == 'graph_heatmap' && this.cell.settings && this.cell.settings.yAxis.groupBy){
                            let grouping = 'hour';
                            const diff = this.$moment(until).diff(this.$moment(from), 'minutes');
                            if (diff <= 1440) {
                                grouping = this.cell.settings.yAxis.interval.day;
                            } else if (diff <= 11520) {
                                grouping = this.cell.settings.yAxis.interval.week;
                            } else if (diff <= 44640) {
                                grouping = this.cell.settings.yAxis.interval.month;
                            } else if (diff <= 525949) {
                                grouping = this.cell.settings.yAxis.interval.year;
                            }

                            options.extraTimeGroup = grouping;
                        }

                        let filters = JSON.parse(JSON.stringify(this.filters));

                        if(this.cell && this.cell.settings && this.cell.settings.queries && this.cell.settings.queries[i]){
                            const query = this.cell.settings.queries[i];
                            const result = this.$helper.getQueryFixedFilters(this.variables, query, template, filters);
                            template = result.template;
                            filters = result.filters;
                        }

                        this.cancelToken = axios.CancelToken.source();

                        this.queriesToLoad.push(i);

                        this.$emit('queueQuery', i, template, filters, options, this.cancelToken);
                    }
                }
            }else{
                this.loading = false;
            }
        },
        prepareRow(serie, k, fieldname, measurementType){
            const row_data = serie[k];
            if (row_data.time_interval) {
                row_data.time_interval = this.$moment.utc(row_data.time_interval).toISOString();
                row_data.start = row_data.time_interval;
                row_data.stop = serie[k - 1] ? serie[k - 1].time_interval : row_data.time_interval;
            } else if (row_data.time) {
                row_data.time = this.$moment.utc(row_data.time).toISOString();
                row_data.start = row_data.time;
                row_data.stop = serie[k - 1] ? serie[k - 1].time : row_data.time;
            }

            if(measurementType){
                row_data.measurement_type_id = measurementType.id;
                row_data.measurement_resolved = measurementType;
            }
            
            row_data.value = row_data[fieldname];

            return row_data;
        },
        queueResult(templateIndex, template, result) {
            let response = result;

            if (template.content.source == 'measurements' || template.content.source == 'responses') {
                this.resolveMeasurements(template, response).then(namesById => {
                    const measurementTypes = namesById;

                    if(template.content.group.key == 'time'){
                        this.fillData(response)
                        .then(data => {
                            return this.getSeries(data);
                        }).then(async series => {
                            let color = this.getSerieSettingsColor(templateIndex);

                            let promises = [];
                            for (let j = 0; j < series.length; j++) {
                                promises.push(new Promise(async (resolve) => {
                                    const serie = series[j];

                                    let measurementType = null;
                                    let fieldname = null;
                                    if(template.content && template.content.measurementTypes){
                                        for (let i = 0; i < template.content.measurementTypes.length; i++) {
                                            fieldname = template.content.measurementTypes[i].aggregation + '_' + template.content.measurementTypes[i].object.id;
                                            if(typeof serie[0][fieldname] != 'undefined'){
                                                measurementType = template.content.measurementTypes[i].object;
                                                break;
                                            }
                                        }
                                    }

                                    for (let k = 0; k < serie.length; k++) {
                                        serie[k] = this.prepareRow(serie, k, fieldname, measurementType);
                                    }
                                    
                                    const serieData = {
                                        sort: (measurementType?measurementType.name + '_':'') + serie[0].sort,
                                        measurementTypes: measurementTypes,
                                        queryIndex: templateIndex,
                                        data: serie,
                                        events: [],
                                        activeEvent: this.loadActiveEvent(serie[0]),
                                        name: this.getSerieLabel(serie[0], measurementTypes)
                                    };

                                    if(this.fakePrediction){
                                        const predictionData = [];

                                        let predictionClosestCheck = null;

                                        const diff = this.$moment(serie[0].time_interval).diff(this.$moment(serie[serie.length-1].time_interval), 'minutes');
                                        for (let dataIndex = 0; dataIndex < serie.length; dataIndex++) {
                                            const data = _.cloneDeep(serie[dataIndex]);

                                            data.time_interval = this.$moment(data.time_interval).add(diff, 'minutes').toISOString();
                                            data.start = this.$moment(data.start).add(diff, 'minutes').toISOString();
                                            data.stop = this.$moment(data.stop).add(diff, 'minutes').toISOString();

                                            const closeToNow = Math.abs(this.$moment(data.time_interval).diff(this.$moment(), 'minutes'));
                                            if(predictionClosestCheck){
                                                if(predictionClosestCheck > closeToNow){
                                                    this.predictionNowValue = data;
                                                    predictionClosestCheck = closeToNow;
                                                }
                                            }else{
                                                this.predictionNowValue = data;
                                                predictionClosestCheck = closeToNow;
                                            }

                                            predictionData.push(data);
                                        }

                                        if(this.predictionNowValue && this.predictionValue != null){
                                            const min = this.predictionNowValue.value*0.90;
                                            const max = this.predictionNowValue.value*1.10;

                                            if(this.predictionValue < min || this.predictionValue > max){
                                                this.showPredictionError = true;
                                            }
                                        }

                                        serieData.dataPrediction = predictionData;
                                    }

                                    serieData.color = this.getSerieColor(color, serie[0].product_id_resolved, Object.values(measurementTypes)[0], j);

                                    this.data[this.serieIndex] = serieData;

                                    this.serieIndex++;

                                    resolve();
                                }));
                            }

                            return Promise.all(promises);
                        }).finally(() => {
                            this.data.sort(this.compare);
                            for (let serieIndex = 0; serieIndex < this.data.length; serieIndex++) {
                                const serieData = this.data[serieIndex];
                                if(serieData){
                                    this.options.color[serieIndex] = serieData.color;
                                }
                            }

                            this.update(templateIndex);
                        });
                    }else{
                        this.data = [{
                            measurementTypes: measurementTypes,
                            data: response
                        }];
                        this.update(templateIndex);
                    }
                });
            } else if(template.content.source == 'events'){

                if(template.content.group.key == 'time'){
                    const fieldname = template.content.measurementTypes[0].object.id;
                    const data = [];
                    for (let l = 0; l < response.length; l++) {
                        const row = response[l];
                        if((!row.product_id && !row.owner_id) || row[template.content.measurementTypes[0].object.id] == null){
                            continue;
                        }
                        data.push(row);
                    }

                    const series = this.getSeries(data);

                    for (let j = 0; j < series.length; j++) {
                        const serie = series[j];
                        for (let k = 0; k < serie.length; k++) {
                            serie[k] = this.prepareRow(serie, k, fieldname);

                            this.data[j] = {
                                sort: serie[0].sort,
                                measurementTypes: [template.content.measurementTypes[0].object],
                                queryIndex: i,
                                data: serie,
                                events: [],
                                activeEvent: null,
                                name: this.getSerieLabel(serie[0], [template.content.measurementTypes[0].object])
                            };
                        }
                    }

                }
                this.update(templateIndex);
            } else {
                this.resolveMeasurements(template, response).then(namesById => {
                    const measurementTypes = namesById;

                    this.data = [
                        {
                            measurementTypes: measurementTypes,
                            data: response
                        }
                    ]
                    this.update(templateIndex);
                });
                
            }
        },
        getSerieColor(color, product, measurementType, index){

            if(product && product.color){
                return product.color;
            }

            if(measurementType && measurementType.color){
                return measurementType.color;
            }

            return this.$helper.increaseBrightness(color, index * 10);
        },
        compare(a, b) {
            if (a.sort < b.sort) {
                return -1;
            }
            if (a.sort > b.sort) {
                return 1;
            }
            return 0;
        },
        getSeries(data) {
            let series = {};
            for (let i = 0; i < data.length; i++) {
                const response = data[i];
                let unique = '';
                let sort = '';

                if (response.owner_id_resolved) {
                    unique += response.owner_id_resolved.id;
                    sort += response.owner_id_resolved.username;
                }

                if (response.product_id_resolved) {
                    unique += '_' + response.product_id_resolved.id;
                    sort += '_' + response.product_id_resolved.name;
                }

                if (!series[unique]) {
                    series[unique] = [];
                }

                response.sort = sort;

                series[unique].push(response);
            }

            return Object.values(series);
        },
        fillData(response) {
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        },
        resolveMeasurements(template, response) {
            let ids = [];
            const measurementTypesById = {};
            for (let i = 0; i < template.content.measurementTypes.length; i++) {
                const measurementType = template.content.measurementTypes[i];
                if(measurementType.object && measurementType.object.id && this.queryMeasurementTypes && this.queryMeasurementTypes[measurementType.object.id]){
                    measurementTypesById[measurementType.object.id] = this.queryMeasurementTypes[measurementType.object.id];
                }else if (!ids.includes(measurementType.object.id)) {
                    ids.push(measurementType.object.id);
                }
            }

            return new Promise(resolve => {
                if (ids.length > 0) {
                    this.$eod.get('measurementTypes', ['id', 'name', 'typeConfig', 'color', 'icon', 'description', 'isWritable', 'unit{id name fields{name description} list{fields{name}}}', 'valueConfigList{id name description itemType fields{name description isActive}, listItems{id name description low high threshold stepSize color fields{name description isActive}}}', 'formConfig{category type enabled required default defaultValue values}'], {
                        whereIn: {
                            column: 'id',
                            array: ids
                        }
                    }).then(response => {
                        if (response.data.data.measurementTypes) {
                            let measurementTypes = response.data.data.measurementTypes.edges;

                            for (let i = 0; i < measurementTypes.length; i++) {
                                const measurementType = measurementTypes[i];
                                measurementTypesById[measurementType.id] = {
                                    ...measurementType,
                                    ... {
                                        valueItems: this.$eod.getMeasurementTypeItems(measurementType),
                                        unitFields: this.$eod.getMeasurementTypeUnitFields(measurementType)
                                    }
                                };
                            }

                            resolve(measurementTypesById);
                        }else{
                            resolve(measurementTypesById);
                        }
                    });
                }else{
                    resolve(measurementTypesById);
                }
            });
        }
    }
}
</script>