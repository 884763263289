<template>
    <v-row>
        <v-col cols="12" md="" v-for="severityInfo in AlarmRule.listItemSeverities" :key="severityInfo.value">
            <v-card :color="severityInfo.color" outlined>
                <v-card-title class="subtitle-1 py-2">{{ severityInfo.text }}</v-card-title>
                <v-card-text>
                    <v-autocomplete class="white" hide-details outlined dense
                        @change="val => saveSeverity(severityInfo.value, val)" :search-input.sync="searchInput"
                        :items="getFilteredItems(severityInfo.value)" item-value="threshold" item-text="name"
                        return-object label="Selecteer een waarde..." :value="searchInput">
                    </v-autocomplete>
                </v-card-text>
                <v-list v-if="value.content.severities" class="transparent" dense>
                    <v-list-item v-for="(listItem, index) in value.content.severities[severityInfo.value]" :key="index">
                        <v-list-item-content>
                            <v-list-item-title>{{ listItem.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn @click="removeItem(severityInfo.value, index)" small
                                icon><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { AlarmRule } from '../..';

export default {
    name: 'eod-alarm-detail-thresholds',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        valueItems: {
            type: Array,
            default: () => ([])
        },
    },
    data() {
        return {
            searchInput: null,
            AlarmRule: AlarmRule
        }
    },
    methods: {
        itemExists(item) {
            if (this.value.content.severities) {
                for (const severityName in this.value.content.severities) {
                    const severity_items = this.value.content.severities[severityName];
                    if(severity_items){
                        for (let i = 0; i < severity_items.length; i++) {
                            const severity_item = severity_items[i];
                            if (item.threshold == severity_item.threshold) {
                                return true;
                            }
                        }
                    }
                }
            }

            return false;
        },
        getFilteredItems() {
            const items = [];



            for (let i = 0; i < this.valueItems.length; i++) {
                const valueItem = this.valueItems[i];
                if (!this.itemExists(valueItem)) {
                    items.push(valueItem);
                }
            }

            return items;
        },
        removeItem(severityName, index) {
            const val = this.value;
            val.content.severities[severityName].splice(index, 1);
            this.$emit('input', val);

            this.$nextTick(this.$forceUpdate);
        },
        saveSeverity(severityName, value) {
            if (!value) {
                return false;
            }

            const val = this.value;
            if (val) {

                if (!val.content.severities) {
                    val.content.severities = {};
                }

                if (!val.content.severities[severityName]) {
                    val.content.severities[severityName] = [];
                }

                val.content.severities[severityName].push({
                    id: value.id,
                    name: value.name,
                    threshold: value.threshold
                });

                this.$emit('input', val);

                this.searchInput = null;

                this.$nextTick(this.$forceUpdate);
            }
        },
    }
}
</script>