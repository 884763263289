<template>
    <v-card id="aside-routes" class="d-flex flex-column">
        <v-card-title @click="$emit('input', !value);" class="aside-toggle body-2 py-2"
            :class="{ 'aside-toggle-active': value }">
            <div>
                <v-icon left>mdi-map-marker-path</v-icon>Routes
            </div>
            <v-divider vertical class="ml-2"></v-divider>
            <div class="d-flex align-items-center px-2">
                <div># Jobs</div>
                <div class="ml-2 kpi-value">{{ totalJobs }}</div>
            </div>
            <v-divider vertical class="ml-2"></v-divider>
            <div class="d-flex align-items-center px-2">
                <div># Km's</div>
                <div class="ml-2 kpi-value">{{ totalDistance }}</div>
            </div>
            <v-divider vertical class="ml-2"></v-divider>
            <div class="d-flex align-items-center px-2">
                <div>Totale kostprijs</div>
                <div class="ml-2 kpi-value">&euro; {{ totalCost }}</div>
            </div>
            <v-divider vertical class="ml-2"></v-divider>
            <div class="d-flex align-items-center px-2">
                <div># Werknemers</div>
                <div class="ml-2 kpi-value">{{ totalEmployees }}</div>
            </div>
            <v-divider vertical class="ml-2"></v-divider>
            <div class="d-flex align-items-center px-2">
                <div># Voertuigen</div>
                <div class="ml-2 kpi-value">{{ totalVehicles }}</div>
            </div>
            <v-divider vertical class="ml-2"></v-divider>
            <div class="d-flex align-items-center px-2">
                <div>Transportkost</div>
                <div class="ml-2 kpi-value">&euro; {{ totalTransportCost }}</div>
            </div>
            <v-divider vertical class="ml-2"></v-divider>
            <div class="d-flex align-items-center px-2">
                <div>Werkkost</div>
                <div class="ml-2 kpi-value">&euro; {{ totalWorkCost }}</div>
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="$eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true" @click.stop="$emit('optimize')" class="mr-4" depressed color="primary" rounded small><v-icon
                    left>mdi-cog-clockwise</v-icon>Optimaliseer</v-btn>
            <v-btn @click.stop="addWorkflow" class="mr-4" depressed color="secondary" rounded small><v-icon
                    left>mdi-plus-circle-outline</v-icon>Workflow</v-btn>
            <v-icon>mdi-chevron-up</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear indeterminate v-if="loading" color="primary"></v-progress-linear>
        <div class="flex-grow-1 d-flex overflow-hidden">
            <div id="sidebar-scheduler" style="overflow-y:scroll;" class="flex-grow-1">
                <eod-routes-scheduler :key="updateScheduler" v-if="value" ref="scheduler" :from="from" :until="until"
                    :value="routes" @updateRoutes="val => $emit('updateRoutes', val)" @deleted="val => $emit('optimize')"
                    @dropped="refresh" @refresh="$emit('refresh')" @cancelItem="val => $emit('cancelItem', val)"
                    @acceptItem="val => $emit('acceptItem', val)"></eod-routes-scheduler>
            </div>
            <div id="sidebar-jobs" style="overflow-y:scroll;" class="flex-grow-1">
                <eod-scheduler-sidebar v-if="value" ref="sidebar" :value="showSidebar" @input="toggleSidebar"
                    @drag="dragSidebarItem" @dragEnd="dragSidebarItemEnd" @cancelItem="val => $emit('cancelItem', val)"
                    @acceptItem="val => $emit('acceptItem', val)"></eod-scheduler-sidebar>
            </div>
        </div>
        <eod-workflow-dialog v-if="addTaskDialog && $eod.getUser().can('tasks.workflows.create')" v-model="addTaskDialog"
            :workflow="activeItem" @saved="taskSaved"></eod-workflow-dialog>
    </v-card>
</template>
<style lang="scss" scoped>
#aside-routes {
    /*
        position:fixed;
        left:0;
        bottom: 0;
        */
    width: 100%;
    height: 100%;

    z-index: 4;
    transition: .4s all;
    overflow: hidden;
    padding-left: 56px;

    .kpi-value {
        background: var(--v-primary-base);
        display: block;
        color: #ffffff;
        padding: 5px;
        line-height: 1;
        border-radius: 10px;
    }

    .aside-toggle {
        &:hover {
            background: #eeeeee;
            cursor: pointer;
        }

        i {
            transition: .4s all;
        }

        &.aside-toggle-active {
            >i {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
<script>
import eodRoutesScheduler from './eod-routes-scheduler.vue';
import eodSchedulerSidebar from './eod-scheduler-sidebar.vue';
import eodWorkflowDialog from './eod-workflow-dialog.vue';
import Split from 'split.js'

export default {
    name: 'eod-routes-sidebar',
    components: {
        eodRoutesScheduler,
        eodSchedulerSidebar,
        eodWorkflowDialog
    },
    props: {
        value: Boolean,
        routes: {
            type: Array,
            default: () => ([])
        },
        from: Object,
        until: Object
    },
    data: () => {
        return {
            items: [],
            loading: false,
            visible: false,
            mapSplit: null,
            mapSplitMin: 0,
            showSidebar: false,
            updateScheduler: 3090343,
            activeItem: {},
            addTaskDialog: false,
        }
    },
    computed: {
        totalWorkCost() {

            let total = 0;
            for (let i = 0; i < this.routes.length; i++) {
                const route = this.routes[i];
                if (route.visible) {
                    total += route.workCost;
                }

            }

            return Math.round(total * 100) / 100;
        },
        totalTransportCost() {
            let total = 0;
            for (let i = 0; i < this.routes.length; i++) {
                const route = this.routes[i];
                if (route.visible) {
                    total += route.transportCost;
                }

            }

            return Math.round(total * 100) / 100;
        },
        totalJobs() {
            let total = 0;

            for (let i = 0; i < this.routes.length; i++) {
                const route = this.routes[i];
                if (route.visible) {
                    for (let i = 0; i < route.jobs.length; i++) {
                        const job = route.jobs[i];
                        if (job.route) {
                            total++;
                        }
                    }
                }

            }

            return total;
        },
        totalDistance() {
            let total = 0;

            for (let i = 0; i < this.routes.length; i++) {
                const route = this.routes[i];
                if (route.visible) {
                    total += route.distance;
                }
            }

            return Math.round(total * 100) / 100;
        },
        totalCost() {
            let total = 0;

            for (let i = 0; i < this.routes.length; i++) {
                const route = this.routes[i];
                if (route.visible) {
                    total += route.totalCost;
                }
            }

            return Math.round(total * 100) / 100;
        },
        totalEmployees() {
            let total = 0;

            for (let i = 0; i < this.routes.length; i++) {
                const route = this.routes[i];
                if (route.visible) {
                    total += route.responsibleUsers.length;
                }

            }

            return total;
        },
        totalVehicles() {
            let vehicles = [];

            for (let i = 0; i < this.routes.length; i++) {
                const route = this.routes[i];
                if (route.visible) {
                    if (route.vehicle && !vehicles.includes(route.vehicle.id)) {
                        vehicles.push(route.vehicle.id);
                    }
                }

            }

            return vehicles.length;
        },
    },
    mounted() {
        const self = this;

        this.mapSplit = Split(['#sidebar-scheduler', '#sidebar-jobs'], {
            direction: 'horizontal',
            minSize: [500, 40],
            maxSize: [Infinity, 800],
            expandToMin: true,
            cursor: 'col-resize',
            onDragEnd: function (sizes) {

                if (sizes[1] <= (self.mapSplitMin[1] * 1)) {
                    self.toggleSidebar(false);
                } else {
                    self.showSidebar = true;
                }

                localStorage.setItem('eod-sidebar-scheduler-split', JSON.stringify(sizes));
                self.resize();
            },
        });

        this.mapSplit.collapse(1);
        this.mapSplitMin = this.mapSplit.getSizes();
    },
    methods: {
        addWorkflow() {
            this.activeItem = {};
            const defaultTaskTypeSetting = this.$eod.getOrganizationSetting('tasks.taskType.default.portal');
            if (defaultTaskTypeSetting) {
                this.activeItem.taskTypeId = defaultTaskTypeSetting.value;
            }

            this.addTaskDialog = true;
        },
        taskSaved() {
            this.addTaskDialog = false;
            this.$emit('refresh');
        },
        toggleSidebar(value) {
            if (value) {
                var sizes = localStorage.getItem('eod-sidebar-scheduler-split');
                if (sizes) {
                    sizes = JSON.parse(sizes);
                } else {
                    sizes = [80, 20] // default sizes
                }

                if (sizes[1] <= (this.mapSplitMin[1] * 2)) {
                    sizes = [80, 20]
                }

                this.mapSplit.setSizes(sizes);

                localStorage.setItem('eod-sidebar-scheduler-split', JSON.stringify(sizes));
            } else {
                this.mapSplit.collapse(1);
            }

            this.showSidebar = value;
        },
        dragSidebarItem(e, item) {
            this.$refs.scheduler.draggingNewItem(e, item);
        },
        dragSidebarItemEnd(e, item) {
            this.$refs.scheduler.draggingNewItemEnd(e, item);
        },
        resize() {
            if (this.$refs.scheduler) {
                this.$refs.scheduler.resizeWindow();
            }
        },
        refresh() {
            if (this.$refs.scheduler) {
                this.$refs.scheduler.refresh().then(() => {
                    this.$refs.scheduler.scrollToNow();
                });
            }
            if (this.$refs.sidebar) {
                this.$refs.sidebar.getDataFromApi();
            }
        },
    }
}
</script>