const Project = require('./../eodvuecomponents/src/models/project');

module.exports = {
    roles: {
        exception: ['uma_authorization', 'offline_access'],
        eod: ['root'],
        contacts: ['contributor', 'responsible'],
        collaborators: ['admin', 'lead', 'member'],
        anonymous: ['informant']
    },
    permissions:{
        'edit-dashboard': ['eod', 'collaborators'],
        'edit-root-settings': ['eod']
    },
    listItemTypes: [
        {id:'RANGE', type:'range', fields:['low', 'high', 'stepSize']},
        {id:'RANGE_TO_TEXT', type:'range', fields:['name', 'low', 'high', 'stepSize']},
        {id:'VALUE_TO_TEXT', type:'value', fields:['name', 'threshold']},
        {id:'USER', type:'user', fields:['user']},
        {id:'COMPANY', type:'object', endpoint:'companies', fields:['object']},
        {id:'PROJECT', type:'object', endpoint:'projects', fields:['object']},
        {id:'SERVICE', type:'object', endpoint:'services', fields:['object']}
    ],
    objectTypes:{
        project: 'projects',
        task: 'tasks',
        service: 'services',
        response: 'responses',
        company: 'companies',
        tasktype: 'taskTypes',
        product: 'products',
        producttype: 'productTypes',
        storageobject: 'storageObjects'
    },
    modules: {
        companies: {
            key: 'companyId',
            item: 'company',
            icon: 'mdi-office-building-outline',
        },
        projects: {
            key: 'projectId',
            item: 'project',
            icon: 'mdi-folder-open-outline',
            parents: {
                companies: 'company'
            },
            model: Project,
        },
        tasks: {
            key: 'taskId',
            item: 'task',
            icon: 'mdi-clipboard-outline',
            parents: {
                projects: 'project',
                taskTypes: 'taskType'
            }
        },
        taskTypes: {
            key: 'taskTypeId',
            item: 'taskType',
            icon: 'mdi-clipboard-outline',
        },
        measurementTypes: {
            key: 'measurementTypeId',
            item: 'measurementType',
            icon: 'mdi-waveform'
        },
        products: {
            key: 'productId',
            item: 'product',
            icon: 'mdi-package-variant',
            parents: {
                productTypes: 'productType'
            }
        },
        productTypes: {
            key: 'productTypeId',
            item: 'productType',
            icon: 'mdi-package-variant'
        }
    },
    getListItemTypeById(id){
        for (let i = 0; i < this.listItemTypes.length; i++) {
            const listItemType = this.listItemTypes[i];
            if (listItemType.id == id) {
                return listItemType;
            }
        }

        return null;
    },
    statusCodes: [{
        icon: 'mdi-check',
        color: 'success',
        value: 'done',
        text: 'Klaar'
    }, {
        icon: 'mdi-calendar-remove',
        color: 'brown lighten-1',
        value: 'backlog',
        text: 'Niet gepland'
    }, {
        icon: 'mdi-calendar',
        color: 'yellow darken-4',
        value: 'to_do',
        text: 'Gepland'
    }, {
        icon: 'mdi-run',
        color: 'info',
        value: 'in_progress',
        text: 'In uitvoering'
    }, {
        icon: 'mdi-eye',
        color: 'lime',
        value: 'to_be_verified',
        text: 'Ter goedkeuring'
    }, {
        icon: 'mdi-close',
        color: 'error',
        value: 'cancelled',
        text: 'Geannuleerd'
    }, {
        icon: 'mdi-close-octagon-outline',
        color: 'teal',
        value: 'no_action',
        text: 'Geen actie nodig'
    }, {
        icon: 'mdi-cancel',
        color: 'grey darken-3',
        value: 'on_hold',
        text: 'Geblokkeerd'
    }],
    getStatusCode(code_id){
        const codes = this.statusCodes;
        for (let i = 0; i < codes.length; i++) {
            if (codes[i].value == code_id) {
                return codes[i];
            }
        }
        return null;
    }
}