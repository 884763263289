<template>
    <div class="h-100 w-100">
        <v-chart v-show="value.length > 0" @finished="finished" @datazoom="selectChanged" :group="groupName" :ref="id"
            :initOptions="graphOptions" :autoresize="true" :manualUpdate="true" @contextmenu="contextmenu"
            @zr:contextmenu="contextmenu" @mousemove="mousemove"
            @mouseup="params => $emit('mouseup', { params: params, data: params.value[2], event: params.event.event ? params.event.event : params.event })" />
        <div v-if="!value">Gelieve de grafiek in te stellen</div>
        <div v-else-if="value.length == 0">Geen waardes gevonden</div>
    </div>
</template>
<style lang="scss">
.echarts {
    width: 100%;
    height: 100%;
}
</style>
<script>
import ECharts from './echarts/ECharts';
import GraphMixin from './../../mixins/graph.js';
import _ from 'lodash';

export default {
    name: 'eod-graph-line',
    components: {
        'v-chart': ECharts,
    },
    props: {
        id: String,
        value: Object | Array,
        cell: Object,
        template: Object | Array,
        xAxis: Object | Array,
        yAxis: Object | Array,
        filters: Array,
        group: String,
        options: {
            type: Object,
            default() {
                return {}
            }
        },
        defaultSerie: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data: () => ({
        defaultOptions: {
            //renderer: 'svg',
            animation:false,
            toolbox: {
                feature: {
                    dataZoom: {
                        show: true,
                        yAxisIndex: 'none',
                        icon: {
                            zoom: 'image://',
                            back: 'image://'
                        },
                        brushStyle: {
                            color: "#000000",
                            opacity: 0.3,
                        }
                    }
                }
            },
            legend: {
                show:false,
                type: 'scroll',
                pageButtonPosition: 'start'
            },
            grid: {
                left: '5%',
                top: 10,
                right: '5%',
                bottom: 10,
                containLabel: true
            },
            xAxis: {
                type: 'time',
                id: 'timeline'
            },
            yAxis: {
                type: 'value'
            },

            series: [],
        }
    }),
    computed: {
        groupName() {
            if (this.group) {
                return this.group;
            }
            return 'eod-graph-line'; //template.content.group.key+'_'+template.content.group.value;
        }
    },
    mixins:[GraphMixin],
    methods: {
        mousemove(params) {
            if (this.$refs[this.id] && params.componentType == 'series') {
                this.$refs[this.id].chart.getZr().setCursorStyle('pointer');
            }
        },
        contextmenu(params) {
            if (params.event) {
                if (params.event.event) {
                    this.$emit('contextmenu', params.value[2]);
                    params.event.event.preventDefault();
                } else {
                    params.event.preventDefault();
                }
            }
        },
        selectChanged(params) {
            this.$emit('selectChanged', this.$refs[this.id], params, this.value);
        },
        finished() {
            this.$emit('finished', this.id, this.$refs[this.id]);
        },
        resize() {
            this.$refs[this.id].resize();
        },
        measurementTypeFieldName(index){
            if (this.template[index]) {
                        const content = this.template[index].content;
                        for (let i = 0; i < content.measurementTypes.length; i++) {
                            const aggMeasurementType = content.measurementTypes[i];
                            return aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id;
                        }
                    }
            return null;
        },
        getSeries(){
            let series = [];
            let predictionSeries = [];
            for (let i = 0; i < this.value.length; i++) {
                const value = this.value[i];
                if (value) {

                    let serie = {
                        name: '',
                        data: [],
                        type: 'line',
                        symbolSize: 14,
                        showSymbol: false,
                        connectNulls: false
                    };

                    const measurementTypeId = Object.keys(value.measurementTypes)[0];
                    const measurementType = value.measurementTypes[measurementTypeId];

                    if (this.defaultSerie) {
                        serie = {
                            ...serie,
                            ...this.defaultSerie
                        }
                    }

                    if (this.cell && this.cell.settings.queries[value.queryIndex]) {
                        serie = {
                            ...serie,
                            ...this.cell.settings.queries[value.queryIndex].options ? this.cell.settings.queries[value.queryIndex].options : {}
                        }
                    }

                    serie.name = value.name;
                    
                    const fieldname = this.measurementTypeFieldName(value.queryIndex);

                    for (let j = 0; j < value.data.length; j++) {
                        const data = value.data[j];
                        if (data) {
                            const compactSerie = {
                                data: value.data[0]?[value.data[0]]:null,
                                measurementTypes: value.measurementTypes
                            }
                            serie.data.push({
                                'value': [data.start, data[fieldname], data, measurementType.name, compactSerie]
                            });
                        }
                    }

                    series[i] = serie;

                    if(value.dataPrediction){
                        const predictionSerie = _.cloneDeep(serie);
                        predictionSerie.data = [];
                        predictionSerie.lineStyle = {
                            color: '#dddddd',
                            width: 2,
                            type: 'dashed'
                        };

                        for (let j = 0; j < value.dataPrediction.length; j++) {
                            const prediction = value.dataPrediction[j];
                            if (prediction) {
                                predictionSerie.data.push({
                                    'value': [prediction.start, prediction[fieldname], prediction, measurementType.name, value]
                                });
                            }
                        }

                        predictionSerie.data.push(serie.data[0]);

                        predictionSeries[i] = predictionSerie;
                    }
                }
            }

            series = series.concat(predictionSeries);

            return series;            
        },
        async update() {

            this.graphOptions = _.merge(this.defaultOptions, this.options);

            if(this.graphOptions.legend.show){
                this.graphOptions.grid.top = 40;
            }

            this.series = this.getSeries();
            this.renderGraph(this.series);
            this.loadAlarmTemplates(this.series);
        }
    }
}
</script>