<template>
    <div>
        <div :class="{'px-4': headerInset}">
        <v-row class="mb-0" v-if="!readonly">
            <v-col>
                <v-autocomplete dense clearable :search-input.sync="search" :loading="isUpdating"
                    :readonly="isUpdating" :items="searchItems" label="Bestaande gebruiker toevoegen" return-object
                    item-text="email" item-key="id" v-model="searchItem" solo rounded auto-select-first no-filter @change="addItem"
                    hide-details :item-disabled="itemDisabled">
                    <template v-slot:no-data>
                        <div v-if="search && search != '' && !isUpdating" class="px-4">Geen resultaten gevonden...</div>
                        <div v-else class="px-4">Geef <strong>gebruikersnaam, naam of
                                e-mailadres</strong> in om te zoeken...</div>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-list-item-avatar color="indigo" size="40">
                            <span class="headline font-weight-light white--text">{{ item.getInitials() }}</span>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.getFullName()"></v-list-item-title>
                            <v-list-item-subtitle>{{ $t(item.getRole().name) }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <!--<template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click.stop="showAddItem">
                            <v-list-item-icon>
                                <v-icon>mdi-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Nieuwe gebruiker aanmaken</v-list-item-title>
                                <v-list-item-subtitle>Maak een gebruiker aan en voeg deze toe aan de
                                    lijst</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>-->
                </v-autocomplete>
            </v-col>
            <v-col sm="auto" class="d-flex align-items-center">
                <v-btn id="btn_create_user" rounded small color="primary" class="mr-2" @click="showAddItem">
                    <v-icon left>mdi-plus</v-icon> Nieuwe gebruiker
                </v-btn>
                <template v-if="importShow">
                <input type="file" class="d-none" ref="importFile" @change="uploadFileImport">
                <v-btn rounded depressed text small :loading="importLoading" class="mr-2" @click="importItems">
                    <v-icon left>mdi-import</v-icon> Importeren
                </v-btn>
                <v-btn v-if="exportShow && hasItems" rounded depressed text small :loading="exportLoading" class="mr-2"
                    @click="exportItems">
                    <v-icon left>mdi-export</v-icon> Exporteren
                </v-btn>
            </template>
            </v-col>
        </v-row>
    </div>
        <v-card :outlined="outlined" :flat="flat">
            <v-card-text>
                <div v-if="hasItems">
                    <v-list subheader v-for="(group, groupname) in itemsGrouped" :key="groupname" dense>
                        <v-subheader>{{ $t(groupname) }}</v-subheader>

                        <v-list-item v-for="item in group" :key="item.id">
                            <v-list-item-avatar color="primary lighten-2" class="white--text" size="30">
                                {{ item.getInitials() }}
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-text="item.getFullName()"></v-list-item-title>
                                <v-list-item-subtitle v-if="showRole">{{ $t(item.getRole().name) }}</v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <v-chip-group>
                                        <v-chip small
                                            v-if="item.attributes && item.attributes.jobtitle && item.attributes.jobtitle[0]"><v-icon
                                                small left>mdi-badge-account-horizontal-outline</v-icon>{{
                                                    item.attributes.jobtitle[0] }}</v-chip>
                                        <v-chip small v-if="item.email" :href="'mailto:' + item.email"><v-icon small
                                                left>mdi-email-outline</v-icon>{{ item.email }}</v-chip>
                                        <v-chip small
                                            v-if="item.attributes && item.attributes.extra_email && item.attributes.extra_email[0]"
                                            :href="'mailto:' + item.attributes.extra_email[0]"><v-icon small
                                                left>mdi-email-outline</v-icon>{{ item.attributes.extra_email[0] }}</v-chip>
                                        <v-chip small
                                            v-if="item.attributes && item.attributes.phone_number && item.attributes.phone_number[0]"
                                            :href="'tel:' + item.attributes.phone_number[0]"><v-icon small
                                                left>mdi-phone</v-icon>{{ item.attributes.phone_number[0] }}</v-chip>
                                        <v-chip small
                                            v-if="item.attributes && item.attributes.extra_phone && item.attributes.extra_phone[0]"
                                            :href="'tel:' + item.attributes.extra_phone[0]"><v-icon small
                                                left>mdi-phone</v-icon>{{ item.attributes.extra_phone[0] }}</v-chip>
                                    </v-chip-group>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-icon v-if="!readonly">
                                <eod-actions-column :item="item" :valueText="getTitle" :deleteAction="deleteItem"
                                    :editAction="editItem" deleteIcon="mdi-link-off" deleteText="Ontkoppelen" :show-copy="false"></eod-actions-column>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </div>
                <v-alert dense color="grey" text v-else>
                    <span v-if="!readonly">Er zijn nog geen gebruikers gekoppeld.</span>
                    <span v-else>Er zijn geen gebruikers gekoppeld.</span>
                </v-alert>

            </v-card-text>

            <v-dialog v-if="userDialog" v-model="userDialog" persistent scrollable max-width="800px">
                <eod-add-contact v-model="editUser" :roles="roles" :show-role="showRole" @onCancel="userDialog = false"
                    @onSaved="itemSaved"></eod-add-contact>
            </v-dialog>

            <eod-errors-dialog v-model="showErrors" :errors="importErrors" title="Fout bij importeren!"></eod-errors-dialog>
        </v-card>
    </div>
</template>

<script>
import { User } from '@/eodvuecomponents';
import eodErrorsDialog from './eod-errors-dialog.vue';
import eodColumnActions from './columns/eod-column-actions.vue';
import eodAddContact from './eod-add-contact.vue';

export default {
    name: 'Contacts',
    props: {
        value: Array,
        roles: Array,
        importAction: Function,
        defaultRole: Object,
        importShow: {
            type: Boolean,
            default: true
        },
        showRole: {
            type: Boolean,
            default: false
        },
        exportShow: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        headerInset: Boolean,
    },
    components: {
        'eod-add-contact': eodAddContact,
        'eod-actions-column': eodColumnActions,
        'eod-errors-dialog': eodErrorsDialog,
    },
    data() {
        return {
            search: '',
            initialUsers: [],
            isUpdating: false,
            exportLoading: false,
            searchResults: [],
            searchItem: null,
            timeout: null,
            userDialog: false,
            importLoading: false,
            editUser: new User(),
            showErrors: false,
            importErrors: [],
            items: [],
            config: {
                import: {
                    endpoint: 'Users',
                    objectName: 'User'
                }
            }
        }
    },
    mounted() {
        if (this.value && this.value.length > 0) {
            this.loadUsers();
        } else {
            this.init();
        }
    },
    computed: {
        searchItems() {

            let arr_items = this.initialUsers;
            if (this.search && this.search != '') {
                arr_items = this.searchResults;
            }

            

            for (let i = 0; i < arr_items.length; i++) {
                if (arr_items[i].getRole() == null) {
                    arr_items.splice(i, 1);
                } else if (this.roles && !this.roles.includes(arr_items[i].getRole().name)) {
                    arr_items.splice(i, 1);
                }
            }

            return arr_items;
        },
        hasItems() {
            for (const groupname in this.itemsGrouped) {
                if (this.itemsGrouped.hasOwnProperty(groupname)) {
                    const items = this.itemsGrouped[groupname];
                    for (let i = 0; i < items.length; i++) {
                        return true;
                    }
                }
            }

            return false;
        },
        itemsGrouped() {
            let grouped = {};

            if (this.items) {
                for (let i = 0; i < this.items.length; i++) {
                    const element = this.items[i];

                    if (!element.getRole()) {
                        continue;
                    }

                    if (!this.roles.includes(element.getRole().name)) {
                        continue;
                    }

                    if (!grouped[element.getRole().name]) {
                        grouped[element.getRole().name] = [];
                    }

                    grouped[element.getRole().name].push(element);
                }
            }

            return grouped;
        }
    },
    methods: {
        init() {
            let searchVariables = {
                limit: 10
            }

            if (this.roles && this.roles.includes('member')) {
                searchVariables.enabled = true;
            } else if (this.roles) {
                searchVariables.enabled = false;
            }

            this.$eod.get('searchUsers', ['id', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{name}', 'notificationSettings'], searchVariables).then(response => {
                if (response.data.data.searchUsers) {
                    let count = response.data.data.searchUsers.edges.length;
                    for (let i = 0; i < count; i++) {
                        this.initialUsers.push(new User(response.data.data.searchUsers.edges[i]));
                    }
                    this.$forceUpdate();
                }
            });
        },
        fillUsers(users) {
            let count = users.length;
            for (let i = 0; i < count; i++) {
                this.items.push(new User(users[i]));
            }
            this.$forceUpdate();
        },
        loadUsers() {
            this.items = [];
            let users = this.value;

            let ids = [];
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                ids.push(user.id);
            }

            // Filter users
            this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{id name}', 'notificationSettings', 'attributes{phone_number extra_email extra_phone jobtitle}'], { // 'attributes{phone_number extra_email extra_phone}'
                whereIn: {
                    column: 'id',
                    array: ids
                }
            })
                .then(result => {
                    let count = result.data.data.resolveUsers.edges.length;
                    for (let i = 0; i < count; i++) {
                        this.items.push(new User(result.data.data.resolveUsers.edges[i]));
                    }
                    this.$forceUpdate();
                });
        },
        getTitle(item) {
            return item.getFullName();
        },
        itemDisabled(item) {
            if (this.items) {
                for (let j = 0; j < this.items.length; j++) {
                    if (item.id == this.items[j].id) {
                        return true;
                    }
                }
            }
            return false;
        },
        deleteItem(item) {
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].id == item.id) {
                    this.items.splice(i, 1);
                    this.$emit('input', this.items);
                    return;
                }
            }
        },
        editItem(item) {

            // Get user
            this.$eod.getById('user', item.id, ['id', 'firstName', 'lastName', 'username', 'email', 'notificationSettings', 'projects{id}', 'companies{id}', 'depot{id name streetAddress postalCode locality country category}', 'assignedRealmRoles{id name}', 'attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone}'])
                .then(result => {
                    this.editUser = new User(result.data.data.user);
                    this.userDialog = true;
                });
        },

        async itemSaved(item) {
            this.userDialog = false;
            this.addItem(item);
        },
        showAddItem() {
            const user = new User();
            if (this.defaultRole) {
                user.assignedRealmRoles = [this.defaultRole];
            }
            this.editUser = user;
            this.userDialog = true;
        },
        addItem(item) {
            if (!this.items) {
                this.items = [];
            }

            this.items.push(item);
            this.$emit('input', this.items);

            this.$emit('add', item);

            this.$nextTick(() => {
                this.searchResults = [];
                this.search = '';
                this.searchItem = null;

                this.$forceUpdate();
            });
        },
        uploadFileImport() {
            this.importLoading = true;

            if (this.importAction) {
                this.importAction(this.$refs.importFile.files[0]).then(result => {
                    this.$toaster.notify({
                        type: 'success',
                        title: this.$t('Succes'),
                        body: this.$t('Gebruikers zijn succesvol geïmporteerd!')
                    });
                    this.importLoading = false;
                }).catch(e => {
                    this.importErrors = e.response.data.errors;
                    this.showErrors = true;
                    this.importLoading = false;
                });
            } else {
                this.$eod.importUsers(this.$refs.importFile.files[0])
                    .then(result => {
                        if (result.data.success) {
                            this.$toaster.notify({
                                type: 'success',
                                title: this.$t('Succes'),
                                body: this.$t('Gebruikers zijn succesvol geïmporteerd!')
                            });
                        }
                        this.importLoading = false;
                    }).catch(error => {
                        this.importErrors = e.response.data.errors;
                        this.showErrors = true;
                        this.importLoading = false;
                    });
            }
        },
        importItems() {
            this.$refs.importFile.click();
        },
        exportItems() {
            this.exportLoading = true;
            let ids = [];
            for (const groupname in this.items) {
                if (this.items.hasOwnProperty(groupname)) {
                    const items = this.items[groupname];
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        ids.push(item.id);
                    }
                }
            }

            this.$eod.exportUsers({
                whereIn: {
                    column: 'id',
                    array: ids
                }
            })
                .then(result => {
                    if (result.data.success) {
                        window.location.href = result.data.path;
                    }
                    this.exportLoading = false;
                }).catch(err => {
                    this.exportLoading = false;
                });
        },
    },
    watch: {
        value(val) {
            this.loadUsers();
        },
        search(val) {
            // Items have already been requested
            if (this.timeout) {
                clearInterval(this.timeout);
            }

            this.timeout = setTimeout(() => {
                if (this.isUpdating) return;

                if (!val || val == '' || val.length < 2) {
                    this.searchResults = [];
                    return;
                };

                this.isUpdating = true;

                let variables = {
                    search: val,
                }

                if(this.roles){
                    variables.roleNames = this.roles;
                }

                // Filter users
                this.$eod.get('users', ['id', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{id name}', 'notificationSettings'], variables)
                    .then(result => {
                        this.searchResults = [];
                        let count = result.data.data.users.edges.length;
                        for (let i = 0; i < count; i++) {
                            this.searchResults.push(new User(result.data.data.users.edges[i]));
                        }
                        this.isUpdating = false;
                        this.$forceUpdate();
                    });

                this.timeout = null;
            }, 500);
        }
    },
}
</script>