<template>
    <td class="w-fixed-50 w-xxl-auto">
        <v-menu v-if="item.company" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="{ ...attrs, ...attrsMenu }" v-on="{ ...on, ...onMenu }" color="transparent">
                            <v-icon left>{{ icon }}</v-icon><span v-if="!collapsed" class="d-none d-xxl-inline">{{
                                item.company.name
                            }}</span>
                        </v-chip>
                    </template>
                    <span>{{
                        item.company.name
                    }}</span>
                </v-tooltip>
            </template>
            <v-card flat width="400" max-width="100%">
                <v-card-title class="text-h6 d-flex justify-content-between"><span>{{ item.company.name }}</span><v-btn icon
                        :to="{ name: 'company', params: { id: item.company.id } }"><v-icon>mdi-pencil-outline</v-icon></v-btn></v-card-title>
                <v-divider></v-divider>
                <eod-company-info :value="item.company.id"></eod-company-info>
            </v-card>
        </v-menu>
    </td>
</template>
<script>
import eodCompanyInfo from './../eod-company-info.vue';

export default {
    name: 'eod-column-extra',
    components: {
        'eod-company-info': eodCompanyInfo
    },
    props: {
        'item': Object,
        'fieldname': String,
        'extra': Object,
    },
    computed: {
        collapsed() {
            return this.extra && this.extra.collapsed;
        },
        icon() {
            return (this.extra && this.extra.icon) ? this.extra.icon : 'mdi-office-building-outline';
        }
    }
}
</script>