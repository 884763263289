<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" ref="item" class="eod-scheduler__depot" @click="gotoDepot"
                :style="'background-color:' + (value.isStartDepot ? '#F1F1F8' : '#F1F1F8') + ';left:' + (offsetX + (colWidth * columnIndex)) + 'px;width:' + (width * colWidth) + 'px;top:' + (offsetY + (rowIndex * rowHeight) + (value.heightIndex * height)) + 'px;height:' + (height - 1) + 'px;' + (value.visible != false ? '' : 'opacity:0.2')">
                <v-icon small v-text="depotIcons[value.depot.category]"></v-icon>
            </div>
        </template>
        <span>
            <strong>{{ value.depot.name }}</strong>
            <br>{{
    value.isStartDepot ? 'Vertrek: ' + getDateFormatted(value.dueBeforeDate) : 'Aankomst: ' +
        getDateFormatted(value.dueAfterDate)
            }}
        </span>
    </v-tooltip>

</template>
<style lang="scss">
.eod-scheduler__depot {
    position: absolute;
    opacity: 0.8;
    border-radius: 8px;
    font-size: .8rem;
    user-select: none;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }
}
</style>
<script>
export default {
    name: 'eod-scheduler-depot',
    props: {
        value: Object,
        offsetX: Number,
        width: Number,
        offsetY: Number,
        height: Number,
        rowIndex: Number,
        columnIndex: Number,
        colWidth: Number,
        rowHeight: Number,
    },
    data() {
        return {
            depotIcons: {
                'home': 'mdi-home-outline',
                'warehouse': 'mdi-warehouse',
            }
        }
    },
    methods: {
        getDateFormatted(date) {
            return this.$moment(date).format('HH:mm')
        },
        gotoDepot() {
            let route = this.$router.resolve({ name: 'depot', params: { id: this.value.depot.id } });
            window.open(route.href);
        }
    }
}
</script>
