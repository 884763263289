<template>
    <div>
        <div class="text-h6 mb-2" v-if="cell.typeId == 'title'" v-html="cell.name"></div>
        <div class="text-subtitle-2 mb-2" v-if="cell.typeId == 'subtitle'" v-html="cell.name"></div>
        <div class="text-body-2 mb-2" v-if="cell.typeId == 'text' && !cell.settings.isCollapsable" v-html="cell.name"></div>
        <v-expansion-panels class="mb-2" v-if="cell.typeId == 'text' && cell.settings.isCollapsable">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{ cell.settings.collapsableTitle }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ cell.name }}
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-card v-if="cell.typeId == 'measurementType' && measurementType" class="mb-4">
            <span v-if="cell.settings.showTitle" class="text-subtitle-2 px-4 py-2 d-block"
                style="background-color:#EDEEF4;color:#7F8EBE;">{{ measurementType.name }}<em
                    v-if="measurementType.formConfig.required">*</em></span>
            <v-card-subtitle v-if="cell.settings.showDescription" v-html="measurementType.description"></v-card-subtitle>
            <v-card-text>
                <eod-measurement :jobTypeId="job.jobType.id" :projectId="job.project.id" ref="measurement" v-model="value"
                    :measurementType="measurementType" :cell="cell" :readonly="readonly" :createTask="createTask"
                    @onCreateTask="onCreateTask"></eod-measurement>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import eodMeasurement from './eod-measurement';

export default {
    name: 'eod-cell',
    components: {
        'eod-measurement': eodMeasurement
    },
    props: {
        value: Object,
        cell: Object,
        measurementType: Object,
        job: Object,
        readonly: {
            type: Boolean,
            default: false
        },
        createTask: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onCreateTask(item) {
            this.$emit('onCreateTask', item);
        },
        refresh() {
            this.$refs.measurement.refresh();
        }
    }
}
</script>