module.exports = {
    routes: [{
        path: '/organization/integrations',
        name: 'organizationIntegrations',
        component: () => import('./views/detail.vue'),
        meta: {
            permissions: ['organization.integrations.read']
        }
    }],
    detail: {
        route: 'organization',
        endpoint: 'organization',
        objectName: 'Organization',
        fields: ['id', 'name', 'configuration{version settings{key value label isRoot isDefault}}'],
            tabs: [{
                path: 'organization',
                text: 'Algemeen'
            },
            {
                path: 'organizationUsers',
                text: 'Medewerkers'
            },
            {
                path: 'organizationContacts',
                text: 'Contacten'
            },
            {
                path: 'organizationConnectors',
                text: 'Data connectors'
            },
            {
                path: 'Teams',
                text: 'Teams'
            },
            {
                path: 'organizationIntegrations',
                text: 'Koppelingen'
            },
            {
                path: 'organizationSettings',
                text: 'Instellingen',
                permissions: ['organization.settings.read']
            }
        ],
    },
}