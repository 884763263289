import moment from 'moment-timezone';

export default class Product {
    static fields = {
        name: String,
    }
    static listItems = ['statusCode', 'type'];
    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam',
        'type': 'property',
        'settings': {
            'fields': ['name'],
            'title': '{name}',
        },
        'filter': 'tree_objects_view.name'
    },
    {
        'icon': 'mdi-file-sign',
        'label': 'Type',
        'type': 'module',
        'settings': {
            'module': 'productTypes',
            'fields': ['name'],
            'title': '{name}',
        },

        'filter': 'tree_objects_view.type_id'
    },
    {
        'icon': 'mdi-alarm',
        'label': 'Wijzigingsdatum',
        'type': 'date-between',
        'filter': 'updatedAt'
    },
    {
        filter:'tree_objects_view.id',
        icon:'mdi-folder-open-outline',
        operator: '=',
        filterMethod:'where',
        label:'Project',
        settings:{
            fields:['name'],
            module:'resolveProjects',
            title:'{name}'
        },
        type:'module'
    }
    ];
    static exportFields = ['id', 'name', 'description', 'quantityInHand', 'zone', 'level', 'depot{name}', 'vehicle{name}', 'aisle', 'rack', 'position', 'shelf', 'bin', 'productType{name}', 'code', 'class', 'warrantyEndDate', 'installedDate', 'lastMaintenanceDate', 'buildYear', 'location{name description streetAddress postalCode locality region country notes access department unit room costcenter}', 'projects{name description}', 'statusCode{name}'];
    static moduleName = 'products';
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    get warrantyEndDateFormatted() {
        if (this.warrantyEndDate) {
            return moment(this.warrantyEndDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set warrantyEndDateFormatted(value) {
        this.warrantyEndDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get installedDateFormatted() {
        if (this.installedDate) {
            return moment(this.installedDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set installedDateFormatted(value) {
        this.installedDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get lastMaintenanceDateFormatted() {
        if (this.lastMaintenanceDate) {
            return moment(this.lastMaintenanceDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set lastMaintenanceDateFormatted(value) {
        this.lastMaintenanceDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get productTypeId() {
        if (this.productType) {
            return this.productType.id;
        }
        return null;
    }
    set productTypeId(value) {
        this.productType = {
            id: value
        };
    }
    set depotId(value) {
        this.depot = {
            id: value
        }
    }
    get depotId() {
        if (this.depot) {
            return this.depot.id
        }
        return null;
    }
    set vehicleId(value) {
        this.vehicle = {
            id: value
        }
    }
    get vehicleId() {
        if (this.vehicle) {
            return this.vehicle.id
        }
        return null;
    }
    getSaveData() {

        let data = Object.assign({}, this);

        if (data.statusCode == null) {
            data.statusCodeId = null;
        } else if (data.statusCode && data.statusCode.id) {
            data.statusCodeId = data.statusCode.id;
        }
        delete data.statusCode;

        data.projects = [];
        if (this.projects) {
            for (let i = 0; i < this.projects.length; i++) {
                data.projects.push({
                    id: this.projects[i].id
                });
            }
        }

        for (let i = 0; i < Product.listItems.length; i++) {
            const propertyName = Product.listItems[i];
            if (data[propertyName]) {
                data[propertyName] = {
                    id: data[propertyName].id
                };
            }
        }

        data.productTypeId = this.productTypeId;
        delete data.productType;

        data.depotId = this.depotId;
        delete data.depot;

        data.vehicleId = this.vehicleId;
        delete data.vehicle;

        const manyToManyRelations = ['parents','children'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relationName = manyToManyRelations[i];
            if (data.hasOwnProperty(relationName) && data[relationName]) {
                const arr = [];
                for (let j = 0; j < data[relationName].length; j++) {
                    if(data[relationName][j].id != data.id){
                        arr.push({
                            id: data[relationName][j].id,
                        });
                    }
                }
                data[relationName] = arr;
            }
        }

        const floats = ['lat','lon','quantityInHand'];
        for (let i = 0; i < floats.length; i++) {
            const propertyName = floats[i];
            if (data.hasOwnProperty(propertyName) && !isNaN(data[propertyName])) {
                data[propertyName] = parseFloat(data[propertyName]);
            }
            
        }

        if(data.hasOwnProperty('measurementTypes')){
            for (let j = 0; j < data['measurementTypes'].length; j++) {
                data['measurementTypes'][j] = {
                    id: data['measurementTypes'][j].id,
                    indicativeValue: parseFloat(data['measurementTypes'][j].indicativeValue)
                }
            }
        }

        return data
    }
}