<template>
    <div>
        <v-card-subtitle class="d-flex grey lighten-4 py-2 mb-4">
            <span>{{ $moment(value.createdAt).format('YYYY-MM-DD | HH:mm') }}</span>
            <v-spacer></v-spacer>
            <v-btn v-if="!readonly" color="error" small icon
                @click="$emit('delete')"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
        </v-card-subtitle>
        <v-card-text>
            <eod-rte v-if="readonly" v-model="value.content" disabled></eod-rte>
            <eod-rte v-else v-model="value.content" ></eod-rte>
        </v-card-text>
    </div>
</template>
<script>
import EodRte from './eod-rte.vue';

export default {
    components: {
        EodRte
    },
    name: 'eod-comment',
    props: {
        value: Object,
        readonly: {
            type: Boolean,
            default: false
        }
    },
    created() {
        const val = this.value;
        if (!val.createdAt) {
            val.createdAt = this.$moment().toISOString()
        }

        if (!val.userId) {
            val.userId = this.$eod.getUser().id
        }

        if (!val.organizationId) {
            val.organizationId = this.$eod.getRealm()
        }
    }
}
</script>