import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            queryQueue: [],
            owners: {},
            devices: {},
            projects: {},
        }
    },
    methods: {
        resolveItems(endpoint, fields, ids) {
            return this.$eod.get(endpoint, fields, {
                whereIn: {
                    column: 'id',
                    array: ids
                }
            }).then(response => {
                if (response.data.data[endpoint]) {
                    return response.data.data[endpoint].edges;
                }

                return [];
            });
        },
        queueQuery(templateIndex, template, filters, options, cancelToken, id) {

            if (typeof id == 'undefined') {
                id = uuidv4();
            }

            const queueData = {
                templateIndex: templateIndex,
                id: id,
                template: template,
                filters, filters,
                options, options,
                cancelToken: cancelToken
            };

            this.queryQueue.push(queueData);

            this.getQueueResult(queueData);
        },
        deleteFromQueue(queueItem){
            for (let i = 0; i < this.queryQueue.length; i++) {
                const queueData = this.queryQueue[i];
                if(queueData.id == queueItem.id){
                    this.queryQueue.splice(i, 1);
                    return;
                }
            }
        },
        allQueriesLoaded(){},
        getQueueResult(queueItem){
            this.$eod.queryMeasurements(queueItem.templateIndex, queueItem.template, queueItem.filters, queueItem.options, {
                cancelToken: queueItem.cancelToken.token
            }).then(async result => {
                if (result.data == null) {
                    this.deleteFromQueue(queueItem);
                    this.gotQueueResult(queueItem, result.data);
                    return;
                }

                // Check owners
                let owner_ids_resolve = [];
                let product_ids_resolve = [];
                let project_ids_resolve = [];
                for (let i = 0; i < result.data.length; i++) {
                    const response = result.data[i];
                    if (response.owner_id && !owner_ids_resolve.includes(response.owner_id) && !this.owners[response.owner_id]) {
                        owner_ids_resolve.push(response.owner_id);
                    }
                    if (response.product_id && !product_ids_resolve.includes(response.product_id) && !this.devices[response.product_id]) {
                        product_ids_resolve.push(response.product_id);
                    }
                    if (response.project_id && !project_ids_resolve.includes(response.project_id) && !this.projects[response.project_id]) {
                        project_ids_resolve.push(response.project_id);
                    }
                }

                if (owner_ids_resolve.length > 0) {
                    const users = await this.resolveItems('resolveUsers', ['id', 'attributes{description}', 'firstName', 'lastName', 'username'], owner_ids_resolve);
                    for (let i = 0; i < users.length; i++) {
                        const user = users[i];
                        this.owners[user.id] = user;
                    }
                }

                if (product_ids_resolve.length > 0) {
                    const products = await this.resolveItems('resolveProducts', ['id', 'name', 'color', 'description'], product_ids_resolve);
                    for (let i = 0; i < products.length; i++) {
                        const product = products[i];
                        this.devices[product.id] = product;
                    }
                }

                if (project_ids_resolve.length > 0) {
                    const projects = await this.resolveItems('resolveProjects', ['id', 'name', 'description'], project_ids_resolve);
                    for (let i = 0; i < projects.length; i++) {
                        const project = projects[i];
                        this.projects[project.id] = project;
                    }
                }

                for (let i = 0; i < result.data.length; i++) {
                    const response = result.data[i];
                    if (response.owner_id && this.owners[response.owner_id]) {
                        result.data[i].owner_id_resolved = this.owners[response.owner_id];
                    }
                    if (response.product_id && this.devices[response.product_id]) {
                        result.data[i].product_id_resolved = this.devices[response.product_id];
                    }
                    if (response.project_id && this.projects[response.project_id]) {
                        result.data[i].project_id_resolved = this.projects[response.project_id];
                    }
                }

                this.deleteFromQueue(queueItem);
                this.gotQueueResult(queueItem, result.data);

                return result;
            }).catch(() => {
                return null;
            }).finally(() => {
                if(!this.queryQueue[0]){
                    this.allQueriesLoaded();
                }
            });
        },
    },
}