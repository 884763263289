module.exports = {
    routes: [
        {
            path: '/organization/settings',
            name: 'organizationSettings',
            component: () => import('./views/detail.vue'),
            meta: {
                permissions: ['organization.settings.update']
            },
        }
    ],
    detail: {
        route: 'organization',
        endpoint: 'organization',
        objectName: 'Organization',
        fields: ['id', 'name', 'configuration{version}'],
        tabs: [{
            path: 'organization',
            text: 'Algemeen'
        },
        {
            path: 'organizationUsers',
            text: 'Medewerkers'
        },
        {
            path: 'organizationContacts',
            text: 'Contacten'
        },
        {
            path: 'organizationConnectors',
            text: 'Data connectors'
        },
        {
            path: 'Teams',
            text: 'Teams'
        },
        {
            path: 'organizationIntegrations',
            text: 'Koppelingen'
        },
        {
            path: 'organizationSettings',
            text: 'Instellingen',
            permissions: ['organization.settings.read']
        }
        ],
    },
}