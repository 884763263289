<template>
    <v-card :id="id" :flat="flat" :color="color">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-card-title v-if="showSearch">
            <v-text-field ref="search" label="Zoeken..." :value="searchVal" @input="searchChanged" dense outlined
                hide-details clearable prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-card-title>
        <v-card-text :style="bodyStyle">
            <v-treeview v-if="!loading" @update:active="val => $emit('change', val)" :active="active" :items="items"
                :load-children="fetch" :open.sync="open" selected-color="primary" :activatable="activatable" return-object
                :open-all="openAll" :open-on-click="openOnClick" expand-icon="mdi-chevron-down" :hoverable="hoverable"
                @input="(val) => { $emit('input', val) }">
                <template v-slot:prepend="{ item }">
                    <v-icon v-if="getModuleByLevel(item.level)" v-text="getModuleByLevel(item.level).icon"></v-icon>
                </template>
                <template v-slot:label="{ item }">
                    {{ item.name }} ({{ item.description }})
                </template>
                <template v-slot:append="{ item }">
                    <v-chip small v-if="item.append" v-html="item.append.value" :color="item.append.color"></v-chip>
                </template>
            </v-treeview>
        </v-card-text>

        <div v-if="showSearchHint && !showMinLength" class="text-center text-body-2 grey lighten-3 pa-2">
            Gebruik de zoekfunctie voor meer resultaten
        </div>
        <div v-if="showMinLength" class="text-center text-body-2 grey lighten-3 pa-2">
            Geen een zoekopdracht van minimaal 3 tekens in...
        </div>
        <slot name="extension"></slot>
    </v-card>
</template>
<script>
export default {
    name: 'eod-item-treeview',
    props: {
        id: String,
        tree: Array,
        modules: Object,
        value: Array,
        initItems: {
            type: Number,
            default: 5
        },
        activatable: {
            type: Boolean,
            default: false
        },
        openOnClick: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        hoverable: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'white'
        },
        bodyStyle: {
            type: String,
            default: ''
        },
        filters: Array,
        append: Array,
        fields: Array,
        search: String,
        showSearch: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        showSearchHint: false,
        showMinLength: false,
        loading: false,
        openAll: false,
        items: [],
        open: [],
        active: [],
        timeout: null,
        searchVal: null
    }),
    mounted() {
        this.loadRootTree();
        this.focusSearch();
        this.searchVal = this.search;
        //this.active = this.value;
    },
    watch: {
        search(val) {
            this.searchVal = val;
            this.doSearch(val);
        },
        open(val, oldVal) {
            this.$emit('open', val, oldVal);
        }
    },
    methods: {
        doSearch(val) {
            this.loading = false;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.items = [];

            if (!val || val == '') {
                this.showSearchHint = true;
                return false;
            }

            this.showSearchHint = false;

            if (val.length < 3) {
                this.showMinLength = true;
                return false;
            }

            this.showMinLength = false;

            this.loading = true;
            this.timeout = setTimeout(async () => {

                let items_per_level = [];
                let parent_query = '';
                for (let i = 0; i < this.tree.length; i++) {

                    let modulename = this.tree[i];
                    if (typeof this.tree[i] == 'object') {
                        modulename = this.tree[i].modulename;
                    }


                    let fields = ['id', 'name', 'description'];
                    if (this.fields && this.fields[i]) {
                        fields = this.fields[i];
                    }

                    if (i == 0) {
                        parent_query = fields;
                    }

                    if (i > 0) {
                        let parentfield = this.getModuleByLevel(i).parents[this.tree[i - 1]];
                        parent_query = parentfield + '{' + parent_query + '}';
                        fields.push(parent_query);

                        let items = await this.searchItems(modulename, val, fields, i);
                        items_per_level.push(items);

                        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
                            const item = items[itemIndex];

                            let to_check = item;
                            for (let j = i - 1; j >= 0; j--) {

                                if (to_check) {
                                    let parentmodule = this.tree[j];
                                    if (typeof this.tree[j] == 'object') {
                                        parentmodule = this.tree[j].modulename;
                                    }

                                    parentfield = this.getModuleByLevel(j + 1).parents[parentmodule];
                                    let parent = to_check[parentfield];

                                    if (Object.prototype.toString.call(parent) === '[object Array]') {

                                        for (let parentIndex = 0; parentIndex < parent.length; parentIndex++) {
                                            const parentItem = parent[parentIndex];
                                            let found = false;
                                            for (let levelIndex = 0; levelIndex < items_per_level[j].length; levelIndex++) {
                                                const element = items_per_level[j][levelIndex];
                                                if (element.id == parentItem.id) {
                                                    found = true;
                                                    break;
                                                }
                                            }

                                            if (!found) {
                                                items_per_level[j].push(parentItem);
                                            }
                                        }

                                        if (parent[0]) {
                                            parent = parent[0];
                                        } else {
                                            parent = null;
                                        }


                                    } else {

                                        let found = false;
                                        for (let levelIndex = 0; levelIndex < items_per_level[j].length; levelIndex++) {
                                            const element = items_per_level[j][levelIndex];
                                            if (element.id == parent.id) {
                                                found = true;
                                                break;
                                            }
                                        }

                                        if (!found) {
                                            items_per_level[j].push(parent);
                                        }

                                    }

                                    to_check = parent;
                                }

                            }
                        }
                    } else {
                        let items = await this.searchItems(modulename, val, fields, i);
                        if (i < this.tree.length - 1) {
                            items = this.giveChildren(items);
                        }
                        items_per_level.push(items);
                    }
                }

                let children = null;
                for (let level = items_per_level.length - 1; level >= 0; level--) {
                    if (children) {
                        ``
                        let parentmodule = this.tree[level];
                        if (typeof this.tree[level] == 'object') {
                            parentmodule = this.tree[level].modulename
                        }
                        let parentfield = this.getModuleByLevel(level + 1).parents[parentmodule];
                        let items = [];

                        for (let i = 0; i < items_per_level[level].length; i++) {
                            const item = items_per_level[level][i];
                            item.level = level;

                            for (let j = 0; j < children.length; j++) {

                                const child = children[j];

                                if (Object.prototype.toString.call(child[parentfield]) === '[object Array]') {
                                    for (let parentIndex = 0; parentIndex < child[parentfield].length; parentIndex++) {
                                        const parentItem = child[parentfield][parentIndex];
                                        if (item.id == parentItem.id) {
                                            if (!item.children) {
                                                item.children = [];
                                            }
                                        }

                                        if (item.children) {
                                            item.children.push(child);
                                        }


                                    }
                                } else {
                                    if (item.id == child[parentfield].id) {
                                        if (!item.children) {
                                            item.children = [];
                                        }

                                        item.children.push(child);
                                    }
                                }



                            }

                            items.push(item);
                        }

                        children = items;

                    } else {
                        for (let j = 0; j < items_per_level[level].length; j++) {
                            items_per_level[level][j].level = level;
                            if (this.append && this.append[level]) {
                                items_per_level[level][j].append = this.append[level](items_per_level[level][j]);
                            }
                        }
                        children = items_per_level[level];
                    }
                }

                this.items = children;
                this.openAll = true;
                this.loading = false;
            }, 500);
        },
        searchChanged(val) {
            this.$emit('searchChanged', val);

            this.$nextTick(() => {
                if (!this.search) {
                    this.doSearch(val);
                }
            });

        },
        focusSearch() {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.$refs.search) {
                        this.$refs.search.focus();
                    }
                }, 200);
            });
        },
        giveChildren(items) {
            if (items) {
                for (let i = 0; i < items.length; i++) {
                    if (!items[i].children) {
                        items[i].children = [];
                    }
                }
            }
            return items;
        },
        async searchItems(modulename, search, fields, index) {
            let options = {
                orderBy: {
                    column: "name",
                    type: "asc"
                },
                where: [
                    {
                        column: 'name',
                        operator: 'ILIKE',
                        value: '%' + search + '%',
                        chain: 'or'
                    },
                    {
                        column: 'description',
                        operator: 'ILIKE',
                        value: '%' + search + '%',
                        chain: 'or'
                    }
                ]
            }

            if (this.filters && this.filters[index]) {
                options = this.filters[index](options, search);
            }

            return await this.$eod.get(modulename, fields, options)
                .then(result => {
                    return result.data.data[modulename].edges;
                });
        },
        getModuleByLevel(level) {
            let modulename = this.tree[level];
            if (typeof modulename == 'object') {
                modulename = this.tree[level].modulename;
            }
            return this.modules[modulename];
        },
        loadRootTree() {
            let root_module = this.tree[0];

            let fields = ['id', 'name', 'description'];
            if (this.fields && this.fields[0]) {
                fields = this.fields[0];
            }

            this.$eod.get(root_module, fields, {
                orderBy: {
                    column: "name",
                    type: "asc"
                },
                offset: 0,
                limit: this.initItems,
            })
                .then(result => {

                    if (result.data.data[root_module].totalCount > this.initItems) {
                        this.showSearchHint = true;
                    }

                    let items = result.data.data[root_module].edges;
                    for (let i = 0; i < items.length; i++) {
                        items[i].level = 0;

                        if (items[i].level < this.tree.length - 1) {
                            items[i].children = [];
                        }
                    }

                    this.items = result.data.data[root_module].edges;

                });
        },
        fetch(parent_item) {

            if (this.tree[parent_item.level + 1]) {
                let property = this.tree[parent_item.level + 1];

                if (typeof property == 'object') {
                    property = this.tree[parent_item.level + 1].property;
                }
                let parent_module = this.getModuleByLevel(parent_item.level);

                let fields = ['id', 'name', 'description'];
                if (this.fields && this.fields[parent_item.level + 1]) {
                    fields = this.fields[parent_item.level + 1];
                }

                return this.$eod.getById(parent_module.item, parent_item.id, [property + '{' + fields.join(' ') + '}'])
                    .then(result => {
                        let items = result.data.data[parent_module.item][property];
                        for (let i = 0; i < items.length; i++) {
                            items[i][parent_module.item] = parent_item;
                            items[i].level = parent_item.level + 1;

                            if (items[i].level < this.tree.length - 1) {
                                items[i].children = [];
                            }

                            if (this.append && this.append[items[i].level]) {
                                items[i].append = this.append[items[i].level](items[i]);
                            }
                        }

                        parent_item.children.push(...items);
                        return parent_item;
                    });
            }
        }
    }
}
</script>