<template>
    <div>
    <v-menu v-model="showMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
             <eod-text-field :info="info" :value="value" :label="label" hide-details v-bind="attrs" v-on="on" @input="search => searchIcon(search)" :dense="dense" :outlined="outlined" clearable @click:clear="() => {$emit('input', null)}">
                 <v-icon slot="prepend" v-if="value" v-html="'mdi-'+value"></v-icon>
            </eod-text-field>
        </template>
        <v-card min-width="300" max-width="500">

            <v-virtual-scroll
                v-if="!searching"
                height="300"
                item-height="50"
                :items="searchIcons"
                bench="4"
            >
            <template v-slot:default="{ item }">
                <v-list-item :key="item.name" @click="setIcon(item.name)">
                    <v-list-item-icon>
                        <v-icon v-html="'mdi-'+item.name"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{item.name}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
            </v-virtual-scroll>
        </v-card>
      </v-menu>
    </div>
</template>
<script>
import eodTextField from './eod-text-field.vue';

export default {
    components: {
        eodTextField
    },
    name:'eod-icon-picker',
    props: {
        value: String,
        label: String,
        info: String,
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            showMenu: false,
            search: '',
            icons: require('./../classes/icons'),
            searchIcons: [],
            timeout: null,
            searching: false,
        }
    },
    created(){
        this.searchIcons = this.icons.icons;
    },
    methods:{
        setIcon(name){
            this.search = '';
            this.showMenu = false;
            this.$emit('input', name);
        },
        searchIcon(search){
            const self = this;
            clearTimeout(this.timeout);
            if(search){
                this.timeout = setTimeout(() => {
                    self.searching = true;
                    let icons = [];
                    if(search != ''){
                        for (let i = 0; i < self.icons.icons.length; i++) {
                            const icon = self.icons.icons[i];
                            if(icon.name.toLowerCase().indexOf(search.toLowerCase()) > -1){
                                icons.push(icon);
                            }
                        }
                    }else{
                        icons = self.icons.icons;
                    }
                
                    self.searching = false;

                    self.searchIcons = icons;
                }, 500);
            }else{
                this.searchIcons = this.icons.icons;
            }
            
        }
    }
}
</script>