<template>
    <div>
            <div v-for="cell in graphs" :key="cell.id" style="width:100%;height:400px;">
                <component v-if="cell.graph.typeId" :item="cell.graph" :from="value.content.dateTimeFrom" :until="value.content.dateTimeUntil" :value="cell.graph" :template="value" :is="config.getCellTypeById(cell.graph.typeId).viewComponent"></component>
            </div>
    </div>
</template>
<script>
export default {
    props:{
        value: Object,
        config: Object,
    },
    computed:{
        graphs(){
            let graphs = [];
            if(this.value && this.value.content.pages){
                for (let i = 0; i < this.value.content.pages.length; i++) {
                    const page = this.value.content.pages[i];
                    for (let j = 0; j < page.cells.length; j++) {
                        const cell = page.cells[j];
                        if(cell.typeId == 'graph'){
                            graphs.push(cell);
                        }
                    }
                }
            }
            

            return graphs;
        }
    }
}
</script>