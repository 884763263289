<template>
    <v-dialog v-model="value" @input="(val) => {$emit('input', val)}" max-width="800" scrollable>
      <v-card>
          <v-card-title class="mb-4">
                  <v-avatar color="error" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              mdi-alert-circle-outline
            </v-icon>
          </v-avatar><span class="headline">{{title}}</span>
          </v-card-title>
                <v-card-text>
                  <v-expansion-panels>
                      <v-expansion-panel v-for="(error, index) in errors" :key="'error_message_'+index">
                        <v-expansion-panel-header v-if="typeof error === 'object'">{{error.message}}</v-expansion-panel-header>
                        <v-expansion-panel-header v-else>{{error}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div v-if="typeof error === 'object'" v-html="error.extensions"></div>
                          <div v-else>{{error}}</div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
                <v-card-actions class="grey lighten-3 py-3 px-3">
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('input', false);value = false" rounded>Sluiten</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
    props:{
      title: String,
        value: Boolean,
        errors: Array
    }
}
</script>