<template>
    <div>
        <v-row>
            <v-col>
                <vc-date-picker
                    :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                    :popover="{ visibility: 'focus' }" :value="$moment(value.time).format('DD-MM-YYYY HH:mm')"
                    @input="val => timeChanged(val)" mode="dateTime" is24hr
                    :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                    <template v-slot="{ inputValue, inputEvents, updateValue }">
                        <v-text-field class="mb-4" hide-details label="Van" prepend-icon="mdi-calendar" :value="inputValue"
                            @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                            @change="updateValue($event, { formatInput: true, hidePopover: false, })" v-on="inputEvents"
                            dense outlined clearable :disabled="isReadonly"></v-text-field>
                    </template>
                </vc-date-picker>
                <vc-date-picker
                    :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                    :popover="{ visibility: 'focus' }"
                    :value="value.duration ? $moment((parseInt($moment(value.time).unix()) + value.duration) * 1000).format('DD-MM-YYYY HH:mm') : null"
                    @input="val => durationChanged(val)" mode="dateTime" is24hr
                    :model-config="{ type: 'string', mask: 'DD-MM-YYYY HH:mm' }">
                    <template v-slot="{ inputValue, inputEvents, updateValue }">
                        <v-text-field class="mb-4" hide-details label="Tot" prepend-icon="mdi-calendar" :value="inputValue"
                            @input="updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                            @change="updateValue($event, { formatInput: true, hidePopover: false, })" v-on="inputEvents"
                            dense outlined clearable :disabled="isReadonly"></v-text-field>
                    </template>
                </vc-date-picker>
                <div class="d-flex align-center mb-4">
                    <v-avatar class="mr-2 ml-1" size="21" v-if="value.id && value.severity" :color="Event.getSeverity(value.severity).color"></v-avatar>
                    <v-text-field hide-details outlined dense v-if="value.id" label="Waarde" :value="valueItems && valueItems[value.value]?valueItems[value.value].name:value.value" disabled :suffix="unitFields?unitFields.Symbol:null"></v-text-field>                    
                </div>
                <eod-list-item-autocomplete
                    v-if="$eod.getOrganizationSetting('events.categories.listId') && $eod.getOrganizationSetting('events.categories.listId').value"
                    class="mb-4" label="Categorie" :listId="$eod.getOrganizationSetting('events.categories.listId').value"
                    return-object v-model="value.category" dense outlined :disabled="isReadonly"></eod-list-item-autocomplete>
                <div class="primary px-2 py-1 rounded white--text"><v-icon dark
                        left>mdi-comment-processing-outline</v-icon>Bericht*</div>
                <v-textarea outlined v-model="value.message" hide-details required class="mb-4" :disabled="isReadonly"></v-textarea>
                <v-alert color="error" dark dense text v-if="errors.message">{{ errors.message }}</v-alert>
            </v-col>
            <v-col cols="auto">
                <div class="grey lighten-3 px-4 py-4 text-caption rounded-lg mb-4"
                    :style="'width:' + (infoAdvanced ? 300 : 250) + 'px;'">
                    <div class="d-flex justify-content-between">
                        <span class="text-subtitle-1">Info</span>
                        <v-btn v-if="!infoAdvanced && !isReadonly" @click="addEventsDialogEditInfo" small icon><v-icon small
                                left>mdi-pencil-outline</v-icon></v-btn>
                    </div>
                    <template v-if="infoAdvanced">
                        <eod-item-treeview-selector v-model="companyProject" outlined label="Project*"
                            :tree="['companies', 'projects']"
                            :fields="[['id', 'name', 'description'], ['id', 'name', 'description']]"
                            :modules="settings.modules" @input="projectsChanged" :disabled="isReadonly"></eod-item-treeview-selector>
                        <v-alert color="error" dark dense text v-if="errors.projectId">{{ errors.projectId }}</v-alert>
                        <v-card outlined class="mt-2">
                            <v-card-text>
                                <div class="mt-4 mb-4">
                                    <eod-objects-treeview-input :disabled="isReadonly" label="Toestel" :value="value.product?value.product.id:null" @input="val => val?value.product={id:val}:null"></eod-objects-treeview-input>
                                </div>
                                <eod-user-selector class="mt-4" label="Databron" v-model="value.owner" return-object dense
                                    outlined :disabled="isReadonly">
                                </eod-user-selector>
                                <eod-user-selector class="mt-4" label="Gebruiker" v-model="value.responsibleUser"
                                    return-object dense outlined :disabled="isReadonly">
                                </eod-user-selector>
                                <eod-object-autocomplete class="mt-4" endpoint="measurementTypes" label="Type meetwaarde"
                                    :value="value.measurementType"
                                    @input="val => updateEventProperty('measurementType', val)" return-object dense
                                    outlined :disabled="isReadonly"></eod-object-autocomplete>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-else>
                        <div v-if="value.project">Project: <strong>{{ value.project.name
                        }}</strong></div>
                        <div v-if="value.company">Bedrijf: <strong>{{ value.company.name
                        }}</strong></div>
                        <div v-if="value.product">Toestel: <strong>{{ value.product.name
                        }}</strong></div>
                        <div v-if="value.owner">Databron: <strong>{{
                            value.owner.getFullName()
                        }}</strong></div>
                        <div v-if="value.responsibleUser && value.responsibleUser.getFullName">Gebruiker: <strong>{{
                            value.responsibleUser.getFullName()
                        }}</strong></div>
                        <div v-if="value.measurementType">Type meetwaarde: <strong>{{
                            value.measurementType.name
                        }}</strong></div>
                    </template>
                </div>

            </v-col>
        </v-row>
        <eod-comments :tags="{
            projectId: (value.project ? value.project.id : null),
            companyId: (value.company ? value.company.id : null),
            productId: (value.product ? value.product.id : null),
            userId: (value.owner ? value.owner.id : null),
        }" class="mb-5" dense image-field-name="storageObjects" image-objects v-model="value.comments"></eod-comments>
    </div>
</template>
<script>
import eodComments from './eod-comments.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import eodItemTreeviewSelector from './eod-item-treeview-selector.vue';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodUserSelector from './eod-user-selector.vue';
import Event from './../models/event';
import User from './../models/user';

export default {
    name: 'eod-event',
    components: {
        eodComments,
        eodListItemAutocomplete,
        eodItemTreeviewSelector,
        eodObjectAutocomplete,
        eodUserSelector,
        eodObjectsTreeviewInput,
    },
    props: {
        value: Object
    },
    data() {
        return {
            settings: require('./../classes/settings'),
            companyProject: [],
            infoAdvanced: false,
            errors: {},
            isReadonly: false,
            valueItems: null,
            unitFields: null,
            Event: Event
        }
    },
    mounted() {
        if (this.value.id) {
            this.loadEvent();
        }
    },
    methods: {
        validate() {
            const data = (new Event(this.value)).getSaveData();

            this.errors = {};

            if (!(data.message && data.message != '')) {
                this.errors.message = 'Bericht is verplicht!';
            }

            if (!data.projectId) {
                this.errors.projectId = 'Project is verplicht!';
                this.infoAdvanced = true;
            }

            if (!data.companyId) {
                this.errors.companyId = 'Bedrijf is verplicht!';
                this.infoAdvanced = true;
            }

            if (Object.keys(this.errors).length > 0) {
                return false;
            }

            return true;
        },
        getMeasurementTypeInfo(measurementType){
            this.valueItems = this.$eod.getMeasurementTypeItems(measurementType);
            this.unitFields = this.$eod.getMeasurementTypeUnitFields(measurementType);
        },
        loadEvent() {
            this.$eod.getById('event', this.value.id, ['id', 'message', 'value', 'time', 'duration', 'category{id name}', 'eventGroupUnique', 'project{id name company{id name}}', 'owner{id}', 'responsibleUser{id}', 'product{id name}', 'measurementType{id, name unit{id name fields{name description} list{fields{name}}} valueConfigList{id name description itemType fields{name description isActive} listItems{id name description low high threshold stepSize color fields{name description isActive}}} valueConfigListItems{id name description low high threshold stepSize color fields{name description isActive}}}', 'comments{content, createdAt, userId, storageObjects{id key name}, organizationId}', 'severity', 'status', 'userStatus', 'type', 'facility'])
                .then(async response => {
                    if (response.data.data.event) {
                        const event = response.data.data.event;

                        if(event.facility && event.facility != 'user'){
                            this.isReadonly = true;
                        }

                        this.companyProject = [];
                        if (event.project && event.project.company) {
                            this.companyProject.push(event.project.company.id);
                            this.companyProject.push(event.project);
                        } else {
                            this.infoAdvanced = true;
                        }

                        if (!event.userStatus) {
                            event.userStatus = 'unassigned';
                        }

                        if (event.owner && event.owner.id) {
                            event.owner = await this.$eod.getById('user', event.owner.id, ['id', 'firstName', 'lastName', 'username']).then(userResponse => {
                                if (userResponse.data.data.user) {
                                    return new User(userResponse.data.data.user);
                                }
                            });
                        } else {
                            event.owner = this.$eod.getUser();
                        }

                        if (event.responsibleUser && event.responsibleUser.id) {
                            event.responsibleUser = await this.$eod.getById('user', event.responsibleUser.id, ['id', 'firstName', 'lastName', 'username']).then(userResponse => {
                                if (userResponse.data.data.user) {
                                    return new User(userResponse.data.data.user);
                                }
                            });
                        }

                        this.getMeasurementTypeInfo(event.measurementType);

                        this.$emit('input', new Event(event));
                        this.$forceUpdate();
                    }
                });
        },
        addEventsDialogEditInfo() {
            this.infoAdvanced = true;
        },
        projectsChanged(val) {
            this.updateEventProperty('company', val[0]);
            this.updateEventProperty('project', val[1]);
        },
        timeChanged(val) {
            this.updateEventProperty('time', this.$moment(val, 'DD-MM-YYYY HH:mm').toISOString());
        },
        durationChanged(val) {

            if (val) {
                const start = this.$moment(this.value.time).unix();
                const end = this.$moment(val, 'DD-MM-YYYY HH:mm').unix();
                const duration = end - start;

                if (duration >= 0) {
                    this.updateEventProperty('duration', duration);
                }
            } else {
                this.updateEventProperty('duration', null);
            }

        },
        updateEventProperty(propertyName, val) {
            const value = this.value;
            value[propertyName] = val;
            this.$emit('input', value);

            this.$nextTick(this.validate);
        }
    }
}
</script>