import Template from './template';

export default class Export extends Template {

    constructor(item) {
        if (!item) {
            super({
                name: null,
                type: 'EXTERNAL_STORAGE',
                object: 'STORAGE_OBJECT',
                access: 'PUBLIC',
                isActive: true,
                content: {
                    type:'sftp',
                    port:22,
                }
            });
        }else{
            super(item);
        }
    }

    static TYPES = [{value:'sftp', text: 'SSH/SFTP'}];

    static getType(type){
        for (let i = 0; i < this.TYPES.length; i++) {
            const myType = this.TYPES[i];
            if(myType.value == type){
                return myType;
            }
        }

        return null;
    }
}