const { Product } = require('../../eodvuecomponents');

module.exports = {
    tabs:{
        ARTICLE: [
        {
              path: 'products.article',
              text: 'Artikelen',
              counter: (eod) => {
                  return eod.count('countProducts', {
                      where: [{
                          column: 'class',
                          operator: '=',
                          value: 'ARTICLE'
                      }],
                  }).then(response => {
                      if (response.data.data && response.data.data.countProducts) {
                          return response.data.data.countProducts.totalCount;
                      } else {
                          return 0;
                      }
                  });
              }
          },
          {
              path: 'products.article.types',
              text: 'Types',
          }
        ],
        TOOL: [
        {
              path: 'products.tool',
              text: 'Gereedschap',
              counter: (eod) => {
                  return eod.count('countProducts', {
                      where: [{
                          column: 'class',
                          operator: '=',
                          value: 'TOOL'
                      }],
                  }).then(response => {
                      if (response.data.data && response.data.data.countProducts) {
                          return response.data.data.countProducts.totalCount;
                      } else {
                          return 0;
                      }
                  });
              }
          },
          {
              path: 'products.tool.types',
              text: 'Types',
          }
        ],
        DEVICE: [
        {
              path: 'products.device',
              text: 'Toestellen',
              counter: (eod) => {
                  return eod.count('countProducts', {
                      where: [{
                          column: 'class',
                          operator: '=',
                          value: 'DEVICE'
                      }],
                  }).then(response => {
                      if (response.data.data && response.data.data.countProducts) {
                          return response.data.data.countProducts.totalCount;
                      } else {
                          return 0;
                      }
                  });
              }
          },
          {
              path: 'products.device.types',
              text: 'Types',
          }
        ]
      },
    routes: [
        {
            path: '/products',
            name: 'products',
            redirect: {
                name: 'products.device'
            }
        },
    {
        path: '/products/articles',
        name: 'products.article',
        meta: {
            class: 'ARTICLE',
            permissions:['products.articles.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/products/tools',
        name: 'products.tool',
        meta:  {
            class: 'TOOL',
            permissions:['products.tools.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/products/devices',
        name: 'products.device',
        meta:  {
            class: 'DEVICE',
            permissions:['products.devices.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/products/types/tools',
        name: 'products.tool.types',
        meta:{
            class: 'TOOL',
            permissions:['products.tools.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/products/types/devices',
        name: 'products.device.types',
        meta:{
            class: 'DEVICE',
            permissions:['products.devices.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/products/types/articles',
        name: 'products.article.types',
        meta:{
            class: 'ARTICLE',
            permissions:['products.articles.read']
        },
        component: () => import('./types/index.vue')
    },
     {
        path: '/products/types/:classname/detail/:id?',
        name: 'productType',
        component: () => import('./types/detail.vue')
    },
    {
        path: '/products/:classname',
        name: 'product',
        meta:{
            permissions:['products.articles.create', 'products.tools.create', 'products.devices.create','products.articles.update', 'products.tools.update', 'products.devices.update']
        },
        component: () => import('./views/index.vue')
    },
    ],
    search: Product.searchConfig,
    detail: {
        route: 'product',
        endpoint: 'product',
        routeParams($route){
            return {
                classname: $route.meta.class.toLowerCase()
            }
        },
        objectName: 'Product',
    },
    index: {
        route: 'products',
        endpoint: 'products',
        fields: ['id', 'name', 'statusCode{id name}', 'description', 'class', 'children{id}','productType{id name}', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('./components/columns/link.vue')
            },
            {
                text: 'Type',
                value: 'productType.name',
                sortable: false,
                component: () => import('./components/columns/link.vue')
            },
            {
                text: 'Status',
                value: 'statusCode.name',
                sortable: false,
                component: () => import('./components/columns/link.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
        tabs: [],
    },
    export: {
        endpoint: 'products',
        fields: ['id', 'name', 'description', 'code', 'warrantyEndDate', 'installedDate', 'lastMaintenanceDate', 'createdAt', 'updatedAt']
    },
    import: {
        endpoint: 'Products',
        objectName: 'Product',
    },
    itemObject: Product,
    types: {
        search: [{
                'icon': 'mdi-format-text',
                'label': 'Naam',
                'type': 'property',
                'filter': 'name'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Type',
                'type': 'property',
                'filter': 'type'
            },
        ],
        itemObject: require('@/eodvuecomponents/src/models/productType').default,
        index: {
            route: 'productTypes',
            endpoint: 'productTypes',
            fields: ['id', 'name', 'vendor', 'type', 'class'],
            headers: [
                {
                    text: 'Naam',
                    value: 'name',
                    component: () => import('./components/columns/link.vue')
                },
                {
                    text: 'Type',
                    value: 'type',
                    width: 250,
                    component: () => import('./components/columns/link.vue')
                },
                {
                    text: 'Verkoper',
                    value: 'vendor',
                    width: 250,
                    component: () => import('./components/columns/link.vue')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('./../default/columns/date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('./../default/columns/date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
        },
        detail: {
            route: 'productType',
            routeParams($route){
                return {
                    classname: $route.meta.class.toLowerCase()
                }
            },
            endpoint: 'productType',
            objectName: 'ProductType',
            fields: ['id', 'name', 'class', 'description', 'unit{id}', 'vendor','url', 'type', 'suppliers{id name}', 'measurementTypes{id name indicativeValue unit{id name}}'],
            tabs: []
        },
        export: {
            endpoint: 'productTypes',
            fields: ['id', 'name', 'description', 'vendor', 'type','url']
        },
        import: {
            endpoint: 'ProductTypes',
            objectName: 'ProductType',
        },
    }
}