<template>
    <div class="h-100 w-100">
        <v-chart @mouseup="params => $emit('mouseup', { params: params, data: params.value[2], event: params.event.event ? params.event.event : params.event })"  v-show="value.length > 0" @finished="finished" @datazoom="selectChanged" :group="groupName" :ref="id" :initOptions="graphOptions" :autoresize="true" :manualUpdate="true"/>
        <div v-if="!value || value.length == 0">Gelieve de grafiek in te stellen</div>
    </div>
</template>
<style lang="scss">
    .echarts {
        width: 100%;
        height: 100%;
    }
</style>
<script>
    import ECharts from './echarts/ECharts';
    import Event from './../../models/event';
    import _ from 'lodash';

    export default {
        name: 'eod-graph-line',
        components:{
            'v-chart': ECharts,
        },
        props:{
            id: String,
            value:Object|Array,
            template:Object|Array,
            xAxis: Object|Array,
            yAxis: Object|Array,
            group: String,
            options: {
                type: Object,
                default(){
                    return {}
                }
            },
            defaultSerie:Object
        },
        data() {
            return {
            graphOptions: {},
            defaultOptions:{
                    //renderer: 'svg',
                    toolbox:{
                        feature:{
                            dataZoom:{
                                show: true,
                                yAxisIndex: 'none',
                                icon:{
                                    zoom:'image://',
                                    back:'image://'
                                },
                                brushStyle:{
                                    color:"#000000",
                                    opacity: 0.3,
                                }
                            }
                        }
                    },
                    legend: {
                        show:false,
                        type: 'scroll',
                        pageButtonPosition: 'start'
                    },
                    grid:{
                        left:'5%',
                        top:10,
                        right:'5%',
                        bottom:10,
                        containLabel: true
                    },
                    xAxis: {
                        type: 'time',
                        id: 'timeline',
                    },
                    yAxis: {
                        type: 'value'
                    },
                    
                    series: [],
                }
            }
        },
        computed:{
            groupName(){
                if(this.group){
                    return this.group;
                }
                return 'eod-graph-line';
            }
        },
        methods: {
            selectChanged(params){
                this.$emit('selectChanged', this.$refs[this.id], params, this.value);
            },
            finished(){
                this.$emit('finished', this.id, this.$refs[this.id]);
            },
            resize(){
                this.$refs[this.id].resize();
            },
            update(){
                this.graphOptions = _.merge(this.defaultOptions, this.options);

                if(this.graphOptions.legend.show){
                    this.graphOptions.grid.top = 40;
                }

                 let series = [];
                    for (let i = 0; i < this.value.length; i++) {
                        const value = this.value[i];
                        if(value){

                            let serie = {
                                name: '',
                                data: [],
                                type: 'bar'
                            }

                            if(this.defaultSerie){
                                serie = {
                                    ...serie,
                                    ...this.defaultSerie
                                }
                            }

                            if(this.cell){
                                serie = {
                                    ...serie,
                                    ...this.cell.settings.queries[value.queryIndex].options?this.cell.settings.queries[value.queryIndex].options:{}
                                }
                            }
                            
                            const measurementTypeId = Object.keys(value.measurementTypes)[0];
                            const measurementType = value.measurementTypes[measurementTypeId];
                            serie.name = value.name;
                            
                            const fieldname = this.measurementTypeFieldName(value.queryIndex);
                            
                            for (let j = 0; j < value.data.length; j++) {
                                const data = value.data[j];

                                const compactSerie = {
                                    data: value.data[0]?[value.data[0]]:null,
                                    measurementTypes: value.measurementTypes
                                }
                                
                                if(data){
                                    serie.data.push({
                                        'value': [data.start, data[fieldname], data, measurementType.name, compactSerie]
                                    });
                                }
                            }

                            if(!(this.cell && this.cell.settings && this.cell.settings.hideEvents)){
                                let events = [];
                                for (let i = 0; i < value.events.length; i++) {
                                    const event = value.events[i];
                                    events.push({
                                        itemStyle: {
                                            color: Event.getSeverity(event.severity).color
                                        },
                                        /*itemStyle: {
                                            color: '#000000'
                                        },*/
                                        name: 'Event',
                                        value: [event.time, '', event, ''],
                                        xAxis: event.time,
                                        yAxis: event.value
                                    });
                                }

                                serie.markPoint = {
                                    symbolSize: 18,
                                    symbol: "circle",
                                    data: events
                                }
                            }
                            
                            series.push(serie);
                        }
                        
                    }

                    this.graphOptions.series = series;
                
                if(this.$refs[this.id]){
                    this.$refs[this.id].mergeOptions(this.graphOptions);
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                }
                
            },
            measurementTypeFieldName(index){
                if (this.template[index]) {
                            const content = this.template[index].content;
                            for (let i = 0; i < content.measurementTypes.length; i++) {
                                const aggMeasurementType = content.measurementTypes[i];
                                return aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id;
                            }
                        }
                return null;
            },
        }
    }
</script>