<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center w-100">
                <eod-text-field :info="info" outlined dense hide-details prepend-icon="mdi-palette" :label="label" :value="value" clearable
                    @blur="evt => colorChanged(evt.target.value)" v-bind="attrs" v-on="on"></eod-text-field>
                <v-avatar class="ml-2" size="20" :color="value"></v-avatar>
            </div>
        </template>
        <v-color-picker v-if="value" :value="value" @input="colorChanged" :dot-size="dotSize" :hide-mode-switch="hideModeSwitch"
            :mode="mode"></v-color-picker>
    </v-menu>
</template>
<script>
import eodTextField from './eod-text-field.vue';

export default {
    components:{
        eodTextField
    },
    props: {
        value: String,
        info: String,
        label: String,
        dotSize: String | Number,
        hideModeSwitch: Boolean,
        mode: String
    },
    data: () => ({
        menu: false
    }),
    watch:{
        menu(val){
            if(val && !this.value){
                this.$emit('input', '#FFFFFF');
            }
        }
    },
    methods: {
        colorChanged(value) {
            if (this.mode == 'hex' && value.length > 7) {
                value = value.substring(0, 7);
            }

            if (typeof value == 'object') {
                value = value[this.mode];
            }

            if(value == ''){
                value = null;
            }

            this.$emit('input', value);
        }
    }
}
</script>