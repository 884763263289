import axios from 'axios';
import eod from './eod';
import getEnv from './utils';

export default {
    get backend_url() {
        return location.protocol + '//' + getEnv('VUE_APP_SHARE_URL');
    },
    get api_url() {
        return this.backend_url + '/private/' + eod.getRealm();
    },
    setToken(token) {
        axios.defaults.headers.common = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    },
    upload(type, objectname, data) {
        // upload share url
        return axios.post(this.api_url + '/upload/' + type + '/' + objectname, data, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            decompress: false,
        });
    }
};