<template>
    <eod-events-table :show-expand="false" height="calc(100% - 60px)" ref="table" :headers="headers" :items="events"
        :expanded.sync="expanded" :options.sync="options" :server-items-length="-1" :loading="loading"
        @item-expanded="itemExpanded" :items-per-page="itemsPerPage">
        <template v-slot:footer.prepend>
            <v-col cols="auto" class="mr-auto">
                <v-btn elevation="2" color="secondary" small title="New" rounded @click="addNewEventDialog = true">
                    <v-icon size="20">mdi-bell-plus</v-icon>Nieuw event
                </v-btn>
                <eod-dialog width="800" v-model="addNewEventDialog" title="Event toevoegen" icon="mdi-plus"
                    iconColor="primary" okText="Toevoegen" @okAction="addEventAction">
                    <eod-event ref="eventForm" v-model="newEvent"></eod-event>
                </eod-dialog>
            </v-col>
        </template>
    </eod-events-table>
</template>
<script>
import eodEventsTable from './../eod-events-table.vue';
import eodDialog from '../eod-dialog.vue';
import eodEvent from '../eod-event.vue';
import User from './../../models/user';
import Event from './../../models/event';
import Project from '../../models/project';
import Product from '../../models/product';

export default {
    name: 'eod-graph-events',
    components: {
        eodEventsTable,
        eodDialog,
        eodEvent
    },
    props: {
        id: String,
        value: Object | Array,
        template: Object | Array,
        xAxis: Object | Array,
        yAxis: Object | Array,
        filters: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            expanded: [],
            events: [],
            itemsPerPage: 10,
            options: {
                sortBy: ['time'],
                sortDesc: [true]
            },
            loading: false,
            headers: [],
            tableHeaders: require('./../../classes/settings').eventTableHeaders,

            // New event dialog
            addNewEventDialog: false,
            newEvent: {
                time: (new Date()).toISOString(),
                duration: null, // 1 hour in seconds
                owner: this.$eod.getUser(),
                responsibleUser: this.$eod.getUser(),
                message: '',
                severity: 'info',
                status: 'open',
                userStatus: 'unassigned',
                type: 'new',
            },

            Event: Event,
        }
    },
    created() {
        // Show headers
        const showHeaders = ['duration', 'measurementType.name', 'value', 'message', 'severity', 'status', 'owner.name', 'product.name', 'project.name'];
        for (let i = 0; i < this.tableHeaders.length; i++) {
            const header = this.tableHeaders[i];
            if (showHeaders.includes(header.value)) {
                this.headers.push(header);
            }
        }

        const defaults = [
            { text: 'Tijd', value: 'time', groupable: false }
        ];

        this.headers = defaults.concat(this.headers);
    },
    methods: {
        addEventAction() {

            if (this.$refs.eventForm) {
                if (!this.$refs.eventForm.validate()) {
                    return false;
                }
            }

            const event = new Event(this.newEvent);
            event.time = (new Date()).toISOString();

            const data = event.getSaveData();
            this.$eod.save('Event', data).finally(() => {
                this.addNewEventDialog = false;
                setTimeout(() => {
                    this.$emit('reload');
                }, 1000);
            });
        },
        itemExpanded() { },
        async update() {
            if (this.filters) {
                const resolvers = {
                    project_id: {
                        class: Project,
                        property: 'project',
                        endpoint: 'project',
                        fields: ['id', 'name', 'company{id name}']
                    },
                    owner_id: {
                        class: User,
                        property: 'owner',
                        endpoint: 'resolveUser',
                        fields: ['id', 'firstName', 'lastName', 'username']
                    },
                    product_id: {
                        class: Product,
                        property: 'product',
                        endpoint: 'product',
                        fields: ['id', 'name']
                    }
                };

                for (let i = 0; i < this.filters.length; i++) {
                    const filter = this.filters[i];
                    if (filter.value) {
                        for (const filterKey in resolvers) {
                            const resolver = resolvers[filterKey];
                            if (filterKey == filter.key) {
                                this.newEvent[resolver.property] = await this.$eod.getById(resolver.endpoint, filter.value, resolver.fields).then(response => {
                                    return new resolver.class(response.data.data[resolver.endpoint]);
                                });
                            }
                        }
                    }
                }

                if (this.newEvent.project && this.newEvent.project.company) {
                    this.newEvent.company = this.newEvent.project.company;
                }
            }

            if (this.value && this.value[0] && this.value[0].data) {
                // Resolve fields
                const items = this.value[0].data;

                const resolve = {
                    project_id: {
                        endpoint: 'projects',
                        property: 'project',
                        ids: [],
                        itemsById: {}
                    },
                    measurement_type_id: {
                        endpoint: 'measurementTypes',
                        property: 'measurementType',
                        ids: [],
                        itemsById: {}
                    },
                };

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    for (const property in resolve) {
                        if (item[property] && !resolve[property].ids.includes(item[property])) {
                            resolve[property].ids.push(item[property]);
                        }
                    }

                    items[i].time = this.$moment.utc(item.time).tz('Europe/Brussels').toISOString();

                    // Fix resolved items property
                    items[i].owner = items[i].owner_id_resolved;
                    if (items[i].owner) {
                        items[i].owner.name = (new User(items[i].owner).getFullName());
                    }

                    items[i].product = items[i].product_id_resolved;
                }

                for (const property in resolve) {
                    const resolver = resolve[property];
                    if (resolver.ids.length > 0) {
                        resolve[property].itemsById = await this.$eod.get(resolver.endpoint, ['id', 'name'], {
                            whereIn: {
                                column: 'id',
                                array: resolver.ids
                            }
                        }).then(response => {
                            if (response.data.data[resolver.endpoint].edges) {
                                const itemsById = {};
                                const items = response.data.data[resolver.endpoint].edges;
                                for (let i = 0; i < items.length; i++) {
                                    const item = items[i];
                                    itemsById[item.id] = item;
                                }

                                return itemsById;
                            }

                            return {};
                        });
                    }

                }

                for (let i = 0; i < items.length; i++) {
                    for (const property in resolve) {
                        if (items[i][property]) {
                            items[i][resolve[property].property] = resolve[property].itemsById[items[i][property]];
                        } else {
                            items[i][resolve[property].property] = null;
                        }

                    }
                }

                this.events = items;
            }
        }
    }
}
</script>