<template>
    <div v-if="item">
        <v-row>
            <v-col cols="auto">
                <ul>
                    <li v-if="item.type && item.type.trim() != ''">Type: <strong>{{item.type}}</strong></li>
                    <li v-if="item.vendor && item.vendor.trim() != ''">Verkoper: <strong>{{item.vendor}}</strong></li>
                    <li v-if="item.description && item.description.trim() != ''">Omschrijving: <strong>{{item.description}}</strong></li>
                </ul>
            </v-col>
            <v-col>
                <ul v-if="item.details">
                    <li v-for="detail in filledDetails" :key="property">{{detail.property}}: <strong>{{detail.value}}</strong></li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'eod-product-type-preview',
    props: {
        value: String
    },
    data: () => ({
        item: null
    }),
    created(){
        this.fetchItem();
    },
    watch: {
        value(){
            this.fetchItem();
        }
    },
    computed: {
        filledDetails(){
            const details = [];
            for (const key in this.item.details) {
                const value = this.item.details[key];
                if(value && value.trim() != ''){
                    details.push({
                        property: key,
                        value: value.trim()
                    });
                }
                
            }
            return details;
        }
    },
    methods:{
        fetchItem(){
            this.$eod.getById('productType', this.value, ['id','name', 'description','details', 'vendor', 'type'])
                .then(response => {
                    if(response && response.data.data && response.data.data.productType){
                        this.item = response.data.data.productType;
                    }
                });
        }
    }
}
</script>