const { Export } = require('@/eodvuecomponents');
const tabs = require('./../tabs').default;

module.exports = {
    routes: [
        {
            path: '/data/exports',
            name: 'exports',
            meta:{
                permissions:['exports.read']
            },
            component: () => import('./views/index.vue')
        },
        {
            path: '/data/exports/detail/:id?',
            name: 'export',
            meta:{
                permissions:['exports.create', 'exports.update']
            },
            component: () => import('./views/detail.vue')
        },
    ],
    itemObject:Export,
    search: Export.searchConfig,
    detail:{
        route:'export',
        endpoint: 'template',
        fields: ['id', 'name', 'content', 'type', 'createdAt', 'updatedAt'],
        objectName: 'Template'
    },
    index: {
        endpoint: 'templates',
        fields: ['id', 'name', 'isActive', 'content', 'type', 'createdAt', 'updatedAt'],
        tabs:tabs,
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue'),
            },
            {
                text: 'Laatst gelopen op',
                value: 'content.lastRunAt',
                sortable:false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: false,
                    prepend: 'Laatst gelopen op'
                }
            },
            {
                text: 'Actief',
                value: 'isActive',
                width:100,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-toggle.vue')
            },
            {
                text: '',
                width: 30,
                value: 'createdAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 30,
                value: 'updatedAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
    }
}