<template>
<v-select
          :value="value"
          @change="val => $emit('input', val)"
          :items="items"
          small-chips
          :disabled="readonly"
          outlined
          item-value="threshold"
          :item-text="getCompanyName"
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              @click:close="data.parent.selectItem(data.item)"
            >
              <v-avatar
                color="primary"
                class="white--text"
                left
                v-text="data.item.threshold"
              ></v-avatar>
              {{ getCompanyName(data.item) }}
            </v-chip>
          </template>
        </v-select>
</template>
<script>
export default {
    name: 'eod-measurement-list',
    props: {
        readonly: Boolean,
        value: Number,
        measurementType: Object,
    },
    data: () => ({
        items: [],
        companies: []
    }),
    mounted(){
        if (this.measurementType.valueConfigListItems && this.measurementType.valueConfigListItems.length > 0) {
          // Use valueConfigListItems
          this.items = this.measurementType.valueConfigListItems;
        }else if (this.measurementType.valueConfigList.listItems && this.measurementType.valueConfigList.listItems.length > 0){
          // Use valueConfigList.listItems
          this.items = this.measurementType.valueConfigList.listItems;
        }

        this.setCompanies(this.items);
    },
    methods: {
      setCompanies(items){
        let ids = [];
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            ids.push(item.name);
          }
          this.$eod.get('resolveCompanies', ['id', 'name', 'description'], {
            whereIn:{
              column: 'id',
              array: ids
            }
          }).then(result => {
            this.companies = result.data.data.resolveCompanies.edges;
          });
      },
      getCompanyName(item){
        let id = item.name;
        for (let i = 0; i < this.companies.length; i++) {
          const company = this.companies[i];
          if (id == company.id) {
            return company.name;
          }
        }

        return '';
      }
    }
}
</script>