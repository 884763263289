<template>
    <div v-if="value">
        <v-card flat class="text-subtitle-1" v-if="showTitle">{{value.name}}</v-card>
        <v-card outlined class="mb-2" v-if="showGeneralInfo">
            <v-expansion-panels flat>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Info
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card flat class="mb-2"><span class="font-weight-light">Workflow</span><br><strong>{{value.taskType.name}}</strong></v-card>
                        <v-card flat class="mb-2"><span class="font-weight-light">Bedrijf</span><br><strong>{{value.company.name}}</strong></v-card>
                        <v-card flat class="mb-2"><span class="font-weight-light">Project</span><br><strong>{{value.project.name}}</strong></v-card>
                        <v-card flat class="mb-2"><span class="font-weight-light">Dienst</span><br><strong>{{value.service.name}}</strong></v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        
        <v-card outlined v-if="value.description && value.description != ''">
        <v-expansion-panels flat>
            <v-expansion-panel>
            <v-expansion-panel-header>
                Omschrijving
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-html="value.description"></div>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-card>

        <v-card outlined v-if="value.storageObjects && value.storageObjects.length > 0" class="mb-2">
            <v-expansion-panels flat>
                <v-expansion-panel>
                <v-expansion-panel-header>
                    <span>Media<v-avatar size="24" color="secondary" class="white--text font-weight-bold ml-2 text--subtitle-1">{{value.storageObjects.length}}</v-avatar></span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col sm="6" md="4" lg="3" xl="2" v-for="(image, index) in value.storageObjects" :key="index">
                            <v-card @click="showLightbox(index)">
                                <v-img :src="$eod.getImageUrl(image.key)"></v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <div class="d-flex align-items-center justify-content-end"  v-if="showGeneralInfo">
        <v-btn @click="showProductsDialog = true" class="mr-3" v-if="(value.products && value.products.length > 0) || (value.productTypes && value.productTypes.length > 0)" icon><v-icon>mdi-package-variant</v-icon>{{value.products.length+value.productTypes.length}}</v-btn>
        <eod-products-dialog v-model="showProductsDialog" :products="value.products" :productTypes="value.productTypes"></eod-products-dialog>

        <v-btn @click="showContactsDialog = true" class="mr-3" v-if="value.project.users && value.project.users.length > 0" icon><v-icon>mdi-account-outline</v-icon>{{value.project.users.length}}</v-btn>
        <eod-contacts-dialog v-model="showContactsDialog" :contacts="value.project.users"></eod-contacts-dialog>

        <v-btn @click="showLocationsDialog = true" class="mr-3" v-if="value.project.locations && value.project.locations.length > 0" icon :color="showLocationAlert()?'accent':''"><v-icon>mdi-map-marker</v-icon>{{value.project.locations.length}}</v-btn>
        <eod-locations-dialog v-model="showLocationsDialog" :locations="value.project.locations"></eod-locations-dialog>
        </div>
        
    </div>
</template>
<script>
import Lightbox from './../classes/lightbox';
import eodContactsDialog from './eod-contacts-dialog';
import eodLocationsDialog from './eod-locations-dialog';
import eodProductsDialog from './eod-products-dialog';

export default {
    name:"eod-task-info",
    components:{
        eodContactsDialog,
        eodLocationsDialog,
        eodProductsDialog
    },
    props: {
        value: Object,
        showTitle:{
            type: Boolean,
            default: false
        },
        showGeneralInfo:{
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showContactsDialog: false,
        showLocationsDialog: false,
        showProductsDialog: false,
    }),
    methods: {
        showLightbox(index){
            var images = [];
            for (let i = 0; i < this.value.storageObjects.length; i++) {
                const el = this.value.storageObjects[i];
                images.push(this.$eod.getImageUrl(el.key));
            }

            let options = {
                title: this.value.name,
            };

            var lightbox = new Lightbox(images, index, options);

            lightbox.show();
        },
        showLocationAlert(){
                for (let i = 0; i < this.value.project.locations.length; i++) {
                    const location = this.value.project.locations[i];
                    if(location.notes && location.notes != ''){
                        return true;
                    }
                }

                return false;
            }
    }
}
</script>