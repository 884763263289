<template>
    <eod-dialog :inset="false" hide-actions icon="mdi-image-multiple" okText="Sluiten" iconColor="primary" title="Media" :value="value" @input="val => $emit('input', val)">
        <template v-slot:headerActions>
            <v-btn @click="$emit('input', false)" icon><v-icon>mdi-close</v-icon></v-btn>
        </template>
        <eod-storage-objects-carousel :value="media"></eod-storage-objects-carousel>
    </eod-dialog>
</template>
<script>
import eodDialog from './eod-dialog.vue';
import eodStorageObjectsCarousel from './eod-storage-objects-carousel.vue';
export default {
    name: 'eod-media-dialog',
    components:{
        eodStorageObjectsCarousel,
        eodDialog
    },
    props:{
        value: Boolean,
        media: Array,
    }
}
</script>