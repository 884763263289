<template>
    <div>    
    <v-row>
              <v-col cols="12" sm="8" lg="9">
                  <v-card class="mb-4">
        <v-card-text>
                <v-row>
                    <v-col>
                        <eod-text-field
                            v-model="value.name"
                            :rules="[val => rules.required(val, 'Naam')]"
                            label="Naam"
                            required
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field
                            v-model="value.description"
                            label="Omschrijving"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-autocomplete
                        :items="value.getCompanyTypes?value.getCompanyTypes():[]"
                        label="Type"
                        v-model="value.type"
                        item-text="text"
                        item-value="value"
                        :rules="[val => rules.required(val, 'Type')]"
                        hide-details
                        outlined
                        ></eod-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-autocomplete :rules="serviceRule?[val => rules.required(val, 'Standaard dienst')]:[]" item-value="id" item-text="name" outlined hide-details label="Standaard dienst*" :items="services" v-model="value.defaultServiceId">
                            <template v-slot:append-item>
                                <v-divider class="mb-2"></v-divider>
                                <v-list-item id="add_service" @click.stop="showCreateService">
                                    <v-list-item-icon>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Nieuwe dienst aanmaken</v-list-item-title>
                                        <v-list-item-subtitle>Maak een dienst aan en voeg deze toe aan het bedrijf</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </eod-autocomplete>
                    </v-col>
                </v-row>
        </v-card-text>
    </v-card>
    <v-card class="mb-4">
        <v-card-text>
                <v-row>
                    <v-col>
                        <eod-text-field
                            v-model="value.nationalIdentificationNumber"
                            label="Nationaal identificatienummer"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field
                            v-model="value.vatNumber"
                            label="BTW-nummer"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    </v-row>
                <v-row>
                    <v-col>
                        <eod-text-field
                            v-model="value.iban"
                            label="IBAN"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field
                            v-model="value.bic"
                            label="BIC"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-text-field
                            v-model="value.website"
                            label="Website"
                            hide-details
                            outlined
                        ></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-listitem-autocomplete dense outlined v-if="$eod.getOrganizationSetting('companies.divisions.listId')" v-model="value.division" label="Divisie" returnObject nullable :listId="$eod.getOrganizationSetting('companies.divisions.listId').value"></eod-listitem-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-listitem-autocomplete dense outlined v-if="$eod.getOrganizationSetting('companies.departments.listId')" v-model="value.department" label="Departement" returnObject nullable :listId="$eod.getOrganizationSetting('companies.departments.listId').value"></eod-listitem-autocomplete>
                    </v-col>
                </v-row>
        </v-card-text>
    </v-card>
    <v-card>
                      <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                                <eod-color-picker outlined v-if="value.configuration && $helper.getConfigurationSetting(value.configuration.settings, 'color_primary')" v-model="$helper.getConfigurationSetting(value.configuration.settings, 'color_primary').value" label="Primair kleur" dot-size="15" hide-mode-switch mode="hexa"></eod-color-picker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <eod-color-picker outlined v-if="value.configuration && $helper.getConfigurationSetting(value.configuration.settings, 'color_secundary')" v-model="$helper.getConfigurationSetting(value.configuration.settings, 'color_secundary').value" label="Secundair kleur" dot-size="15" hide-mode-switch mode="hexa"></eod-color-picker>
                            </v-col>
                          </v-row>
                      </v-card-text>
                      <v-card-actions></v-card-actions>
                  </v-card>
              </v-col>
              <v-col cols="12" sm="4" lg="3">
                  <v-card class="mb-4">
                      <v-card-text>
                            <label class="text-subtitle-1">Logo</label>
                            <eod-storage-library v-if="value && value.id" @added="logoAdded" :storageObjects="logo" :tags="{companyId:value.id}" :filterable="false" :searchable="false" :colAttrs="{cols:12}"></eod-storage-library>
                            <p v-else>Gelieve op te slaan om media toe te voegen.</p>
                      </v-card-text>
                      <v-card-actions></v-card-actions>
                  </v-card>
                  <v-card class="mb-4" v-if="sharepoint">
                      <v-card-text>
                            <label class="text-subtitle-1">Koppelingen</label>
                            <v-row class="mt-2">
                                <v-col cols="4" lg="2">
                                    <a target="_blank" :href="sharepoint"><v-img :src="require('@/assets/img/integrations/sharepoint.svg')" contain/></a>
                                </v-col>
                            </v-row>
                      </v-card-text>
                      <v-card-actions></v-card-actions>
                  </v-card>
                  <v-card class="mb-4">
                      <v-card-text>
                          <label class="text-subtitle-1">Tags</label>
                          <eod-tags deletable addable v-model="value.tags"></eod-tags>
                      </v-card-text>
                  </v-card>
                  <v-card>
                      <v-card-text>
                          <v-textarea rows="6" label="Opmerkingen" outlined hide-details v-model="value.remarks"></v-textarea>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
        </div>
</template>
<script>
import eodListitemAutocomplete from './eod-list-item-autocomplete.vue';
import eodTags from './eod-tags.vue';
import eodColorPicker from './eod-color-picker.vue';
import eodStorageLibrary from './eod-storage-library.vue';

export default {
    name: 'eod-company-detail',
    components:{
        eodListitemAutocomplete,
        eodTags,
        eodColorPicker,
        eodStorageLibrary,
    },
    props:{
        value: {
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return {
            services: [],
            sharepoint: null,
            serviceRule: true,
            rules: {
                required: (value, fieldName) => !!value || (fieldName?fieldName:'Veld')+' is verplicht.',
            },
        }
    },
    computed: {
      logo(){
        if(this.value.configuration){
            let setting = this.$helper.getConfigurationSetting(this.value.configuration.settings, 'logo');
            if(setting != null && setting.value && setting.value != ''){
                return [setting.value];
            }
        }
          
          
          return [];
      }
    },
    mounted(){
        let sharepointURLSetting = this.$eod.getOrganizationSetting('share.sharepoint.companies.url');
            if(sharepointURLSetting && sharepointURLSetting.value){
                this.sharepoint = this.$helper.replaceTags(sharepointURLSetting.value, {
                    company: this.value
                });
            }

          if(this.value.id){
            this.$eod.get('services', ['id', 'name'], {
                where: [
                    {
                        'column': 'companyId',
                        'operator': '=',
                        'value': this.value.id
                    }
                ]
            }).then(response => {
                if(response.data.data.services){
                    this.services = response.data.data.services.edges;
                }
            });
          }
    },
    methods:{
        serviceCreated(item){
            this.$eod.getById('service', item.id, ['id', 'name']).then(response => {
                this.services.push(response.data.data.service);
            });

            this.serviceRule = true;

            let val = this.value;
            val.defaultServiceId = item.id;
            this.$emit('input', val);
        },
        showCreateService(){
            if(!this.value.id){
                // Temp disable check default service check
                this.serviceRule = false;
            }
            
            this.$nextTick(() => {
                this.$emit('createService');
            });
            
        },
        logoAdded(object){
            this.$helper.getConfigurationSetting(this.value.configuration.settings, 'logo').value = object.id;
        },
    }
}
</script>