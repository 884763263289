<template>
    <div class="eod-contacts">
        <v-card v-if="!hideTitle" :flat="flat" :outlined="outlined" :class="dense?'':'mb-4'">
            <v-card-title class="primary white--text"><v-icon left dark>mdi-account-group-outline</v-icon>{{$t('Contacten')}}</v-card-title>
        </v-card>
            <template v-if="value">
                <v-card v-for="(contact, index) in contacts" :key="index" :class="dense?'mb-2':'mb-4'" :outlined="outlined">
                    <v-card-subtitle class="grey lighten-4 py-2 d-flex align-items-center">
                        <v-avatar color="primary lighten-2" size="30" class="white--text mr-2">{{contact.getInitials()}}</v-avatar>
                        {{contact.getFullName()}}
                        <v-spacer></v-spacer>
                        <v-tooltip bottom v-if="showEdit">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="$emit('showUserDetails', contact.id)"><v-icon>mdi-pencil-outline</v-icon></v-btn>
      </template>
      <span>Aanpassen</span>
    </v-tooltip>
                    </v-card-subtitle>
                    <v-list :dense="dense">
                        <v-list-item v-if="contact.attributes && contact.attributes.jobtitle && contact.attributes.jobtitle[0]">
                            <v-list-item-icon>
                                <v-btn small icon disabled><v-icon>mdi-badge-account-horizontal-outline</v-icon></v-btn>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{contact.attributes.jobtitle[0]}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="contact.email">
                            <v-list-item-icon>
                                <v-btn icon :href="'mailto:'+contact.email" small><v-icon color="primary">mdi-email-outline</v-icon></v-btn>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{contact.email}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="contact.attributes && contact.attributes.extra_email && contact.attributes.extra_email[0]" >
                            <v-list-item-icon>
                                <v-btn small icon :href="'mailto:'+contact.attributes.extra_email[0]"><v-icon color="primary">mdi-email-outline</v-icon></v-btn>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{contact.attributes.extra_email[0]}}</v-list-item-title>
                            </v-list-item-content>
                            
                        </v-list-item>
                        <v-list-item v-if="contact.attributes && contact.attributes.phone_number && contact.attributes.phone_number[0]">
                            <v-list-item-icon>
                                <v-btn small icon :href="'tel:'+contact.attributes.phone_number[0]"><v-icon color="primary">mdi-phone</v-icon></v-btn>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{contact.attributes.phone_number[0]}}</v-list-item-title>
                            </v-list-item-content>
                            
                        </v-list-item>
                        <v-list-item v-if="contact.attributes && contact.attributes.extra_phone && contact.attributes.extra_phone[0]">
                            <v-list-item-icon>
                                <v-btn small icon :href="'tel:'+contact.attributes.extra_phone[0]"><v-icon color="primary">mdi-phone</v-icon></v-btn>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{contact.attributes.extra_phone[0]}}</v-list-item-title>
                            </v-list-item-content>
                            
                        </v-list-item>
                    </v-list>
                </v-card>
            </template>
            <template v-else>
                <!-- Extra div needed for padding -->
                <v-card>
                    <v-card-text>
                        Geen contacten gevonden
                    </v-card-text>
                </v-card>
            </template>
    </div>
</template>
<script>

import User from './../models/user';

export default {
    name:'eod-contacts',
    props: {
        value: Array,
        flat: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        showEdit: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        contacts(){
            let contacts = [];
            if(this.value){
                for (let i = 0; i < this.value.length; i++) {
                    const val = this.value[i];
                    contacts.push(new User(val));
                }
            }

            return contacts;
        }
    },
}
</script>