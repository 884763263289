<template>
    <v-menu
      :close-on-content-click="false"
      :offset-x='isOffsetX'
      :offset-y='isOffsetY'
      :open-on-hover='isOpenOnHover'
      :transition='transition'
      :value="openMenu"
      v-model="menuOpened"
    >
    <template
        v-slot:activator="{ on, attrs}"
      >
    <slot name="activator" v-bind:on="on" v-bind:attrs="attrs">
        <v-list-item
          class='d-flex justify-space-between'
          v-if='isSubMenu'
          v-on="on"
        >
        <v-list-item-action v-if="icon">
            <v-icon small>
            mdi-{{icon}}
          </v-icon>
        </v-list-item-action>
        <v-list-item-title>
          {{ name }}
        </v-list-item-title>
          <v-list-item-action>
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-list-item-action>
        </v-list-item>
        <v-btn
          :color='color'
          @click="openMenu=true"
          text
          v-else
          v-on="on"
        >
          {{ name }}
        </v-btn>
      
    </slot>
</template>
      
      <v-list dense>
        <template
          v-for="(item, index) in menuItems"
        >
          <v-divider
            :key='index'
            v-if='item.isDivider'
          />
          <eod-menu
            :is-offset-x=true
            :is-offset-y=false
            :is-open-on-hover=true
            :is-sub-menu=true
            :key='index'
            :menu-items='item.menu'
            :name='item.name'
            :icon="item.icon"
            @sub-menu-click='emitClickEvent'
            v-else-if='item.menu'
          />
          <v-list-item
            :key='index'
            @click='emitClickEvent(item)'
            v-else
          >
            <v-list-item-action v-if="item.icon">
              <v-icon small>mdi-{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
</template>
<script>
export default {
    name:'eod-menu',
    props: {
    name: String,
    icon: String,
    menuItems: Array,
    color: { type: String, default: "secondary" },
    isOffsetX: { type: Boolean, default: false },
    isOffsetY: { type: Boolean, default: true },
    isOpenOnHover: { type: Boolean, default: false },
    isSubMenu: { type: Boolean, default: false },
    transition: { type: String, default: "scale-transition" }
  },
  methods: {
    emitClickEvent(item) {
      this.$emit("sub-menu-click", item);
      this.openMenu = false;
      this.menuOpened = false;
    }
  },
  watch: {
    menuOpened: function () {
      //this.isOpenOnHover = !this.menuOpened;
    }
  },
  data: () => ({
    openMenu: false,
    //isOpenOnHover: false,
    menuOpened: false
  })
}
</script>