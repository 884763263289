<template>
    <eod-dialog :inset="false" hide-actions icon="mdi-map-marker-radius" okText="Sluiten" @okAction="$emit('input', false)" iconColor="primary" title="Locaties" :value="value" @input="val => $emit('input', val)">            
        <template v-slot:headerActions>
            <v-btn @click="$emit('input', false)" icon><v-icon>mdi-close</v-icon></v-btn>
        </template>
        <eod-locations-info :value="locations" hide-title dense></eod-locations-info>
    </eod-dialog>
</template>
<script>
import eodLocationsInfo from './eod-locations-info';
import eodDialog from './eod-dialog';

export default {
    name: 'eod-locations-dialog',
    components:{
        eodLocationsInfo,
        eodDialog,
    },
    props:{
        value: Boolean,
        locations: Array,
    }
}
</script>