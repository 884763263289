import moment from 'moment-timezone';
import Task from './task';
import User from './user';

export default class Response{
    static fields = {
        id: String,
    }

    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    if(key == 'job' || key == 'task'){
                        item[key] = new Task(item[key]);
                    } else if (key == 'user') {
                        item[key] = new User(item[key]);
                    }

                    this[key] = item[key];
                }
            }
        }
    }

    getCreatedAt(){
        return moment(this.createdAt);
    }

    getUpdatedAt(){
        return moment(this.updatedAt);
    }

    getReportedAt() {
        if(this.reportedAt){
            return moment(this.reportedAt);
        }
        return null;
    }

    getWorkflowTemplate(){
        if(this.job && this.job.templates){
            for (let i = 0; i < this.job.templates.length; i++) {
                const template = this.job.templates[i];
                if(template.type == 'FORM'){
                    return template;
                }
            }
        }

        return null;
    }

    getMeasurementTypeById(measurementTypeId){
        for (let i = 0; i < this.task.taskType.measurementTypes.length; i++) {
            const measurementType = this.task.taskType.measurementTypes[i];
            if (measurementType.id == measurementTypeId) {
                return measurementType;
            }
        }

        return null;
    }

    getComments(){
        if(!this.comments){
            return [];
        }

        let comments = this.comments;
        for (let i = 0; i < comments.length; i++) {
            if(typeof comments[i].images == 'string'){
                comments[i].images = JSON.parse(comments[i].images);
            }

            if(isNaN(comments[i].createdAt)){
                comments[i].createdAt = moment(comments[i].createdAt).unix()*1000;
            }
        }

        return comments;
    }

    getMetricByMeasurementTypeId(measurementTypeId) {
        for (let i = 0; i < this.metrics.length; i++) {
            const metric = this.metrics[i];
            if (metric.measurementTypeId == measurementTypeId) {
                return metric;
            }
        }
        
        return null;
    }

    addTimetracking(timetracking){
        if(!this.timetrackings){
            this.timetrackings = [];
        }

        this.timetrackings.push(timetracking);
    }

    getSendData(){
        let data = Object.assign({}, this);

        data.job = this.job.getSendData();

        return data;
    }

    async getSaveData() {
        let data = Object.assign({}, this);
        let eod = require('./../classes/eod').default;

        data.jobId = this.job?this.job.id:null;
        data.taskId = this.task?this.task.id:null;
        data.userId = this.user?this.user.id:null;

        delete data.job;
        delete data.task;
        delete data.user;
        delete data.createdAt;

        data.templates = [];
        if (this.templates) {
            for (let i = 0; i < this.templates.length; i++) {
                let id = await eod.save('Template', this.templates[i]).then(result => {
                    if (result.data.data.createTemplate) {
                        return result.data.data.createTemplate.id;
                    } else {
                        return result.data.data.updateTemplate.id;
                    }
                });

                data.templates.push({
                    id: id,
                    default: this.templates[i].default ? this.templates[i].default : false
                });
            }
        }

        return data;
    }
}