<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" ref="item" class="eod-scheduler__transport"
                :style="'left:' + (offsetX + (colWidth * columnIndex)) + 'px;width:' + (width * colWidth) + 'px;top:' + (offsetY + (rowIndex * rowHeight) + (value.heightIndex * height)) + 'px;height:' + (height - 1) + 'px;' + (value.visible != false ? '' : 'opacity:0.2')">
                <v-icon small>mdi-truck</v-icon>
            </div>
        </template>
        <span>
            <strong>Verplaatsing</strong><br>
            {{ getDateFormatted(value.dueAfterDate) }} - {{ getDateFormatted(value.dueBeforeDate) }}
        </span>
    </v-tooltip>

</template>
<style lang="scss">
.eod-scheduler__transport {
    position: absolute;
    opacity: 0.8;
    border-radius: 8px;
    font-size: .8rem;
    user-select: none;
    background-color: #f2f7fa;
    border: 1px solid #f2f7fa;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
        border-color: #5c7886;
    }
}
</style>
<script>
export default {
    name: 'eod-scheduler-break',
    props: {
        value: Object,
        offsetX: Number,
        width: Number,
        offsetY: Number,
        height: Number,
        rowIndex: Number,
        columnIndex: Number,
        colWidth: Number,
        rowHeight: Number,
    },
    methods: {
        getDateFormatted(date) {
            return this.$moment(date).format('HH:mm')
        },
    }
}
</script>