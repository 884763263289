import {eod} from '../..';
import _ from 'lodash';

export default class ProductType{
    static fields = {
        name: String,
    }
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }

        if (!this.organizationId) {
            this.organizationId = eod.getRealm();
        }
    }
    getSaveData() {
        let data = _.cloneDeep(this);

        if(data.hasOwnProperty('unit')){
            data.unitId = data.unit?data.unit.id:null;
        }
        delete data.unit;

        if(data.hasOwnProperty('measurementTypes')){
            for (let j = 0; j < data['measurementTypes'].length; j++) {
                data['measurementTypes'][j] = {
                    id: data['measurementTypes'][j].id,
                    indicativeValue: parseFloat(data['measurementTypes'][j].indicativeValue)
                }
            }
        }

        return data;
    }
}