<template>
    <div>
        <eod-list-item-autocomplete dense outlined v-model="selected" placeholder="Toevoegen..." :fields="['id name description color']" :exclude="value" :addable="addable" clearable return-object @change="addItem" v-if="$eod.getOrganizationSetting(settingKey) && $eod.getOrganizationSetting(settingKey).value && addable" :listId="$eod.getOrganizationSetting(settingKey).value"></eod-list-item-autocomplete>
        <v-chip-group column v-if="value && value.length > 0">
            <v-chip v-for="tag in clean" :key="tag.id" :color="tag.color?tag.color:null" :close="deletable" @click:close="deleteItem(tag)" :small="small">{{tag.name}}</v-chip>
        </v-chip-group>
    </div>
</template>
<script>
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';

export default {
    name:'eod-tags',
    components: {
        'eod-list-item-autocomplete': eodListItemAutocomplete
    },
    props:{
        value: Array,
        deletable:{
            type: Boolean,
            default: false
        },
        addable:{
            type: Boolean,
            default: false
        },
        small:{
            type: Boolean,
            default: false
        },
        settingKey:{
            type: String,
            default: 'tags.listId'
        }
    },
    data: () => ({
        selected: null
    }),
    computed:{
        clean(){
            let clean = [];
            if(this.value){
                for (let i = 0; i < this.value.length; i++) {
                    const val = this.value[i];
                    if(val && val.id){
                        clean.push(val);
                    }
                }                
            }
            return clean;
        }
    },
    methods:{
        deleteItem(item){
            if(!item){
                return false;
            }

            let items = this.clean;
            for (let i = 0; i < items.length; i++) {
                const tag = items[i];
                if(tag.id == item.id){
                    items.splice(i, 1);
                }
            }

            this.$emit('input', items);
        },
        addItem(item){
            if(!item){
                return false;
            }

            let items = this.clean;
            items.push(item);
            this.$emit('input', items);

            this.$nextTick(() => {
                this.selected = null;
            });
        }
    }
}
</script>