<template>
    <div class="eod-task-list" style="min-height:300px">
        <div class="py-2 d-flex">
            <eod-search class="flex-grow-1" :config="searchSettings" v-model="searchFilters" hide-saved template-type="JOB_FILTER" object-type="TASK" flat @update="searchUpdated" @templatesLoaded="searchTemplatesLoaded"></eod-search>
            <v-autocomplete class="flex-grow-0" v-model="selectedTemplate" :items="searchTemplates" item-text="name" dense outlined hide-details @change="templateSelected" return-object></v-autocomplete>
        </div>
        <v-divider></v-divider>
        <v-progress-linear indeterminate v-if="loading" color="primary"></v-progress-linear>
        <v-data-table :value="value" @input="val => $emit('input', val)" :headers="headers" :items="items" :options.sync="options" :show-select="multiple"
        :server-items-length="totalItems" :footer-props="{
            'items-per-page-options': [15, 50, 100],
            showFirstLastPage: true,
        }" :loading="loading" locale="nl-BE" item-key="id" dense fixed-header height="calc(100vh - 340px)">
        <template v-slot:item="{ item, isSelected, select }">
            <tr>
                <td v-if="multiple">
                    <v-tooltip top :disabled="item.isSelectable">
                        <template v-slot:activator="{on, attrs}">
                            <div v-on="on" v-bind="attrs">
                                <v-checkbox v-on="on" v-bind="attrs" :disabled="!item.isSelectable" @change="select" :input-value="isSelected"></v-checkbox>
                            </div>
                        </template>
                        <span>Zet de taak in 'Te doen' of 'In uitvoering' om deze in te plannen</span>
                    </v-tooltip>
                </td>
                <component v-for="(header, index) in headers"
                    :show-copy="false"
                    :key="index" :is="header.component" :item="item" :fieldname="header.value" @changed="getDataFromApi"
                    :extra="header.extra" :config="{itemObject: Task, detail: {objectName:'Task'}}" @click="itemClicked" :editAction="previewAction" :deleteAction="deleteAction"></component>
            </tr>
        </template>
    </v-data-table>
    <eod-workflow-dialog v-if="isWorkflowDialogVisible" v-model="isWorkflowDialogVisible" :workflow="activeWorkflow" @saved="workflowSaved"></eod-workflow-dialog>
    </div>
</template>
<script>
import settings from './../classes/settings';
import eodSchedulerSidebarItem from './eod-scheduler-sidebar-item.vue';
import eodSearch from './eod-search.vue';
import Task from './../models/task.js';
import eodWorkflowDialog from './eod-workflow-dialog.vue';

export default {
    name: 'eod-task-list',
    components:{
        eodSearch,
        eodSchedulerSidebarItem,
        eodWorkflowDialog,
    },
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        defaultFilters: {
            type: Array,
            default: null
        },
        multiple:{
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        items: [],
        selectedItems: [],
        totalItems: null,
        isWorkflowDialogVisible: false,
        options: {
            itemsPerPage:15,
            page:1
        },
        Task: Task,
        headers:[
            {
                text: 'Naam',
                value: 'parent.name',
                extra: {
                    chars: 60,
                    description: {
                        value: 'parent.description',
                    }
                },
                component: () => import('./columns/eod-column-task-list.vue')
            },
            {
                text: 'Van',
                value: 'dueAfterDate',
                sortable: true,
                component: () => import('./columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-calendar',
                    collapsed: false,
                }
            },
            {
                text: 'Tot',
                value: 'dueBeforeDate',
                sortable: true,
                component: () => import('./columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-calendar',
                    collapsed: false,
                }
            },
            {
                text: 'Gewenste planningsdatum',
                value: 'dueRequestDate',
                sortable: true,
                component: () => import('./columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-calendar',
                    collapsed: false,
                    format:'YYYY-MM-DD'
                }
            },
            {
                text: 'Status',
                value: 'statusCode',
                sortable: true,
                width: 160,
                component: () => import('./columns/eod-column-status-code.vue'),
                extra: {
                    readonly: false,
                    getStatusCodes(settings){
                        const codes = [];
                        const validCodes = ['on_hold', 'to_do', 'in_progress'];
                        for (let i = 0; i < settings.statusCodes.length; i++) {
                            const code = settings.statusCodes[i];
                            if(validCodes.includes(code.value)){
                                codes.push(code);
                            }
                        }
                        return codes; 
                    }
                }
            },
            {
                text: '',
                value: 'preview',
                sortable: false,
                width: 50,
                component: () => import('./columns/eod-column-actions.vue'),
            }],
        loading: false,
        jobFilters: [],
        searchTemplates: [],
        selectedTemplate: null,
        searchFilters: [],
        searchSettings: [{
                'icon': 'mdi-format-text',
                'label': 'Naam',
                'type': 'property',
                'filter': 'name'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Omschrijving',
                'type': 'property',
                'filter': 'description'
            },
            {
                'icon': 'mdi-clipboard-outline',
                'label': 'Workflow',
                'type': 'module',
                'settings': {
                    'module': 'taskTypes',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'taskTypeId'
            },
            {
                'icon': 'mdi-folder-open-outline',
                'label': 'Project',
                'type': 'module',
                'settings': {
                    'module': 'projects',
                    'fields': ['name', 'description'],
                    'title': '{name}',
                },

                'filter': 'projectId'
            },
            {
                'icon': 'mdi-file-sign',
                'label': 'Dienst',
                'type': 'module',
                'settings': {
                    'module': 'services',
                    'fields': ['name'],
                    'title': '{name}',
                },

                'filter': 'serviceId'
            },
            {
                'icon': 'mdi-office-building-outline',
                'label': 'Bedrijf',
                'type': 'module',
                'settings': {
                    'module': 'companies',
                    'fields': ['name', 'description'],
                    'title': '{name}',
                },

                'filter': 'companyId'
            },
            {
                'icon': 'mdi-tag-outline',
                'label': 'Status',
                'type': 'options',
                'settings': {
                    'options': () => {

                        let statusCodes = [];

                        for (let i = 0; i < settings.statusCodes.length; i++) {
                            const statusCode = settings.statusCodes[i];
                            if(statusCode.job_enabled){
                                statusCodes.push(statusCode);
                            }
                        }

                        return statusCodes;
                    }
                },
                'filter': 'statusCode'
            },
            {
                'icon': 'mdi-calendar',
                'label': 'Datum',
                'type': 'date-between',
                'filter': ['due_after_date', 'due_before_date']
            },
            {
                'icon': 'mdi-account-outline',
                'label': 'Verantwoordelijke',
                'type': 'relation',
                'settings': {
                    'module': 'searchUsers',
                    'fields': ['firstName', 'lastName', 'username'],
                    'title': '{firstName} {lastName} ({username})',
                    'options': {
                        enabled: true
                    }
                },
                'filter': 'responsibleUsers'
            },
            {
                'icon': 'mdi-account-group-outline',
                'label': 'Team',
                'type': 'module',
                'settings': {
                    'module': 'groups',
                    'fields': ['name'],
                    'title': '{name}',
                },

                'filter': 'responsibleGroupId'
            },
        ],
    }),
    created(){
        let options = localStorage.getItem('options-planning-tasks');
        if (options) {
            this.options = JSON.parse(options);
            if (this.options.itemsPerPage < 0) {
                this.options.itemsPerPage = 100;
            }
        }

        this.jobFilters = this.$eod.getDefaultJobFilters();
        const defaultJobFilterSetting = this.$eod.getOrganizationSetting('planning.defaultJobsFilter');
        if(defaultJobFilterSetting){
            for (let i = 0; i < this.jobFilters.length; i++) {
                const jobFilter = this.jobFilters[i];
                if(jobFilter.value == defaultJobFilterSetting.value){
                    this.selectedTemplate = jobFilter;
                    break;
                }
            }
        }else{
            this.selectedTemplate = this.jobFilters[0];
        }

        if(this.defaultFilters){
            this.searchFilters = this.defaultFilters;
        }else{
            let filters = localStorage.getItem('filters-planning-tasks');
            if (filters) {
                this.searchFilters = JSON.parse(filters);
            }else{
                this.searchFilters = Object.assign([], this.selectedTemplate.content.filter);
            }
        }
        

        this.getDataFromApi();
    },
    watch: {
        options: {
            handler() {
                localStorage.setItem('options-planning-tasks', JSON.stringify(this.options));
                this.getDataFromApi();
            },
            deep: true,
        },
    },
    methods:{
        itemClicked(item){
            if(item.isSelectable){
                this.$emit('click', item);
            }
            
        },
        templateSelected(item){
            this.searchFilters = Object.assign([], item.content.filter);
            localStorage.setItem('filters-planning-tasks', JSON.stringify(this.searchFilters));
            this.getDataFromApi();
        },
        getDataFromApi(){
            this.loading = true;

            let variables = this.$helper.filtersToVariables(this.searchFilters);

            if (this.options.sortBy && this.options.sortBy[0]) {
                variables.orderBy = {
                    column: this.options.sortBy[0],
                    type: this.options.sortDesc[0] ? 'desc' : 'asc'
                }
            } else {
                variables.orderBy = {
                    column: 'createdAt',
                    type: 'desc'
                }
            }

            if(!variables.where){
                variables.where = [];
            }
            if(!variables.whereIn){
                variables.whereIn = [];
            }

            variables.where.push({
                column: 'category',
                operator: '=',
                value: 'JOB'
            });

            variables.where.push({
                column: 'statusCode',
                operator: '!=',
                value: 'done'
            });

            variables.whereIn.push({
                column: 'stepType',
                array: ['PLAN'],
            });

            variables.where.push({
                column: 'isJobActive',
                operator: '=',
                value: '1'
            });

            if (this.options.page) {
                variables.offset = this.options.page - 1;
            }
            if (this.options.itemsPerPage && this.options.itemsPerPage >= 0) {
                variables.limit = this.options.itemsPerPage;
            }

            this.$eod.get('tasks', ['id', 'name', 'estimatedDuration', 'dueRequestDate', 'dueBeforeDate', 'isJobActive', 'stepType', 'route{id}', 'statusCode','dueBeforeDate', 'dueAfterDate', 'project{id name}', 'company{id name}', 'parent{id description dueRequestDate name company{id name} service{id name} project{id name} taskType{id name configuration{settings{key value}}}}'], variables)
            .then(response => {
                if(response.data.data){
                    let items = response.data.data.tasks.edges;
                    for (let i = 0; i < items.length; i++) {
                        items[i].isSelectable = (items[i].statusCode != 'on_hold');
                        items[i] = new Task(items[i]);
                    }
                    this.items = items;
                    this.totalItems = response.data.data.tasks.totalCount;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        searchTemplatesLoaded(templates){
            this.searchTemplates = templates;
            for (let i = this.jobFilters.length-1; i >= 0; i--) {
                const filter = this.jobFilters[i];
                this.searchTemplates.unshift(filter);
            }

            if(this.defaultFilters){
                this.setCustomFilter();
            }
        },
        previewAction(item){
            this.activeWorkflow = item.parent;
            this.isWorkflowDialogVisible = true;
        },
        deleteAction(item, callback){
            this.loading = true;
            callback();
            this.$eod.delete('Task', item.parent.id).then(() => {
                this.getDataFromApi();
            }).finally(() => {
                this.loading = false;
            });
        },
        workflowSaved(){
            this.isWorkflowDialogVisible = false;
            this.getDataFromApi();
        },
        searchUpdated(){
            for (let i = 0; i < this.searchTemplates.length; i++) {
                const template = this.searchTemplates[i];
                if(template.value == 'custom'){
                    this.searchTemplates.splice(i, 1);
                }
            }

            localStorage.setItem('filters-planning-tasks', JSON.stringify(this.searchFilters));

            this.getDataFromApi();

            if(this.searchFilters && this.searchFilters.length > 0){
                this.setCustomFilter();
            }else if(!this.searchFilters || (this.searchFilters && this.searchFilters.length == 0)){
                this.selectedTemplate = {
                    value: null
                }
            }
        },
        setCustomFilter(){
            const custom_filter = {
                value:'custom',
                name:'Aangepaste filter',
                content: {
                    filter: this.searchFilters
                }
            };
            this.searchTemplates.unshift(custom_filter);
            this.selectedTemplate = custom_filter;
        }
    }
}
</script>