export default class Depot {
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getSaveData() {
        let data = Object.assign({}, this);

        if (data.lat) {
            data.lat = parseFloat(data.lat);
        }
        
        if (data.lon) {
            data.lon = parseFloat(data.lon);
        }

        return data;
    }
}
