<template>
    <div>
        <v-carousel v-if="items && items.length > 0" show-arrows-on-hover
            hide-delimiters :show-arrows="items.length > 1"
            class="white" style="overflow:hidden;">
            <v-carousel-item v-for="storageObject in items" :key="storageObject.id" :src="$eod.getImageUrl(storageObject.key)" contain>
            </v-carousel-item>
        </v-carousel>
        <v-alert color="grey" text dense v-else>Geen resultaten gevonden.</v-alert>
    </div>
</template>
<script>
export default{
    name: 'eod-storage-objects-carousel',
    props:{
        filters: Array,
        value: Array
    },
    data: () => ({
        loading: false,
        items: []
    }),
    created(){
        if(this.value){
            this.items = this.value;
        }else{
            this.getDataFromApi();
        }
    },
    methods:{
        getDataFromApi(){

            this.loading = true;

            let variables = {
                offset: 0,
                limit: 10,
            };
            
            if(this.filters && this.filters.length > 0){
                variables = {
                    ...variables,
                    ...this.$helper.filtersToVariables(this.filters)
                }
            }

            this.$eod.get('storageObjects', ['id', 'key', 'isFavorite', 'name', 'type', 'createdAt'], variables).then(result => {
                this.items = result.data.data.storageObjects.edges;
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>