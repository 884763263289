<template>
    <div style="position:relative;min-height:200px;width:100%;height:100%;">
        <div ref="map" id="map"></div>
        <eod-project-popup @edit="val => $emit('itemClicked', val)" ref="popup" v-if="popupProject" :project="popupProject"
            hide-generated hide-delete></eod-project-popup>
    </div>
</template>
<style lang="scss" scoped>
#map {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ffffff;
    z-index: 1;
}
</style>
<script>
import eodProjectPopup from './eod-dashboards-map-project-popup';

export default {
    name: 'eod-dashboards-map',
    components: {
        eodProjectPopup
    },
    props: {
        items: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            popupProject: null,
            map: null,
            markers: [],
        }
    },
    watch: {
        items() {
            this.initMap();
        }
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {

            if (!this.$refs.map) {
                return false;
            }

            if (this.map) {
                this.map.remove();
                this.map = null;
            }

            this.map = this.$leaflet.map(this.$refs.map, {
                center: [51.022684, 3.187897],
                zoom: 13
            });
            this.map.createPane('labels');
            this.map.getPane('labels').style.pointerEvents = 'none';

            this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png', {
                attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>'
            }).addTo(this.map);
            this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png', {
                attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>',
                pane: 'labels'
            }).addTo(this.map);

            this.addMarkers();
        },
        addMarkers() {
            let bounds = [];
            for (let j = 0; j < this.items.length; j++) {
                const company = this.items[j];
                for (let i = 0; i < company.projects.length; i++) {
                    const project = company.projects[i];
                    if (project.locations && project.locations[0] && project.locations[0].lat && project.locations[0].lon) {
                        const pos = [project.locations[0].lat, project.locations[0].lon];
                        let marker = this.createMarker(project, pos);
                        marker.data = project;
                        marker.on('click', this.showPopup);
                        bounds.push(pos);
                        this.markers.push(marker);
                    }
                }
            }

            if (bounds.length > 0) {
                this.map.fitBounds(bounds, { padding: [25, 25] });
            }
        },
        showPopup(e) {
            this.popupProject = e.target.data;
            this.$nextTick(() => {
                if (this.$refs.popup) {
                    e.target.bindPopup(this.$refs.popup.$el, { 'className': 'eod-popup-card', maxWidth: 240, minWidth: 240 }).openPopup();
                    e.target.off('popupclose', this.popupClosed);
                    e.target.on('popupclose', this.popupClosed);
                }
            });
        },
        popupClosed(e) {
            this.popupProject = null;
            e.target.unbindPopup();
        },
        createPopup(project) {
            let popup_html = '';

            popup_html += '<h3>' + project.name + '</h3><hr class="mb-2" />';
            if (project.favoriteStorageObjects) {
                popup_html += '<img style="width:100%;" src="' + this.$eod.getImageUrl(project.favoriteStorageObjects[0].key) + '" />';
            }
            if (project.company) {
                popup_html += "<strong>Bedrijf:</strong> " + project.company.name + "<br>";
            }

            popup_html += '</div>';

            return popup_html;
        },
        createMarker(project, pos) {
            let icon = this.$leaflet.divIcon({
                html: this.getMarkerHtml(project),
                iconSize: [30, 34],
                iconAnchor: [15, 38],
                className: ''
            });

            return this.$leaflet.marker(pos, { icon: icon }).addTo(this.map);
        },
        getMarkerHtml(project) {
            return '<div class="eod-marker primary"><div class="triangle" style="border-top-color:var(--v-primary-base);"></div><i style="color:#ffffff;" class="mdi mdi-circle"></i></div>';
        },
    }
}
</script>