export default [
    {
        path: 'queries',
        text: 'Queries',
        permissions: ['queries.read']
    },
    {
        path: 'exports',
        text: 'Exports',
        permissions: ['exports.read']
    }
];