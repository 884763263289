export default class Measurement {
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getSaveData() {
        let data = Object.assign({}, this);

        const oneToManyRelations = ['task', 'job', 'response', 'organization', 'product', 'owner', 'jobType', 'company', 'project', 'service', 'responsibleUser', 'responsibleGroup', 'taskType', 'measurementType'];
        for (let i = 0; i < oneToManyRelations.length; i++) {
            const relation = oneToManyRelations[i];
            if (data.hasOwnProperty(relation)) {
                if (data[relation]){
                    data[relation + 'Id'] = data[relation].id;
                }else{
                    data[relation + 'Id'] = null;
                }
            }
            delete data[relation];
        }

        return data;
    }
}
