<template>
  <v-data-iterator :items="events" item-key="id" :items-per-page="6" hide-default-footer>
    <template v-slot:default="props">
      <v-btn class="btn-event" :class="'btn-event--' + getColor(item.severity)" v-for="item in props.items" :key="item.id"
        @click="$emit('openSidebar', item)">
        <v-row align="center">
          <v-col cols="auto" class="pe-0">
            <v-icon left size="20" :title="item.eventType">{{ eventTypeIcons[item.eventType] ?
              eventTypeIcons[item.eventType] : '' }}</v-icon>
          </v-col>

          <v-col>
            {{ $moment(item.timestamp).format('hh:mm | YYYY-MM-DD') }}
          </v-col>

          <v-col cols="auto">
            <v-icon size="20" :title="item.status">{{ statusIcons[item.status] ? statusIcons[item.status] : '' }}</v-icon>
          </v-col>
        </v-row>
      </v-btn>
    </template>

  </v-data-iterator>
</template>

<script>
export default {
  name: 'eod-events-table-compact',
  props: ['events', 'statusIcons', 'eventTypeIcons'],
  methods: {
    getColor(severity) {
      if (severity >= 4) {
        return 'error';
      }
      if (severity >= 3) {
        return 'warning';
      }
      if (severity >= 2) {
        return 'info';
      }
      if (severity >= 1) {
        return 'success';
      }

      return null;
    }
  }
}
</script>
<style lang="scss">
.btn-event {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: none;
  text-align: left;
  letter-spacing: 0;
}

.btn-event--success {
  background-color: var(--v-success-lighten3) !important;
}

.btn-event--info {
  background-color: var(--v-info-lighten3) !important;
}

.btn-event--warning {
  background-color: var(--v-warning-lighten3) !important;
}

.btn-event--error {
  background-color: var(--v-error-lighten3) !important;
}
</style>