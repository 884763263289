<template>
<div>
    <eod-object-autocomplete :rules="rules" :dense="false" :value="value.depot" @input="sendUpdate" label="Selecteer een depot" endpoint="depots" return-object :fields="['id', 'name', 'description', 'streetAddress','postalCode','locality','region','country','category']">
                <template v-slot:append-item>
                    <v-list-item @click="addDepot">
                        <v-list-item-icon>
                            <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Depot toevoegen</v-list-item-title>
                            <v-list-item-subtitle>Voeg een depot toe wanneer u deze niet in de lijst vindt.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </eod-object-autocomplete>
            
            <eod-item-dialog @onSaved="depotSaved" v-if="showAddDepotDialog" :defaultItem="{category:'home'}" :objectClass="require('@/eodvuecomponents/src/models/depot').default" name="Depot" v-model="showAddDepotDialog" endpoint="depot">
                <template v-slot:default="{item}">
                    <eod-depot-detail v-model="item"></eod-depot-detail>
                </template>
            </eod-item-dialog>
            <v-divider class="mt-4 mb-4"></v-divider>
    <eod-depot-detail :value="value.depot" readonly></eod-depot-detail>
</div>
</template>

<script>
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodItemDialog from './eod-item-dialog.vue';
import eodDepotDetail from './eod-depot-detail.vue';

export default {
    components:{
        eodObjectAutocomplete,
        eodItemDialog,
        eodDepotDetail
    },
    props: {
        value: Object,
        rules: Array
    },
    data(){
        return {
            showAddDepotDialog: false
        }
    },
    methods:{
        sendUpdate(depot){
            let val = this.value;
            val.depot = depot;
            this.$emit('input', val);
            this.$forceUpdate();
        },
        depotSaved(depot){
            this.sendUpdate({id: depot.id});
            this.showAddDepotDialog = false;
        },
        addDepot(){
            this.showAddDepotDialog = true;
        }
    }
}
</script>
