<template>
    <div class="eod-locations">
        <v-card v-if="!hideTitle" :flat="flat" :outlined="outlined" :class="dense?'':'mb-4'">
            <v-card-title class="primary white--text"><v-icon left dark>mdi-map-marker-radius-outline</v-icon>{{$t('Locaties')}}</v-card-title>
        </v-card>
        <template v-if="value && value.length > 0">
                <v-card v-for="(location, index) in locations" :key="index" :class="dense?'':'mb-4'">
                    <v-card-subtitle class="d-flex align-items-center grey lighten-4 py-2">{{location.name}}<v-btn small class="ml-auto" target="_blank" icon v-if="getLine1(location) != ''" @click="navigate(location)"><v-icon color="primary">mdi-directions</v-icon></v-btn></v-card-subtitle>
                    <v-card-text class="mt-2">
                        <div v-if="location.description" v-html="location.description"></div>
                        <span>{{getLine1(location)}}</span><br>
                        <span>{{getLine2(location)}}</span>
                        <div v-if="location.notes" class="alert alert-warning">{{location.notes}}</div>
                    </v-card-text>
                </v-card>
            </template>
            <template v-else>
                <!-- Extra div needed for padding -->
                <v-card>
                <v-card-text>
                    Geen locaties gevonden
                </v-card-text>
                </v-card>
            </template>
            <v-dialog max-width="400px" v-model="navigationDialog">
                <v-card>
                    <v-card-title class="primary white--text">Kies een app</v-card-title>
                    <v-card-text class="pt-4">
                        <v-row>
                    <v-col cols="6"><v-img @click="setNavigationApp('gmaps')" :src="require('@/eodvuecomponents/src/assets/img/integrations/logo-gmaps.svg')"></v-img></v-col>
                    <v-col cols="6"><v-img @click="setNavigationApp('waze')" :src="require('@/eodvuecomponents/src/assets/img/integrations/logo-waze.svg')"></v-img></v-col>
                </v-row>
                <v-checkbox v-model="rememberAppChoice" label="Onthoud mijn keuze"></v-checkbox>
                    </v-card-text>
                </v-card>
                
            </v-dialog>
    </div>
</template>
<script>
import _ from 'lodash';

export default {
    name:'eod-locations',
    props: {
        value: Array,
        flat: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        hideTitle: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            navigationDialog: false,
            rememberAppChoice: false,
            activeLocation: null
        }
    },
    computed:{
        locations(){
            let locations = [];
            
            if(this.value && this.value.length > 0){
                for (let i = 0; i < this.value.length; i++) {
                    const location = this.value[i];
                    if(this.showLocation(location)){
                        locations.push(location);
                    }
                }
            }

            return locations;
        }
    },
    methods:{
        navigate(location, navigationApp){
            
            if(!navigationApp){
                navigationApp = this.$helper.getValueByDotName(this.$eod.getUser().configuration, 'mobile.navigation.app');
            }
            
            if(!navigationApp){
                this.activeLocation = location;
                this.navigationDialog = true;
            }else{
                if(navigationApp == 'gmaps'){
                    window.open('https://www.google.com/maps/dir/?api=1&destination='+encodeURIComponent(this.getLine1(location)));
                }else if(navigationApp == 'waze'){
                    if(this.$helper.ios()){
                        window.open('waze://?navigate=yes&q='+encodeURIComponent(this.getLine1(location)));
                    }else{
                        window.open('https://waze.com/ul?navigate=yes&q='+encodeURIComponent(this.getLine1(location)));
                    }
                    
                }

                this.navigationDialog = false;
            }
        },
        setNavigationApp(app){
            if(this.rememberAppChoice){
                const user = this.$eod.getUser();
                user.configuration = _.merge(user.configuration, {
                    mobile:{
                        navigation:{
                            app: app
                        }
                    }
                });

                this.$eod.save('User', {
                    id: user.id,
                    configuration: user.configuration
                });
            }

            this.navigate(this.activeLocation, app);
        },
        showLocation(location){

            if(location.description && location.description != ''){
                return true;
            }

            if(location.notes && location.notes != ''){
                return true;
            }

            if(this.getLine1(location) != ''){
                return true;
            }

            if(this.getLine2(location) != ''){
                return true;
            }

            return false;
        },
        getLine1(location){
            let line = '';

            if(location.streetAddress && location.streetAddress != ''){
                line += location.streetAddress;
            }

            if(line != '' && ((location.postalCode && location.postalCode != '') || (location.locality && location.locality != ''))){
                line += ', ';
            }

            if(location.postalCode && location.postalCode != ''){
                line += location.postalCode;
            }
            
            if(location.locality && location.locality != ''){
                if(line != '' && location.postalCode && location.postalCode != ''){
                    line += ' ';
                }
                line += location.locality;
            }

            return line;
        },
        getLine2(location){
            let line = '';

            if(location.region && location.region != ''){
                line += location.region;
            }

            if(line != '' && location.country && location.country != ''){
                line += ', ';
            }

            if(location.country && location.country != ''){
                line += location.country;
            }

            return line;
        },
    }
}
</script>