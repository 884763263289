const Company = require('./../../eodvuecomponents/src/models/company').default;

module.exports = {
    routes: [{
        path: '/companies',
        name: 'companies',
        meta:{
            permissions:['companies.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/companies/detail/:id?',
        name: 'company',
        meta:{
            permissions:['companies.create', 'companies.update']
        },
        component: () => import('./views/detail.vue')
    }],
    search: Company.searchConfig,
    itemObject: Company,
    detail: {
        route: 'company',
        endpoint: 'company',
        objectName: 'Company',
        fields: ['id', 'name', 'description', 'defaultService{id}', 'remarks', 'tags{id name color}', 'projects{id}', 'services{id}', 'type', 'vatNumber', 'website', 'iban', 'bic', 'division{id}', 'department{id}', 'nationalIdentificationNumber', 'users{id, firstName, lastName, username, email, assignedRealmRoles{id name}, attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone}}, locations{name description streetAddress postalCode locality region country notes department lat lon}', 'emails{address type}', 'telephones{number type}', 'groups{id name}', 'configuration{version settings{key value label isDefault isRoot}}'],
        tabs: [{
            tab: 'general',
            text: 'Algemeen'
        },
        {
            tab: 'locations',
            text: 'Locaties',
            counter: 'locations'
        },
        {
            tab: 'contacts',
            text: 'Contacten',
            counter: 'contacts'
        },
        {
            tab: 'collaborators',
            text: 'Medewerkers',
            counter: 'collaborators'
        },
        {
            tab: 'groups',
            text: 'Teams',
            counter: 'groups'
        },
        {
            tab: 'projects',
            text: 'Projecten',
            counter: 'projects'
        },
        {
            tab: 'tasks',
            text: 'Taken',
            counter:{
                endpoint: 'countTasks',
                getVariables: (item) => {
                    return {
                        where: [
                            {column:'category', operator:'=',value:'JOB'},
                            {column:'companyId', operator:'=',value:item.id},
                        ]
                    };
                }
            }
        },
        {
            tab: 'services',
            text: 'Diensten',
            counter: 'services'
        }],
    },
    index: {
        route: 'companies',
        endpoint: 'companies',
        fields: ['id', 'name', 'type','tags{id name color}', 'description', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Omschrijving',
                value: 'description',
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Type',
                value: (item => {
                    let type = Company.getCompanyType(item.type);
                    return type ? type.text:null;
                }),
                component: () => import('./../default/columns/link.vue')
            },
            {
                text: 'Tags',
                value: 'tags',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-tags.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('./../default/columns/date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            }, {
                width: 120,
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
        tabs: [{
            path: 'companies',
            text: 'Overzicht'
        }],
    },
    export: {
        endpoint: 'companies',
        fields: ['id', 'name', 'description', 'type', 'vatNumber', 'website', 'iban', 'bic', 'nationalIdentificationNumber', 'createdAt', 'updatedAt'
        ]
    },
    import: {
        endpoint: 'Companies',
        objectName: 'Company',
    },
}