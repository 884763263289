<template>
    <div class="h-100 w-100">
        <v-chart v-show="value.length > 0" @finished="finished" @datazoom="selectChanged" :group="groupName" :ref="id"
            :initOptions="graphOptions" :autoresize="true" :manualUpdate="true" @contextmenu="contextmenu"
            @zr:contextmenu="contextmenu"
            @mouseup="params => $emit('mouseup', { params: params, data: params.value[5], event: params.event.event ? params.event.event : params.event })" />
        <div v-if="!value || value.length == 0">Gelieve de grafiek in te stellen</div>
    </div>
</template>
<style lang="scss">
.echarts {
    width: 100%;
    height: 100%;
}
</style>
<script>
import ECharts from './echarts/ECharts';
import * as echarts from 'echarts';
import _ from 'lodash';
import Event from './../../models/event';

export default {
    name: 'eod-graph-profile',
    components: {
        'v-chart': ECharts,
    },
    props: {
        id: String,
        value: Object | Array,
        template: Object | Array,
        xAxis: Object | Array,
        yAxis: Object | Array,
        group: String,
        cell: Object,
        options: {
            type: Object,
            default() {
                return {}
            }
        },
        defaultSerie: Object
    },
    data: () => ({
        graphOptions: {},
        defaultOptions: {
            //renderer: 'svg',
            toolbox: {
                feature: {
                    dataZoom: {
                        show: true,
                        yAxisIndex: 'none',
                        icon: {
                            zoom: 'image://',
                            back: 'image://'
                        },
                        brushStyle: {
                            color: "#000000",
                            opacity: 0.3,
                        }
                    }
                }
            },
            grid: {
                left: 200,
                top: 30,
                right: 50,
                bottom: 20,
                containLabel: false
            },
            xAxis: {
                show: true,
                id: null,
                name: null,
                type: 'time',

                //scale: true,
                //data: []
            },
            yAxis: {
                //type: 'value'
                axisLabel: {
                    width:150,
                    lineHeight: 16
                }
            },
            legend: {
                show: true,
                type: 'scroll',
                pageButtonPosition: 'start'
            },
            series: [],
        }
    }),
    computed: {
        groupName() {
            return null;
        }
    },
    methods: {
        contextmenu(params) {
            if (params.event) {
                if (params.event.event) {
                    this.$emit('contextmenu', { componentType: params.componentType, data: params.value[5], event: params.event.event });
                    params.event.event.preventDefault();
                } else {
                    params.event.preventDefault();
                }
            }
        },
        selectChanged(params) {
            this.$emit('selectChanged', this.$refs[this.id], params, this.value);
        },
        finished() {
            this.$emit('finished', this.id, this.$refs[this.id]);
        },
        resize() {
            this.$refs[this.id].resize();
        },
        renderItem(params, api) {
            var categoryIndex = api.value(0);
            var start = api.coord([api.value(1), categoryIndex]);
            var end = api.coord([api.value(2), categoryIndex]);
            var height = api.size([0, 1])[1] - 2;

            var rectShape = echarts.graphic.clipRectByRect(
                {
                    x: start[0],
                    y: start[1] - height / 2,
                    width: end[0] - start[0],
                    height: height
                },
                {
                    x: params.coordSys.x,
                    y: params.coordSys.y,
                    width: params.coordSys.width,
                    height: params.coordSys.height
                }
            );

            return (
                rectShape && {
                    type: 'rect',
                    transition: ['shape'],
                    shape: rectShape,
                    style: api.style()
                }
            );
        },
        getListItemByValue(measurementType, val) {
            for (const index in measurementType.valueItems) {
                const valueItem = measurementType.valueItems[index];
                if (valueItem.threshold == parseInt(val)) {
                    return valueItem;
                }
            }

            return null;
        },
        getLabel(dataSerie, measurementType){
            let label = '';

                    if(this.cell){
                        if(measurementType && (!this.cell.settings.fields || (this.cell.settings.fields && this.cell.settings.fields.includes('measurementType')))){
                            label += measurementType.name;
                        }

                        if (dataSerie.data[0] && dataSerie.data[0].owner_id_resolved && (this.cell.settings.fields && this.cell.settings.fields.includes('owner'))) {
                            if(dataSerie.data[0].owner_id_resolved.firstName || dataSerie.data[0].owner_id_resolved.lastName){
                                label += (label!=''?"\n":'') + dataSerie.data[0].owner_id_resolved.firstName + ' ' + dataSerie.data[0].owner_id_resolved.lastName;
                            }else{
                                label += (label!=''?"\n":'') + dataSerie.data[0].owner_id_resolved.username;
                            }
                            
                        }

                        if (dataSerie.data[0] && dataSerie.data[0].product_id_resolved && (!this.cell.settings.fields || (this.cell.settings.fields && this.cell.settings.fields.includes('product')))) {
                            label += (label!=''?"\n":'') + dataSerie.data[0].product_id_resolved.name;
                        }
                    }else{
                        if(measurementType){
                            label += measurementType.name;
                        }
                        
                        label += (label!=''?"\n":'') + dataSerie.data[0].owner_id_resolved.username;
                        label += (label!=''?"\n":'') + dataSerie.data[0].product_id_resolved.name;
                    }
            return label;
        },
        update() {

            this.graphOptions = _.merge(this.defaultOptions, this.options);

            let labels = [];
            let events = [];
            let legend = [];

            let series = {};

            if(this.template && this.template[0] && this.template[0].content.source == 'events'){
                const fieldname = 'severity_code';

                for (let i = 0; i < this.value.length; i++) {
                    const dataSerie = this.value[i];
                    const defaultColor = this.options.color[i];

                    if (dataSerie) {

                        const label = this.getLabel(dataSerie);
                        labels.push(label);

                        let previous = null;
                        const serieData = dataSerie.data.reverse();
                        
                        for (let j = 0; j < serieData.length; j++) {
                        const val = serieData[j];
                        
                        if (val && typeof val[fieldname] != 'undefined' && val[fieldname] != null) {
                            const listItem = Event.getSeverityByCode(val[fieldname]);

                            if (listItem) {
                                let found = false;
                                for (let legendIndex = 0; legendIndex < legend.length; legendIndex++) {
                                    const legendItem = legend[legendIndex];
                                    if (legendItem.name == listItem.text) {
                                        found = true;
                                    }
                                }

                                if (!found) {
                                    legend.push({
                                        name: listItem.text,
                                        itemStyle: {
                                            color: listItem.color
                                        }
                                    });
                                }

                            }

                            if (!series[listItem ? listItem.text : val[fieldname]]) {
                                series[listItem ? listItem.text : val[fieldname]] = {
                                    color: listItem ? listItem.text : defaultColor,
                                    data: [],
                                };
                            }

                            if (previous) {
                                // Set previous end time to new start time
                                const prevListItem = Event.getSeverityByCode(previous[fieldname]);
                                let prevIndex = series[prevListItem ? prevListItem.text : previous[fieldname]].data.length - 1;
                                series[prevListItem ? prevListItem.text : previous[fieldname]].data[prevIndex].value[2] = val.start;
                            }

                            series[listItem ? listItem.text : val[fieldname]].data.push({
                                name: (listItem ? listItem.text : val[fieldname]),
                                value: [i, val.start, val.stop, 0, listItem ? listItem.text : val[fieldname], val, dataSerie],
                                itemStyle: {
                                    color: listItem ? listItem.color : defaultColor
                                }
                            });

                            previous = val;
                        }
                    }
                    }
                }
            }else{
                for (let i = 0; i < this.value.length; i++) {
                const dataSerie = this.value[i];
                const defaultColor = this.options.color[i];

                if (dataSerie) {
                    const measurementTypeId = Object.keys(dataSerie.measurementTypes)[0];
                    const measurementType = dataSerie.measurementTypes[measurementTypeId];

                    const fieldname = this.measurementTypeFieldName(dataSerie.queryIndex);

                    const label = this.getLabel(dataSerie, measurementType);


                    if(!(this.cell && this.cell.settings && this.cell.settings.hideEvents)){
                        for (let i = 0; i < dataSerie.events.length; i++) {
                            const event = dataSerie.events[i];
                            events.push({
                                itemStyle: {
                                    color: Event.getSeverity(event.severity).color
                                },
                                /*itemStyle: {
                                    color: '#000000'
                                },*/
                                name: 'Event',
                                value: [0, event.time, event.time, 0, event.message, event],
                                xAxis: event.time,
                                yAxis: label
                            });
                        }
                    }
                    

                    labels.push(label);

                    let previous = null;

                    const serieData = dataSerie.data.reverse();

                    for (let j = 0; j < serieData.length; j++) {
                        const val = serieData[j];
                        if (val) {
                            const listItem = this.getListItemByValue(measurementType, val[fieldname]);

                            if (listItem) {
                                let found = false;
                                for (let legendIndex = 0; legendIndex < legend.length; legendIndex++) {
                                    const legendItem = legend[legendIndex];
                                    if (legendItem.name == listItem.name) {
                                        found = true;
                                    }
                                }

                                if (!found) {
                                    legend.push({
                                        name: listItem.name,
                                        itemStyle: {
                                            color: listItem.color
                                        }
                                    });
                                }

                            }

                            if (!series[listItem ? listItem.name : val[fieldname]]) {
                                series[listItem ? listItem.name : val[fieldname]] = {
                                    color: listItem ? listItem.name : defaultColor,
                                    data: [],
                                };
                            }

                            if (previous) {
                                // Set previous end time to new start time
                                const prevListItem = this.getListItemByValue(measurementType, previous[fieldname]);
                                let prevIndex = series[prevListItem ? prevListItem.name : previous[fieldname]].data.length - 1;
                                series[prevListItem ? prevListItem.name : previous[fieldname]].data[prevIndex].value[2] = val.start;
                            }

                            if(val.start != val.stop){
                                series[listItem ? listItem.name : val[fieldname]].data.push({
                                    name: measurementType.name,
                                    value: [i, val.start, val.stop, 0, listItem ? listItem.name : val[fieldname], val, dataSerie],
                                    itemStyle: {
                                        color: listItem ? listItem.color : defaultColor
                                    }
                                });

                                previous = val;
                            }
                        }
                    }
                }
            }
            }

            this.graphOptions.yAxis.data = labels;

            this.graphOptions.series = [];
            for (const serieName in series) {
                
                const serie = series[serieName];
                const graphSerieData = {
                    name: serieName,
                    type: 'custom',
                    renderItem: this.renderItem,
                    itemStyle: {
                        opacity: 0.8
                    },
                    encode: {
                        x: [1, 2],
                        y: 0
                    },
                    data: serie.data
                };

                this.graphOptions.series.push(graphSerieData);
            }

            if (this.graphOptions.series[0]) {
                this.graphOptions.series[0].markPoint = {
                    symbolSize: 18,
                    symbol: "circle",
                    data: events
                }
            }

            const self = this;
            this.graphOptions.tooltip = {
                confine:true,
                formatter: function (params) {
                    let label = self.$moment.tz(params.value[1], 'Europe/Brussels').format('DD-MM HH:mm') + '<br>' + params.marker + params.name + ': ' + params.value[4];
                    return label;
                }
            }

            this.graphOptions.legend.data = legend;

            if (this.$refs[this.id]) {
                this.$refs[this.id].mergeOptions(this.graphOptions);
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }
        },
        measurementTypeFieldName(index){
                if (this.template[index]) {
                            const content = this.template[index].content;
                            for (let i = 0; i < content.measurementTypes.length; i++) {
                                const aggMeasurementType = content.measurementTypes[i];
                                return aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id;
                            }
                        }
                return null;
            },
    }
}
</script>