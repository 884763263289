<template>
    <v-card id="aside" class="d-flex" :style="fixed?'position:fixed;'+(value?'right:0;':'right: -562px;')+';top:50px;bottom:0;z-index:4;width: 600px;max-width: 50%;':''">
        <button @click="$emit('input', !value)" type="button" class="aside-toggle" :class="{'aside-toggle-active':value}">
            <v-icon>mdi-chevron-left</v-icon>
        </button>
        <v-card class="d-flex flex-column" style="width:calc(100% - 40px);">
        <v-card-title>
            <v-row>
            <v-col>
                <v-icon left>mdi-clipboard-outline</v-icon>Taken
            </v-col>
            <v-col class="flex-grow-1">
                <div class="d-flex">
                    <v-autocomplete v-model="selectedTemplate" :items="searchTemplates" item-text="name" dense outlined hide-details @change="templateSelected" return-object></v-autocomplete>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on"><v-icon v-html="sorting.icon"></v-icon></v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item v-for="sorting in sortings" :key="sorting.icon" @click="changeSorting(sorting)">
                                <v-list-item-icon><v-icon v-html="sorting.icon"></v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{sorting.text}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
            </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <div class="py-2">
            <eod-search :config="searchSettings" v-model="searchFilters" hide-saved template-type="JOB_FILTER" object-type="TASK" flat @update="searchUpdated" @templatesLoaded="searchTemplatesLoaded"></eod-search>
        </div>
        <v-divider></v-divider>
        <v-progress-linear indeterminate v-if="loading" color="primary"></v-progress-linear>
        <div style="overflow-y:scroll;">
            <v-list dense>
                <template v-for="item in items">
                <eod-scheduler-sidebar-item :key="item.id" :value="item" @drag="drag" @dragEnd="(e, item) => $emit('dragEnd', e, item)" @cancelItem="val => $emit('cancelItem', val)" @acceptItem="val => $emit('acceptItem', val)"></eod-scheduler-sidebar-item>
                <v-divider :key="'divider_'+item.id"></v-divider>
                </template>
            </v-list>
        </div>
        <v-card-actions v-if="pagesCount > 1" style="overflow:hidden;">
            <div class="text-center w-100">
                <v-pagination v-model="page" :length="pagesCount" circle></v-pagination>
            </div>
        </v-card-actions>
        </v-card>
    </v-card>
</template>
<style lang="scss" scoped>
    #aside{
        height: 100%;
        transition: .4s all;
        overflow: hidden;
        min-width: 300px;

        .aside-toggle{
            width: 40px;
            background: #ffffff;
            height: 100%;
            padding: 0;
            border-right: 1px solid #dddddd;
            border-left: 1px solid #dddddd;

            &:hover{
                background: #eeeeee;
            }

            i{
                transition: .4s all;
            }

            &.aside-toggle-active{
                i{
                    transform: rotate(180deg);
                }
            }
        }
    }
</style>
<script>
import {Task} from '@/eodvuecomponents';
import eodSchedulerSidebarItem from './eod-scheduler-sidebar-item.vue';
import eodSearch from './eod-search.vue';
import settings from './../classes/settings';

export default {
    name:'eod-scheduler-sidebar',
    components:{
        eodSchedulerSidebarItem,
        eodSearch
    },
    props:{
        value: Boolean,
        fixed: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        page: 1,
        itemsPerPage: 10,
        pagesCount: 0,
        loading: false,
        items: [],
        settings: settings,
        selectedTemplate: null,
        jobFilters: [],
        sorting: null,
        sortings: [
            {
                column: 'dueAfterDate',
                type: 'ASC',
                icon: 'mdi-sort-calendar-ascending',
                text: 'Starttijd'
            },
            {
                column: 'dueAfterDate',
                type: 'DESC',
                icon: 'mdi-sort-calendar-descending',
                text: 'Starttijd'
            },
            {
                column: 'dueRequestDate',
                type: 'ASC',
                icon: 'mdi-sort-calendar-ascending',
                text: 'Planningsdatum'
            },
            {
                column: 'dueRequestDate',
                type: 'DESC',
                icon: 'mdi-sort-calendar-descending',
                text: 'Planningsdatum'
            }
        ],
        searchTemplates: [],
        searchFilters: [],
        searchSettings: [{
                'icon': 'mdi-format-text',
                'label': 'Naam',
                'type': 'property',
                'filter': 'name'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Omschrijving',
                'type': 'property',
                'filter': 'description'
            },
            {
                'icon': 'mdi-clipboard-outline',
                'label': 'Workflow',
                'type': 'module',
                'settings': {
                    'module': 'taskTypes',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'taskTypeId'
            },
            {
                'icon': 'mdi-folder-open-outline',
                'label': 'Project',
                'type': 'module',
                'settings': {
                    'module': 'projects',
                    'fields': ['name', 'description'],
                    'title': '{name} ({description})',
                },

                'filter': 'projectId'
            },
            {
                'icon': 'mdi-file-sign',
                'label': 'Dienst',
                'type': 'module',
                'settings': {
                    'module': 'services',
                    'fields': ['name'],
                    'title': '{name}',
                },

                'filter': 'serviceId'
            },
            {
                'icon': 'mdi-office-building-outline',
                'label': 'Bedrijf',
                'type': 'module',
                'settings': {
                    'module': 'companies',
                    'fields': ['name', 'description'],
                    'title': '{name} ({description})',
                },

                'filter': 'companyId'
            },
            {
                'icon': 'mdi-tag-outline',
                'label': 'Status',
                'type': 'options',
                'settings': {
                    'options': () => {

                        let statusCodes = [];

                        for (let i = 0; i < settings.statusCodes.length; i++) {
                            const statusCode = settings.statusCodes[i];
                            if(statusCode.job_enabled){
                                statusCodes.push(statusCode);
                            }
                        }

                        return statusCodes;
                    }
                },
                'filter': 'statusCode'
            },
            {
                'icon': 'mdi-calendar',
                'label': 'Datum',
                'type': 'date-between',
                'filter': ['due_after_date', 'due_before_date']
            },
            {
                'icon': 'mdi-account-outline',
                'label': 'Verantwoordelijke',
                'type': 'relation',
                'settings': {
                    'module': 'searchUsers',
                    'fields': ['firstName', 'lastName', 'username'],
                    'title': '{firstName} {lastName} ({username})',
                    'options': {
                        enabled: true
                    }
                },
                'filter': 'responsibleUsers'
            },
            {
                'icon': 'mdi-account-group-outline',
                'label': 'Team',
                'type': 'module',
                'settings': {
                    'module': 'groups',
                    'fields': ['name'],
                    'title': '{name}',
                },

                'filter': 'responsibleGroupId'
            },
        ]
    }),
    created(){
        this.jobFilters = this.$eod.getDefaultJobFilters();
        const defaultJobFilterSetting = this.$eod.getOrganizationSetting('planning.defaultJobsFilter');
        if(defaultJobFilterSetting){
            for (let i = 0; i < this.jobFilters.length; i++) {
                const jobFilter = this.jobFilters[i];
                if(jobFilter.value == defaultJobFilterSetting.value){
                    this.selectedTemplate = jobFilter;
                    break;
                }
            }
        }else{
            this.selectedTemplate = this.jobFilters[0];
        }
        
        this.searchFilters = Object.assign([], this.selectedTemplate.content.filter);
        this.sorting = this.sortings[0];
    },
    mounted(){
        this.getDataFromApi();
    },
    watch:{
        page(){
            this.getDataFromApi();
        }
    },
    methods:{
        changeSorting(sort){
            this.sorting = sort;
            this.getDataFromApi();
        },
        searchTemplatesLoaded(templates){
            this.searchTemplates = templates;
            for (let i = this.jobFilters.length-1; i >= 0; i--) {
                const filter = this.jobFilters[i];
                this.searchTemplates.unshift(filter);
            }
        },
        templateSelected(item){
            this.searchFilters = Object.assign([], item.content.filter);
            this.getDataFromApi();
        },
        drag(e, item) {
            if(this.fixed){
                this.$emit('input', false);
            }
            
            this.$emit('drag', e, item);
        },
        removeItem(item){
            for (let i = 0; i < this.items.length; i++) {
                const existingItem = this.items[i];
                if(existingItem.id == item.id){
                    this.items.splice(i, 1);
                }
            }
        },
        searchUpdated(){

            for (let i = 0; i < this.searchTemplates.length; i++) {
                const template = this.searchTemplates[i];
                if(template.value == 'custom'){
                    this.searchTemplates.splice(i, 1);
                }
            }

            this.getDataFromApi();

            if(this.searchFilters && this.searchFilters.length > 0){
                const custom_filter = {
                    value:'custom',
                    name:'Aangepaste filter',
                    content: {
                        filter: this.searchFilters
                    }
                };
                this.searchTemplates.unshift(custom_filter);

                this.selectedTemplate = custom_filter;
            }else if(!this.searchFilters || (this.searchFilters && this.searchFilters.length == 0)){
                this.selectedTemplate = {
                    value: null
                }
            }
        },
        getDataFromApi(){

            this.loading = true;

            let variables = this.$helper.filtersToVariables(this.searchFilters);

            if(!variables.where){
                variables.where = [];
            }

            variables.where.push({
                column: 'category',
                operator: '=',
                value: 'JOB'
            });

            variables.where.push({
                column: 'statusCode',
                operator: '!=',
                value: 'done'
            });

            variables.where.push({
                column: 'stepType',
                operator: '=',
                value: 'PLAN'
            });

            variables.where.push({
                column: 'isJobActive',
                operator: '=',
                value: '1'
            });

            variables.limit = this.itemsPerPage;
            variables.offset = this.page-1;

            variables.orderBy = {
                column: this.sorting.column,
                type: this.sorting.type
            };

            this.$eod.get('tasks', ['id', 'name', 'isJobActive', 'stepType', 'route{id}', 'statusCode','dueBeforeDate', 'dueAfterDate', 'project{id name}', 'company{id name}', 'parent{id dueRequestDate name project{name} taskType{configuration{settings{key value}}}}'], variables)
            .then(response => {
                if(response.data.data){
                    let items = response.data.data.tasks.edges;
                    for (let i = 0; i < items.length; i++) {
                        items[i] = new Task(items[i]);
                    }
                    this.items = items;
                    this.pagesCount = Math.ceil(response.data.data.tasks.totalCount/this.itemsPerPage);
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>