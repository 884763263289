<template>
  <div>
    <v-radio-group v-if="measurementType.formConfig.type == 'scale'" :value="value" @change="val => $emit('input', val)" :disabled="readonly">
      <v-radio
        v-for="item in items"
        :key="item.id"
        :label="item.name"
        :value="item.threshold"
      ></v-radio>
    </v-radio-group>
    <v-select
      v-else
      :value="value"
      @change="val => $emit('input', val)"
      :items="items"
      small-chips
      :disabled="readonly"
      outlined
      item-value="threshold"
      item-text="name"
    >
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-avatar
            color="primary"
            class="white--text"
            left
            v-text="data.item.threshold"
          ></v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
    </v-select>
  </div>
</template>
<script>
export default {
    name: 'eod-measurement-list',
    props: {
        readonly: Boolean,
        value: Number,
        measurementType: Object
    },
    data: () => ({
        items: []
    }),
    mounted(){
      
        if (this.measurementType.valueConfigListItems && this.measurementType.valueConfigListItems.length > 0) {
            // Use valueConfigListItems
            this.items = this.measurementType.valueConfigListItems;
        }else if (this.measurementType.valueConfigList.listItems && this.measurementType.valueConfigList.listItems.length > 0){
            this.items = this.measurementType.valueConfigList.listItems;
        }

        this.items.sort( this.compare );
    },
    methods:{
      compare( a, b ) {
        if ( a.threshold < b.threshold ){
          return -1;
        }
        if ( a.threshold > b.threshold ){
          return 1;
        }
        return 0;
      }
    }
}
</script>