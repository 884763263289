<template>
    <v-row no-gutters>
        <v-col>
            <v-card flat>
                <v-card-title>Van</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col v-if="startType != 'date'" cols="6" lg="4">
                            <v-text-field type="number" :value="startDate" @input="startDateChanged" dense outlined></v-text-field>
                        </v-col>
                        <v-col>
                            <v-select dense outlined v-model="startType" :items="startChoices" @input="startDateTypeChanged"></v-select>
                        </v-col>
                    </v-row>
                    <v-date-picker v-if="startType == 'date'" width="100%" no-title scrollable :value="startDate" @input="startDateChanged"></v-date-picker>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card flat>
                <v-card-title>Tot</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col v-if="stopType != 'date'" cols="6" lg="4">
                            <v-text-field type="number" :value="endDate" @input="endDateChanged" dense outlined></v-text-field>
                        </v-col>
                        <v-col>
                            <v-select dense outlined v-model="stopType" :items="stopChoices" @input="endDateTypeChanged"></v-select>
                        </v-col>
                    </v-row>
                    <v-date-picker width="100%" v-if="value[0] && stopType == 'date'" :value="endDate" no-title scrollable @input="endDateChanged"></v-date-picker>
                </v-card-text>
            </v-card>
        </v-col>
        
    </v-row>
</template>
<script>
    export default {
        props:{
            value: Array,
        },
        data: () => ({
            startType: 'days',
            startChoices:[
                {value: 'days', text:'Dagen geleden'},
                {value: 'weeks', text:'Weken geleden'},
                {value: 'months', text:'Maanden geleden'},
                {value: 'date', text:'Datum'},
            ],
            stopType: 'days',
            stopChoices:[
                {value: 'days', text:'Dagen'},
                {value: 'weeks', text:'Weken'},
                {value: 'months', text:'Maanden'},
                {value: 'date', text:'Datum'},
            ],
            dates: []
        }),
        mounted(){
            this.dates = this.value;
            if(!this.dates[0]){
                this.dates[0] = 'days:0';
            }
            if(!this.dates[1]){
                this.dates[1] = 'days:0';
            }
        },
        computed:{
            startDate(){
                if(this.value && this.value.length > 0){
                    let parts = this.value[0].split(':');
                    return parts[1];
                }

                return 0;
            },
            endDate(){
                if(this.value && this.value.length > 1){
                    let parts = this.value[1].split(':');
                    return parts[1];
                }

                return 0;
            }
        },
        methods:{
            startDateChanged(val){
                this.dates[0] = this.startType+':'+val;
                this.$emit('input', this.dates);
            },
            startDateTypeChanged(val){
                this.dates[0] = val+':'+this.startDate;
                this.$emit('input', this.dates);
            },
            endDateTypeChanged(val){
                this.dates[1] = val+':'+this.endDate;
                this.$emit('input', this.dates);
            },
            endDateChanged(val){
                this.dates[1] = this.stopType+':'+val;
                this.$emit('input', this.dates);
            }
        }
    }
</script>