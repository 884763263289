<template>
    <v-autocomplete :value="value" :items="items" :return-object="returnObject" chips dense outlined :label="label" @input="value => $emit('input', value)" hide-details>
              <template v-slot:selection="data">
                  <v-chip small label>
                        <div :style="'background-color:'+data.item.color+';width:8px;height:100%;left:0;top:0;position:absolute;'"></div>
                        <div style="padding-left:10px;">
                        <v-icon small left>{{data.item.icon}}</v-icon>
                        {{data.item.text}}
                        </div>
                    </v-chip>
              </template>
              <template v-slot:item="data">
                  <div :style="'background-color:'+data.item.color+';width:8px;height:100%;left:0;top:0;position:absolute;'"></div>
                <v-list-item-action>
                <v-icon small>{{data.item.icon}}</v-icon>
              </v-list-item-action>
                  <v-list-item-subtitle>{{data.item.text}}</v-list-item-subtitle>
              </template>
        </v-autocomplete>
</template>
<script>
export default {
    name:"eod-statuscode-autocomplete",
    props:{
        value: String|Object,
        items: Array,
        label: String,
        returnObject:{
            type: Boolean,
            default: false,
        }
    },
}
</script>