<template>
    <v-list :dense="dense">
                <v-list-item v-for="productType in value" :key="productType.id">
                    <v-list-item-content>
                        <v-list-item-title>{{productType.name}}</v-list-item-title>
                        <v-list-item-subtitle v-if="productType.quantity"><strong>Aantal:</strong> {{productType.quantity}}<span v-if="productType.unit"> {{ productType.unit.name }}</span></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
</template>
<script>
export default {
    name:'eod-producttypes-list',
    props:{
        value: Array,
        dense:{
            type: Boolean,
            default: false
        }
    }
}
</script>