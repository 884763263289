<template>
    <v-form ref="form" v-if="item">
        <v-card-title>{{ item.name }}<v-btn v-if="item.id" target="_blank" :to="{name:'dashboard.product', params:{id:item.id}}" class="ml-4" depressed rounded color="gray" small><v-icon left small>mdi-view-dashboard-outline</v-icon>Dashboard</v-btn>
            <v-spacer></v-spacer>
            <v-alert dense type="warning" v-if="isSaveAlertVisible" class="mr-3 mb-0 py-1">Er zijn onopgeslagen wijzigingen</v-alert>
            <v-btn @click="saveItem" :loading="saving" v-if="isSaveAlertVisible" color="secondary" depressed rounded><v-icon left>mdi-content-save</v-icon>Opslaan</v-btn>
        </v-card-title>
        <template v-if="!['DEVICE_FOLDER', 'TOOL_FOLDER', 'ARTICLE_FOLDER'].includes(item.class)">
        <v-card-text>
            <v-tabs v-model="selectedtab">
                <v-tab>Algemeen</v-tab>
                <v-tab>Media</v-tab>
                <v-tab>Locatie</v-tab>
                <v-tab>Meetwaardes</v-tab>
            </v-tabs>
            </v-card-text>
            <v-card-text>
                <v-alert v-if="errors" color="error" text>
                <ul>
                    <li v-for="(error, index) in errors" :key="'error_'+index">{{ error }}</li>
                </ul>
                </v-alert>
        <v-tabs-items :value="selectedtab">
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
            <v-row class="mb-4">
                <v-col>
                    <v-row>
                    <v-col cols="12" :xl="6">
                        <v-text-field
                            v-model="item.name"
                            :rules="[val => $validator.required(val, 'Naam')]"
                            label="Naam*"
                            required
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" :xl="6">
                        <v-text-field
                            v-model="item.description"
                            label="Omschrijving"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :xl="6">
                        <eod-object-autocomplete :default-filter="{whereIn:[{column:'class',array:[item.class]}]}" dense hide-details :rules="[val => $validator.required(val, 'Type')]" label="Type*" endpoint="productTypes" v-model="item.productType.id" required></eod-object-autocomplete>
                    </v-col>
                    <v-col cols="12" :xl="6">
                        <eod-objects-treeview-input :classes="['COMPANY','PROJECT',item.class+'_FOLDER', item.class]" :rules="[val => $validator.required(val, 'Bovenliggend item')]" required v-model="item.treeParentId" :exclude="item.id?[item.id]:null" label="Bovenliggend item*"></eod-objects-treeview-input>
                    </v-col>
                </v-row>
                <v-row v-if="item.productType && item.productType.id">
                    <v-col>
                        <v-alert color="grey lighten-4" class="text-body-2" dense>
                        <eod-product-type-preview :value="item.productType.id"></eod-product-type-preview>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :xl="4">
                        <v-menu
                            ref="installedDateMenu"
                            v-model="datepickers.installedDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.installedDateFormatted"
                                label="Installatiedatum"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.installedDateFormatted" no-title scrollable @input="datepickers.installedDate = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <v-menu
                            ref="warrantyEndDateMenu"
                            v-model="datepickers.warrantyEndDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.warrantyEndDateFormatted"
                                label="Vervaldatum garantie"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.warrantyEndDateFormatted" no-title scrollable @input="datepickers.warrantyEndDate = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <v-menu
                            ref="lastMaintenanceDateMenu"
                            v-model="datepickers.lastMaintenanceDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.lastMaintenanceDateFormatted"
                                label="Laatste onderhoud"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.lastMaintenanceDateFormatted" no-title scrollable @input="datepickers.lastMaintenanceDate = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="12" :xl="4">
                        <v-text-field
                            v-model="item.code"
                            label="Code"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <eod-list-item-autocomplete dense outlined v-if="$eod.getOrganizationSetting('products.statusCode.listId')" v-model="item.statusCode.id" label="Status code" :listId="$eod.getOrganizationSetting('products.statusCode.listId').value"></eod-list-item-autocomplete>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <eod-color-picker label="Kleur" v-model="item.color" dot-size="15" hide-mode-switch mode="hexa"></eod-color-picker>
                    </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" :lg="'auto'">
                    <v-img width="200" max-height="280" :src="qr_code" aspect-ratio="1" contain />
                </v-col>
            </v-row>
            
        </v-tab-item>
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
        <eod-storage-library :tags="{ productId: item.id }" :searchFilters="[{ field: 'productId', name: item.name, value: item.id }]"></eod-storage-library>
      </v-tab-item>
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
        <v-row>
            <v-col cols="12" :md="8">
                <v-card v-if="item.location">
              <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field dense type="number" outlined hide-details label="Beschikbare hoeveelheid" v-model="item.quantityInHand"></v-text-field>
                    </v-col>
                    <v-col>
                        <eod-object-autocomplete label="Depot" outlined endpoint="depots" v-model="item.depot" return-object></eod-object-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-object-autocomplete label="Voertuig" outlined endpoint="vehicles" v-model="item.vehicle" return-object></eod-object-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense outlined hide-details label="Zone" v-model="item.zone"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense outlined hide-details label="Niveau" v-model="item.level"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense outlined hide-details label="Gang" v-model="item.aisle"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense outlined hide-details label="Rek" v-model="item.rack"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense outlined hide-details label="Positie" v-model="item.position"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense outlined hide-details label="Schap" v-model="item.shelf"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense outlined hide-details label="Container" v-model="item.bin"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-list-item-autocomplete outlined dense v-if="$eod.getOrganizationSetting('products.location.department.listId')" v-model="item.location.department" label="Vestiging" :returnObject="false" :listId="$eod.getOrganizationSetting('products.location.department.listId').value"></eod-list-item-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-list-item-autocomplete outlined dense v-if="$eod.getOrganizationSetting('products.location.unit.listId')" v-model="item.location.unit" label="Afdeling" :returnObject="false" :listId="$eod.getOrganizationSetting('products.location.unit.listId').value"></eod-list-item-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-list-item-autocomplete outlined dense v-if="$eod.getOrganizationSetting('products.location.costcenter.listId')" v-model="item.location.costcenter" label="Kostenplaats" :returnObject="false" :listId="$eod.getOrganizationSetting('products.location.costcenter.listId').value"></eod-list-item-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-list-item-autocomplete outlined dense v-if="$eod.getOrganizationSetting('products.location.room.listId')" v-model="item.location.room" label="Ruimte" :returnObject="false" :listId="$eod.getOrganizationSetting('products.location.room.listId').value"></eod-list-item-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea v-model="item.location.notes" outlined dense label="Opmerkingen"></v-textarea>
                    </v-col>
                </v-row>
                
              </v-card-text>
          </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="overflow-hidden">
                    <eod-location-form v-model="item"></eod-location-form>
                </v-card>
            </v-col>
        </v-row>
          
      </v-tab-item>
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
      <eod-measurement-type-indicative v-model="item.measurementTypes"></eod-measurement-type-indicative>
    </v-tab-item>
        </v-tabs-items>
    </v-card-text>
</template>
    <v-card-text v-else-if="['DEVICE_FOLDER', 'TOOL_FOLDER', 'ARTICLE_FOLDER'].includes(item.class)">
        <v-text-field label="Naam" dense outlined v-model="item.name"></v-text-field>
    </v-card-text>
        <v-snackbar right top v-model="isSavedSnackbarVisible" rounded="pill" timeout="5000" color="success"><v-icon left>mdi-content-save</v-icon>Item succesvol bewaard!</v-snackbar>
</v-form>
</template>
<script>
import QRCode from 'qrcode'
import eodMeasurementTypeIndicative from './eod-measurementType-indicative.vue';
import eodColorPicker from './eod-color-picker.vue';
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodStorageLibrary from './eod-storage-library.vue';
import eodLocationForm from './eod-location-form.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodProductTypePreview from './eod-product-type-preview.vue';
import Product from './../models/product.js';

export default{
    name: 'eod-product-detail',
    components:{
        eodMeasurementTypeIndicative,
        eodColorPicker,
        eodListItemAutocomplete,
        eodObjectAutocomplete,
        eodStorageLibrary,
        eodLocationForm,
        eodObjectsTreeviewInput,
        eodProductTypePreview,
    },
    props:{
        value: String|Object
    },
    data: () => ({
        item: null,
        loading: false,
        saving: false,
        isSaveAlertVisible: false,
        isSavedSnackbarVisible: false,
        errors: null,
        selectedtab: 'general',
        datepickers: {
            warrantyEndDate: false,
            installedDate: false,
            lastMaintenanceDate: false,
        },
        qr_code: null
    }),
    created(){
        if(this.value){
            if(typeof this.value == 'string'){
                this.loading = true;
                
                this.$eod.getById('product', this.value, ['id', 'name', 'treeParentId', 'description', 'color', 'productType{id}', 'code', 'class', 'warrantyEndDate', 'installedDate', 'lastMaintenanceDate', 'statusCode{id}', 'measurementTypes{id name indicativeValue unit{id name}}', 'lat','lon', 'quantityInHand', 'zone', 'level', 'depot{id}', 'vehicle{id}', 'aisle', 'rack', 'position', 'shelf', 'bin', 'location{name description streetAddress postalCode locality region country notes access department unit room costcenter}'])
                    .then(response => {
                        if(response && response.data.data && response.data.data.product){
                            this.item = new Product(response.data.data.product);
                        }else{
                            this.item = null;
                        }

                        if(!this.item.statusCode){
                            this.item.statusCode = {};
                        }
                    }).finally(() => {
                        this.loading=false;
                    });
            }else{
                this.item = this.value
            }
        }
        

        // QR code
        QRCode.toDataURL(window.location.href).then(result => {
            this.qr_code = result;
        });
    },
    watch:{
        loading(val){
            this.$emit('loading', val);
        },
        item: {
            deep: true,
            handler() {
                if (!this.loading && !this.isSaveAlertVisible) {
                    this.isSaveAlertVisible = true;
                }
            }
        }
    },
    methods:{
        getFormErrors() {
            const errors = [];
            this.$refs.form.inputs.forEach(e => {
                if (e.errorBucket && e.errorBucket.length) {
                    errors.push(...e.errorBucket);
                }
            });
            return errors;
        },
        saveItem(){
            this.errors = null;
            if(!this.$refs.form){
                return false;
            }

            const validation = this.$refs.form.validate();
            if(!validation){
                console.log('Error!', this.getFormErrors());
                this.errors = this.getFormErrors();
                return false;
            }

            this.saving = true;

            const data = (new Product(this.item)).getSaveData();
            this.$eod.save('Product', data)
                .then(response => {
                    if(response && response.data.data){
                        if(response.data.data.createProduct){
                            this.$emit('saved', response.data.data.createProduct.id);
                        }else if (response.data.data.updateProduct){
                            this.$emit('saved', response.data.data.updateProduct.id);
                        }

                        this.isSavedSnackbarVisible = true;
                    }
                })
                .finally(() => {
                    this.isSaveAlertVisible = false;
                    this.saving = false;
                });
        },
    }
}
</script>