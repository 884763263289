let queries = require('./queries');
let exportSettings = require('./exports');

module.exports = {
    routes: [
        {
            path: '/data',
            name: 'data',
            redirect: { name: 'queries' }
        },
        ...queries.routes,
        ...exportSettings.routes,
    ],
    queries: queries,
    exports: exportSettings,
}