const { Query } = require('@/eodvuecomponents');
const tabs = require('./../tabs').default;

module.exports = {
    routes: [
        {
            path: '/data/queries',
            name: 'queries',
            meta:{
                permissions:['queries.read']
            },
            component: () => import('./views/index.vue')
        },
        {
            path: '/data/queries/detail/:id?',
            name: 'query',
            meta:{
                permissions:['queries.create', 'queries.update']
            },
            component: () => import('./views/detail.vue')
        },
    ],
    search: Query.searchConfig,
    detail:{
        route:'query',
        objectName: 'Template'
    },
    index: {
        endpoint: 'templates',
        fields: ['id', 'name', 'content', 'type', 'createdAt', 'updatedAt'],
        tabs:tabs,
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('./columns/eod-column-activate.vue')
            },
            {
                text: '',
                width: 30,
                value: 'createdAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 30,
                value: 'updatedAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
    },
    sources: Query.SOURCES,
    options: Query.OPTIONS
}