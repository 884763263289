<template>
    <div v-if="task">
        <eod-task-info v-model="task" :show-title="showTitle"></eod-task-info>

        <v-card flat class="mb-2" v-if="task.products && task.products[0]">
                <div class="text-subtitle-2 mb-2">Materialen</div>
                <v-card class="mb-2" v-for="item in task.products" :key="item.id">
                    <v-card-title class="text-subtitle-2 py-1 grey lighten-3">{{ item.name }}</v-card-title>
                    <v-card-text class="mt-2 pb-2">
                        <div v-if="item.description && item.description != ''">{{ item.description }}</div>
                        <div v-if="item.stockLocation">Locatie: <strong>{{ item.stockLocation.name }}</strong></div>
                        <div v-if="item.quantity">Aantal: <strong>{{ item.quantity }}</strong></div>
                    </v-card-text>
                </v-card>
            </v-card>
    </div>
</template>
<script>
import eodTaskInfo from './eod-task-info.vue';

export default {
    name:"eod-task-overview",
    components: {
        'eod-task-info': eodTaskInfo
    },
    props: {
        value: String|Object,
        showTitle:{
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        task: null
    }),
    mounted(){
        this.loadTaskInfo();
    },
    watch: {
        value(val){
            this.loadTaskInfo();
        }
    },
    methods:{
        loadTaskInfo(){
            if(typeof this.value == 'string'){
                this.$eod.getById('task', this.value, ['id', 'name', 'description', 'project{name users{id username firstName lastName email attributes{extra_email phone_number}} locations{name streetAddress postalCode region country locality}}', 'service{name}', 'taskType{name}', 'company{name}', 'products{id name stockLocation{id name} quantity}', 'productTypes{id name stockLocation{id name} quantity}', 'storageObjects{id name key}']).then(response => {
                    this.task = response.data.data.task;
                });
            }else{
                this.task = this.value;
            }
            
        }
    }
}
</script>